import { IRunTracker, ISignal, IAmpfea,
         IStaticThermalAnalysis, IThermalSignals, IThermalStats, ITransitionAnalysis,
} from '@/interfaces/bcdb';
import { RunState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setRuns(state: RunState, payload: IRunTracker[]) {
        state.runs = payload;
    },
    setRun(state: RunState, payload: IRunTracker) {
        const runs = state.runs.filter((run: IRunTracker) => run.id !== payload.id);
        runs.push(payload);
        state.runs = runs;
    },
    setRunsCount(state: RunState, payload: number) {
        state.runs_count = payload;
    },

    setRunsOptions(state: RunState, payload:
        {page: number, itemsPerPage: number, sortBy: string, sortDesc: boolean}) {
        state.page = payload.page;
        state.perPage = payload.itemsPerPage;
        state.sortBy = payload.sortBy;
        state.sortDesc = payload.sortDesc;
    },
    setRunsPage(state: RunState, payload: number) {
        state.page = payload;
    },
    setRunsPerPage(state: RunState, payload: number) {
        state.perPage = payload;
    },
    setRunsSortBy(state: RunState, payload: string) {
        state.sortBy = payload;
    },
    setRunsSortDesc(state: RunState, payload: boolean) {
        state.sortDesc = payload;
    },

    setRunSummary(state: RunState, payload: string) {
        state.runSummary = payload;
    },

    setRunSignals(state: RunState, payload: ISignal[]) {
        console.log('setRunSignals: payload =', payload);
        state.signals = payload;
    },

    setRunAmpfeas(state: RunState, payload: IAmpfea[]) {
        console.log('setRunAmpfeas: payload =', payload);
        state.ampfeas = payload;
    },

    setRunStaticThermalAnalysis(state: RunState, payload: IStaticThermalAnalysis[]) {
        console.log('setRunStaticThermalAnalysis: payload =', payload);
        state.static_thermal_analysis = payload;
    },

    setRunThermalSignals(state: RunState, payload: IThermalSignals) {
        console.log('setRunThermalSignals: payload =', payload);
        state.thermal_signals = payload;
    },

    setRunThermalStats(state: RunState, payload: IThermalStats[]) {
        console.log('setRunThermalStats: payload =', payload);
        state.thermal_stats = payload;
    },

    setRunTransitionAnalysis(state: RunState, payload: ITransitionAnalysis[]) {
        console.log('setRunTransitionAnalysis: payload =', payload);
        state.transition_analysis = payload;
    },

    setArchiveTask(state: RunState, payload: {taskId: string, runIds: number[]}) {
        state.archiveTasks[payload.taskId] = payload.runIds;
    },
    removeArchiveTask(state: RunState, payload: string) {
        delete state.archiveTasks[payload];
    },
};

const { commit } = getStoreAccessors<RunState, State>('');

export const commitSetRun = commit(mutations.setRun);
export const commitSetRuns = commit(mutations.setRuns);
export const commitSetRunsCount = commit(mutations.setRunsCount);
export const commitSetRunsPage = commit(mutations.setRunsPage);
export const commitSetRunsPerPage = commit(mutations.setRunsPerPage);
export const commitSetRunsSortBy = commit(mutations.setRunsSortBy);
export const commitSetRunsSortDesc = commit(mutations.setRunsSortDesc);
export const commitSetRunsOptions = commit(mutations.setRunsOptions);

export const commitSetRunSummary = commit(mutations.setRunSummary);
export const commitSetRunSignals = commit(mutations.setRunSignals);
export const commitSetRunAmpfeas = commit(mutations.setRunAmpfeas);
export const commitSetRunStaticThermalAnalysis = commit(mutations.setRunStaticThermalAnalysis);
export const commitSetRunThermalSignals = commit(mutations.setRunThermalSignals);
export const commitSetRunThermalStats = commit(mutations.setRunThermalStats);
export const commitSetRunTransitionAnalysis = commit(mutations.setRunTransitionAnalysis);


export const commitSetArchiveTask = commit(mutations.setArchiveTask);
export const commitRemoveArchiveTask = commit(mutations.removeArchiveTask);
