<template> 
  <div v-if="errorMsg" class='run-alert'>
      No data found for at least one of the runs selected. QC Report cannot be generated.
  </div>
  <div v-else-if="loading">
    <v-snackbar v-model="loading">
      {{ qcReportMsg.message }}
    </v-snackbar>
  </div>
  <div v-else> 
    <v-container fill-height fluid>
    <v-row class="buttons">
      <v-col class="buttons">
        <v-btn
           rounded
           color="primary"
           @click="downloadReport()">
          Download Report
        </v-btn>
      </v-col></v-row>
      <v-row v-if="filename" class="description-text">{{filename}} downloaded</v-col></v-row>
   <!--<v-row v-show="getUserInfo" class="buttons">
      <v-col class="buttons">
        <v-btn
           rounded
           color="primary"
           style="float:left"
           @click="updateRunDescriptions()"
           @mouseenter="updatehover = true"
           @mouseleave="updatehover = false">
          Update Run Descriptions
        </v-btn>
        <div @mouseenter="updatehover = true" @mouseleave="updatehover = false"></div>
      <v-icon v-if="updated" color="green" line-height="1" vertical-align="bottom">mdi mdi-check-circle</v-icon>
    </v-col>
  </v-row>
  <v-row></v-row>
  <v-row v-if="updatehover" class="description-text">
    Permanently update the run descriptions of the selected experiments to "Buffer Only" and "50nM TgtC1C2"
  </v-row>-->
  <v-row class="hline"></v-row>
  <v-row>
    <v-col class="header-col">QC Report: Sign Off</v-col>
  </v-row>
  <v-row>
    <v-col cols=4>
      <div class="header-col">{{bufferStr}} Metrics</div>
    </v-col>
    <v-col class="condense">Null Count</v-col>
    <v-col class="condense">Min</v-col>
    <v-col class="condense">Max</v-col>
    <v-col class="condense">Mean</v-col>
    <v-col class="condense">Median</v-col>
  </v-row>

  <v-row>
    <v-col cols=4 class="header-col">
      NP Signal (FIO: ≥ {{np_signal}} fA)</span> 
        </v-tooltip>
    </v-col>
      <v-col :class = "[(qcData.np_signal_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.np_signal_null}}</v-col>
      <v-col :class = "[(qcData.np_signal_min < np_signal) ? 'results-fail' : 'format-cell']">
        {{qcData.np_signal_min}}</v-col>
      <v-col :class = "[(qcData.np_signal_max < np_signal) ? 'results-fail' : 'format-cell']">
        {{qcData.np_signal_max}}</v-col>
      <v-col :class = "[(qcData.np_signal_mean < np_signal) ? 'results-fail' : 'format-cell']">
        {{qcData.np_signal_mean}}</v-col>
      <v-col :class = "[(qcData.np_signal_median < np_signal) ? 'results-fail' : 'format-cell']">
        {{qcData.np_signal_median}}</v-col>
  </v-row>
  <v-row>
    <v-col cols=4 class="header-col">
      NP Background (FIO: ≤ {{np_background}} fA)</span>
        </v-tooltip> 
      </v-col>
    <v-col :class = "[(qcData.np_background_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.np_background_null}}</v-col>
    <v-col :class = "[(qcData.np_background_min > np_background) ? 'results-fail' : 'format-cell']">
      {{qcData.np_background_min}}</v-col>
    <v-col :class = "[(qcData.np_background_max > np_background) ? 'results-fail' : 'format-cell']">
      {{qcData.np_background_max}}</v-col>
    <v-col :class = "[(qcData.np_background_mean > np_background) ? 'results-fail' : 'format-cell']">
      {{qcData.np_background_mean}}</v-col>
    <v-col :class = "[(qcData.np_background_median > np_background) ? 'results-fail' : 'format-cell']">
      {{qcData.np_background_median}}</v-col>
  </v-row>
    <v-row>
    <v-col cols=4 class="header-col">
      NP SBR (FIO: ≥ {{np_sbr}})</span>
        </v-tooltip> 
      </v-col>
    <v-col :class = "[(qcData.np_sbr_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.np_sbr_null}}</v-col>
    <v-col :class = "[(qcData.np_sbr_min < np_sbr) ? 'results-fail' : 'format-cell']">
      {{qcData.np_sbr_min}}</v-col>
    <v-col :class = "[(qcData.np_sbr_max < np_sbr) ? 'results-fail' : 'format-cell']">
      {{qcData.np_sbr_max}}</v-col>
    <v-col :class = "[(qcData.np_sbr_mean < np_sbr) ? 'results-fail' : 'format-cell']">
      {{qcData.np_sbr_mean}}</v-col>
    <v-col :class = "[(qcData.np_sbr_median < np_sbr) ? 'results-fail' : 'format-cell']">
      {{qcData.np_sbr_median}}</v-col>
  </v-row>
    <v-row>
    <v-col cols=4 class="header-col">
      NP Fluorescence Residue (≥ {{np_stability}})</v-col>
    <v-col :class = "[(qcData.np_stability_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.np_stability_null}}</v-col>
    <v-col :class = "[(qcData.np_stability_min < np_stability) ? 'results-fail' : 'format-cell']">
      {{qcData.np_stability_min}}</v-col>
    <v-col :class = "[(qcData.np_stability_max < np_stability) ? 'results-fail' : 'format-cell']">
      {{qcData.np_stability_max}}</v-col>
    <v-col :class = "[(qcData.np_stability_mean < np_stability) ? 'results-fail' : 'format-cell']">
      {{qcData.np_stability_mean}}</v-col>
    <v-col :class = "[(qcData.np_stability_median < np_stability) ? 'results-fail' : 'format-cell']">
      {{qcData.np_stability_median}}</v-col>
  </v-row>

  <v-row>
    <v-col>
      <div class="header-col">{{tgtStr}} Metrics</div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols=4 class="header-col">
      HybC1 Pq (≥ {{hybc1_pq}})</v-col>
    <v-col :class = "[(qcData.hybc1_pq_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.hybc1_pq_null}}</v-col>
    <v-col :class = "[(qcData.hybc1_pq_min < hybc1_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc1_pq_min}}</v-col>
    <v-col :class = "[(qcData.hybc1_pq_max < hybc1_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc1_pq_max}}</v-col>
    <v-col :class = "[(qcData.hybc1_pq_mean < hybc1_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc1_pq_mean}}</v-col>
    <v-col :class = "[(qcData.hybc1_pq_median < hybc1_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc1_pq_median}}</v-col>
  </v-row>
  <v-row>
    <v-col cols=4 class="header-col">
      HybC2 Pq (≥ {{hybc2_pq}})</span>
        </v-tooltip> 
      </v-col>
    <v-col :class = "[(qcData.hybc2_pq_null > 0) ? 'results-fail' : 'format-cell']">
        {{qcData.hybc2_pq_null}}</v-col>
    <v-col :class="[(qcData.hybc2_pq_min < hybc2_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc2_pq_min}}</v-col>
    <v-col :class="[(qcData.hybc2_pq_max < hybc2_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc2_pq_max}}</v-col>
    <v-col :class="[(qcData.hybc2_pq_mean < hybc2_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc2_pq_mean}}</v-col>
    <v-col :class="[(qcData.hybc2_pq_median < hybc2_pq ) ? 'results-fail' : 'format-cell']">
      {{qcData.hybc2_pq_median}}</v-col>
  </v-row>
  <v-row>
    <hr>
  </v-row>
  <v-row>
    <v-col cols=6 class="header2">Amplitude Threshold of non-NP Probes: ≥ {{probe_sig}} fA</v-col>
    <v-col cols=3 class="header2">{{bufferStr}}</v-col>
    <v-col cols=3 class="header2">{{tgtStr}}</v-col>
  </v-row>
  <v-row> 
   <v-col cols=6 class="condense">Probes with median amplitude passing</v-col>
   <v-col cols=3 class="condense">{{buffer_median}}</v-col>
   <v-col cols=3 class="condense">{{tgt_median}}</v-col>
  </v-row>
  <v-row> 
   <v-col cols=6 class="condense">Probes with majority of amplitude passing</v-col>
   <v-col cols=3 class="condense">{{majority_str[0]}}</v-col>
   <v-col cols=3 class="condense">{{majority_str[1]}}</v-col>
  </v-row>
  <v-row>
   <v-col cols=6 class="condense">Probes with at least one replicate amplitude passing</v-col> 
   <v-col cols=3 class="condense">{{at_least_one_str[0]}}</v-col>
   <v-col cols=3 class="condense">{{at_least_one_str[1]}}</v-col>
  </v-row>
  <v-row>
   <v-col cols=6 class="condense">Non-Depletion Probes with median Pq ≥ {{pq}}</v-col> 
   <v-col cols=3 class="condense">{{pq_pass_str[0]}}</v-col>
   <v-col cols=3 class="condense">{{pq_pass_str[1]}}</v-col>
  </v-row>
  <v-row class="spacer"></v-row>
  <v-expansion-panels v-if="signals">
    <v-expansion-panel>
      <v-expansion-panel-header>
      <v-container>
        <v-row><v-col><div style="font-weight: bold;">Replicate Signals for {{bufferStr}}</div></v-col></v-row>
      </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="bufferSignalData"
          :dense="true"
          :disable-sort="true"
          :items-per-page="10"
          class="elevation-1"
        > 
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="(header, index) in headers" :key="header.value">
              <span :style="index > 1 ? flagSignal(item[header.value], item) : ''">
                {{ item[header.value] }}
              </span>
            </td>
          </tr>
      </template>
      </v-data-table>
    </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
      <v-container>
        <v-row><v-col><div style="font-weight: bold;">Replicate Signals for {{tgtStr}}</div></v-col></v-row>
      </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="tgtSignalData"
          :dense="true"
          :disable-sort="true"
          :items-per-page="10"
          class="elevation-1"
        > 
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="(header, index) in headers" :key="header.value">
              <span :style="index > 1 ? flagSignal(item[header.value], item) : ''">
                {{ item[header.value] }}
              </span>
            </td>
          </tr>
      </template>
      </v-data-table>
    </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-if="failedPq">
      <v-expansion-panel-header>
      <v-container>
        <v-row><v-col><div style="font-weight: bold;">Out of Spec Pq Results</div></v-col></v-row>
      </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="pqHeaders"
          :items="failedPqData"
          :dense="true"
          :disable-sort="true"
          :items-per-page="10"
          class="elevation-1"
        > 
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="header in pqHeaders" :key="header.value">{{ item[header.value] }}
            </td>
          </tr>
      </template>
      </v-data-table>
    </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</div>
</template>


<script lang="ts">
import { Component, Vue, ModelSync, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken, readHasAdminAccess, readUserProfile} from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';

import { IRunTracker, IRunTrackerExtended,IAmpfea, IQCResult, ISnackBars } from '@/interfaces/bcdb'; 
import buildQCReport from '@/scripts/to-excel/qcreport-to-excel';
import { api } from '@/api';;
import VSnackbars from "v-snackbars"
import eventBus from '@/eventBus';

@Component({
  components: {
    "v-snackbars": VSnackbars,
  },
  })

export default class QCReport extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedRuns!: IRunTrackerExtended[];
  public loading: boolean = true;
  public qcReportMsg: ISnackBars = {
    message: "Generating QC Report...",
    color: "primary",
    timeout:-1,
    enable:true,
    button:null,
    buttonTxt:null,
    infoButton: null
  };
  public bufferStr: string = '';
  public tgtStr: string = '';
  public buffer_id: number = 0;
  public tgt_id: number = 0;
  public errorMsg: boolean = false;
  public signals: boolean = false;
  public failedPq: boolean = false;
  public majority_str: string[] = [];
  public at_least_one_str: string[] = [];
  public pq_pass_str: string[] = [];
  public bufferSignalData: Object[] = [];
  public tgtSignalData: Object[] = [];
  public failedPqData: Object[] = [];
  public headers: Object[] = [];
  public pqHeaders: Object[] = [];
  public updatehover: boolean = false;
  public updated: boolean = false;
  public filename: string = '';
  public username: string = '';
  public useremail: string = '';

  // threshold values
  public np_sbr: number = 0.5;
  public np_stability: number = 0.2;
  public np_background: number = 2000;
  public np_signal: number = 1000;
  public hybc1_pq: number = 70;
  public hybc2_pq: number = 85;
  public pq: number = 5;
  public probe_sig: number = 500;

  public buffer_median: string = '';
  public tgt_median: string = '';
  public median_signals: number[] = [];
  public qcData: IQCResult = {
    valid: false,
    majority_count: [0,0],
    at_least_one: [0,0],
    total_probes: 0,
    np_stability_null: 0,
    np_stability_min: 0,
    np_stability_max: 0,
    np_stability_mean: 0,
    np_stability_median: 0,
    np_sbr_null: 0,
    np_sbr_min: 0,
    np_sbr_max: 0,
    np_sbr_mean: 0,
    np_sbr_median: 0,
    np_signal_min: 0,
    np_signal_max: 0,
    np_signal_mean: 0,
    np_signal_median: 0,
    np_signal_null: 0,
    np_background_null: 0,
    np_background_min: 0,
    np_background_max: 0,
    np_background_mean: 0,
    np_background_median: 0,
    hybc1_pq_min: 0,
    hybc1_pq_max: 0,
    hybc1_pq_mean: 0,
    hybc1_pq_median: 0,
    hybc1_pq_null: 0,
    hybc2_pq_null: 0,
    hybc2_pq_min: 0,
    hybc2_pq_max: 0,
    hybc2_pq_mean: 0,
    hybc2_pq_median: 0,
    pq_fail_count: [0,0],
    failed_pq_probes: {},
    replicate_signals: {},
  };
  private qcReportHandler: (e: any) => void = this.handleQcReport.bind(this);

  public mounted() {
    //console.log('!! mounted selectedRuns:', this.selectedRuns, this.bufferStr, this.tgtStr);
    this.getUserInfo;
    this.getRunIDs();
    eventBus.on('generateQC', this.qcReportHandler)
  }

  public beforeDestroy() {
    eventBus.off('generateQC', this.qcReportHandler)
  }

  private handleQcReport(e: any) {
    console.log("emit received");
    this.updated = false;
    this.getRunIDs();
  }

  public get getUserInfo() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        this.username = userProfile.full_name;
      } 
      this.useremail = userProfile.email;
      return(userProfile.is_superuser)
    }
    return false;
  }

  public getRunIDs() {
    const regex = new RegExp("run[0-9a-z]+", "gi");
    for (var j = 0; j < this.selectedRuns.length; j++) {
      let desc = this.selectedRuns[j].run_description;
      let expname = this.selectedRuns[j].experiment;
      let g2bname = expname.match(regex);
      if (desc.includes("Buffer"))  {
        this.buffer_id = this.selectedRuns[j].id;
        if (g2bname) {
          this.bufferStr = g2bname[0];  
        } else {
        this.bufferStr = expname;
        } 
      } else {
        this.tgt_id = this.selectedRuns[j].id;
        if (g2bname) {
          this.tgtStr = g2bname[0];  
        } else {
        this.tgtStr = expname;
        } 
      } 
    }
    if (this.buffer_id > 0 && this.tgt_id > 0) {
      this.loadQCReportData();
    }
  } 
  
  public updateRunDescriptions() {
    for (var j = 0; j < this.selectedRuns.length; j++) {
      let run_id = this.selectedRuns[j].id;
      let rt_obj = this.selectedRuns[j];
      api.updateRunDescriptions(readToken(this.$store), run_id, rt_obj).then((response) => {
        if (response.data){
          this.updated = true;
        }
      })
    }
  }

  public async loadQCReportData() {
    //api call
    try {
      await api.qcReport(readToken(this.$store), this.buffer_id, this.tgt_id).then((response) => {
        this.qcData = response.data;
      });
    } catch (e) {
      this.errorMsg = true;
      this.qcReportMsg.message = 'No data found for at least one of the runs selected. QC Report cannot be generated.';
      this.qcReportMsg.color = 'red';
    }
    if (this.qcData.valid) {
      for (var j = 0; j < this.qcData.majority_count.length; j++) {
        this.majority_str.push(`${this.qcData.majority_count[j]} of ${this.qcData.total_probes}`);
        this.at_least_one_str.push(`${this.qcData.at_least_one[j]} of ${this.qcData.total_probes}`);
        this.pq_pass_str.push(`${this.qcData.pq_fail_count[j]}`);
      }
      //console.log(this.qcData.replicate_signals);
      this.formatPqData();
    } else {
      this.errorMsg = true;      
      this.qcReportMsg.message = 'No data found for at least one of the runs selected. QC Report cannot be generated.';
      this.qcReportMsg.color = 'red';
    }
  }

  public formatPqData() {
    var expNames = {
    "BufferOnly": this.bufferStr,
    "TgtC1C2": this.tgtStr,
    };
    const repCols: string[] = []; 
    let counter = 0;
    this.pqHeaders[0] = { text: 'Probe', value: 'Probe'};
    this.pqHeaders[1] = { text: 'Experiment', value: 'Exp'};
    this.pqHeaders[2] = { text: 'Median', value: 'Median'};

    for (const probe in this.qcData.failed_pq_probes) {
      let ordername = probe.split("|")[0];
      let exptype = probe.split("|")[1];
      this.failedPqData[counter] = {'Probe': ordername, 'Exp': expNames[exptype]};
      let pqArr = this.qcData.failed_pq_probes[probe];
      for (var j = 0; j < pqArr.length; j++) {
        let rep = j;
        if (rep > 0) {
          let col = "Rep_" + rep;
          let exists = repCols.includes(col);
          if (!exists) {
            repCols.push(col);
          }
          this.failedPqData[counter][col] = pqArr[j];
        } else {
          this.failedPqData[counter]['Median'] = pqArr[j];
        }
      }
      counter++;
    }
    for (var j = 0; j < repCols.length; j++ ) {
      let col = repCols[j];
      this.pqHeaders[j+3] = { text: col, value: col };
    }
    if (this.failedPqData.length > 0) {
      this.failedPq = true;
    }
    this.formatSignalData();
  } 
  public formatSignalData() {
    let eCounter = -1;
    let tCounter = -1;
    let buffer_count = 0;
    let tgt_count = 0;
    const repCols: string[] = [];

    this.headers[0] = { text: 'Probe', value: 'Probe'};
    this.headers[1] = { text: 'N', value: 'N'};
    this.headers[2] = { text: 'Median', value: 'Median'};

    for (const probe in this.qcData.replicate_signals) {
      let ordername = probe.split("|")[0];
      let exptype = probe.split("|")[1];
      let median = this.qcData.replicate_signals[probe][0];
      let ncount = this.qcData.replicate_signals[probe].length - 1;
      if (exptype === "BufferOnly") {
        eCounter++;
        if (median >= this.probe_sig) {
          buffer_count++;
        }
        this.bufferSignalData[eCounter] = {'Probe': ordername, 'N': ncount, 'Median': median};
      } else {
        tCounter++;
        if (median >= this.probe_sig) {
          tgt_count++;
        }
        this.tgtSignalData[tCounter] = {'Probe': ordername, 'N': ncount, 'Median': median};
      }
 
      for (let ix in this.qcData.replicate_signals[probe]) {
        let val = this.qcData.replicate_signals[probe][ix];
        let rep = Number(ix);
        if (rep > 0) {
          let col = "Rep_" + rep;
          let exists = repCols.includes(col);
          if (!exists) {
            repCols.push(col);
          }
          if (exptype === "BufferOnly") {
            this.bufferSignalData[eCounter][col] = val;
          } else {
            this.tgtSignalData[tCounter][col] = val; 
          }
        }
      }
    }
    for (var j = 0; j < repCols.length; j++ ) {
      let col = repCols[j];
      this.headers[j+3] = { text: col, value: col };
      for (var k = 0; k < this.bufferSignalData.length; k++) {
       if (!this.bufferSignalData[k][col]) {
        this.bufferSignalData[k][col] = null;
       }
      }
    }
    this.buffer_median = `${buffer_count} of ${this.qcData.total_probes}`;
    this.tgt_median = `${tgt_count} of ${this.qcData.total_probes}`;
    this.median_signals = [buffer_count, tgt_count];
    this.signals = true;
    this.loading = false;
  }

  public flagSignal(value: any, item: any) {
    if (item) {

      const isNP = item.Probe === "NP";
      const threshold = isNP ? this.np_signal : this.probe_sig;
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      if (typeof numValue === 'number' &&  !isNaN(numValue) && numValue < threshold) {
        return 'color: red;';     
      }
    }
    return '';    
  }

  public async downloadReport() {
    const maxLength = 31;
    let sheet1 = this.bufferStr.concat(" Replicate Signal");
    let sheet2 = this.tgtStr.concat(" Replicate Signal");
    if (sheet1.length > maxLength) {
      sheet1 = this.bufferStr.concat(" Signals");
      if (sheet1.length > maxLength) {
        sheet1 = this.bufferStr.substring(0, maxLength);
      }
    }
    if (sheet2.length > maxLength) {
      sheet2 = this.tgtStr.concat(" Signals");
      if (sheet2.length > maxLength) {
        sheet2 = this.tgtStr.substring(0, maxLength);
      }
    }    
    let sheetNames = ["QC Report Sign Off", sheet1, sheet2]; 
    let tableHeaders = [
        { text: 'Metric', value: 'Metric'},
        { text: 'Passing Threshold', value: 'Passing Threshold'},
        { text: 'Null Count', value: 'Null Count'},
        { text: 'Min', value: 'Min'},
        { text: 'Max', value: 'Max'},
        { text: 'Median', value: 'Median'},
        { text: 'Mean', value: 'Mean'},
      ];
    let tableItems: object[] = [];
    let metricNames = [this.bufferStr.concat(' NP Signal'), this.bufferStr.concat(' NP Background'), 
      this.bufferStr.concat(' NP SBR'), this.bufferStr.concat(' NP Fluorescence Residue'), 
      this.tgtStr.concat(' HybC1 Pq'), this.tgtStr.concat(' HybC2 Pq',)];
    let results = {0: [this.qcData.np_signal_null, this.qcData.np_signal_min, this.qcData.np_signal_max, this.qcData.np_signal_median, this.qcData.np_signal_mean],
      1: [this.qcData.np_background_null, this.qcData.np_background_min, this.qcData.np_background_max, this.qcData.np_background_median, this.qcData.np_background_mean],
      2: [this.qcData.np_sbr_null, this.qcData.np_sbr_min, this.qcData.np_sbr_max, this.qcData.np_sbr_median, this.qcData.np_sbr_mean],
      3: [this.qcData.np_stability_null, this.qcData.np_stability_min, this.qcData.np_stability_max, this.qcData.np_stability_median, this.qcData.np_stability_mean],
      4: [this.qcData.hybc1_pq_null, this.qcData.hybc1_pq_min, this.qcData.hybc1_pq_max, this.qcData.hybc1_pq_median, this.qcData.hybc1_pq_mean],
      5: [this.qcData.hybc2_pq_null, this.qcData.hybc2_pq_min, this.qcData.hybc2_pq_max, this.qcData.hybc2_pq_median, this.qcData.hybc2_pq_mean],
      };
    let thresholds = [this.np_signal, this.np_background, this.np_sbr, this.np_stability, this.hybc1_pq, this.hybc2_pq];

    let probesData = [this.median_signals, this.qcData.majority_count, this.qcData.at_least_one,this.qcData.pq_fail_count];

    for (var j = 0; j < metricNames.length; j++ ) {
      tableItems[j] = {'Metric': metricNames[j],  'Null Count': results[j][0], 'Min': results[j][1], 'Max': results[j][2], 'Median': results[j][3], 'Mean': results[j][4]};
    }
    this.filename = buildQCReport(this.username, this.useremail, tableItems, tableHeaders.map((h:any)=>h.value), thresholds, this.headers.map((h:any)=>h.value), this.bufferSignalData, this.tgtSignalData, this.qcData.total_probes, probesData, this.pqHeaders.map((h:any)=>h.value), this.failedPqData, sheetNames);
  }
}

</script>

<style scoped>
.run-alert {
  padding: 12px;
  color: red;

}.results-fail {
  background-color: #F6C9CE;
  color: red;
  border-block: double;
  border-right: double;
  border-left: double;
  display: grid;
  text-align: center;
  align-content: center;
  padding: 0px;
  margin: 0px;
}
.format-cell {
  border-block: double;
  border-right: double;
  border-left: double;
  display: grid;
  text-align: center;
  align-content: center;
  padding: 0px;
  margin: 0px;
}
.description-text {
  margin: 4px;
  opacity: 0.7;
  font-size: 11pt;
  font-weight: 400;
  line-height: 1em;
  padding-bottom: 4px;
}
.condense {
  padding: 0px; 
  margin: 0px;
  display: grid;
  align-content: center;
  text-align: center;
  font-size: medium;
}
.buttons {
  padding: 2px; 
  margin-bottom: 18px;
}
.header2 {
  font-size: medium;
  padding-top: 8px;
  margin-top: 8px;
  text-decoration: underline;
  text-align: center;
}
.header-col {
  font-size: medium;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  word-wrap: break-word;
}
.spacer {
  padding-top: 24px;
  margin-top: 24px;
}
.hline {
  border: 1px solid lightgrey; 
  margin-bottom:8px;
}
.buttons {
  padding-left: 0px;
  margin: 0px 0px 6px 0px;
  display:inline-block;
  text-align: left;
  vertical-align: middle;
}
</style>