import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IProduct,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getProduct(token: string) {
    return axios.get<IProduct[]>(`${apiUrl}/api/v1/product/`, authHeaders(token));
  },
  async getInternalProducts(token: string, internal:boolean) {
    return axios.get<IProduct[]>(`${apiUrl}/api/v1/productinternal/?internal=${internal}`, authHeaders(token));
  },
  
};
