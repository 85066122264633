import Router from 'vue-router';

import RouterComponent from '@/components/RouterComponent.vue';

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: { breadCrumb: 'Home' },
      component: () => import(/* webpackChunkName: "start" */ '@/views/main/Start.vue'),
      children: [
        {
          path: 'login',
          meta: { breadCrumb: 'Login' },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ '@/views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
        },
        {
          path: 'main',
          meta: { breadCrumb: 'Main' },
          component: () => import(/* webpackChunkName: "main" */ '@/views/biochip/Main.vue'),
          children: [
            {
              path: 'dashboard',
              meta: { breadCrumb: 'Dashboard' },
              component: () => import(/* webpackChunkName: "main-dashboard" */ '@/views/biochip/Dashboard.vue'),
            },
            {
              path: 'files',
              meta: { breadCrumb: 'BIOCHIP Files' },
              component: () => import(/* webpackChunkName: "main-files" */ '@/views/biochip/Files.vue'),
            },
            {
              path: 'assays/:id',
              name: 'main-assay-view',
              meta: { breadCrumb: 'View Assay' },
              component: () => import(/* webpackChunkName: "main-assay-view" */ '@/views/main/Assay.vue'),
            },
            {
              path: 'wafer-dashboard',
              meta: { breadCrumb: 'Wafer Dashboard' },
              component: () => import(/* webpackChunkName: "main-wafer-dashboard" */ '@/views/biochip/WaferDashboard.vue'),
            },
            {
              path: 'preclinical-dashboard',
              meta: { breadCrumb: 'Pre-clinical Dashboard' },
              component: () => import(/* webpackChunkName: "main-preclinical-dashboard" */ '@/views/biochip/PreclinicalDashboard.vue'),
            },
            {
              path: 'runs',
              meta: { breadCrumb: 'Runs' },
              component: RouterComponent,
              redirect: 'runs/all',
              children: [
                {
                  path: 'all',
                  meta: { breadCrumb: 'All Runs' },
                  component: () => import(/* webpackChunkName: "runs" */ '@/views/biochip/Runs.vue'),
                },
                {
                  path: ':id',
                  name: 'biochip-run-view',
                  meta: { breadCrumb: 'View Run' },
                  component: () => import(/* webpackChunkName: "run" */ '@/views/biochip/Run.vue'),
                },
              ],
            },
            {
              path: 'probes',
              meta: { breadCrumb: 'Probes' },
              component: RouterComponent,
              redirect: 'probes/all',
              children: [
                {
                  path: 'all',
                  meta: { breadCrumb: 'All Probes' },
                  component: () => import(/* webpackChunkName: "probes" */ '@/views/biochip/Probes.vue'),
                },
                {
                  path: ':id',
                  name: 'biochip-probe-detail',
                  meta: { breadCrumb: 'Probe Detail' },
                  component: () => import(/* webpackChunkName: "probe" */ '@/views/biochip/Probe.vue'),
                },
              ],
            },
            {
              path: 'callouts',
              meta: { breadCrumb: 'Callouts' },
              component: RouterComponent,
              redirect: 'callouts/all',
              children: [
                {
                  path: 'all',
                  name: 'biochip-callouts-all',
                  meta: { breadCrumb: 'All' },
                  component: () => import(/* webpackChunkName: "callouts" */ '@/views/biochip/Callouts.vue'),
                },
                {
                  path: 'all?id=:id',
                  name: 'biochip-callouts-detail',
                  meta: { breadCrumb: 'All' },
                  component: () => import(/* webpackChunkName: "callouts" */ '@/views/biochip/Callouts.vue'),
                },
              ],
            },
            {
              path: 'samples',
              meta: { breadCrumb: 'Sample Data' },
              name: 'biochip-samples-view',
              component: () => import('@/views/biochip/Samples.vue'),
            },
            {
              path: 'print-runs',
              meta: { breadCrumb: 'Print Runs' },
              component: RouterComponent,
              redirect: 'print-runs/all',
              children: [
                {
                  path: 'all',
                  meta: { breadCrumb: 'All' },
                  component: () => import(/* webpackChunkName: "print-runs" */ '@/views/biochip/ArrayPrintRuns.vue'),
                },
                {
                  path: 'create',
                  meta: { breadCrumb: 'Create' },
                  name: 'biochip-print-run-create',
                  component: () => import(
                    /* webpackChunkName: "biochip-print-run-create" */ '@/views/biochip/ArrayPrintRun.vue'),
                },
                {
                  path: 'edit/:id',
                  meta: { breadCrumb: '' },
                  name: 'biochip-print-run-edit',
                  component: () => import(
                    /* webpackChunkName: "biochip-print-run-edit" */ '@/views/biochip/ArrayPrintRun.vue'),
                },
                {
                  path: ':id',
                  name: 'biochip-print-run-view',
                  meta: { breadCrumb: 'View' },
                  component: () => import(/* webpackChunkName: "print-runs" */ '@/views/biochip/ArrayPrintRun.vue'),
                },
              ],
            },
            {
              path: 'array-templates',
              meta: { breadCrumb: 'Array Templates' },
              component: RouterComponent,
              redirect: 'array-templates/all',
              children: [
                {
                  path: 'all',
                  meta: { breadCrumb: 'All' },
                  component: () => import('@/views/biochip/ArrayTemplates.vue'),
                },
                {
                  path: 'create',
                  meta: { breadCrumb: 'Create' },
                  name: 'biochip-array-template-create',
                  component: () => import(
                    /* webpackChunkName: "biochip-array-template-create" */ '@/views/biochip/ArrayTemplate.vue'),
                },
                {
                  path: ':id',
                  name: 'biochip-array-template-view',
                  meta: { breadCrumb: 'Template' },
                  component: () => import('@/views/biochip/ArrayTemplate.vue'),
                },
              ],
            },
            {
              path: 'profile',
              meta: { breadCrumb: 'Profile' },
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ '@/views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ '@/views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ '@/views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              meta: { breadCrumb: 'Admin' },
              component: () => import(/* webpackChunkName: "main-admin" */ '@/views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  meta: { breadCrumb: 'Users' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ '@/views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  meta: { breadCrumb: 'Edit User' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  meta: { breadCrumb: 'Create User' },
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'releases',
                  meta: { breadCrumb: 'Releases' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-releases" */ '@/views/main/admin/AdminReleases.vue'),
                },
                {
                  path: 'comparatortestresults',
                  meta: { breadCrumb: 'Comparator Test Results' },
                  name: 'biochip-comparator-test-results-view',
                  component: () => import('@/views/biochip/ComparatorTestResults.vue'),
                },
                {
                  path: 'clinicaldata',
                  meta: { breadCrumb: 'Clinical Data' },
                  component: RouterComponent,
                  redirect: 'clinicaldata/all',
                  children: [
                    {
                      path: 'all',
                      meta: { breadCrumb: 'All' },
                      component: () => import('@/views/biochip/ClinicalData.vue'),
                    },
                    {
                      path: 'all?',
                      name: 'biochip-clinical-data-detail',
                      meta: { breadCrumb: 'All' },
                      component: () => import('@/views/biochip/ClinicalData.vue'),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
