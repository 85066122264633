import runsApi from './runs';
import probesApi from './probes';
import arrayPrintRunsApi from './array_print_runs';
import arrayTemplatesApi from './array_templates';
import ruleSetsApi from './rule_sets';
import probeRulesApi from './probe_rules';
import panelRulesApi from './panel_rules';
import calloutsApi from './callouts';
import calloutRulesApi from './callout_rules';
import samplesApi from './samples';
import clinicalDataApi from './clinical_data';
import formulasApi from './formulas';
import workersApi from './workers';
import wafersApi from './wafers';
import vendorCalloutsApi from './vendor_callouts';
import calloutAliasesApi from './callout_aliases';
import reportsApi from './reports';
import comparatorTestResultsApi from './comparator_test_results';
import processStepsApi from './process_steps';
import diesApi from './dies';
import instrumentAPi from './instruments';
import product from './product';
import product_comparison_rules from './product_comparison_rules';
import assay from './assay';

export default {
  ...runsApi,
  ...probesApi,
  ...arrayPrintRunsApi,
  ...arrayTemplatesApi,
  ...ruleSetsApi,
  ...probeRulesApi,
  ...panelRulesApi,
  ...calloutsApi,
  ...calloutRulesApi,
  ...samplesApi,
  ...clinicalDataApi,
  ...formulasApi,
  ...workersApi,
  ...wafersApi,
  ...vendorCalloutsApi,
  ...calloutAliasesApi,
  ...reportsApi,
  ...comparatorTestResultsApi,
  ...processStepsApi,
  ...diesApi,
  ...instrumentAPi,
  ...product,
  ...product_comparison_rules,
  ...assay
};


