<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar flat height="96px">
        <v-toolbar-title>
          <v-row>
            <v-col cols="3">
              Callouts:
            </v-col>
            <v-col style="margin-left: 10px;">
              <v-autocomplete
                v-model="selectedProduct"
                :items="internalProducts"
                auto-select-first
                item-text="name"
                :menu-props="{
                  contentClass: 'autocomplete-overlay'
                }"
                @change="loadCallouts()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="userRole" v-on:click="addCallout()">New Callout</v-btn>
      </v-toolbar>
    </v-card>

    <v-data-table
      :headers="calloutHeaders"
      :items="callouts"
      item-key="name"
      :dense="true"
      class="elevation-1"
      :sort-by="sortBy"
      v-model="selectedCallouts"
      v-on:item-selected="itemSelected"
      v-on:click:row="rowClicked"
      disable-pagination
      hide-default-footer
      single-select
      fixed-header
      height="75vh"
    > 
      <template v-slot:body="props">
        <tbody>
          <tr v-for="item in props.items" @click='rowClicked(item, null)'>
            <td v-for="(header, index) in calloutHeaders">
              <span v-if="index">
                <tr v-for="(call, index) in displayItem(item,header)" :key="index">
                  <td>
                    {{ call }}
                    <hr v-if="index+1 < displayItem(item, header).length" style="border-top: 1px dashed;">
                  </td>
                </tr>
              </span>
              <span v-else>{{ item.name }}</span>
            </td>
          </tr>
        </tbody>
      </template>

      
      <template v-for="(vendorPanel, idx) in vendorPanels" v-slot:item.vendor_[idx]="{ header, item }">
        <span>{{ displayItem(item,header) }}</span>
      </template>  

    </v-data-table>

    <v-card v-if="calloutDetail">
      <v-btn v-if="calloutDetail" v-on:click="removeDetail">Close Detail</v-btn>
    </v-card>

    <v-card v-html="calloutDetail" v-if="calloutDetail" class="callout-detail">
    </v-card>

    <RightPanel :showPanel="selectedCallouts.length > 0 && userRole">
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{selectedCallouts[0] ? selectedCallouts[0].name : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form @keyup.enter="submit" v-model="valid" ref="form" @submit.prevent="" lazy-validation>
            <p class="subtitle-1">Callout Details</p>
            <v-text-field
              label="Name"
              type="text"
              v-model="calloutName"
              v-validate="'required|uniqueCalloutName'"
              data-vv-name="name"
              :error-messages="errors.collect('name')"
              required>
            </v-text-field>
              <v-row 
                v-for="(vcallout,idx) in vendorCallouts"
                :key='vcallout.id'
                no-gutters
                class="vendor-callout-row">
                <v-col cols="5">
                  <v-select
                    :items="vendorPanels"
                    label="Panel"
                    dense
                    :single-line="true"
                    v-model="vcallout.panel_name"
                    v-validate="'uniquePanelName'"
                    :data-vv-name="panel_name(idx)"
                    :error-messages="errors.collect(panel_name(idx))"
                    :class="vcallout.delete ? 'vendor-callout-panel-name disabled' : 'vendor-callout-panel-name'"
                    :disabled="vcallout.delete"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Name"
                    v-model="vcallout.name"
                    dense
                    single-line
                    hide-details="true"
                    class="vendor-callout-name"
                    :disabled="vcallout.delete">
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="vendor-callout-action">
                  <v-btn
                    small icon
                    :color="vcallout.delete ? 'gray' : 'error'"
                    v-on:click="removeVendorCallout(vcallout)">
                    <v-icon>{{ vcallout.delete ? "undo" : "remove_circle"}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="vendorCallouts.length < vendorPanels.length"
                no-gutters
                class="vendor-callout-row">
                <v-col>
                  <v-btn
                    small icon
                    color="success"
                    v-on:click="addVendorCallout()">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            <p class="subtitle-1">Aliases</p>
              <v-row 
                v-for="(alias,idx) in calloutAliases"
                :key='idx'
                no-gutters
                class="callout-alias-row">
                <v-col cols="11">
                  <v-text-field
                    label="Alias"
                    v-model="alias.name"
                    single-line
                    v-validate="{ required: true, uniqueCalloutAlias: alias }"
                    :data-vv-name="alias_name(idx)"
                    :error-messages="errors.collect(alias_name(idx))"
                    class="callout-alias-name"
                    :disabled="alias.delete">
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="callout-alias-action">
                  <v-btn
                    small icon
                    :color="alias.delete ? 'gray' : 'error'"
                    v-on:click="removeCalloutAlias(alias)">
                    <v-icon>{{ alias.delete ? "undo" : "remove_circle"}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="callout-alias-row">
                <v-col>
                  <v-btn
                    small icon
                    color="success"
                    v-on:click="addCalloutAlias()">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="selectedCallouts.length > 0 && selectedCallouts[0].id != 0" @click="deleteCallout" :disabled="!valid">Delete</v-btn>
          <v-btn color="primary" @click.prevent="submit" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>

    </RightPanel>
  </v-container>
</template>



<script lang="ts">
import RightPanel from '@/components/RightPanel.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile, readToken } from '@/store/main/getters';
import { api } from '@/api';
import { ICallout, IVendorCallout, ICalloutAlias, IProduct, ICalloutExtended } from '@/interfaces/bcdb';
import { dispatchCheckApiError } from '@/store/main/actions';
import { Validator } from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import { alphabetically } from '@/scripts/support-functions';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);


@Component({
  components: {
    RightPanel,
    VueSweetalert2,
  },
})
export default class Callouts extends Vue {

  public initialCalloutHeaders: object[] = [
        { text: 'Name', value: 'name' },
      ];
  public product: IProduct[] = []
  public internalProducts: IProduct[] = []
  public selectedProduct: string = 'ERP'

  public calloutHeaders: object[] = [];
  public callouts: ICalloutExtended[] = [];
  public vendorPanels: string[] = [];
  public selectedCallouts: ICalloutExtended[] = [];
  public calloutDetail: boolean = false;
  public sortBy = 'name';
  public valid = true;
  public calloutName: string = '';
  public vendorCallouts: Array<any> = [];
  public calloutAliases: Array<any> = [];
  public allAliases: ICalloutAlias[] = [];

  public displayItem(item, header) {
    return(item.product_aliases.filter((pa) => pa.product_id == header.value).map((pa => pa.name)));
  }

  public panel_name(i) { return "panel_name_" + i }
  public alias_name(i) { return "alias_name_" + i }

  get userRole() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      return(userProfile.is_superuser)
    }
    return(false)
  }

  public async loadCallouts() {
    this.selectedCallouts = [];
    const response = await api.getCalloutMapByProduct(readToken(this.$store), this.selectedProduct);
    if (response) {
      this.callouts = response.data;
      const id = parseInt(this.$router.currentRoute.params.id)
      if( id ) {
        const row = this.callouts.find((o) => o.id == id)
        if( row ) {
          this.rowClicked(row, null)
        } else {
          console.log('no matching callout found');
        }
      }
      const product_ids = this.callouts.flatMap((c)=>c.product_aliases).map((c)=>c.product_id)
      const products = this.product.filter((p)=> product_ids.includes(p.id))
      this.calloutHeaders = [...this.initialCalloutHeaders]
      products.forEach((product,i) => {
        this.calloutHeaders.push({ text:product.name, value:product.id })
      });
    }
  }

  public async loadProduct() {
    api.getProduct(readToken(this.$store)).then((response) => {
      this.product = response.data
      this.product = this.product.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      this.internalProducts =this.product.filter((p)=>p.internal)
    });
  }

  public async loadVendorCallouts() {
    api.getVendorCalloutPanels(readToken(this.$store)).then((response) => {
      this.vendorPanels = response.data

      // add each vendor to the list of headers
      this.calloutHeaders = [...this.initialCalloutHeaders]
      this.vendorPanels.forEach((panel,i) => {
        this.calloutHeaders.push({ text:panel, value:`vendor_${i}` })
      });
    });
  }

  public async loadCalloutAliases() {
    api.getCalloutAliases(readToken(this.$store)).then((response) => {
      this.allAliases = response.data
    });
  }

  public itemSelected(selected) {
    this.calloutName = this.selectedCallouts[0].name;
    this.vendorCallouts = JSON.parse(JSON.stringify(this.selectedCallouts[0].vendor_callouts)); // deep copy
  }

  public rowClicked(row, slot) {
    // if the same row is clicked, toggle the panel
    if( this.selectedCallouts.length > 0 && this.selectedCallouts[0] == row ) {
      this.selectedCallouts = [];
    } else {
      this.selectedCallouts = [row];
      this.calloutName = this.selectedCallouts[0].name;
      this.vendorCallouts = JSON.parse(JSON.stringify(this.selectedCallouts[0].vendor_callouts)); // deep copy
      this.calloutAliases = JSON.parse(JSON.stringify(this.selectedCallouts[0].aliases)); // deep copy
    }
  }

  public addCallout() {
    const callout: ICalloutExtended = {
      id: 0,    // 0 indicates potientially new callout
      name: '',
      active:true,
      product_id: null,
      created_on: null,
      created_by_id: null,
      modified_by_id: null,
      probes: [],
      vendor_callouts: [],
      aliases: [],
      product: [],
      product_aliases: []
    };
    this.calloutName = '';
    this.vendorCallouts = [];
    this.calloutAliases = [];
    this.selectedCallouts = [callout]
  }

  public async deleteCallout() {
    const result = await this.$swal({
      title: 'Warning!',
      text: 'Are you sure you want to delete this callout?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      allowOutsideClick: false,
      focusCancel: true,
      reverseButtons: true,
      customClass: { container: 'confirm' },
    });
    if( result.isConfirmed ) {
      api.deleteCallout(readToken(this.$store), this.selectedCallouts[0].id)
      .then(response => {
        this.selectedCallouts = []
        this.calloutAliases = []
        this.refresh();
      })
      .catch((error) => {
        console.log('ERROR: deleting callout', error);
        dispatchCheckApiError(this.$store, error);
        this.$swal({
          title: 'Error',
          html: 'There was a problem deleting this callout.<br>'+error.response.data.detail,
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          customClass: { container: 'confirm' },
        });
        console.log('ERROR: deleting callout HERE');
      });
    }

  }

  public removeVendorCallout(vcallout) {
    if( "add" in vcallout ) {
      this.vendorCallouts = this.vendorCallouts.filter((co) => co != vcallout)
    } else {
      vcallout.delete = !vcallout.delete;
      this.$forceUpdate();    // if the name isn't changed, it doesn't know to update
    }
  }

  public addVendorCallout() {
    if( this.vendorCallouts.length >= this.vendorPanels.length ) {
      return;
    }

    const usedPanels = this.vendorCallouts.map((co: IVendorCallout) => co.panel_name);
    const unUsedPanels = this.vendorPanels.filter((p) => !usedPanels.includes(p));
    this.vendorCallouts.push({ name:'', panel_name:unUsedPanels[0], add:usedPanels.length+1, callout_id:this.selectedCallouts[0].id })
  }

  public removeCalloutAlias(alias) {
    if( "add" in alias ) {
      this.calloutAliases = this.calloutAliases.filter((al) => al != alias)
    } else {
      alias.delete = !alias.delete;
      this.$validator.reset();
      this.$forceUpdate();    // if the name isn't changed, it doesn't know to update
    }
  }

  public addCalloutAlias() {
    this.calloutAliases.push({ name:'', add:this.calloutAliases.length+1, callout_id:this.selectedCallouts[0].id })
  }

  public mounted() {
    this.refresh();

    Validator.extend('uniqueCalloutName', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => !this.callouts.find((o) => o.name === value && o.id !== this.selectedCallouts[0].id),
    });
    Validator.extend('uniquePanelName', {
      getMessage: (field) => 'This field is not unique.',
      validate: (value) => {
        const found = this.vendorCallouts.filter((vc) => {
          return(vc.panel_name === value && vc.delete != true)
        });
        return(found.length < 2);
      },
    });
    Validator.extend('uniqueCalloutAlias', {
      getMessage: (field) => 'This field is not unique.',
      validate: (value, alias) => {
        if( (alias as any).delete == true ) {
          return(true);
        }
        const found = this.allAliases.find((al) => {
          return(al.name.toLowerCase() === value.toLowerCase() && al.id != (alias as any).id)
        });
        return(found ? false : true);
      },
    });
  }

  public refresh() {
    this.loadCallouts();
    this.loadProduct();
    // this.loadVendorCallouts();
    this.loadCalloutAliases();
  }

  public close() {
    this.selectedCallouts = [];
  }
  public async submit(event) {
    if (await this.$validator.validateAll()) {
      this.selectedCallouts[0].name = this.calloutName.trim();
      if( this.selectedCallouts[0].id == 0 ) {    // if id is not set, then it is a new callout
        const response = await api.createCallout(readToken(this.$store), this.selectedCallouts[0]);
        if( response ) {
          this.vendorCallouts.forEach((vc) => {   // update the vendor callouts with this new callout_id
            vc.callout_id = response.data.id;
          });
          this.calloutAliases.forEach((al) => {   // update the callout aliases with this new callout_id
            al.callout_id = response.data.id;
          });
        }
      } else {
        await api.updateCallout(readToken(this.$store), this.selectedCallouts[0].id, this.selectedCallouts[0])
      }

      // create/update/delete the vendor callouts
      for await (const vc of this.vendorCallouts) {
        if( vc.delete ) {
          await api.deleteVendorCallout(readToken(this.$store), vc.id);
        } else if( vc.add ) {
          await api.createVendorCallout(readToken(this.$store), vc);
        } else if( vc.id ) {
          const current_vc = this.selectedCallouts[0].vendor_callouts.find((c) => c.id == vc.id);
          if( vc.name != current_vc?.name || vc.panel_name != current_vc?.panel_name ) {
            await api.updateVendorCallout(readToken(this.$store), vc.id, vc);
          }
        }
      }
      // create/update/delete the callout aliases
      for await (const al of this.calloutAliases) {
        if( al.delete ) {
          await api.deleteCalloutAlias(readToken(this.$store), al.id);
        } else if( al.add ) {
          await api.createCalloutAlias(readToken(this.$store), al);
        } else if( al.id ) {
          const current_al = this.selectedCallouts[0].aliases.find((a) => a.id == al.id);
          if( al.name != current_al?.name || al.name != current_al?.name ) {
            await api.updateCalloutAlias(readToken(this.$store), al.id, al);
          }
        }
      }
  
      this.refresh();
    }
    else {
      console.log('not valid');
    }

  }

  public trimStringList(str) {
    // remove whitespace and trailing comma
    str = str?.trim().replace(/,$/, '');
    // trim each in the list
    str = str?.split(',').map((n) => n.trim()).join(', ');
    return(str)
  }
}
</script>

<style>
.confirm {
  font-family: Roboto, sans-serif !important;
}
</style>

<style scoped>
.v-data-table >>> .col-name {
  min-width: 8em;
}
.v-data-table >>> .col-callout-type {
  min-width: 14em;
}

.vendor-callout-row {
  text-align: left;
  font-size: small;
  border-bottom: 1px solid #EEE;
  height: 44px;
}
.vendor-callout-panel-name {
  font-size: inherit;
  font-weight: bold;
  padding-right: 5px;
}
.vendor-callout-name {
  font-size: inherit;
}
.vendor-callout-action, .callout-alias-action {
  padding-left: 8px;
}
.vendor-callout-action > button {
  margin-left: 10px;
}

.callout-alias-row {
  text-align: left;
  font-size: small;
  border-bottom: 1px solid #EEE;
  height: 55px;
}
.callout-alias-name {
  padding-top: 0;
}

.disabled {
  color: #BBB;
}

.autocomplete-overlay .v-list-item-title {
  font-size: 1.5rem !important;
}

</style>
