<template>
    <v-container fluid>
      <v-card class="ma-3 pa-3">
        <v-card-title primary-title class="d-flex justify-space-between align-center">
          <v-row :align="'center'">
            <v-col class="headline primary--text"> Pre-clinical Dashboard </v-col>
            <v-col sm="4" md="3" cols="2">
              <v-menu offset-y :close-on-click="true" v-if="greetedUserRole">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" 
                  v-bind="attrs" v-on="on" class="mx-1" 
                  :disabled="dataPpaNpaResults.length===0"
                  :loading="loadingPpaNpaDownload">Download</v-btn>
                </template>
                <v-list>
                  <v-list-item><v-list-item-title v-if="dataPpaNpaResults.length && greetedUserRole" @click="downloadOptionSelected('DLL')">Download DLL</v-list-item-title></v-list-item>
                  <v-list-item><v-list-item-title v-if="dataPpaNpaResults.length" @click="downloadOptionSelected('REPORT')">Download PPA/NPA Report</v-list-item-title></v-list-item>
                </v-list>
              </v-menu>
              <v-btn color="primary"
                  class="mx-1"
                  :disabled="dataPpaNpaResults.length===0"
                  :loading="loadingPpaNpaDownload"
                  @click="downloadOptionSelected('REPORT')"
                  v-else>
                  Download Report
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <PreclinicalFilters
          v-model="preClinicalFilters"
        ></PreclinicalFilters>
        
        <v-card-text>
            <SiteComparatorTable 
                v-model="filteredTestResults" 
                :selectedRuns="selectedExperiments"
                :selectedErrors="runErrors"
                :dataGroupBy="dataGroupBy"
                @selection-change-comparator-table="applySelected($event)">
            </SiteComparatorTable>

          <v-row>
            <v-col class="no_padding">
              <div id="dateChart"></div>
            </v-col>
            <v-col class="no_padding">
              <div id="ndRateChart"></div>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col class="no_padding">
              <div id="patientSexChart"></div>
            </v-col>
            <v-col class="no_padding">
              <div id="patientAgeChart"></div>
            </v-col>
          </v-row>
    
          <!-- PPA NPA SUMMARY TABLE -->
          <ComparatorSummaryTable
            :panel_counts="panel_counts"
            :testResults="filteredTestResults"
            :dataPpaNpaResults="dataPpaNpaResults"
          ></ComparatorSummaryTable>
          
          <v-row>
            <v-col>
              <div class="description-text">
                <ul>
                  <li>
                      <router-link
                      :title="`Callout Relationships`"
                      :to="{ name: 'biochip-callouts-all', path: 'all'}"
                      > Callout Vendor Relationships </router-link> are used to make comparisons against comparator panels.
                  </li>
                  <li>
                    When targets have separate CEP subtype callouts but a comparator 
                    reports a grouped callout, the confusion matrix reports are 
                    reduced to a single grouped call.
                    <ul>
                      <li>Viewing tabular performance results for these callouts 
                        require the selection of supplementary CEP target in the 
                        “Callouts” drop down menu.
                      </li>
                      <i>*Note: There might be slight differences between the numbers 
                        reported in the table and what is shown on the graphs where each 
                        call out is separately represented on the x-axis.
                      </i>
                    </ul>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="ppaNpaGraphCols" class="no_padding">
              <div id="fnChart"></div>
            </v-col>
            <v-col :cols="ppaNpaGraphCols" class="no_padding">
              <div id="ppaChart"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="ppaNpaGraphCols" class="no_padding">
              <div id="fpChart"></div>
            </v-col>
            <v-col :cols="ppaNpaGraphCols" class="no_padding">
              <div id="npaChart"></div>
            </v-col>
          </v-row>

          <v-dialog v-model="dllDialog" max-width="800">
            <DllFilter v-model="dllDialog" :selectedRuns="selectedExperiments" v-if="dllDialog"></DllFilter>
          </v-dialog>
        </v-card-text>
    
      </v-card>

      <v-snackbar v-model="loadingPpaNpa" :color="messageColor" :timeout="loadingMessage==='Processing...'?-1:2500">
        {{ loadingMessage }}&nbsp;
        <v-progress-circular indeterminate v-if="loadingMessage==='Processing...'"></v-progress-circular>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="loadingPpaNpa = false" >Close</v-btn>
        </template>
      </v-snackbar>

    </v-container>
  </template>
  
  <script lang="ts">
  import CompositeReflexRules from '@/components/clinical/CompositeReflexRules.vue'
  import PreclinicalFilters from '@/components/filters/PreclinicalFilters.vue';
  import SiteComparatorTable from '@/components/clinical/SiteComparatorTable.vue';
  import ComparatorSummaryTable from '@/components/clinical/ComparatorSummaryTable.vue';
  import DllFilter from '@/components/filters/DllFilter.vue';
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { VMenu, colors } from 'vuetify/lib';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IRunTracker, IRunErrors, ICallout, IPpaNpa, IInternalCallouts, ISample, 
    IComparatorMap, IClinicalRuntracker, IComparatorMatrix } from '@/interfaces/bcdb';
  import { IFilter } from '@/interfaces';
  import { Socket } from 'vue-socket.io-extended';
  
  import { subWeeks, startOfToday, parseISO, format, formatISO, isThisMinute, isThisHour } from 'date-fns';
  import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
  import buildObjectToExcel from '@/scripts/to-excel/object-to-excel';
  import {getUniqueField, deepClone, alphabetically} from '@/scripts/support-functions';
  import { samples } from '@/scripts/constants'

  import {createPanelCounts, getGroupCounts, formatSocData} from '@/scripts/clinical-data-helpers';
  import buildPpaNpaExcel from '@/scripts/to-excel/ppa-npa-to-excel'
  import {calcNPA, calcPPA} from '@/scripts/calculations'

  import eventBus from '@/eventBus';
  
  import * as Plotly from 'plotly.js';
  import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';
  import { toDateWithOptions } from 'date-fns-tz/fp';
  // import { FunctionalComponentMountOptions } from '@vue/test-utils';
  import { internals } from '@azure/msal-browser';
  
  
  @Component({
    components: {
      CompositeReflexRules,
      PreclinicalFilters,
      SiteComparatorTable,
      ComparatorSummaryTable,
      DllFilter,
    },
  })
  export default class PreclinicalDashboard extends Vue {
    // TODO: determine how to query contrived v. non contrived
    public preClinicalFilters: {dateRange:string[] , filters:IFilter[], ppaNpaParams:{
        includeReport:boolean, callouts:number[], comparators:string[], rules:string[]}} = {dateRange:[] , filters:[], ppaNpaParams:{
            includeReport:true, callouts:[], comparators:[], rules:[]}}
    public filters: IFilter[] = [
        {field: 'experiment_type', table: 'run_trackers', operator: 'in', value: samples['Combined'].types}
    ]
    
    public sampleNames: string[] =['Contrived Samples', 'Clinical Samples', 'Combined Samples']
  
    public filteredTestResults: IClinicalRuntracker[] = [];
    public selectedExperiments: IClinicalRuntracker[] = [];
    public deSelectedExperiments: IClinicalRuntracker[] = [];
    public testResults: IClinicalRuntracker[] = [];
    public testsND: IClinicalRuntracker[] = [];
    public testGroupBy: string[] = ['Callout']
    public ppaNpaGraphCols: number = 6
  
    public selectedTestTypeCount = 0;
    public selectedInstCount = 0;
  
    public viewDialog = false;
    public selectable = false;
    public dllDialog = false;

    public loadingPpaNpaDownload = false;
    public loadingPpaNpa = false;
    public loadingMessage = '';
    public messageColor = ''
    
    public startUp = true;
    public clearablePlotlyIDs = ['fnChart', 'ppaChart', 'fpChart', 'npaChart']
    public ndStrings = ['INVALID','ERROR','NO RESULT']
  
    public cicQueryParams = {
          run_ids: [0],
          callout_id: 186, 
          result_str: 'FAIL'
        }
  
    public runErrors: IRunErrors[] = [];
  
    public cicFails: number[] = [];
    public dataPpaNpaResults: IPpaNpa[] = [];
    public dataProjects: string = 'ERP';
    public dataGroupBy: string = 'Callout'
    
    public panel_counts: Record<string, {fp:number,fn:number,tp:number,tn:number,undetermined:number, experiments:Set<string>}> = {};
  
    public ppaWorkerId: string = ''
  
    public graphHeight = 400;
    public graphConfig = {
      displaylogo: false, 
      responsive: true,
      editable: true
    };
    public runsChartLayout = {
        autosize: true,
        legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2},
        height: this.graphHeight,
        title: 'Experiment Counts By Site',
        yaxis: {title: '', automargin: true, showgrid:false},
        xaxis: {automargin: true, title: 'Count', showgrid:true},
      } as Plotly.Layout;
  
    public ndBarChartLayout = {
        legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2},
        height: this.graphHeight,
        title: 'Cumulative ND Rate',
        yaxis: {title: 'ND%',
          automargin: true,
          range: [0, 102]},
        xaxis: {automargin: true, title: '', type:'category'},
        shapes: [
            {
                type: 'line',
                x0: 0,
                y0: 5,
                y1:5,
                x1:1,
                xref: 'paper',
                line:{
                    color: 'rgb(255, 0, 0)',
                    width: 2,
                    dash:'dash'
                }
            }
        ]
      } as Plotly.Layout;

    public ageChartLayout = {
        height: this.graphHeight,
        title: 'Clinical Sample: Patient Age (Bin=10 years)',
        yaxis: {title: 'Count', automargin: true},
        legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2},
        xaxis: {automargin: true, title: 'Age (years)'}
      } as Plotly.Layout;
  
    public sexChartLayout = {
        height: this.graphHeight,
        title: 'Clinical Sample: Patient Sex',
        yaxis: {title: 'Count', automargin: true},
        legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2},
        xaxis: {automargin: true, type:'category', title: ''}
      } as Plotly.Layout;
  
    public ppaChartLayout = {
        autosize:true,
        title: '',
        yaxis: {title: 'Count',
            automargin: true},
        yaxis2: {
            automargin: true,
            title: '',
            overlaying: 'y',
            showgrid: false,
            side: 'right',
            range: [0, 102]
        },
        legend: {orientation:'h', xanchor:'center', x:0.5, y:1.02, yanchor:"bottom"},
        xaxis: {automargin: true, type:'category', title: ''},
    } as Plotly.Layout;
  

    @Watch('preClinicalFilters', {deep: true})
    public async updateFilters() {
      this.loadTestResults()
    }

    get greetedUser() {
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        if (userProfile.full_name) {
          return userProfile.full_name;
        } else {
          return userProfile.email;
        }
      }
    }

    get greetedUserRole() {
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        return(userProfile.is_superuser)
      } else {
        return(false)
      }
    }
  
    @Socket('calcPpaNpa-complete')
    public calcPpaNpaComplete(payload) {
      if (payload.task_id === this.ppaWorkerId) {
        this.loadingPpaNpa = true
        this.loadingMessage = 'Completed'
        this.messageColor = 'green'

        this.dataPpaNpaResults = [];
        payload.results.forEach(r => {
          const parsedArray: IPpaNpa = JSON.parse(r);
          this.dataPpaNpaResults.push(parsedArray);
        });
        
        if( this.dataGroupBy === 'Date' ){
          //Sort dates in ascending order
          this.dataPpaNpaResults.sort((a: IPpaNpa, b: IPpaNpa) => new Date(a.group).getTime() - new Date(b.group).getTime());
        } else {
          this.dataPpaNpaResults.sort((a: IPpaNpa, b: IPpaNpa) => a.group.localeCompare(b.group));
        }
        this.ppaNpaGraphCols = this.dataPpaNpaResults.length>10?12:6
        this.panel_counts = createPanelCounts(this.dataPpaNpaResults, this.dataGroupBy, this.preClinicalFilters.ppaNpaParams.rules)

        this.updatePPANPACharts();
        eventBus.emit('updatePpaNpaComparatorTable', this.dataPpaNpaResults)
      }
    }
  
    @Socket('calcPpaNpa-error')
    public calcPpaNpaError(payload) {
      if (payload.task_id === this.ppaWorkerId) {
        this.loadingPpaNpa = true
        this.loadingMessage = 'Completed'
        this.messageColor = 'green'
        console.log('NPA PPA error')
        console.log(payload)
        this.dataPpaNpaResults = []
        this.panel_counts = {}
        this.updatePPANPACharts()
        eventBus.emit('updatePpaNpaComparatorTable', [])
      }
    }

    public async downloadOptionSelected(downloadOption:string){
      if( downloadOption === 'DLL'){
        this.dllDialog = true
      } else if( downloadOption === 'REPORT' ){
        this.loadingPpaNpaDownload = true
        this.downloadPpaNpa()
      }
    }
    public async downloadPpaNpa() {
      buildPpaNpaExcel(this.dataPpaNpaResults, this.selectedExperiments)
      this.loadingPpaNpaDownload = false
    }
  
    public async closeSelected() {
      if(this.selectable){
        this.selectedExperiments = this.filteredTestResults.filter(e => !this.deSelectedExperiments.some(de => de.RunTracker.id === e.RunTracker.id));
      }
      this.viewDialog=false
    }
  
    public async applySelected(selected:IClinicalRuntracker[]) {
        this.selectedExperiments = selected
        const notInSelected = this.deSelectedExperiments.filter(e => !this.selectedExperiments.some(s => s.RunTracker.id===e.RunTracker.id))
        const notInFiltered = this.filteredTestResults.filter(t => !this.selectedExperiments.some(e => e.RunTracker.id === t.RunTracker.id))
        const subset = [...notInSelected, ...notInFiltered];
        this.deSelectedExperiments = this.testResults.filter(t => subset.some(e => e.RunTracker.id === t.RunTracker.id))

        await this.loadFilteredResults()
        this.updateCharts()
    
        this.viewDialog=false
    }
    
    public async loadTestResults() {
      const response = await api.postRunsFromRangeClinical(readToken(this.$store), this.preClinicalFilters.dateRange, this.preClinicalFilters.filters);
      if (response) {
        this.testResults = response.data;
        await this.loadFilteredResults()
        this.startUp = false;
        this.updateCharts()
      }
    }
  
    public async loadFilteredResults(){
        //Filter RunTracker to specific exp types
        this.filteredTestResults = deepClone(this.testResults)
    
        this.deSelectedExperiments = this.deSelectedExperiments.filter(e => this.testResults.some(f => f.RunTracker.id === e.RunTracker.id));
        this.selectedExperiments = this.filteredTestResults.filter(e => !this.deSelectedExperiments.some(de => de.RunTracker.id === e.RunTracker.id));
        this.selectedTestTypeCount = (new Set(this.selectedExperiments.map(s => s.RunTracker.experiment_type))).size
        this.selectedInstCount = (new Set(this.selectedExperiments.map(s => s.rig))).size
        const run_ids: number[] = this.selectedExperiments.map((r)=> r.RunTracker.id)

        //Get all the errors associated with runs
        await api.getRunErrors(readToken(this.$store), run_ids).then((response_errors) => {
            this.runErrors = response_errors.data
        }).catch((error) => {
            console.log(error)
        });
    
        //Get exps with CIC FAIL
        this.cicQueryParams.run_ids = run_ids
        await api.getCalloutRunsEqual(readToken(this.$store), this.cicQueryParams).then((respons_CIC) => {
            this.cicFails = respons_CIC.data;
            this.selectedExperiments=this.formatNDRunList(this.selectedExperiments)
        }).catch((error) => {
            console.log(error)
        });
    
        this.testsND = this.selectedExperiments.filter((r)=> (r.RunTracker.failures !== null && this.ndStrings.includes(r.RunTracker.failures)) || this.cicFails.includes(r.RunTracker.id))
        this.testsND=this.formatNDRunList(this.testsND)

        this.requestPpaNpa()
        
        this.getSocData(); 
    }

    public async requestPpaNpa(){
      if(!this.preClinicalFilters.ppaNpaParams.includeReport){
        this.dataPpaNpaResults = []
        this.panel_counts = {}
        this.updatePPANPACharts()
        eventBus.emit('updatePpaNpaComparatorTable', this.dataPpaNpaResults)
        return
      }
      const run_ids: number[] = this.selectedExperiments.map((r)=> r.RunTracker.id)
      const nd_ids = this.testsND.map((t) => t.RunTracker.id)
      const params = {
          runIds: run_ids.filter((r) => !nd_ids.includes(r)),
          calloutIds: this.preClinicalFilters.ppaNpaParams.callouts,
          comparators: this.preClinicalFilters.ppaNpaParams.comparators,
          composite_rules: this.preClinicalFilters.ppaNpaParams.rules,
          groupBy: this.dataGroupBy,
      }
      if (this.ppaWorkerId !== ''){
          await api.terminateWorker(readToken(this.$store), this.ppaWorkerId).then((response) =>{
        }).catch((error) => {
          console.log('worker termination error', error)
        });
      }

      await api.getPpaNpa(readToken(this.$store), params).then((response) =>{
        this.loadingPpaNpa = true
        this.loadingMessage = 'Processing...'
        this.messageColor = ''
        this.ppaWorkerId = response.data
      }).catch((error) => {
          console.log(error)
          this.loadingPpaNpa = true
          this.loadingMessage = error
          this.messageColor = 'red'
          eventBus.emit('updatePpaNpaComparatorTable', [])
          this.clearPlotly(this.clearablePlotlyIDs);
      });
      eventBus.emit('siteComparatorTable');
    }
  
    public async expTypeChanged() {
      await this.loadFilteredResults()
      this.updateCharts()
    }
  
    public formatNDRunList(results: IClinicalRuntracker[]): IClinicalRuntracker[] {
      results.forEach((r) => {
        if ( this.cicFails.includes(r.RunTracker.id) ) {
          r.RunTracker.failures = 'CIC FAIL'
        }
      })
      return results
    }

    public async getSocData() {
      const clin = samples["Clinical"].types;
      // this.loadingComparatorTable = true;
      const sampleIDs = this.selectedExperiments.filter(
        exp => exp.ref && exp.soc_test === undefined && clin.some(sample => exp.ref.includes(sample))).map(exp => exp.ref);
      if (sampleIDs.length > 0) {
        await api.getClinicalDataPairs(readToken(this.$store), sampleIDs).then((response) => {
            const pairs: Record<string, any>[] = response.data;
            const socData = formatSocData(sampleIDs, pairs);
            Object.keys(socData).forEach(ref => {
              const index = this.selectedExperiments.findIndex(exp => exp.ref === ref);
              if (index !== -1) {
                this.selectedExperiments[index].soc_result = socData[ref].soc_result.join(",");
                this.selectedExperiments[index].soc_test = socData[ref].soc_test.join(",");
                this.selectedExperiments[index].soc_collection = socData[ref].soc_collection;
                this.selectedExperiments[index].soc_test_date = socData[ref].soc_test_date;
              }
            });
        });
      }
      // fill in rest of soc_test and soc_result
      for (var idx in this.selectedExperiments) {
        if (this.selectedExperiments[idx].soc_test === undefined) {
          this.selectedExperiments[idx].soc_test = "-";
        }
        if (this.selectedExperiments[idx].soc_result === undefined) {
          this.selectedExperiments[idx].soc_result = "-";
        }
      }
      // this.loadingComparatorTable = false;
    }  

    public async mounted() {
      // await this.loadTestResults(this.dateRange);
      // this.updateCharts();
    }

    public beforeDestroy() {
      if (this.ppaWorkerId !== ''){
          api.terminateWorker(readToken(this.$store), this.ppaWorkerId).then((response) =>{
        }).catch((error) => {
          console.log('worker termination error', error)
        });
      }
    }
  
    public async updateCharts() {
      // await this.loadTestResults(this.dateRange);
      this.updateRunsChart() 
      this.updateNDChart()
      // this.updatePatientAgeChart()
      // this.updatePatientSexChart()
    }
  
    public async updateRunsChart() {
        let data:any = []
        let uniqueSites = getUniqueField(this.selectedExperiments,'site_name')
        uniqueSites = uniqueSites.sort(alphabetically(false))

        for (const [key, value] of Object.entries(samples)) {
            const y: string[] = []
            const x: number[] =[]
            for( let site=0; site<uniqueSites.length; site++) {
                const filteredRuns = this.selectedExperiments.filter((r)=> value.types.includes(r.RunTracker.experiment_type) && r.site_name===uniqueSites[site])
                y.push(uniqueSites[site]===null?'N/A':uniqueSites[site])
                x.push(filteredRuns.length)
            }
            const name = key=='Contrived'?'Control/Contrived':key
            const trace = {
                    x: x as Plotly.Datum[],
                    y: y as Plotly.Datum[],
                    type: 'bar',
                    name: name,
                    orientation: 'h',
                    marker: {
                    color: value.color
                    }
                };
            data.push(trace)
        }
        Plotly.newPlot('dateChart', data, this.runsChartLayout, this.graphConfig);
    }
  
    public async updateNDChart() {
        let data:any = []
        let sortedDates: any = this.selectedExperiments.sort((a, b) => new Date(a.RunTracker.date_of_run).getTime() - new Date(b.RunTracker.date_of_run).getTime());
        sortedDates = getUniqueField(sortedDates, 'RunTracker.date_of_run')
        // const failureLabels = ['CIC Fail', 'Invalid', 'Error/No Results']
        for (const [key, value] of Object.entries(samples)) {
            const filteredRuns = this.selectedExperiments.filter((r)=> value.types.includes(r.RunTracker.experiment_type))
            let runningSumND = 0
            let runningSumTotal = 0
            const x: string[] = [];
            const y: number[] = [];
            for (const d of sortedDates) {
                const date: string = d
                let nd = filteredRuns.filter((r)=>r.RunTracker.date_of_run === date && r.RunTracker.failures!==null).length
                runningSumTotal += filteredRuns.filter((r)=>r.RunTracker.date_of_run === date).length
                runningSumND += nd
                nd = Math.round(runningSumND/runningSumTotal*100)
                x.push(date)
                y.push(Number.isNaN(nd)?0:nd)
            }
            const name = key=='Contrived'?'Control/Contrived':key
            const trace = {
                x: x as Plotly.Datum[],
                y: y as Plotly.Datum[],
                type: 'bar',
                name: name,
                marker: {
                  color: value.color
                }
            };
            data.push(trace)
        }
      Plotly.newPlot('ndRateChart', data, this.ndBarChartLayout, this.graphConfig);
    }
  
    public async updatePatientSexChart(){
        const totalPatientsMale = this.selectedExperiments.filter((r)=> ['M','Male'].includes(r.subject_sex)).length
        const totalPatientsFemale = this.selectedExperiments.filter((r)=> ['F','Female'].includes(r.subject_sex)).length
        const totalPatientsUnknown = this.selectedExperiments.filter((r)=> r.subject_sex===null && samples['Clinical'].types.includes(r.RunTracker.experiment_type)).length
        const data:any = [{
            values: [totalPatientsMale, totalPatientsFemale, totalPatientsUnknown],
            labels: ['Male','Female', 'Not Listed'],
            type: 'pie',
            marker: {
                colors: ['#1f77b4', '#F89880', '#808080']
            }
        }];
        Plotly.newPlot('patientSexChart', data, this.sexChartLayout, this.graphConfig);
    }

    public async updatePatientAgeChart(){
        const patientAge = this.selectedExperiments.map((r)=> r.subject_age)
        const trace = {
            x: patientAge as Plotly.Datum[],
            name: 'patientAge',
            autobinx: false,
            histnorm: "count",
            marker: {
                line: {
                width: 1
                }
            },
            type: "histogram", 
            xbins: {
                size: 10,
            }
            };
        const data:any = [trace]
        Plotly.newPlot('patientAgeChart', data, this.ageChartLayout, this.graphConfig);
    }

    public buildObject(keys:string[], values:string[]) {
      const obj = {};
      keys.forEach((key, index) => {
          obj[key] = values[index % values.length];
      });
      return obj;
    }
  
    public async updatePPANPACharts() {
      const symbols = ["circle","square","diamond","triangle-up","star"]
      const comparator_symbols = this.buildObject(this.preClinicalFilters.ppaNpaParams.comparators, symbols)

      const panel_group_count = getGroupCounts(this.dataPpaNpaResults)
  
      let dataFN: Plotly.Data[] = []
      let dataPPA: Plotly.Data[] = []
      let dataFP: Plotly.Data[] = []
      let dataNPA: Plotly.Data[] = []
  
      for (const key in panel_group_count) {
        dataFN.push({
          x: panel_group_count[key].group as Plotly.Datum[],
          y: panel_group_count[key].fn as Plotly.Datum[],
          mode:'markers',
          type: 'scatter',
          name: key,
          marker:{
            symbol: Array(panel_group_count[key].fn.length).fill(comparator_symbols[key]),
            size:12
          }
        })
        dataPPA.push({
          x: panel_group_count[key].group as Plotly.Datum[],
          y: panel_group_count[key].ppa as Plotly.Datum[],
          mode:'markers',
          type: 'scatter',
          name: key,
          marker:{
            symbol: Array(panel_group_count[key].fn.length).fill(comparator_symbols[key]),
            size:12
          }
        })
        dataFP.push({
          x: panel_group_count[key].group as Plotly.Datum[],
          y: panel_group_count[key].fp as Plotly.Datum[],
          mode:'markers',
          type: 'scatter',
          name: key,
          marker:{
            symbol: Array(panel_group_count[key].fn.length).fill(comparator_symbols[key]),
            size:12
          }
        })
        dataNPA.push({
          x: panel_group_count[key].group as Plotly.Datum[],
          y: panel_group_count[key].npa as Plotly.Datum[],
          mode:'markers',
          type: 'scatter',
          name: key,
          marker:{
            symbol: Array(panel_group_count[key].fn.length).fill(comparator_symbols[key]),
            size:12
          }
        })
      }
  
      let fnLayout = deepClone(this.ppaChartLayout)
      fnLayout.title = 'FN'
      let ppaLayout = deepClone(this.ppaChartLayout)
      ppaLayout.title = 'PPA'
      ppaLayout.yaxis.title = '%'
      let fpLayout = deepClone(this.ppaChartLayout)
      fpLayout.title = 'FP'
      let npaLayout = deepClone(this.ppaChartLayout)
      npaLayout.title = 'NPA'
      npaLayout.yaxis.title = '%'
  
      Plotly.newPlot('fnChart', dataFN, fnLayout, this.graphConfig);
      Plotly.newPlot('ppaChart', dataPPA, ppaLayout, this.graphConfig);
      Plotly.newPlot('fpChart', dataFP, fpLayout, this.graphConfig);
      Plotly.newPlot('npaChart', dataNPA, npaLayout, this.graphConfig);
      //Need to specify the resize to fill container. Otherwise it will only resize when window resizes
      Plotly.Plots.resize('fnChart');
      Plotly.Plots.resize('ppaChart');
      Plotly.Plots.resize('fpChart');
      Plotly.Plots.resize('npaChart');
    }
  
    public async clearPlotly( plotlyIds: string[]) {
      plotlyIds.forEach((i) => {
        const graphContainer = document.getElementById(i);
        if(graphContainer!== null) {
          graphContainer.innerHTML = '';
        }
      });
    }
  }
  </script>
  
  <style scoped>
  .duration {
    width: 7em;
    display: inline-block;
  }
  .exp_types {
    width: 19em;
    justify-content: center;
    display: inline-block;
  }
  .no_padding {
    padding: 0px;
  }
  
  .viewButton {
    margin: 10px;
  }

  .description-text {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    opacity: 0.6;
    font-size: small;
    font-weight: 400;
  }
  .description-text, .v-row {
    line-height: 1.2em;
  }
  .no-gutters {
    margin-top: -12px;
    margin-bottom: -24px;
    padding-top: -12px;
    padding-bottom: -24px;
  }
  .custom-offset {
    margin-left: calc(80% - 10px); /* Adjust the percentage and px value as needed */
  }

  .comparator-experiment-col{ max-width: 7em; min-width: 6em;}
  .comparator-col{ max-width: 5em; }
  .comparator-total-callout-col{ max-width: 5em; }
  .comparator-ppa-col{ max-width: 3em; }
  .comparator-npa-col{ max-width: 3em; }
  .comparator-stats-col{ max-width: 5em; }
  
  .v-data-table >>> .col-experiment { max-width: 16em; padding: 0; font-size:small; }
  .v-data-table >>> .col-run { width: 3em; padding: 0; font-size:small; }
  .v-data-table >>> .col-experiment-type { max-width: 4em; padding: 0; font-size:small; }
  .v-data-table >>> .col-rig { max-width: 4em; padding: 0; font-size:small; }
  .v-data-table >>> .col-failures { min-width: 4em; padding: 0; font-size:small; }
  .v-data-table >>> .col-descriprion { max-width: 10em; padding: 0; font-size:small; }
  .v-data-table >>> .col-date { min-width: 7em; padding: 0; font-size:small; }
  .v-data-table >>> .col-sicore-error-code { max-width: 8em; padding: 0; font-size:small; }
  .v-data-table >>> .col-sicore-error { width: 4em; padding: 0; font-size:small; }
  </style>