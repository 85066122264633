<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar flat height="96px">
        <v-toolbar-title>
          Pre-Clinical Data
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div style='width: 300px;'>
          <file-pond
            name="upload_file"
            ref="pond"
            label-idle="Upload Pre-Clinical Data"
            v-bind:allow-multiple="false"
            v-bind:allow-revert="false"
            v-bind:allow-remove="true"
            accepted-file-types="text/csv"
            :server="serverClinicalDataUploadOptions"
            v-on:processfile="handleFilePondProcess"
            v-on:addfilestart="handleFilePondAddFileStart"
          />
        </div>
      </v-toolbar>
    </v-card>

    <ClinicalDataFilterPicker
      v-model="selectedFilters">
    </ClinicalDataFilterPicker>

    <ClinicalDataList
      :filters="selectedFilters"
      v-model="selectedClinicalData"
      @click:row="rowClicked(item)"
      :rowclick="rowClicked"
    >
    </ClinicalDataList>

    <RightPanel :showPanel="selectedClinicalData.length > 0">
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{selectedClinicalData.length > 0 ? selectedClinicalData[0].sample_ref : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="subtitle-1">Clinical Data Details</p>

          <v-container class="clinical-data-detail">
            <v-row
              v-for="pair in (selectedClinicalData.length > 0 ? selectedClinicalData[0].pairs : [])"
              :key="pair.id"
              class="detail-row"
              no-gutters
            >
              <v-col class="detail-key">{{ pair.key.replace(/_/g,' ').toLowerCase() }}</v-col>
              <v-col class="detail-value">{{ pair.value }}</v-col>
            </v-row>
          </v-container>
 
        </v-card-text>
      </v-card>
    </RightPanel>

    <v-snackbar v-model="showMessage" timeout="-1">
      {{ message }}&nbsp;
      <v-progress-circular indeterminate></v-progress-circular>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="showMessage = false" >Close</v-btn>
        </template>

    </v-snackbar>

  </v-container>
</template>



<script lang="ts">
import RightPanel from '@/components/RightPanel.vue';
import ClinicalDataList from '@/components/ClinicalDataList.vue';
import ClinicalDataFilterPicker from '@/components/ClinicalDataFilterPicker.vue';

import { apiUrl } from '@/env';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IClinicalData } from '@/interfaces/bcdb';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);

@Component({
  components: {
    FilePond,
    RightPanel,
    ClinicalDataList,
    ClinicalDataFilterPicker,
    VueSweetalert2,
  },
})
export default class ClinicalDataView extends Vue {

  public selectedFilters: IFilter[] = [];
  public showMessage = false;
  public message: string = '';

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };

  public selectedClinicalData: IClinicalData[] = [];

  public rowClicked(row, slot) {
    this.selectedClinicalData = [row]
  }

  public close() {
    this.selectedClinicalData = [];
  }

  public mounted() {
  }

  public handleFilePondAddFileStart() {
    this.showMessage = true;
    this.message = "Uploading...";
  }

  public handleFilePondProcess(error, file) {
    this.message = "Processing...";

    // Remove the file after 4 seconds.
    // setTimeout(() => this.$refs.pond.removeFile(), 4000) # TODO: This will not compile
    if( error ) {
      console.log('handleFilePondProcess: error=', error);
      if (error && error.code === 415) {
        this.message = "Error: " + error;
      }
    }
  }

  public get serverClinicalDataUploadOptions() {
    return {
      url: `${apiUrl}/api/v1/clinical_data/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
    };
  }

  @Socket('upload-clinical-data-complete')
  public async onUploadComplete(info) {
    this.showMessage = false;
    const summary = JSON.parse(info.summary)
    const result = await this.$swal({
      html: `
        <div class="title">Success</div>
        <div class="text-left">
          <table class='w-100 small'><tbody>
            <tr><td colspan="2">Rows processed:</td><td>${summary.rows_processed}</td></tr>
            <tr><td colspan="3">Rows skipped:</td></tr>
            <tr><td width="10%"></td><td>no test date:</td><td>${summary.rows_no_test_date}</td></tr>
            <tr><td width="10%"></td><td>no test type:</td><td>${summary.rows_no_test_type}</td></tr>
            <tr><td colspan="2">Clinical Data records created: </td><td>${summary.cd_created}</td></tr>
            <tr><td colspan="2">Clinical Data Pairs created:</td><td>${summary.cdp_created}</td></tr>
            <tr><td colspan="2">Samples created:</td><td>${summary.samples_created}</td></tr>
            <tr><td colspan="2">Samples updated:</td><td>${summary.samples_updated}</td></tr>
            <tr><td colspan="2">Runs updated:</td><td>${summary.runs_updated}</td></tr>
            <tr><td colspan="3">Comparator Results updated/created:</td></tr>
            <tr><td></td><td>known callout:</td><td>${summary.ctr_w_co_processed}</td></tr>
            <tr><td></td><td>unknown callout:</td><td>${summary.ctr_wo_co_processed}</td></tr>
          </tbody></table>
        </div>
      `,
      icon: 'info',
      confirmButtonText: 'Ok',
      customClass: { container: 'confirm' },
    });
  }

  @Socket('upload-clinical-data-error')
  public async onUploadError(info) {
    console.log('onUploadError:', info);
    this.showMessage = false;
    const result = await this.$swal({
      html: 'There was an error processing clinical data.<br>'+info.message,
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass: { container: 'confirm' },
    });
  }

}
</script>

<style>
.confirm {
  font-family: Roboto, sans-serif !important;
}
.title { font-size: large; padding-bottom: 1em; }
.small { font-size: small; }
.text-left { text-align: left; }
.w-100 { width: 100%; }

</style>

<style scoped>
.filepond--credits {
  display:none !important;
}

.clinical-data-detail {
  border: 1px solid #c8c8c8;
}
.clinical-data-detail >>> .detail-row {
  border-bottom: 1px solid lightgray;
  font-size: smaller;
}
.clinical-data-detail >>> .detail-key {
  text-transform: capitalize;
  overflow-wrap: break-word;
  font-weight: bold;
}
.clinical-data-detail >>> .detail-value {
  overflow-wrap: anywhere;
}

</style>
