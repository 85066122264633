<template>
    <!-- Logic Generator 
    Creates a hierarchical logic of strings utilizing the FormulaGenerator component to modify string.
    Assumes that order will start at 1 not 0.
    -->
    <v-card>
        <v-card-title>
            <v-container>
            <v-row class="settings-text" >
                <v-col>
                <div class="title-font">Logic Generator</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <div class="description-text">
                    Logic generator provides the ability to create hierarchical logic to define callouts. 
                    Logic is order specific such that if the top logic is true the paired callout will be selected. 
                    If none of the provided logics are true, then the otherwise callout shall be selected.
                    Logic can be dragged into preferred order. Logic can also be edited by clicking on the formula you would like to edit. 
                    Be sure to select save to apply changes!
                </div>
                </v-col>
            </v-row>
            </v-container>
        </v-card-title>

        <v-container>
        <draggable v-model="formulaPairs" :disabled="enable" @change="updateOrder()">
        <v-banner elevation="3" shaped v-for="item in formulaPairs" :key="item.order">
            <v-row>
                <v-col cols="1" class="d-flex align-center">
                    <v-icon :disabled="enable" >mdi-reorder-horizontal</v-icon>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="d-flex align-center">
                    <v-textarea
                        outlined
                        auto-grow
                        :readonly="true"
                        :disabled="enable"
                        v-model="item.formula"
                        placeholder="Click to edit/add a formula"
                        class="mx-auto"
                        @click="editLogic(item)"
                    ></v-textarea>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="d-flex align-center">
                    <v-select
                    v-model="item.result"
                    :disabled="enable"
                    :items="callouts"
                    placeholder="Select a callout"
                    class="mx-auto">
                    </v-select>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="auto" class="d-flex align-center">
                    <v-btn
                    icon
                    color="grey"
                    :disabled="enable"
                    @click="removeItem(item)"
                    v-if="formulaPairs.length>1"
                    >
                    <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-banner>
        </draggable>

        <v-row class="centeredRow" style="margin-bottom: 10px; margin-top: 10px;">
              <v-btn x-small :disabled="enable" color="primary" elevation="5" fab v-on:click="addField">+</v-btn>
        </v-row>

        <v-row>
            <v-col>
                <v-select
                    v-model="otherwiseResult"
                    :disabled="enable"
                    :items="callouts"
                    label="Otherwise,"
                    style="max-width: 200px; margin-left: 25px;"
                    @change="updateOtherwise()">
                </v-select>
            </v-col>
        </v-row>

        <v-row class="centeredRow" style="margin-bottom: 10px;" v-if="!enable">
              <v-btn medium color="primary" style="margin-right: 10px;" @click="save()">Save</v-btn>
              <v-btn medium color="primary" @click="close()">Cancel</v-btn>
        </v-row>
        </v-container>

        <FormulaGenerator v-if="enable"
            v-model="edittedItem"
            :itemList="itemList"
            :metricList="metricList"
            @save="updateLogic"
            @close="revertLogic">
        </FormulaGenerator>
    </v-card>
</template>

<script lang="ts">
import FormulaGenerator from '@/components/FormulaGenerator.vue';

import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { IPanelRuleCreate, IFormulaEditor } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import { filter } from 'vue/types/umd';
import { Dictionary } from 'vue-router/types/router';
import draggable from 'vuedraggable'

@Component({
  components: {
    FormulaGenerator,
    draggable,
  },
  })

export default class LogicGenerator extends Vue {
    @ModelSync('value', 'change') public formulaPairs!: IFormulaEditor[];
    @Prop() public readonly itemList!: string[];
    @Prop() public readonly callouts!: string[];
    @Prop({ default: () => [] }) public readonly metricList!: string[];

    public toggle_graphs = null;

    public enable: boolean = false;
    public edittedItem: string = '';
    public otherwiseResult: string = '';
    public edittedIndex: number = 0;

    public async save(){
      this.$emit('save');
    }

    public async close(){
      this.$emit('close');
    }

    public updateLogic() {
        this.enable = false;
        this.formulaPairs[this.edittedIndex].formula = this.edittedItem;
    }

    public async updateOtherwise(){
        for (let i = 0; i < this.formulaPairs.length; i++) {
            this.formulaPairs[i].otherwise_result = this.otherwiseResult;
        }
    }

    public revertLogic() {
        this.enable = false;
    }

    public async editLogic(item: IFormulaEditor) {
        this.edittedItem = item.formula;
        this.edittedIndex = item.order-1;
        this.enable = true;
    }

    public async removeItem(item: IFormulaEditor) {
        const index = item.order-1;
        // Remove the object at the specified index
        this.formulaPairs.splice(index, 1);
        // Update order values for the remaining objects
        this.updateOrder(index);
    }

    public async updateOrder(index: number = 0) {
        for (let i = index; i < this.formulaPairs.length; i++) {
            this.formulaPairs[i].order = i+1;
        }
    }

    public async addField() {
        const newRuleCreate: IFormulaEditor = {
            rule_set_id: this.formulaPairs[0].rule_set_id,
            order: this.formulaPairs.length+1,
            formula: '',
            result: this.callouts[0],
            otherwise_result: this.otherwiseResult
        };
        
        this.formulaPairs.push(newRuleCreate);
    }

    public mounted(){
        this.formulaPairs = this.formulaPairs.sort((a, b) => a.order - b.order);
        this.otherwiseResult = this.formulaPairs[0].otherwise_result;
    }
}

</script>

<style>
.title-font {
  font-size: medium;
}

.description-text {
  margin-top: 0px;
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}

.description-text, .v-row {
  line-height: 1em;
}

.v-banner{
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1em;
}

.v-text-field {
  font-size: small;
  max-width: 2000px;
}

.centeredRow {
  justify-content: center;
}
</style>