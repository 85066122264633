import { writeFile, utils } from 'xlsx-js-style';
import { format } from 'date-fns'
import {  } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';
/*
Purpose: To translate a ts object into an excel spreadsheet.

fields can contain nested objects denoted by a '.'. Example passing in instrument.rig will write the rig value into a cell.

TODO: Need to consider nested list of objects.
*/

function formatDateFromTimestamp(timestamp): string {
  const year = timestamp.getFullYear();
  const month = String(timestamp.getMonth() + 1).padStart(2, '0');
  const day = String(timestamp.getDate()).padStart(2, '0');
  
  const hours = String(timestamp.getHours()).padStart(2, '0');
  const minutes = String(timestamp.getMinutes()).padStart(2, '0');
  const seconds = String(timestamp.getSeconds()).padStart(2, '0');
  
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

function buildQCReport(username: string, useremail: string, data:object[], fields:string[], thresholds:number[], repHeaders:string[], repData1:object[],
                      repData2:object[], totalProbes: number, probesData:number[][], pqHeaders:string[], pqData:object[], title:string[]) {
  const STYLE_BORDER_ALL = { border: { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' } } };
  const STYLE_BORDER_BOTTOM_THICK = { border: { bottom: { style: 'thick' } } };
  const STYLE_BORDER_BOTTOM_THIN = { border: { bottom: { style: 'thin' } } };
  const STYLE_TITLE = { font: { sz: 16, bold: true } };
  const STYLE_HEADING = { font: { sz: 14, bold: true } };
  const STYLE_SUBHEADING = { font: { sz: 12, bold: true } };
  const STYLE_DESCRIPTION = { font: { sz: 12 } };
  const STYLE_CENTER = { alignment: { horizontal: 'center' } };
  const STYLE_FILL_FAIL = { alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: 'F6C9CE' }}, font: { color: { rgb: 'dc1518'}} };

  type Sheet = { v?:any; t?:any; s?:any; }[][];

  const wb = utils.book_new();

  const date = new Date();
  let datetime = date.toLocaleString();
  
  const createdStr = ['Created by', username, useremail, 'on', datetime].join(' ');
  
  let failedPq = false;
  let sheet:Sheet = [[{ v: title[0], t: 's', s: STYLE_TITLE },{ v: createdStr, t: 's', s: STYLE_DESCRIPTION },],[]];
  let rep1:Sheet = [[{ v: title[1].concat("_Signals"), t: 's', s: STYLE_TITLE },],[]];
  let rep2:Sheet = [[{ v: title[2].concat("_Signals"), t: 's', s: STYLE_TITLE },],[]];
  let pq:Sheet = [[{ v: 'Out of Spec Pq', t: 's', s: STYLE_TITLE },],[]];
  if (pqData.length > 0) {
    failedPq = true;
  }

  type Column = { v?:any; t?:any; s?:any; }
  let header_titles: Column[] = [] ;
  fields.forEach((h) => {
    header_titles.push({ v: h, t: 's', s: STYLE_HEADING });
  })
  sheet.push(header_titles);

  let counter = 0;
  data.forEach((o) => {
    let row: Column[] = []; 
    fields.forEach((f) => {
      let value = f.split('.').reduce((obj, key) => obj ? obj[key] : undefined, o);
      let floatval = parseFloat(String(value));
      let failed = false;
      let threshold = thresholds[counter];

      if (f == "Passing Threshold") {
        let thresholdstr = '';
        if (counter == 1) {
          thresholdstr = "≤ ".concat(String(threshold));
        } else {
          thresholdstr = "≥ ".concat(String(threshold));
        }
        row.push({v: thresholdstr, t: 's', s: STYLE_CENTER});
      } else if( value === undefined || value === null) {
        row.push({ v:'' , t: 's', s: STYLE_CENTER });
      } else {
        if (isNaN(floatval) || f == "Null Count") {
          //console.log(`${f} ${value} nan`);
          row.push({ v:value , t: 's', s: STYLE_CENTER});
        } else {
          if (counter == 1) {
            failed = floatval > threshold;
          } else {
            failed = floatval < threshold;
          }
          //console.log(`failed ${failed} threshold ${threshold}`);
          if (failed) {
            //console.log(`${f} ${value} failed threshold ${threshold}`);
            row.push({ v:value , t: 'n', s: STYLE_FILL_FAIL });
          } else {
            //console.log(`${f} ${value} passed threshold ${threshold}`);
            row.push({ v:value , t: 'n', s: STYLE_CENTER });  
          }
        }
      }
    })
    sheet.push(row);
    counter++;
  })

  let row: Column[] = [];  
  row.push({v:'Amplitude Threshold of non-NP Probes: ≥ 500 fA', t:'s' });
  row.push({v:title[1].split(" ")[0], t:'s', s: STYLE_CENTER });
  row.push({v:title[2].split(" ")[0], t:'s', s: STYLE_CENTER });
  row.push({v:'Total Probes', t:'s', s: STYLE_CENTER });
  sheet.push(row);
  row = []; 
  row.push({v:'Probes with median amplitude passing', t:'s' });
  row.push({v:probesData[0][0], t:'n', s: STYLE_CENTER });
  row.push({v:probesData[0][1], t:'n', s: STYLE_CENTER });
  row.push({v:totalProbes, t:'n', s: STYLE_CENTER });
  sheet.push(row);
  row = []; 
  row.push({v:'Probes with majority replicate signals passing', t:'s' });
  row.push({v:probesData[1][0], t:'n', s: STYLE_CENTER });
  row.push({v:probesData[1][1], t:'n', s: STYLE_CENTER });
  row.push({v:totalProbes, t:'n', s: STYLE_CENTER });
  sheet.push(row);
  row = []; 
  row.push({v:'Probes with at least one replicate signals passing', t:'s' });
  row.push({v:probesData[2][0], t:'n', s: STYLE_CENTER });
  row.push({v:probesData[2][1], t:'n', s: STYLE_CENTER });
  row.push({v:totalProbes, t:'n', s: STYLE_CENTER });
  sheet.push(row);
  row = []; 
  row.push({v:'Non depletion probes with Pq ≥ 5', t:'s' });
  row.push({v:probesData[3][0], t:'n', s: STYLE_CENTER });
  row.push({v:probesData[3][1], t:'n', s: STYLE_CENTER });
  sheet.push(row);

  const ws1 = utils.aoa_to_sheet(sheet);
  ws1['!cols'] = [{width: 40}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}];
  utils.book_append_sheet(wb, ws1, title[0]);

  let rep_titles: Column[] = [];
  repHeaders.forEach((h) => {
    rep_titles.push({ v: h, t: 's', s: STYLE_HEADING })
  })
  rep1.push(rep_titles);
  rep2.push(rep_titles);

  repData1.forEach((o) => {
    let row: Column[] = [];
    repHeaders.forEach((f) => {
        let value = f.split('.').reduce((obj, key) => obj ? obj[key] : undefined, o);
        if( value === undefined || value === null) {
            row.push({ v:'' , t: 's', s: STYLE_CENTER });
        } else {
            if (f === "Probe") {
              row.push({ v:value , t: 's', s: STYLE_CENTER });
            } else {
              let floatval = parseFloat(String(value));
              row.push({ v:floatval , t: 'n', s: STYLE_CENTER });
            }
        }
    })
    rep1.push(row)
  })

  repData2.forEach((o) => {
    let row: Column[] = [];
    repHeaders.forEach((f) => {
        let value = f.split('.').reduce((obj, key) => obj ? obj[key] : undefined, o);
        if( value === undefined || value === null) {
            row.push({ v:'' , t: 's', s: STYLE_CENTER });
        } else {
            if (f === "Probe") {
              row.push({ v:value , t: 's', s: STYLE_CENTER });
            } else {
              let floatval = parseFloat(String(value));
              row.push({ v:floatval , t: 'n', s: STYLE_CENTER });
            }
        }
    })
    rep2.push(row)
  })

  const ws2 = utils.aoa_to_sheet(rep1);
  const ws3 = utils.aoa_to_sheet(rep2);
  ws2['!cols'] = [{width: 30}];
  ws3['!cols'] = [{width: 30}];
  utils.book_append_sheet(wb, ws2, title[1]);
  utils.book_append_sheet(wb, ws3, title[2]);

  if(failedPq){
    let pq_titles: Column[] = []
    pqHeaders.forEach((h) => {
    pq_titles.push({ v: h, t: 's', s: STYLE_HEADING });
    })
    pq.push(pq_titles);
    pqData.forEach((o) => {
      let row: Column[] = [];
      pqHeaders.forEach((f) => {
          let value = f.split('.').reduce((obj, key) => obj ? obj[key] : undefined, o);
          if( value === undefined || value === null) {
              row.push({ v:'' , t: 's', s: STYLE_CENTER });
          } else {
              if (f.startsWith("Rep")) {
                let floatval = parseFloat(String(value));
                row.push({ v:floatval, t: 'n', s: STYLE_CENTER });
              } else {
                row.push({ v:value, t: 's', s: STYLE_CENTER });
              }
          }
      })
      pq.push(row)
    })
    const ws4 = utils.aoa_to_sheet(pq);
    ws4['!cols'] = [{width: 30}, {width: 20}];
    utils.book_append_sheet(wb, ws4, 'Out of Spec Pq');
  }
  const expnames = title[1].split(" ")[0].concat("_", title[2].split(" ")[0]); 

  const timestamp = formatDateFromTimestamp(date);
  const joinedString = ['BCDB_QC_Report', expnames, timestamp].join('_');

  const filename = joinedString + '.xlsx';
  writeFile(wb, filename);
  return filename;
}

export default buildQCReport;