import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IProbe, IProbeCreate,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getProbe(token: string, probeId: number) {
    return axios.get<IProbe>(`${apiUrl}/api/v1/probes/${probeId}`, authHeaders(token));
  },
  async getProbes(token: string) {
    return axios.get<IProbe[]>(`${apiUrl}/api/v1/probes/`, authHeaders(token));
  },
  async searchProbes(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    return axios.post<IProbe[]>(`${apiUrl}/api/v1/probes/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getProbesCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/probes/stats`, filters, authHeaders(token));
  },
  async getProbesFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/probes/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },
  // async getProbeUsage(token: string, filters: IFilter[] = []) {
  //   const paramsArray: string[] = [];
  //   if (filters.length > 0) {
  //     for (const filter of filters) {
  //       paramsArray.push(`f=${JSON.stringify(filter)}`);
  //     }
  //   }
  //   return axios.post<IProbeUsage>(`${apiUrl}/api/v1/probes/usage`, filters, authHeaders(token));
  // },
  async updateProbe(token: string, id: number, data: IProbe) {
    return axios.put(`${apiUrl}/api/v1/probes/${id}`, data, authHeaders(token));
  },
  async createProbe(token: string, data: IProbeCreate) {
    return axios.post(`${apiUrl}/api/v1/probes/`, data, authHeaders(token));
  },
  async deleteProbe(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/probes/${id}`, authHeaders(token));
  },

};
