<template> 
  <v-container fluid>
    <RunFilterPicker
      v-model="selectedFilters">
    </RunFilterPicker>
    <RunList
      :filters="selectedFilters"
      v-model="selectedRuns">    
    </RunList>
    
    <!-- Rules table -->
    <v-expansion-panels class="my-2" v-if="analyses.find((a)=>a.name===dataReductionName).enable">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-container>
            <v-row>
              <v-col>
                <div style="font-weight: bold; font-size: medium;">Data Reduction</div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <RulesTable
            v-model="selectedRuns">
          </RulesTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!--QC Report -->
    <v-container class="qc" v-if="analyses.find((a)=>a.name===qcReportName).enable">
      <QCReport v-model="selectedRuns"></QCReport>
    </v-container>

    <v-container v-if="analyses.find((a)=>a.name===pressureOverlayName).enable">
      <CrossRunGraphs
        v-model="loadingAnalysis"
        :selectedAnalysis="selectedAnalysis"
        :selectedRuns="selectedRuns"
      ></CrossRunGraphs>
    </v-container>

    <RightPanel :showPanel="selectedRuns.length">
      <template v-slot:header>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Selected Runs</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="selectedRuns.length" v-on:click="clearselectedRuns()">Clear</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-data-table
        :items="selectedRuns"
        :headers="selectedRunsHeaders"
        group-by="array_template.name"
        show-group-by
        dense
        disable-pagination
        hide-default-footer
        >
        <template v-slot:item.id="{ item }">
          <v-btn icon plain color="primary" v-on:click="deselectRun(item.id)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.print_run_id="{ item }">
          {{ printRunName(item.print_run) }}
        </template>
        <template v-slot:group.summary="props">
          {{ collapseGroups(props) }}
        </template>
        <template v-slot:group.header="props">
          <td class='mx-2' colspan="2">
<!--
          maybe someday allow expand/collapse.
          I'm not sure how to collapse all groups by default
            <v-btn
              icon plain color='primary'
              @click="props.toggle">
              <v-icon v-if='props.isOpen'>remove</v-icon>
              <v-icon v-if='!props.isOpen'>add</v-icon>
            </v-btn>
 -->  
        
            {{ arrayTemplateName(props.group) }}
            ({{ props.items.length }})
          </td>
          <td class='mx-2'>
            <v-btn
              icon
              @click="deselectRuns(props.items)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </template>

      </v-data-table>

      <v-row class="buttons"
      @mouseenter="analysisHover = true"
      @mouseleave="analysisHover = false"
      >
        <v-col>
          <v-select
          :items="analyses"
          v-model="selectedAnalysis"
          color="primary"
          item-text='name'
          item-value='name'
          label="Analysis"
          dense
          @change="validateRuns()"
          ></v-select>
        </v-col>
        <v-col>
          <v-btn
            rounded
            :loading="loadingAnalysis"
            color="primary"
            :disabled="!verifyAnalysisValidity()"
            @click="showAnalysis()">
            View
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="analysisHover && qcReportName===selectedAnalysis" class="description-text">
          QC Report requires two experiments from the same print run of the same array template: 
        one Buffer Only experiment and one TgtC1C2 experiment.
        <br><br>
        Note: QC Report currently cannot be generated for array templates that have more than one pair of HybC probes.
      </v-row>
      <v-row v-else-if="analysisHover && dataReductionName===selectedAnalysis" class="description-text" :style="`color:${!verifyAnalysisValidity()?'red':''};`">
        Select at least one experiment with an array template to see data reduction settings and call outs.
      </v-row>
      <v-row v-else-if="analysisHover" class="description-text">
        Select at least one experiment and your analysis type to view cross run results.
      </v-row>
      <hr>
      <v-form id="experiment-select" v-if="selectionMode">
        <v-row class="enzform"><v-col cols="6">
          <v-select 
            label="Buffer Only Experiment"
            v-model="bufferStr"
            :items="experimentNames"
            data-vv-name="bufferStr"
            @change="remainingExperiment()"
            dense>
          </v-select>
        </v-col></v-row>
        <v-row class="tgtform"><v-col cols="6">
          <v-autocomplete
            label="TgtC1C2 Experiment"
            v-model="tgtStr"
            :items="tgtList"
            data-vv-name="tgtStr"
            dense>
          </v-autocomplete>
        </v-col></v-row>
      </v-form> 
      <v-row v-if="array_template_match !== true && qcReportName===selectedAnalysis" class="warning-text"> 
        {{ array_template_match === false ? "Array templates do not match. Must select experiments that have the same array template." : "Array template information not found. Make sure selected experiments have the same array template." }}
      </v-row>
    </RightPanel>
  </v-container>
</template>



<script lang="ts">
import RunList from '@/components/RunList.vue';
import RunFilterPicker from '@/components/RunFilterPicker.vue';
import RightPanel from '@/components/RightPanel.vue';
import RulesTable from '@/components/RulesTable.vue';
import QCReport from '@/components/QCReport.vue';
import CrossRunGraphs from '@/components/CrossRunGraphs.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { Store } from 'vuex';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IRunTrackerExtended } from '@/interfaces/bcdb';
import { computed } from 'vue'
import { api } from '@/api';

import eventBus from '@/eventBus';

@Component({
  components: {
    RunList,
    RunFilterPicker,
    RightPanel,
    RulesTable,
    QCReport,
    CrossRunGraphs,
  },
})
export default class Runs extends Vue {

  public ruleName: string = '';
  public selectedRule: string = '';
  public showRules: boolean = false;
  public selectionMode: boolean = false;
  public bufferStr: string = '';
  public tgtStr: string = '';
  public tgtList: string[] = [];
  public experimentNames: string[] = ['',''];
  public array_template_match: boolean | null = null;
  public warning_msg: string = '';

  public pressureOverlayName: string = 'Pressure Overlay'
  public dataReductionName: string = 'Data Reduction'
  public qcReportName: string = 'QC Report'
  //name = displayed name, enable = enables/disables feature, loading = will enable spinner when button clicked, emitMsg = eventBus message (null to not send)
  public analyses: any[] = [{name:this.pressureOverlayName, enable:false, loading:true, emitMsg:'pressureOverlay'},
        {name:this.dataReductionName, enable:false, loading:false, emitMsg:null},
        {name:this.qcReportName, enable:false, loading:false, emitMsg:'generateQC'},
      ]
  public selectedAnalysis: string = this.analyses[0].name
  public analysisHover: boolean = false
  public loadingAnalysis: boolean = false

  public selectedRunsHeaders: object[] = [
        { text: 'Array Template', value: 'experiment', groupable: false },
        { text: '', value: 'print_run_id', groupable: false },
        { text: '', value: 'id', groupable: false },
      ];

  public selectedFilters: IFilter[] = [];
  public selectedRuns: IRunTrackerExtended[] = [];

  public mounted() {
    // dispatchGetRuns(readToken(this.$store), this.selectedFilters);
  }

  public showAnalysis(){
    //console.log("show analyses");
    for (let i=0; i < this.analyses.length; i++) {
      //flip non selected analyses
      this.analyses[i].enable = this.analyses[i].name === this.selectedAnalysis && this.verifyAnalysisValidity()

      if (this.analyses[i].enable){
        //Make the button show a loading circle if enabled for the analysis
        this.loadingAnalysis = this.analyses[i].loading
        this.analyses[i].emitMsg===null ? null : eventBus.emit(this.analyses[i].emitMsg)
      }
    }
  }
  public verifyAnalysisValidity(){
    switch(this.selectedAnalysis){
      case this.qcReportName:
        return(this.verifyQCReport())
        break
      case this.dataReductionName:
        return(this.verifyHasArrayTemplate())
        break
      default:
        return(true)
    }
  }
  public verifyQCReport() {
    if (this.bufferStr.length > 0 && this.tgtStr.length > 0 && this.array_template_match !== false) {
      for (var j = 0; j < this.selectedRuns.length; j++) {
        let name = this.selectedRuns[j].experiment;
        if (name === this.bufferStr) {
          this.selectedRuns[j].run_description = "Buffer Only";
        } else if (name === this.tgtStr) {
          this.selectedRuns[j].run_description = "50nM TgtC1C2";
        }
      }
     return true; 
    } else {
      return false;
    }

  }
  public verifyHasArrayTemplate() {
    return(this.selectedRuns.find((run: IRunTrackerExtended) => run.array_template) !== undefined);
  }

  get selectedRunIds() {
    return this.selectedRuns.map((t) => t.id as number);
  }

  public clearselectedRuns() {
    this.selectedRuns = [];
    for( let i=0; i<this.analyses.length; i++){
      this.analyses[i].enable = false
    }
    this.selectionMode = false;
    this.bufferStr = '';
    this.tgtStr = '';
    this.tgtList = [];
    this.experimentNames = [];
  }

  // For removing runs from the cart
  public deselectRun(runId) {
    //console.log("deselectRun");
    this.selectedRuns = this.selectedRuns.filter((run: IRunTrackerExtended) => run.id !== runId);
  }
  public deselectRuns(runIds) {
    //console.log("deselectRunS");
    runIds.forEach((run) => this.deselectRun(run.id));
  }

  public async getRules() {
    // placeholder for now
  }

  public async buildNewRules() {
    this.showRules = true;
  }

  public printRunName(printRun) {
    if( printRun ) {
      const matches = printRun.name.match(/Run([^_]+)_/);
      return( matches ? matches[1] : printRun.name );
    }
    return('');
  }

  public arrayTemplateName(name) {
    if( name ) {
      const matches = name.match(/ArrayTemplate_(.*)/);
      return( matches ? matches[1] : name );
    }
    return('<none>');
  }

  public getTemplateCount() {
    return(this.selectedRuns.map((r)=> r?.array_template?.id).filter(Number).length);
  }

  @Watch('selectedRuns')
  public validateRuns() {
    this.experimentNames = [];
    this.selectionMode = false;
    if (this.selectedRuns.length === 2) {
      let buffer_id = this.selectedRuns[0].array_templates_id;
      let tgt_id = this.selectedRuns[1].array_templates_id;
      if (buffer_id === tgt_id) {
        this.experimentNames[0] = this.selectedRuns[0].experiment;
        this.experimentNames[1] = this.selectedRuns[1].experiment;  
        this.selectionMode = true && this.selectedAnalysis===this.qcReportName;     
        if (buffer_id === null) {
          this.array_template_match = null;
        } else {
          this.array_template_match = true;
        }
      } else {
        this.array_template_match = false;
      } 
    } else {
      this.tgtList = [];
      this.array_template_match = false;
    }
  } 

  public getWarning() {
    if (this.array_template_match === false) {

    }
  }
  public remainingExperiment() {
    if (this.bufferStr.length > 0 ){
      let idx = this.experimentNames.indexOf(this.bufferStr);
      if (idx === 0) {
        this.tgtList = [this.experimentNames[1]];
      } else {
        this.tgtList = [this.experimentNames[0]];
      };
      this.tgtStr = this.tgtList[0];
      //console.log("remainingExperiment", this.tgtStr);
      return this.tgtList 
    }
  }

  // for now collapse all groups all the time.
  public collapseGroups(props) {
    if( props.isOpen === true ) {
      props.toggle(false);
    }
  }

}
</script>


<style scoped>
.v-data-table >>> .col-sample-id {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-name {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-system {
  min-width: 5em;
}


.run-summary {
  overflow-x: scroll;
}

.run-summary >>> .dataframe tbody td {
  text-align: right;
  padding-right: 0.2em;
  border-bottom: 1px solid #ccc;
  border-left:  1px solid #ccc;
}

.run-summary >>> .dataframe tbody tr:first-child td {
  border-top: 1px solid #ccc;
}

.run-summary >>> .dataframe tbody td:last-child {
  border-right: 1px solid #ccc;
}

.run-summary >>> .dataframe thead tr:last-child th {
  border-bottom: 1px solid #222;
  border-top: 1px solid #222;
  min-width: 5em;
}

.run-summary >>> .dataframe thead th:last-child {
  border-right: 1px solid #222;
}

.run-summary >>> .dataframe thead th {
  border-left: 1px solid #222;
  border-top: 1px solid #222;
}

.run-summary >>> .dataframe thead th:empty {
  border: 0;
}

.run-summary >>> .dataframe {
  /*border-collapse: collapse !important;*/
  border-spacing: 0 !important;
}

.run-alert {
  padding: 12px;
  color: red;
}

.description-text {
  margin: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
  line-height: 1em;
  padding-bottom: 8px;
}

.warning-text {
  margin: 8px;
  color: red;
  font-size: medium;
  font-weight: 400;
  line-height: 1em;
  padding-bottom: 8px;
}
.buttons {
  padding: 4px; 
  margin: 4px;
}

.qcbox {
  border: 2px solid black
}

.enzform {
  padding: 8px 0px 0px 4px; 
  margin: 8px 0px 0px 4px; 
}

.tgtform {
  padding: 0px 0px 0px 4px; 
  margin: 0px 0px 0px 4px;
}
</style>
