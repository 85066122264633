var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.samples,"item-key":"id","dense":true,"server-items-length":_vm.sampleCount,"page":_vm.options.page,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions,"click:row":_vm.rowclick},scopedSlots:_vm._u([{key:"item.collection_date",fn:function({ value }){return [_vm._v(" "+_vm._s(value?.formatDate() || null)+" ")]}},{key:"item.created_on",fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true, "America/Los_Angeles"))+" ")]}},{key:"item.run_tracker",fn:function({ value,item }){return [_c('v-chip',{attrs:{"color":value.length>0?'green':'red',"dark":"","small":""}},[(value.length===0)?_c('div',[_vm._v(" "+_vm._s(_vm.timeDelta(item))+" ")]):_vm._e()])]}},{key:"item.comparator_results",fn:function({ value,item }){return [_c('v-chip',{attrs:{"color":value.length>0?'green':'red',"dark":"","small":""}},[(value.length===0)?_c('div',[_vm._v(" "+_vm._s(_vm.timeDelta(item))+" ")]):_vm._e()])]}}]),model:{value:(_vm.selectedSamples),callback:function ($$v) {_vm.selectedSamples=$$v},expression:"selectedSamples"}})
}
var staticRenderFns = []

export { render, staticRenderFns }