<template>
  <!-- All Probes -->
  <v-data-table
    :headers="headers"
    :items="probes"
    item-key="panel_name"
    :dense="true"
    class="elevation-1"
    :server-items-length="probeCount"
    v-on:update:options="updateOptions"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    v-model="selectedProbes"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.panel_name="{ item }">
      <router-link :title="`${item.panel_name}\n${item.probe_description}`" v-if="item.id != null" :to="{ name: 'biochip-probe-detail', params: { id: item.id }}">{{item.panel_name}}</router-link>
    </template>  

    <template v-slot:item.callout_id="{ item }">
      {{ item.callout ? item.callout.name : "" }}
    </template>

    <template v-slot:item.date_added="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { IAutocompleteItem, IFilter, ITableOptions } from '@/interfaces';
import { IProbe } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class ProbeList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedProbes!: IProbe[];

  public headers: object[] = [
        { text: 'Panel Name', value: 'panel_name' },
        { text: 'Order Name', value: 'order_name' },
        { text: 'Function', value: 'function' },
        { text: 'Callout', value: 'callout_id' },
        { text: 'Date Added', value: 'date_added', cellClass: 'col-date' },
      ];

  @Prop() public readonly filters!: IFilter[];

  public probes: IProbe[] = [];
  public probeCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'panel_name',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateProbeView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getProbesCount() {
    try {
      const response = await api.getProbesCount(readToken(this.$store), this.filters || []);
      if (response) {
        this.probeCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getProbes() {
    try {
      const response = await api.searchProbes(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.probes = response.data;
          // this.probeCount = response.data.length;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async updateProbeView() {
    this.getProbesCount();
    this.getProbes();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'panel_name';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    // emit an event?
    this.updateProbeView();
  }

  public mounted() {
    // console.log('ProbeList mounted');
    // if (readHasAdminAccess(this.$store)) {
    //   this.headers.push(
    //     { text: 'Parse', value: 'parse' },
    //     { text: 'Assay', value: 'assay_file' },
    //     { text: 'Test', value: 'test_file' },
    //   );
    // }

    this.updateProbeView();
  }

  @Socket('new-probe')
  public onNewProbe(probeInfo) {
    console.log('new-proble: ',JSON.parse(probeInfo));
    this.updateProbeView();
  }

}
</script>

<style scoped>
</style>
