<template>
  <v-card>
    <v-card-text>
      <v-row v-for="(filter, idx) in selectedFilters" :key="filter.id">
        <v-col cols="5">
          <v-select
            v-model="selectedFilters[idx].value"
            :items="availableFilters"
            item-text="name"
            item-value="value"
            label="Field"
            single-line
            dense
            v-on:change="filterTargetChange(idx, ...arguments)"
          >
            <template v-slot:prepend>
              <v-btn small icon plain color="primary" v-on:click="removeFilter(idx)"><v-icon>mdi-close</v-icon></v-btn>
            </template>

          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-if="filterOperators[filter.value]"
            v-model="selectedFilters[idx].selectedOperator"
            :items="filterOperators[filter.value]"
            item-text="name"
            item-value="name"
            label="Filter"
            single-line
            clearable
            dense
            @change="selectedFilters[idx].filterValue = undefined"
          ></v-select>
        </v-col>
        <v-col cols="5">
          <v-combobox
            dense
            v-if="filterOperators[filter.value] && filterTypes[filter.value] == 'text'"
            v-model="selectedFilters[idx].filterValue"
            :items="selectedFilters[idx].items"
            :return-object="false"
            item-value="value"
            v-on:update:search-input="filterValueChange(idx, ...arguments)"
            clearable
          >
            <!--<template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>-->


          </v-combobox>

          <v-menu
            v-model="selectedFilters[idx].showMenu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="selectedFilters[idx].selectedOperator && filterOperators[filter.value] && filterTypes[filter.value] == 'date'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedFilters[idx].filterValue"
                label="Select Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedFilters[idx].filterValue"
              :range="selectedFilters[idx].selectedOperator == 'between'"
              @change="selectedFilters[idx].showMenu = false; updatefilters();"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-btn
        rounded
        color="primary"
        dark
        v-on:click="addFilter"
      >
        Add Filter
      </v-btn>
    </v-card-text>
  </v-card>
</template>



<script lang="ts">
import { Component, Vue, Watch, Model } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';

import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class WaferFilterPicker extends Vue {

  @Model('change', { type: Array })
  public readonly value!: IFilter[];

  public valueIndex: number = 0;

  public availableFilters: object[] = [
        { name: '', value: 'none'},
        { name: 'Lot', value: 'lot_code'},
        { name: 'Wafer Number', value: 'identifier'},
        { name: 'Fab', value: 'fab_code'},
        { name: 'Date Added', value: 'date_added'},
      ];

  public filterOperators: object = {
    lot_code: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    identifier: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    fab_code: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    date_added: [
      { name: 'on', op: '='},
      { name: 'after', op: '>='},
      { name: 'before', op: '<='},
      { name: 'between', op: '><'},
    ],
  };

  public filterTypes: object = {
    lot_code: 'text',
    identifier: 'text',
    fab_code: 'text',
    date_added: 'date',
  };

  public selectedFilters: Array<{
    name?: string,
    value?: string,
    id: any,
    items?: IAutocompleteItem[],
    selectedOperator?: string,
    filterValue?: string,
  }> = [];

  public updatefilters() {
    const filters: IFilter[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const filter of this.selectedFilters) {
      if (filter.value && filter.selectedOperator && filter.filterValue) {
        filters.push(
          { field: filter.value.split('__')[0],
            table: filter.value.split('__')[1],   // handle {table}__{field} formats
            operator: filter.selectedOperator,
            value: filter.filterValue },
        );
      }
    }
    this.$emit('change', filters);
  }

  public async filterTargetChange(idx: number, item) {
    let items;
    items = await api.getWaferFields(readToken(this.$store), {field: item, skip: 0, limit: 100, filter: ''});
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.$set(this.selectedFilters[idx], 'filterValue', undefined );
    this.$set(this.selectedFilters[idx], 'selectedOperator', undefined );
  }
/*
  public async endIntersect(entries, observer, isIntersecting) {
    if (isIntersecting) {
      const moreItems = await api.getFields(readToken(this.$store), {field: item, skip: 0, limit: 100, filter: ''});
      this.$set(this.selectedFilters[idx], 'items', items.data );
      this.items = [ ...this.items, ...moreItems.data]
    }
  }
*/
  public async filterValueChange(idx: number, item) {
    this.$set(this.selectedFilters[idx], 'filterValue', item );
    let items;
    items = await api.getWaferFields(readToken(this.$store), {
      field: this.selectedFilters[idx].value?.split('__')[0] || '',
      skip: 0,
      limit: 100,
      filter: item,
    });
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.updatefilters();
  }

  public addFilter() {
    const filter = {id: uuidv4(), ...this.availableFilters[0]};
    this.selectedFilters.push(filter);
  }

  public removeFilter(idx: number) {
    this.selectedFilters.splice(idx, 1);
    this.updatefilters();
  }

  public mounted() {
    // this.addFilter();
  }
}
</script>

<style scoped>

</style>
