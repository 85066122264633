import { writeFile, utils } from 'xlsx-js-style';
import { format } from 'date-fns'
import { IRulesTable } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';


function buildDataReductionExcel(params) {
  const STYLE_BORDER_ALL = { border: { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' } } };
  const STYLE_BORDER_BOTTOM_THICK = { border: { bottom: { style: 'thick' } } };
  const STYLE_BORDER_BOTTOM_THIN = { border: { bottom: { style: 'thin' } } };
  const STYLE_TITLE = { font: { sz: 16, bold: true } };
  const STYLE_HEADING = { font: { sz: 14, bold: true } };
  const STYLE_SUBHEADING = { font: { sz: 12, bold: true } };
  const STYLE_DESCRIPTION = { font: { sz: 10 } };
  const STYLE_CENTER = { alignment: { horizontal: 'center' } };
  const STYLE_FILL_VALID = { fill: { fgColor: { rgb: 'ADD9E6' }}};
  const STYLE_FILL_INVALID = { fill: { fgColor: { rgb: 'D9D9D9' }}};
  const STYLE_FILL_POSITIVE = { fill: { fgColor: { rgb: 'F44336' }}};
  const STYLE_FILL_NEGATIVE = { fill: { fgColor: { rgb: '4CAE50' }}};

  type Sheet = { v?:any; t?:any; s?:any; }[][];
  const probeNames = Object.assign({}, ...params.uniqueProbes.map((p) => ({[p.probe_id]: p.panel_name})));

  const infoSheet:Sheet = [[
      { v: 'Data Reduction Export', t: 's', s: STYLE_TITLE },
    ],[
    ],[
      { v: 'Date:', t: 's', s: STYLE_HEADING },
      { v: new Date().toLocaleString(), t: 's' },
    ],[
      { v: 'User:', t: 's', s: STYLE_HEADING },
      { v: params.username, t: 's' },
    ],[
      { v: 'Array Template:', t: 's', s: STYLE_HEADING },
      { v: params.templateName, t: 's' },
    ],[
      { v: 'Rule Set:', t: 's', s: STYLE_HEADING },
      { v: params.ruleName, t: 's' },
    ],[
      { v: 'Experiments:', t: 's', s: STYLE_HEADING },
      { v: 'Count = '+ params.templateRuns.length, t: 's' },
    ]
  ];
  params.templateRuns.forEach((r) => {
    infoSheet.push([
      { },
      { v: r.experiment, t: 's' },
    ]);
  });

  const defaultRulesSheet:Sheet = [[
      { v: 'Default Rules', t: 's', s: STYLE_TITLE },
    ],[
    ],[
      { v: 'Replicate Reduction', t: 's', s: STYLE_HEADING },
      { v: params.defaultRule.rr_method, t: 's' },
    ],[
    ],[
      { v: 'Normalization Probe', t: 's', s: STYLE_HEADING },
      {},
      { v: params.defaultRule.use_sbr?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.sbr_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ SBR', t: 's' },
      {},
      { v: params.defaultRule.use_amplitude?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.amplitude_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Amplitude', t: 's' },
      {},
      { v: params.defaultRule.use_stability?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.stability_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Stability', t: 's' },
    ],[
      { v: 'Define NP settings to make a VALID call.', t: 's', s: STYLE_DESCRIPTION },
    ],[
      { v: 'HybC1 Probe', t: 's', s: STYLE_HEADING },
      {},
      { v: params.defaultRule.use_hybc1_pq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc1_pq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Pq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc1_pq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_hybc1_aq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc1_aq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Aq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc1_aq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_hybc1_tm?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc1_tm_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Tm ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc1_tm_th_high, t: 's', s: STYLE_CENTER },
    ],[
      { v: 'Define HybC1 settings to make a VALID call.', t: 's', s: STYLE_DESCRIPTION },
    ],[
      { v: 'HybC2 Probe', t: 's', s: STYLE_HEADING },
      {},
      { v: params.defaultRule.use_hybc2_pq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc2_pq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Pq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc2_pq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_hybc2_aq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc2_aq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Aq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc2_aq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_hybc2_tm?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.hybc2_tm_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Tm ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.hybc2_tm_th_high, t: 's', s: STYLE_CENTER },
    ],[
      { v: 'Define HybC2 settings to make a VALID call.', t: 's', s: STYLE_DESCRIPTION },
    ],[
      { v: 'Target Probes', t: 's', s: STYLE_HEADING },
      {},
      { v: params.defaultRule.use_pq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.pq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Pq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.pq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_aq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.aq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Aq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.aq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_tm?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.tm_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Tm ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.tm_th_high, t: 's', s: STYLE_CENTER },
    ],[
      { v: 'Define target probes settings to make a POSITIVE call.', t: 's', s: STYLE_DESCRIPTION },
    ],[
      { v: 'Discrimination Probes', t: 's', s: STYLE_HEADING },
      {},
      { v: params.defaultRule.use_dpq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.dpq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ ΔPq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.dpq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_daq?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.daq_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ ΔAq ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.daq_th_high, t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.use_dtm?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.dtm_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ ΔTm ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.dtm_th_high, t: 's', s: STYLE_CENTER },
    ],[
      { v: 'Define discrimination probes settings to make a POSITIVE call.', t: 's', s: STYLE_DESCRIPTION },
    ],[
      { v: 'Depletion Probes', t: 's', s: STYLE_HEADING },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      { v: params.defaultRule.use_ct?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: params.defaultRule.ct_th_low, t: 's', s: STYLE_CENTER },
      { v: '≤ Ct ≤', t: 's', s: STYLE_CENTER },
      { v: params.defaultRule.ct_th_high, t: 's', s: STYLE_CENTER },
    ],[
      { v: 'Define depletion probes settings to make a POSITIVE call.', t: 's', s: STYLE_DESCRIPTION },
    ],
  ];

  const controlResults = params.l2Results.find((p) => p.panel_association==='Controls');
  const callOutsSheet:Sheet = [[
      { v: 'Inspection Call Outs', t: 's', s: STYLE_TITLE },
      {},
      {},
      {},
      {},
      {},
      { v: 'Rules', t: 's', s: STYLE_TITLE },
  ],[
      {},
      {},
      { v: 'Valid', t: 's', s: STYLE_SUBHEADING },
      { v: 'Invalid', t: 's', s: STYLE_SUBHEADING },
  ],[
      { v: 'Controls', t: 's', s: STYLE_HEADING },
      {},
      { v: controlResults?controlResults.positive:'0', t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
      { v: controlResults?controlResults.invalid:'0', t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
  ]];

  let controlsArray: IRulesTable[] = [];

  for (let i = params.rulesTable.length - 1; i >= 0; i--) {
      if (params.rulesTable[i].panel_association === 'Controls') {
          controlsArray.push(params.rulesTable[i]);
          params.rulesTable.splice(i, 1);
      }
  }
  controlsArray.forEach((p) => {
    callOutsSheet.push([
      {},
      { v: p.panel_name, t: 's' },
      { v: p.l1_call[0]+p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
      { v: p.l1_call[2], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
      {},
      {},
      {},
      { v: p.use_metric1?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: p.metric1_low, t: 's', s: STYLE_CENTER },
      { v: p.metric1_text, t: 's', s: STYLE_CENTER },
      { v: p.metric1_high, t: 's', s: STYLE_CENTER },
      { v: p.use_metric2?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: p.metric2_low, t: 's', s: STYLE_CENTER },
      { v: p.metric2_text, t: 's', s: STYLE_CENTER },
      { v: p.metric2_high, t: 's', s: STYLE_CENTER },
      { v: p.use_metric3?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
      { v: p.metric3_low, t: 's', s: STYLE_CENTER },
      { v: p.metric3_text, t: 's', s: STYLE_CENTER },
      { v: p.metric3_high, t: 's', s: STYLE_CENTER },
    ]);
  });
  callOutsSheet.push([
  ],[
    { v: 'Panel Call Outs', t: 's', s: STYLE_TITLE },
  ],[
  ],[
    { v: 'Panel Name', t: 's', s: { ...STYLE_SUBHEADING, ...STYLE_BORDER_BOTTOM_THICK } },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v: 'Valid', t: 's', s: { ...STYLE_SUBHEADING, ...STYLE_BORDER_BOTTOM_THICK } },
    { v: 'Invalid', t: 's', s: { ...STYLE_SUBHEADING, ...STYLE_BORDER_BOTTOM_THICK } },
    { v: 'Positive', t: 's', s: { ...STYLE_SUBHEADING, ...STYLE_BORDER_BOTTOM_THICK } },
    { v: 'Negative', t: 's', s: { ...STYLE_SUBHEADING, ...STYLE_BORDER_BOTTOM_THICK } },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
    { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THICK },
  ]);

  var groupName = '';
  const sortedRulesTable = params.rulesTable.sort((a, b) => 
    ('' + a.panel_association).localeCompare(b.panel_association, 'en', { numeric: true }) );
  sortedRulesTable.forEach((p) => {
    if( p.panel_association !== groupName ) {
      if( groupName !== '' ) {
        callOutsSheet.push([
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
            { v:'', t: 's', s: STYLE_BORDER_BOTTOM_THIN },
        ]);
      }
      groupName = p.panel_association;
      const panelResults = params.l2Results.find((r) => r.panel_association === p.panel_association);
      let formulaPairs = params.panelRules.filter((r) => r.panel_name === p.panel_association);
      formulaPairs = formulaPairs.sort((a, b) => a.order - b.order);
      const valid = panelResults?panelResults.positive:0 + panelResults?panelResults.negative:0;
      callOutsSheet.push([
        { v: p.panel_association?p.panel_association:"", t: 's', s: STYLE_HEADING },
        {},
        { v: valid, t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
        { v: panelResults?panelResults.invalid:0, t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
        { v: panelResults?panelResults.positive:0, t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_POSITIVE } },
        { v: panelResults?panelResults.negative:0, t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_NEGATIVE } },
        {},
        { v: `ƒ${formulaPairs[0]?formulaPairs[0].order:''}: ${formulaPairs[0]?formulaPairs[0].formula:''} = ${formulaPairs[0]?formulaPairs[0].result:''} `, t: 's' },
      ]);
      
      for (let i = 1; i < formulaPairs.length; i++) {
        callOutsSheet.push([
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          { v: `ƒ${formulaPairs[i].order}: ${formulaPairs[i].formula} = ${formulaPairs[i].result} `, t: 's' },
        ]);
      }

      callOutsSheet.push([
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { v: `Otherwise: ${formulaPairs[0]?formulaPairs[0].otherwise_result:''} `, t: 's' },
      ]);
    }
    if( p.function === EProbeFunction.depletion ) {
      callOutsSheet.push([
        {},
        { v: p.panel_name, t: 's' },
        { v: p.l1_call[0]+p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
        { v: p.l1_call[2], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
        { v: p.l1_call[0], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_POSITIVE } },
        { v: p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_NEGATIVE } },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { v: p.use_metric4?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric4_low, t: 's', s: STYLE_CENTER },
        { v: p.metric4_text, t: 's', s: STYLE_CENTER },
        { v: p.metric4_high, t: 's', s: STYLE_CENTER },
      ]);
    } else {
      callOutsSheet.push([
        {},
        { v: p.panel_name, t: 's' },
        { v: p.l1_call[0]+p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
        { v: p.l1_call[2], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
        { v: p.l1_call[0], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_POSITIVE } },
        { v: p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_NEGATIVE } },
        {},
        { v: p.use_metric1?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric1_low, t: 's', s: STYLE_CENTER },
        { v: p.metric1_text, t: 's', s: STYLE_CENTER },
        { v: p.metric1_high, t: 's', s: STYLE_CENTER },
        { v: p.use_metric2?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric2_low, t: 's', s: STYLE_CENTER },
        { v: p.metric2_text, t: 's', s: STYLE_CENTER },
        { v: p.metric2_high, t: 's', s: STYLE_CENTER },
        { v: p.use_metric3?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric3_low, t: 's', s: STYLE_CENTER },
        { v: p.metric3_text, t: 's', s: STYLE_CENTER },
        { v: p.metric3_high, t: 's', s: STYLE_CENTER },
      ]);
    }
  });

  const callOutsDetailSheet:Sheet = [[
      { v: 'Panel Name', t: 's', s: STYLE_HEADING },
      { v: 'Probe Code', t: 's', s: STYLE_HEADING },
      { v: 'Probe Order Name', t: 's', s: STYLE_HEADING },
      { v: 'Valid', t: 's', s: STYLE_SUBHEADING },
      { v: 'Invalid', t: 's', s: STYLE_SUBHEADING },
      { v: 'Positive', t: 's', s: STYLE_SUBHEADING },
      { v: 'Negative', t: 's', s: STYLE_SUBHEADING },
      { },
      { v: 'use Pq', t: 's', s: STYLE_SUBHEADING },
      { v: 'Pq low', t: 's', s: STYLE_SUBHEADING },
      { v: 'Pq', t: 's', s: STYLE_SUBHEADING },
      { v: 'Pq high', t: 's', s: STYLE_SUBHEADING },
      { v: 'use Aq', t: 's', s: STYLE_SUBHEADING },
      { v: 'Aq low', t: 's', s: STYLE_SUBHEADING },
      { v: 'Aq', t: 's', s: STYLE_SUBHEADING },
      { v: 'Aq high', t: 's', s: STYLE_SUBHEADING },
      { v: 'use Tm', t: 's', s: STYLE_SUBHEADING },
      { v: 'Tm low', t: 's', s: STYLE_SUBHEADING },
      { v: 'Tm', t: 's', s: STYLE_SUBHEADING },
      { v: 'Tm high', t: 's', s: STYLE_SUBHEADING },
      { v: 'use Ct', t: 's', s: STYLE_SUBHEADING },
      { v: 'Ct low', t: 's', s: STYLE_SUBHEADING },
      { v: 'Ct', t: 's', s: STYLE_SUBHEADING },
      { v: 'Ct high', t: 's', s: STYLE_SUBHEADING },
  ]];
  sortedRulesTable.forEach((p) => {
    if( p.function === EProbeFunction.depletion ) {
      callOutsDetailSheet.push([
        { v: p.panel_association?p.panel_association:'', t: 's' },
        { v: p.panel_name?p.panel_name:'', t: 's' },
        { v: p.order_name?p.order_name:'', t: 's' },
        { v: p.l1_call[0]+p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
        { v: p.l1_call[2], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
        { v: p.l1_call[0], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_POSITIVE } },
        { v: p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_NEGATIVE } },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { v: p.use_metric4?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric4_low, t: 's', s: STYLE_CENTER },
        { v: p.metric4_text, t: 's', s: STYLE_CENTER },
        { v: p.metric4_high, t: 's', s: STYLE_CENTER },
      ]);
    } else {
      callOutsDetailSheet.push([
        { v: p.panel_association, t: 's' },
        { v: p.panel_name, t: 's' },
        { v: p.order_name, t: 's' },
        { v: p.l1_call[0]+p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_VALID } },
        { v: p.l1_call[2], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_INVALID } },
        { v: p.l1_call[0], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_POSITIVE } },
        { v: p.l1_call[1], t: 's', s: { ...STYLE_CENTER, ...STYLE_FILL_NEGATIVE } },
        {},
        { v: p.use_metric1?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric1_low, t: 's', s: STYLE_CENTER },
        { v: p.metric1_text, t: 's', s: STYLE_CENTER },
        { v: p.metric1_high, t: 's', s: STYLE_CENTER },
        { v: p.use_metric2?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric2_low, t: 's', s: STYLE_CENTER },
        { v: p.metric2_text, t: 's', s: STYLE_CENTER },
        { v: p.metric2_high, t: 's', s: STYLE_CENTER },
        { v: p.use_metric3?'√':'', t: 's', s: { ...STYLE_CENTER, ...STYLE_BORDER_ALL } },
        { v: p.metric3_low, t: 's', s: STYLE_CENTER },
        { v: p.metric3_text, t: 's', s: STYLE_CENTER },
        { v: p.metric3_high, t: 's', s: STYLE_CENTER },
      ]);
    }
  });

  const dataSheet:Sheet = [[
      { v: 'Experiment Name', t: 's', s: STYLE_SUBHEADING },
      { v: 'Probe Code', t: 's', s: STYLE_SUBHEADING },
      { v: 'Order Name', t: 's', s: STYLE_SUBHEADING },
      { v: 'Metric', t: 's', s: STYLE_SUBHEADING },
      { v: 'Replicate', t: 's', s: STYLE_SUBHEADING },
      { v: 'Value', t: 's', s: STYLE_SUBHEADING },
  ]];

  const sortedRawData = params.rawData.sort((a,b) => {
    if (parseInt(probeNames[a.panel_name_id]) < parseInt(probeNames[b.panel_name_id])) {
      return -1;
    }
    else if (parseInt(probeNames[a.panel_name_id]) > parseInt(probeNames[b.panel_name_id])) {
      return 1;
    }
    else {
      if (a.metric < b.metric) {
        return -1;
      }
      else if (a.metric > b.metric) {
        return 1;
      }
      else {
        if (a.replicate < b.replicate) {
          return -1;
        }
        else if (a.replicate > b.replicate) {
          return 1;
        }
        else {
          return 0;
        }
      }
    }
  });

  // get a dict of just the run names
  sortedRawData.forEach((a) => {
    const probe = params.uniqueProbes.find((p) => {p.id === a.panel_name_id});
    dataSheet.push([
      { v: params.runNames[a.experiment_id] },
      { v: probeNames[a.panel_name_id] },
      { v: a.order_name },
      { v: a.metric },
      { v: a.replicate },
      { v: a.result == null ? '' : a.result },
    ]);
  });
  const time4 = new Date().getTime();

  const ws1 = utils.aoa_to_sheet(infoSheet);
  const ws2 = utils.aoa_to_sheet(defaultRulesSheet);
  const ws3 = utils.aoa_to_sheet(callOutsSheet);
  const ws4 = utils.aoa_to_sheet(callOutsDetailSheet);
  const ws5 = utils.aoa_to_sheet(dataSheet);

  const time5 = new Date().getTime();

  ws1['!cols'] = [{width: 20}, {width: 50}];
  ws2['!cols'] = [{width: 24}, {width: 3},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4}];
  ws3['!cols'] = [{width: 3.5}, {width: 31},
    {width: 8}, {width:8}, {width: 8}, {width: 8}, {width: 8},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4}];
  ws4['!cols'] = [{width: 30}, {width: 30}, {width: 30},
    {width: 8}, {width:8}, {width: 8}, {width: 8}, {width: 8},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4},
    {width: 3}, {width:4}, {width: 6.5}, {width: 4}];
  ws5['!cols'] = [{width: 35}, {width: 25},
    {width: 25}, {width:10}, {width: 10}, {width: 25}];

  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws1, "Info");
  utils.book_append_sheet(wb, ws2, "Default Rules");
  utils.book_append_sheet(wb, ws3, "Call Outs");
  utils.book_append_sheet(wb, ws4, "Call Outs Detail");
  utils.book_append_sheet(wb, ws5, "Data");
  const time6 = new Date().getTime();

  // return(wb)
  const filename = 'BCDB Data Reduction Export' + format(new Date(),'yyMMddHHmmss') + '.xlsx';
  writeFile(wb, filename);
}

export default buildDataReductionExcel;