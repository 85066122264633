import axios from 'axios';
import { apiUrl } from '@/env';
import { IDie } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getDiesFromWafer(token: string, wafer_id: number) {
    return axios.get<IDie[]>(`${apiUrl}/api/v1/dies/?wafer_id=${wafer_id}`, authHeaders(token));
  },
};
