<template>
  <!-- All Comparator Test Results -->
  <v-data-table
    :headers="headers"
    :items="results"
    item-key="id"
    :dense="true"
    class="elevation-1"
    :server-items-length="resultCount"
    v-on:update:options="updateOptions"
    v-on:click:row="rowclick"
    v-model="selectedResults"
    :page="options.page"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.sample_id="{ value }">
      <router-link
        v-if="value != null"
          :to="{ name: 'biochip-samples-view', params: { ref: sampleRef(value) }}">
        {{ sampleRef(value) }}
      </router-link>
      <span v-else></span>
    </template>

    <template v-slot:item.callout_id="{ value }">
      <router-link
        v-if="value != null"
          :to="{ name: 'biochip-callouts-detail', params: { id: value }}">
        {{ calloutName(value) }}
      </router-link>
      <span v-else></span>
    </template>

    <template v-slot:item.test_date="{ value }">
      {{ value?.formatDate() || null }}
    </template>
    <template v-slot:item.created_on="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IFilter, ITableOptions } from '@/interfaces';
import { ISample, ICallout, IComparatorTestResult } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

type rowclickHandler = (row:object, slot:object) => void;

@Component
export default class ComparatorTestResultsList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedResults!: IComparatorTestResult[];
  @Prop() public readonly filters!: IFilter[];
  @Prop() public readonly callouts!: ICallout[];
  @Prop() public readonly samples!: ISample[];
  @Prop() public readonly rowclick!: rowclickHandler;
  @Prop() public readonly message!: string;

  public headers: object[] = [
        { text: 'Panel Name', value: 'panel_name' },
        { text: 'Target', value: 'target_name' },
        { text: 'Detected', value: 'detected' },
        { text: 'Site', value: 'site_name' },
        { text: 'Test Date', value: 'test_date' },
        { text: 'Sample', value: 'sample_id' },
        { text: 'Callout', value: 'callout_id' },
        { text: 'Created on', value: 'created_on', cellClass: 'col-date' },
      ];

  public results: IComparatorTestResult[] = [];
  public resultCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'created_on',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    // console.log('onFiltersChanged', val)
    this.options.page = 1;
    this.updateComparatorResultsView();
  }

  @Watch('message')
  public async onMessage(val: string, oldVal: string) {
    // console.log('onMessage')
    this.updateComparatorResultsView();
  }

  public getComparatorResultsCount() {
    api.getComparatorTestResultsCount(readToken(this.$store), this.filters || []).then((response) => {
      this.resultCount = response.data;
    });
  }

  public getComparatorResults() {
    api.searchComparatorTestResults(readToken(this.$store), {filters: this.filters , ...this.options},).then((response) => {
      this.results = response.data;
    });
  }

  public updateComparatorResultsView() {
    this.getComparatorResultsCount();
    this.getComparatorResults();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'created_on';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    this.updateComparatorResultsView();
  }

  public sampleRef(id) {
    const sample = this.samples.find((r) => r.id === id)
    return( sample ? sample.ref : '' );
  }
  public calloutName(id) {
    const callout = this.callouts.find((o) => o.id === id)
    return( callout ? callout.name : '' );
  }

  // public mounted() {
    // this.updateComparatorResultsView();
  // }
}
</script>

<style scoped>
.v-data-table >>> .col-name {
  min-width: 11em;
}
.v-data-table >>> .col-wrap {
  max-width: 12em;
  overflow-wrap: break-word;
}
.v-data-table >>> .col-date {
  min-width: 12em;
}

</style>
