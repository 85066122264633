export const STYLE_BORDER_ALL = { border: { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' } } };
export const STYLE_BORDER_BOTTOM_THICK = { border: { bottom: { style: 'thick' } } };
export const STYLE_BORDER_BOTTOM_THIN = { border: { bottom: { style: 'thin' } } };
export const STYLE_BORDER_RIGHT_THIN = { border: { right: { style: 'thin' } } };
export const STYLE_TITLE = { font: { sz: 16, bold: true } };
export const STYLE_HEADING = { font: { sz: 14, bold: true } };
export const STYLE_SUBHEADING = { font: { sz: 12, bold: true } };
export const STYLE_DESCRIPTION = { font: { sz: 10 } };
export const STYLE_CENTER = { alignment: { horizontal: 'center' } };
export const STYLE_FILL_VALID = { fill: { fgColor: { rgb: 'ADD9E6' }}};
export const STYLE_FILL_INVALID = { fill: { fgColor: { rgb: 'D9D9D9' }}};
export const STYLE_FILL_POSITIVE = { fill: { fgColor: { rgb: 'F44336' }}};
export const STYLE_FILL_NEGATIVE = { fill: { fgColor: { rgb: '4CAE50' }}};

export default({
    STYLE_BORDER_ALL,
    STYLE_BORDER_BOTTOM_THICK,
    STYLE_BORDER_BOTTOM_THIN,
    STYLE_BORDER_RIGHT_THIN,
    STYLE_TITLE,
    STYLE_HEADING,
    STYLE_SUBHEADING,
    STYLE_DESCRIPTION,
    STYLE_CENTER,
    STYLE_FILL_VALID,
    STYLE_FILL_INVALID,
    STYLE_FILL_POSITIVE,
    STYLE_FILL_NEGATIVE
})