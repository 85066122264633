import {  } from '@/interfaces/bcdb';

export function alphabetically(ascending: boolean) {
    /* Used with a sort function. Will ensure null is at the bottom
    for ascending and top for descending order */
    return function (a, b) {
        // Convert non-null values to lowercase for case-insensitive comparison
        const lowerA = a !== null ? a.toLowerCase() : a;
        const lowerB = b !== null ? b.toLowerCase() : b;
        if (lowerA === lowerB) {
            return 0;
        } else if (lowerA === null && ascending) {
            return 1;
        } else if (lowerB === null && ascending) {
            return -1;
        } else if (lowerA === null && !ascending) {
            return -1;
        } else if (lowerB === null && !ascending) {
            return 1;
        } else if (ascending) {
            return lowerA < lowerB ? -1 : 1;
        } else {
            return lowerA < lowerB ? 1 : -1;
        }
    };
}

export function moveFieldToEnd(obj, field) {
    if (obj.hasOwnProperty(field)) {
      const value = obj[field];
      delete obj[field];
      obj[field] = value;
    }
}

export function getUniqueField( obj:any[], field:string):any[] {
    /* From a list of objects return a list of the unique items from a field */
    let uniqueField: any[] = []
    const uniqueFieldSet = new Set<string>();
    uniqueField = obj.map((f) => {
        const item = field.split('.').reduce((obj, key) => obj ? obj[key] : undefined, f);
        uniqueFieldSet.add(item);
        return item;
    });
    uniqueField = Array.from(uniqueFieldSet);
    return(uniqueField)
}

export function getUniqueObjects(data: any[], field: string):any[] {
    /* Used to return an object array where the array is distinct base on one field in the objects */
    const seen = new Set();
    return data.filter(item => {
        const fieldValue = item[field];
        if (!seen.has(fieldValue)) {
            seen.add(fieldValue);
            return true;
        }
        return false;
    });
}

export function deepClone<T>(obj: T): T {
    /* Creates and returns a clone of an object */
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const newArr: any[] = [];
      for (let i = 0; i < obj.length; i++) {
        newArr[i] = deepClone(obj[i]);
      }
      return newArr as T;
    }

    const newObj: Record<string, any> = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = deepClone(obj[key]);
      }
    }

    return newObj as T;
}

const supportFncs = {
    getUniqueField,
    alphabetically,
    getUniqueObjects
}
  
export default supportFncs;