<template>
  <v-container fluid>
    <ProbeFilterPicker
      v-model="selectedFilters">
    </ProbeFilterPicker>

    <ProbeList
      :filters="selectedFilters"
      v-model="selectedProbes">    
    </ProbeList>

  </v-container>
</template>



<script lang="ts">
import ProbeList from '@/components/ProbeList.vue';
import ProbeFilterPicker from '@/components/ProbeFilterPicker.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchGetRunSummary, dispatchMakeArchive, dispatchGetRunSignals } from '@/store/runs/actions';
import { commitSetRunSummary } from '@/store/runs/mutations';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IProbe } from '@/interfaces/bcdb';

import { api } from '@/api';


@Component({
  components: {
    ProbeList,
    ProbeFilterPicker,
  },
})
export default class Probes extends Vue {

  public selectedFilters: IFilter[] = [];
  public selectedProbes: IProbe[] = [];

  public tableOptions: object = {
    itemsPerPage: 25,
    // sortBy: ['created_at'],
    sortDesc: [true],
  };

  public mounted() {
    // console.log('Probes mounted');
    // dispatchGetRuns(this.$store, this.selectedFilters);
  }


}
</script>
