<template>
    <v-navigation-drawer
      app
      permanent
      right
      clipped
      class="test-drawer"
      :width="450"
      v-if="showPanel"
    >
      <template v-slot:prepend>
        <slot name="header"></slot>
      </template>
      <slot></slot>
    </v-navigation-drawer>
</template>



<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RightPanel extends Vue {
  @Prop()
  public readonly showPanel!: boolean;
}
</script>

<style scoped>

</style>
