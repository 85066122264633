import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RunState } from './state';
import { IThermalSignals } from '@/interfaces/bcdb';


const defaultState: RunState = {
  runs: [],
  runs_count: 0,
  page: 1,
  perPage: 10,
  sortBy: 'date_of_run',
  sortDesc: false,
  runSummary: '',
  signals: [],
  ampfeas: [],
  static_thermal_analysis: [],
  thermal_signals: {} as IThermalSignals,
  thermal_stats: [],
  transition_analysis:[],
  archiveTasks: {},
};

export const runsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
