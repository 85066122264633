<template>
  <v-container fluid>
    <v-toolbar light>
      <v-toolbar-title>
        Array Print Runs
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="hasAdminAccess" color="primary" to="/main/print-runs/create">Create Array Print Run</v-btn>
    </v-toolbar>

    <PrintRunFilterPicker
      v-model="selectedFilters">
    </PrintRunFilterPicker>

    <PrintRunList
      :filters="selectedFilters"
      v-model="selectedPrintRuns">    
    </PrintRunList>

  </v-container>
</template>



<script lang="ts">
import PrintRunList from '@/components/PrintRunList.vue';
import PrintRunFilterPicker from '@/components/PrintRunFilterPicker.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IArrayPrintRun } from '@/interfaces/bcdb';
import { readHasAdminAccess } from '@/store/main/getters';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';


@Component({
  components: {
    PrintRunList,
    PrintRunFilterPicker,
  },
})
export default class ArrayPrintRuns extends Vue {

  public selectedPrintRunsHeaders: object[] = [
        { text: 'Name', value: 'name', groupable: false },
        { text: 'Date', value: 'run_date', groupable: false },
        { text: 'Project', value: 'project', groupable: false },
        { text: 'Substrate', value: 'substrate', groupable: false },
        { text: 'Printed Targets', value: 'printed_target_count', groupable: false },
        { text: 'Probes', value: 'probe_count', groupable: false },
        { text: 'Filter', value: 'filter', groupable: false },
        { text: 'Printed', value: 'printed', groupable: false },
        { text: 'Purpose', value: 'Purpose', groupable: false },
        { text: 'Status', value: 'status', groupable: false },
        { text: 'Array Template', value: 'array_template_id', groupable: false },
        { text: '', value: 'id', groupable: false },
      ];

  public selectedFilters: IFilter[] = [];
  public hasAdminAccess = readHasAdminAccess(this.$store);

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };

  public selectedPrintRuns: IArrayPrintRun[] = [];

  public mounted() {
    // dispatchGetRuns(this.$store, this.selectedFilters);
  }

}
</script>

