<template>
  <v-container fluid>

    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div v-if="system" class="headline primary--text">{{system.system_name}} ({{system.system_mac}})</div>
      </v-card-title>
      <v-tabs vertical>
        <v-tab>
          <v-icon left>
            receipt_long
          </v-icon>
          Logs
        </v-tab>
        <v-tab>
          <v-icon left>
            description
          </v-icon>
          Assays
        </v-tab>
        <v-tab>
          <v-icon left>
            list_alt
          </v-icon>
          Tests
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="logHeaders"
                :items="logs"
                :items-per-page="5"
                class="elevation-1"
                @click:row="showLog"
              ></v-data-table>
              <v-virtual-scroll
                height="400"
                item-height="20"
                :items="logData"
              >
                <template v-slot:default="{ item }">
                  {{ item }}
                </template>

              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="assayHeaders"
                :items="system.assays"
                :items-per-page="10"
                class="elevation-1"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <TestList
                :tests="tests">    
              </TestList>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import TestList from '@/components/TestList.vue';

import { Component, Vue, PropSync } from 'vue-property-decorator';
import { ITestResult, IFile, ISystem, IAssay } from '@/interfaces';
import { dispatchGetSystems, dispatchUpdateUser } from '@/store/admin/actions';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readAdminOneSystem } from '@/store/admin/getters';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component({
  components: {
    TestList,
  },
})
export default class ViewSystem extends Vue {
  public tests: ITestResult[] = [];
  public logs: IFile[] = [];

  public logHeaders = [
          { text: 'Filename', value: 'filename' },
          { text: 'Size', value: 'file_size' },
          { text: 'Upload Date', value: 'upload_date' },
        ];

  public assayHeaders = [
          { text: 'Name', value: 'name' },
          { text: 'Version', value: 'assay_version' },
          { text: 'Comments', value: 'comments' },
        ];

  public logData: string[] = [];

  // @PropSync('system', { type: Object }) syncedSystem!: ISystem

  public async mounted() {
    await dispatchGetSystems(this.$store);
    // console.log(this.$router.currentRoute.params.id);
    if (this.system) {
      this.getLogs();
      this.getTests();
    }
  }

  public cancel() {
    this.$router.back();
  }

  public showLog(item, other) {
    this.getLog(item.id);
  }

  public async getTests() {
    try {
      const response = await api.getTestsForSystem(
        readToken(this.$store),
        this.system?.id ?? -1,
      );
      if (response) {
          this.tests = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  /*public async getAssays() {
    try {
      const response = await api.getAssaysForSystem(
        readToken(this.$store),
        this.system?.id ?? -1
      );
      if (response) {
          this.logs = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }*/

  public async getLogs() {
    try {
      const response = await api.getLogsForSystem(
        readToken(this.$store),
        this.system?.id ?? -1,
      );
      if (response) {
          this.logs = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getLog(logId) {
    try {
      const response = await api.getFile(
        readToken(this.$store),
        logId,
      );
      if (response) {
          this.logData = response.data.split('\n');
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  get system() {
    return readAdminOneSystem(this.$store)(+this.$router.currentRoute.params.id);
  }

}
</script>
