<template>
  <!-- All Clinical Data -->
  <v-data-table
    :headers="headers"
    :items="clinicalData"
    item-key="subjectId"
    :dense="true"
    class="elevation-1"
    :server-items-length="clinicalDataCount"
    v-on:update:options="updateOptions"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    v-on:click:row="rowclick"
    v-model="selectedClinicalData"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.target_name="{ item }">
      <router-link :title="`${item.target_name}\n${item.panel_name}`" v-if="item.id != null" :to="{ name: 'biochip-clinical-data-view', params: { id: item.id }}">{{item.target_name}}</router-link>
      <span v-else>{{ item.target_name }}</span>
    </template>  

    <!-- <template v-slot:item.subjectId="{ item }">{{ getValue('subjectId', item) }}</template> -->

    <template v-slot:item.experiment_id="{ value }">
      <router-link
        v-if="value != null"
          :to="{ name: 'biochip-run-view', params: { id: value }}">
        {{ experimentName(value) }}
      </router-link>
      <span v-else></span>
    </template>

    <template v-slot:item.created_on="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { IFilter, ITableOptions } from '@/interfaces';
import { IClinicalData, IRunTracker } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

type rowclickHandler = (row:object, slot:object) => void;

@Component
export default class ClinicalDataList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedClinicalData!: IClinicalData[];

  public headers: object[] = [
        { text: 'Reference', value: 'sample_ref', cellClass: 'col-name' },
        { text: 'Experiment', value: 'experiment_id' },
        { text: 'Created on', value: 'created_on', cellClass: 'col-date' },
      ];


  @Prop() public readonly filters!: IFilter[];
  @Prop() public readonly rowclick!: rowclickHandler;

  public clinicalData: IClinicalData[] = [];
  public clinicalDataCount: number = 0;
  public experiments: any = [];

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'created_on',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateClinicalDataView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getClinicalDataCount() {
    try {
      const response = await api.getClinicalDataCount(readToken(this.$store), this.filters || []);
      if (response) {
        this.clinicalDataCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getClinicalData() {
    try {
      const response = await api.searchClinicalData(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.clinicalData = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getExperiments() {
    try {
      const response = await api.getRunNames(readToken(this.$store));
      if (response) {
        this.experiments = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }
  public experimentName(id) {
    const run = this.experiments.find((r) => r.id === id)
    return( run ? run.experiment : '' );
  }

  public async updateClinicalDataView() {
    this.getClinicalDataCount();
    this.getClinicalData();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'created_on';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    // emit an event?
    this.updateClinicalDataView();
  }

  public getValue(key, item) {
    const pair = item.pairs.find((p) => p.key.toLowerCase() == key.toLowerCase());
    return( pair?.value );
  }

  public mounted() {
    this.getExperiments();
    this.updateClinicalDataView();
  }

  @Socket('upload-clinical-data-complete')
  public async onUploadComplete(info) {
    this.updateClinicalDataView();
  }

}
</script>

<style scoped>
.v-data-table >>> .col-name {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}

</style>
