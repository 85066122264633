import { format, parseISO } from 'date-fns';

export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

declare global {
  interface String {
    capitalize(): string;
    formatDate(boolean,string): string;
    formatDateTime(boolean,string): string;
  }
  interface Number {
    convertMilliseconds(): string;
  }
}

String.prototype.capitalize = function(this:string) {
	return(this.replace(/([a-zA-Z])(\w*)/g, (c,d,e) => d.toUpperCase()+e));
}

String.prototype.formatDate = function(this:string, isUTC: boolean = false, tz: string = 'UTC') {
  if( !this ) { return '' }
  let date = parseISO(this);
  if( isUTC ) { date = parseISO(`${this}Z`) }
  return format(date, 'MM/dd/yyyy');
}

String.prototype.formatDateTime = function(this:string, isUTC: boolean = false, tz: string = 'UTC') {
  if( !this ) { return '' }
  let date = parseISO(this);
  if( isUTC ) { date = parseISO(`${this}Z`) }
  return format(date, 'MM/dd/yyyy h:mmaaa');
}

Number.prototype.convertMilliseconds = function(this:number) {
  var days: number, hours: number, minutes: number, seconds: number, total_hours: number, total_minutes: number, total_seconds: number;
  
  if(this===0){return `0`};
  
  total_seconds = (Math.floor(this / 1000));
  total_minutes = (Math.floor(total_seconds / 60));
  total_hours = (Math.floor(total_minutes / 60));
  days = (Math.floor(total_hours / 24));

  seconds = (total_seconds % 60);
  minutes = (total_minutes % 60);
  hours = (total_hours % 24);
  
	return String(`${days} days, ${hours} hours`)

};
