<template>
  <v-container fluid>

    <v-card v-if="arrayTemplate"  class="ma-3 pa-3">
      <v-card-title primary-title>
        <div>
          <div class="headline d-inline primary--text">{{arrayTemplate.name}}</div>
          <div
              class="mx-2 px-2 d-inline grey lighten white--text rounded"
              v-if="arrayTemplate.state == EArrayTemplateState.draft">Draft</div>
          <div
              class="mx-2 px-2 d-inline grey lighten white--text rounded"
              v-if="arrayTemplate.state == EArrayTemplateState.layout">Layout</div>
          <div
              class="subtitle-1 grey--text"
              v-if="arrayTemplate.state == EArrayTemplateState.draft">
            This template is a draft. Click Submit For Layout when finished.
          </div>
          <div
              class="subtitle-1 orange--text"
              v-if="arrayTemplate.state == EArrayTemplateState.layout">
            This template is currently in Layout and is not editable.
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn
            class="mx-1 mb-auto"
            v-if="!isEditing && arrayTemplate.state == EArrayTemplateState.draft"
            @click="submit"
            :disabled="arrayTemplateProbes.length == 0">
          Submit for Layout
        </v-btn>
        <v-btn
            class="mx-1 mb-auto"
            v-if="!isEditing && arrayTemplate.state == EArrayTemplateState.layout"
            @click="unsubmit">
          Unsubmit from Layout
        </v-btn>
      </v-card-title>
      <v-card-title>
        <div class='error-text'>{{ errorMessage }}</div>
        <div class='success-text'>{{ successMessage }}</div>
        <v-spacer></v-spacer>
        <v-btn
            class="mx-1"
            v-if="!isEditing && arrayTemplate.state == EArrayTemplateState.draft"
            @click="remove">
          Delete
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="!isEditing" @click="exportTemplate">
          Export Excel
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="!isEditing" @click="exportNXT">
          Export NXT
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="!isEditing && arrayTemplate.state == EArrayTemplateState.draft"
            @click="edit" color="primary">
          Edit
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="isEditing && arrayTemplate.state == EArrayTemplateState.draft"
            @click="cancel">
          Cancel
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="isEditing && isNew && arrayTemplate.state == EArrayTemplateState.draft"
            @click="create" :disabled="!valid || selectedNPProbes.length == 0" color="primary">
          Create
        </v-btn>
        <v-btn
            class="mx-1"
            v-if="isEditing && !isNew && arrayTemplate.state == EArrayTemplateState.draft"
            @click="update" :disabled="!valid || selectedNPProbes.length == 0" color="primary">
          Save
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-4">

        <template v-if='isEditing'>
          <v-form v-model="valid" ref="form" lazy-validation>
            <div>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Name"
                    v-model="templateName"
                    v-validate="'required|uniqueName|max:5'"
                    data-vv-name="Name"
                    :error-messages="errors.collect('Name')"
                    @input="onChange"
                    dense
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    label="Project"
                    v-model="templateProject"
                    :items="projects"
                    v-validate="'required'"
                    data-vv-name="Project"
                    :error-messages="errors.collect('Project')"
                    @change="onChange"
                    dense>
                  </v-select>
                </v-col>
              </v-row>

            </div>

          </v-form>
        </template>

        <v-tabs>
          <v-tab>Probe List</v-tab>
          <v-tab v-if="arrayTemplate.id > 0 && arrayTemplate.state != EArrayTemplateState.draft">Matrix Detail</v-tab>
          <v-tab v-if="arrayTemplate.id > 0">Profiles</v-tab>

          <v-tab-item eager>
            <v-card flat>
              <v-card-text>
                <div>
                  <v-btn 
                      v-if="isEditing"
                      color="primary"
                      class='mb-2'
                      @click="openNPProbePicker">
                    Set NP Probe
                  </v-btn>
                </div>
                <div>
                  <ArrayProbeList
                    v-if="arrayTemplateNPProbes.length > 0"
                    v-model="arrayTemplateNPProbes"
                    :replicates="arrayTemplateProbeReplicates"
                    :editMode="isEditing"
                    :allowDrag="false"
                    :draftMode="arrayTemplate.state == EArrayTemplateState.draft">
                  </ArrayProbeList>
                  <span v-else class='pa-3 red--text'>No NP probe set</span>
                </div>

                <div class="py-4">
                  <v-btn 
                      v-if="isEditing"
                      color="primary"
                      class='mb-2'
                      @click="openProbePicker">
                    Edit Probes
                  </v-btn>
                  <v-btn 
                      v-if="isEditing"
                      color="primary"
                      class='mb-2 ml-2'
                      @click="openNewProbeDialog">
                    New Probe
                  </v-btn>
                </div>
                <div>

                  <ArrayProbeList
                    v-model="arrayTemplateProbes"
                    :replicates="arrayTemplateProbeReplicates"
                    :editMode="isEditing"
                    :draftMode="arrayTemplate.state == EArrayTemplateState.draft">
                  </ArrayProbeList>

                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item eager v-if="arrayTemplate.id > 0 && arrayTemplate.state != EArrayTemplateState.draft">
            <v-card flat>
              <v-card-text>
                <div class="matrix">
                  <table class="matrix">
                    <tr>
                      <td class="vlabel">+</td>
                      <td
                        class="hlabel"
                        v-for="i in 32"
                        :key="i"
                        >
                          {{ i }}
                      </td>
                    </tr>
                    <tr
                      v-for="row in 32"
                      :key="row"
                      >
                      <td class="vlabel">{{ row }}
                      </td>
                      <td
                        class="cell"
                        :class="cellClass(col,row)"
                        v-for="col in 32"
                        :key="col"
                        >
                          <router-link
                            :title="getProbeTitle(col,row)"
                            v-if="getProbe(col,row) != null"
                            :to="{ name: 'biochip-probe-detail', params: { id: getProbe(col,row).probe_id }}">
                              {{ getProbeName(col,row) }}
                          </router-link>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Profiles -->
          <v-tab-item eager v-if="arrayTemplate.id > 0">
            <v-card flat>
              <v-card-text>
                <div>
                  <ArrayTemplateProfileList
                    v-model="arrayTemplate.profiles"
                    :arrayTemplateId="arrayTemplate.id"
                  > 
                  </ArrayTemplateProfileList>
                </div>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <ProbePicker
      v-model="selectedNPProbes"
      :showDialog="probeNPPicker"
      :onCancel="handleProbeNPPickerCancel"
      :onSuccess="updateNPProbes"
      :filter="{function:EProbeFunction.normalization}"
      :allowMultiple="false"
    >
    </ProbePicker>

    <ProbePicker
      v-model="selectedProbes"
      :showDialog="probePicker"
      :onCancel="handleProbePickerCancel"
      :onSuccess="updateProbes"
      :maxProbes=160
    >
    </ProbePicker>

    <NewProbeDialog
      v-model="newProbe"
      :showDialog="newProbeDialog"
      :onCancel="handleNewProbeCancel"
      :onSuccess="handleNewProbe"
    >
    </NewProbeDialog>

    <div id='confirm'></div>

  </v-container>
</template>

<script lang="ts">
import ArrayProbeList from '@/components/ArrayProbeList.vue';
import ArrayTemplateProfileList from '@/components/ArrayTemplateProfileList.vue';
import ProbePicker from '@/components/ProbePicker.vue';
import NewProbeDialog from '@/components/NewProbeDialog.vue';
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Validator } from 'vee-validate';
import { IArrayTemplate, IArrayTemplateCreate, EArrayTemplateState } from '@/interfaces/bcdb';
import { IArrayTemplateProbe, IArrayTemplateProbeCreate, IArrayTemplateProbeReplicate } from '@/interfaces/bcdb';
import { IProbe, IProbeCreate } from '@/interfaces/bcdb';
import { EProbeFunction } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import FileSaver from 'file-saver';

import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

Vue.use(VueSweetalert2);

@Component({
  components: {
    ArrayProbeList,
    ArrayTemplateProfileList,
    ProbePicker,
    NewProbeDialog,
    VueSweetalert2,
  },
})
export default class ViewArrayTemplate extends Vue {
  EArrayTemplateState = EArrayTemplateState;
  // TODO: Store these in DB
  public projects: string[] = [ 'ERP', 'URP', 'BCP', 'ONC', 'BCID', 'URP-MP', 'TGF', 'MAS', 
                                'PDV', 'FURP', 'AOP', 'SARS-COV2', 'MURP', 'URP20', 'ARD',
                                'TFG', 'XRP161', 'XRP', 'TST', 'HIV' ];
  // public probes: IProbe[] = [];
  public selectedProbes: IProbe[] = [];
  public newProbe: IProbe = this.emptyProbe();
  public selectedNPProbes: IProbe[] = [];
  public arrayTemplate: IArrayTemplate = {
    id: 0,
    name: 'ArrayTemplate__0Probes',
    template_date:format(new Date(), "yyyy-MM-dd"),
    state: EArrayTemplateState.draft,
    barcode: null,
    profiles: [] };
  public originalArrayTemplateProbes: IArrayTemplateProbe[] = [];
  public arrayTemplateProbes: IArrayTemplateProbe[] = [];
  public arrayTemplateNPProbes: IArrayTemplateProbe[] = [];
  public arrayTemplateProbeReplicates: IArrayTemplateProbeReplicate[] = [];

  public id: number = 0;
  // public search = '';
  public probeCount = 1;
  public isEditing = false;
  public isNew = false;
  public hasAdminAccess = readHasAdminAccess(this.$store);
  public successMessage = '';
  public errorMessage = '';

  public templateName: string = '';
  public templateProject: string = '';

  public valid = false;
  public probePicker = false;
  public probeNPPicker = false;
  public newProbeDialog = false;

  public EProbeFunction = EProbeFunction;


  public getProbe(x,y) {
    return this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
  }

  public getProbeTitle(x,y) {
    const probe = this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
    return probe ? `Probe Code: ${probe.panel_name}\nOrder Name: ${probe.order_name}\nPanel Association: ${probe.panel_association}` : '';
  }

  public getProbeName(x,y) {
    const probe = this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
    return probe ? (probe.probe_ref ? probe.probe_ref : probe.probe_id) : '';
  }

  public isBlank(x,y) {
    const probe = this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
    return( !probe );
  }

  public isBarcode(x,y) {
    if( this.arrayTemplate.barcode ) {
      return( !!this.arrayTemplate.barcode.find((b) => x === b[0] && y === b[1]));
    }
    return false;
  }

  public isHypC(x,y) {
    const probe = this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
    if( probe && (/hybc/i).test(probe.panel_name) ) {
      return true;
    }
    return false;
  }

  public isNP(x,y) {
    const probe = this.arrayTemplateProbeReplicates.find((p) => p.x === x && p.y === y);
    if( probe && (/00[-_]np/i).test(probe.panel_name) ) {
      return true;
    }
    return false;
  }

  public cellClass(x,y) {
    return (!this.isBlank(x,y) ? 'probe' : '')
      + ' ' + (this.isBarcode(x,y) ? 'barcode' : '')
      + ' ' + (this.isHypC(x,y) ? 'hybc-probe' : '')
      + ' ' + (this.isNP(x,y) ? 'np-probe' : '');
  }

  public async loadArrayTemplate() {
    if( this.id ) {
      api.getArrayTemplate(readToken(this.$store), this.id)
      .then((response) => {
        this.arrayTemplate = response.data;
        this.templateName = this.parseName(this.arrayTemplate.name);
        this.templateProject = this.parseProject(this.arrayTemplate.name);
      })
      .catch((error) => {
        this.errorMessage = 'Error loading array template: '+error.response.data.detail
        console.log('ERROR: load template', error);
        dispatchCheckApiError(this.$store, error);
      });
    } else {
      console.log('not loading template')
    }
  }

  public async createArrayTemplate() {
    api.createArrayTemplate(readToken(this.$store), this.arrayTemplate)
      .then((response) => {
        this.arrayTemplate = response.data;
        this.id = this.arrayTemplate.id;
        this.successMessage = 'Created array template';
        this.templateName = this.parseName(this.arrayTemplate.name);
        this.templateProject = this.parseProject(this.arrayTemplate.name);
        // save the probes now
        const atps = [ ...this.arrayTemplateProbes, ...this.arrayTemplateNPProbes ];    
        api.updateArrayTemplateProbes(readToken(this.$store), this.id, atps)
          .then((response) => {
            this.originalArrayTemplateProbes = response.data;
            this.$router.push('/main/array-templates/'+this.id);
          })
          .catch((error) => {
            console.log('ERROR: save array template probes', error);
          });
      })
      .catch((error) => {
        this.errorMessage = 'Error creating array template: '+error.response.data.detail
        console.log('ERROR: Create array template', error);
        dispatchCheckApiError(this.$store, error);
      });
  }

  public async saveArrayTemplate() {
    api.updateArrayTemplate(readToken(this.$store), this.arrayTemplate.id, this.arrayTemplate)
    .then((response) => {
      this.arrayTemplate = response.data;
      this.successMessage = 'Saved successfully'
      this.isEditing = false;
      this.isNew = false;      
      this.templateName = this.parseName(this.arrayTemplate.name);
      this.templateProject = this.parseProject(this.arrayTemplate.name);
    })
    .catch((error) => {
      this.errorMessage = 'Error saving array template: '+error.response.data.detail
      console.log('ERROR: save template', error);
      dispatchCheckApiError(this.$store, error);
    });
  }

  public async saveArrayTemplateProbes() {
    // 1) combine NP and non-NP atps for comparison with original list
    // 2) call delete api on any atps that are no longer in original list of atps saved off at load
    // 3) call update atps api which will update existing and create any new atps
    // 4) update original atps list

    const atps = [ ...this.arrayTemplateProbes, ...this.arrayTemplateNPProbes ];
    this.originalArrayTemplateProbes.forEach((oatp) => {
      if( !atps.find((atp) => oatp.probe_id == atp.probe_id) ) {
        api.deleteArrayTemplateProbe(readToken(this.$store), oatp.id);
      }
    });

    api.updateArrayTemplateProbes(readToken(this.$store), this.arrayTemplate.id, atps)
    .then((response) => {
      this.originalArrayTemplateProbes = response.data;
    })
    .catch((error) => {
      this.errorMessage = 'Error saving array template probes: '+error.response.data.detail
      console.log('ERROR: save array template probes', error);
      dispatchCheckApiError(this.$store, error);
    });
  }

  public loadArrayTemplateProbes() {
    if( this.id ) {
      api.getArrayTemplateProbes(readToken(this.$store), this.id)
      .then((response) => {
        // split probes in to NP and non-NP lists for managing in UI
        // which displays as two separate lists.  We will combine back
        // to one list at save time.
        this.selectedProbes = [];
        this.selectedNPProbes = [];
        this.arrayTemplateProbes = response.data.filter(p => p.probe_ref != 'NP');
        this.arrayTemplateNPProbes = response.data.filter(p => p.probe_ref == 'NP');
        this.originalArrayTemplateProbes = JSON.parse(JSON.stringify(response.data)) as typeof response.data;
        this.arrayTemplateProbes.forEach((atp) => {
          if( atp.probe ) {
            this.selectedProbes.push(atp.probe)
          }
        });
        this.arrayTemplateNPProbes.forEach((atp) => {
          if( atp.probe ) {
            this.selectedNPProbes.push(atp.probe)
          }
        });
      })
      .catch((error) => {
        this.errorMessage = 'Error loading array template probes: '+error.response.data.detail
        console.log('ERROR: load probes', error);
        dispatchCheckApiError(this.$store, error);
      });
    } else {
      console.log('no route id: not loading probes')
    }
  }

  public loadArrayTemplateProbeReplicates() {
    if( this.id ) {
      api.getArrayTemplateProbeReplicates(readToken(this.$store), this.id)
      .then((response) => {
        this.arrayTemplateProbeReplicates = response.data;
      })
      .catch((error) => {
        this.errorMessage = 'Error loading array template probes replicates: '+error.response.data.detail
        console.log('ERROR: load probe replicates', error);
        dispatchCheckApiError(this.$store, error);
      });
    } else {
      console.log('not loading probe replicates')
    }
  }

  public async mounted() {
    this.id = parseInt(this.$router.currentRoute.params.id);
    if( this.id ) {
      this.refresh();
    } else {
      this.isNew = true;
      this.isEditing = true;
    }
  
    Validator.extend('uniqueName', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => true,  // TODO: check against existing names
    });
  }

  public refresh() {
    this.loadArrayTemplate();
    this.loadArrayTemplateProbes();
    this.loadArrayTemplateProbeReplicates();
  }

  public async cancel() {
    const result = await this.$swal({
      html: 'Are you sure you want to cancel?<br>Changes will be lost.',
      icon: 'info',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      allowOutsideClick: false,
      focusCancel: true,
      reverseButtons: true,
      customClass: { container: 'confirm' },
    });
    if( result.isConfirmed ) {
      this.$validator.reset();
      this.isEditing = false;
      this.isNew = false;
      this.errorMessage = '';
      this.successMessage = '';
      if( this.id ) {
        this.refresh();
      } else {
        // this is a new template that has never been saved, so redirect back to the list
        this.goToTemplateList();
      }
    }
  }

  public goToTemplateList() {
    this.$router.push('/main/array-templates/');
  }

  public edit() {
    this.isEditing = true;
    this.isNew = false;
    this.errorMessage = '';
    this.successMessage = '';
    this.arrayTemplateProbes.sort((a,b) => a.template_order - b.template_order);
  }

  public async remove() {
    const result = await this.$swal({
      title: 'Warning!',
      text: 'Are you sure you want to delete this template?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      allowOutsideClick: false,
      focusCancel: true,
      reverseButtons: true,
      customClass: { container: 'confirm' },
    });
    if( result.isConfirmed ) {
      // don't really care if it was successful or not
      api.deleteArrayTemplate(readToken(this.$store), this.arrayTemplate.id)
      this.goToTemplateList();
    }
  }

  public async create() {
    if( await this.$validator.validateAll() ) {
      this.createArrayTemplate()
        .then(() => {
          this.isEditing = false;
          this.isNew = false;
        });
    } else {
      console.log('validation error')
    }
  }

  public async update() {
    if( await this.$validator.validateAll() ) {
      this.saveArrayTemplate();
      this.saveArrayTemplateProbes();
    } else {
      console.log('validation error')
    }
  }

  public submit() {
    this.arrayTemplate.state = EArrayTemplateState.layout;
    this.saveArrayTemplate()
    // TODO: Call API to submit
    api.submitArrayTemplate(readToken(this.$store), this.arrayTemplate.id)
      .then((response) => {
        console.log('submit response =', response.data);
        this.arrayTemplate = response.data;
        this.templateName = this.parseName(this.arrayTemplate.name);
        this.templateProject = this.parseProject(this.arrayTemplate.name);      
    })
  }

  public unsubmit() {
    this.arrayTemplate.state = EArrayTemplateState.draft;
    this.saveArrayTemplate()
    // TODO: Call API to unsubmit
    api.unsubmitArrayTemplate(readToken(this.$store), this.arrayTemplate.id)
      .then((response) => {
        console.log('unsubmit response =', response.data);
        this.arrayTemplate = response.data;
        this.templateName = this.parseName(this.arrayTemplate.name);
        this.templateProject = this.parseProject(this.arrayTemplate.name);      
    })
  }

  public exportTemplate() {
    api.getArrayTemplateExport(readToken(this.$store), this.arrayTemplate.id)
      .then((response) => {
        const fileNameHeader = 'x-suggested-filename';
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName = (suggestedFileName === undefined
                    ? 'ArrayTemplate.xlsx'
                    : suggestedFileName);
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  public exportNXT() {
    api.getArrayTemplateNXT(readToken(this.$store), this.arrayTemplate.id)
      .then((response) => {
        const fileNameHeader = 'x-suggested-filename';
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName = (suggestedFileName === undefined
                    ? 'ArrayTemplate.nxt'
                    : suggestedFileName);
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  public updateProbes() {
    // var probe_count = 1;

    var probe_count = 1;
    this.arrayTemplateProbes = [];
    this.selectedProbes.forEach((p) => {
      this.arrayTemplateProbes.push({
        array_template_id: this.arrayTemplate.id,
        probe_id: p.id,
        probe_ref: '',
        template_order: probe_count++,
        probe: {
          panel_name: p.panel_name,
          order_name: p.order_name,
          panel_association: p.panel_association,
          callout_id: p.callout_id,
          gene_region: p.gene_region,
          function: p.function,
          probe_sequence: p.probe_sequence,
          probe_type: p.probe_type,
          wild_type_probe_id: p.wild_type_probe_id,
          analysis_code: p.analysis_code,
          probe_description: p.probe_description
        } as IProbe
      } as IArrayTemplateProbe);
    });
    this.probePicker = false;
    this.updateTemplateName();
  }

  public updateNPProbes() {
    this.arrayTemplateNPProbes = [];
    this.selectedNPProbes.forEach((p) => {
      this.arrayTemplateNPProbes.push({
        array_template_id: this.arrayTemplate.id,
        probe_id: p.id,
        probe_ref: 'NP',
        template_order: 0,
        probe: {
          panel_name: p.panel_name,
          order_name: p.order_name,
          panel_association: p.panel_association,
          callout_id: p.callout_id,
          gene_region: p.gene_region,
          function: p.function,
          probe_sequence: p.probe_sequence,
          probe_type: p.probe_type,
          wild_type_probe_id: p.wild_type_probe_id,
          analysis_code: p.analysis_code,
          probe_description: p.probe_description
        } as IProbe
      } as IArrayTemplateProbe);
    });
    this.probeNPPicker = false;
    this.updateTemplateName();
  }

  public openProbePicker() {
    this.probePicker = true;
  }

  public openNPProbePicker() {
    this.probeNPPicker = true;
  }

  public openNewProbeDialog() {
    this.newProbeDialog = true;
  }

  public handleNewProbe() {
    this.newProbeDialog = false;
    this.selectedProbes.push(this.newProbe);
    this.updateProbes();
}

  public handleProbePickerCancel() {
    this.probePicker = false;
  }

  public handleProbeNPPickerCancel() {
    this.probeNPPicker = false;
  }

  public handleNewProbeCancel() {
    this.newProbeDialog = false;
    this.newProbe = this.emptyProbe();
  }

  public parseName(name) {
    const regex = /Array_?Template_([^_]+)_([^_]+)_.*/i;
    const parts = regex.exec(name);
    return( parts ? parts[2] : '' )
  }
  public parseProject(name) {
    const regex = /Array_?Template_([^_]+)_([^_]+)_.*/i;
    const parts = regex.exec(name);
    return( parts ? parts[1] : '' )
  }

  public calculateTemplateName() {
    const count = this.arrayTemplateProbes.length + this.arrayTemplateNPProbes.length
    const date = format(new Date(), "yyyyMMdd")
    return `ArrayTemplate_${this.templateProject}_${this.templateName}_${count}Probes_${date}`
  }

  public updateTemplateName() {
    this.errorMessage = '';
    this.arrayTemplate.name = this.calculateTemplateName();
  }

  public onChange(newValue) {
    this.arrayTemplate.name = this.calculateTemplateName();
  }

  public emptyProbe() {
    return({
      id: 0,
      panel_name: '',
      order_name: null,
      panel_association: null,
      callout_id: null,
      gene_region: null,
      function: null,
      probe_sequence: null,
      probe_type: null,
      analysis_code: null,
      probe_description: null,
      date_added: '',
      created_by_id: 0,
      modified_by_id: 0,
      callout: null,
    } as IProbe);
  }
}


</script>

<style>
.confirm {
  font-family: Roboto, sans-serif !important;
}

</style>

<style scoped>

.matrix {
  min-width: 1100px;
  border-collapse: collapse;
}

.matrix .vlabel {
  width: 26px;
  height: 24px;
  text-align: left;
}
.matrix .hlabel {
  width: 35px;
  height: 24px;
  text-align: center;
}
.matrix .cell {
  width: 35px;
  height: 24px;
  font-size: x-small;
  border: 1px lightgray solid;
  text-align: center;
}
.matrix .barcode {
    border: 2px black solid;
}
.matrix .probe {
    background-color: #D5E6FB;
}
.matrix .hybc-probe {
    background-color: #FFCDAB;
}
.matrix .np-probe {
    background-color: #FFF2AB;
}

.message-text, .success-text, .error-text {
  font-size: medium;
  font-weight: normal;
}
.success-text {
  color: green;
}
.error-text {
  color: red;
}

</style>