var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clinicalData,"item-key":"subjectId","dense":true,"server-items-length":_vm.clinicalDataCount,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions,"click:row":_vm.rowclick},scopedSlots:_vm._u([{key:"item.target_name",fn:function({ item }){return [(item.id != null)?_c('router-link',{attrs:{"title":`${item.target_name}\n${item.panel_name}`,"to":{ name: 'biochip-clinical-data-view', params: { id: item.id }}}},[_vm._v(_vm._s(item.target_name))]):_c('span',[_vm._v(_vm._s(item.target_name))])]}},{key:"item.experiment_id",fn:function({ value }){return [(value != null)?_c('router-link',{attrs:{"to":{ name: 'biochip-run-view', params: { id: value }}}},[_vm._v(" "+_vm._s(_vm.experimentName(value))+" ")]):_c('span')]}},{key:"item.created_on",fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true, "America/Los_Angeles"))+" ")]}}]),model:{value:(_vm.selectedClinicalData),callback:function ($$v) {_vm.selectedClinicalData=$$v},expression:"selectedClinicalData"}})
}
var staticRenderFns = []

export { render, staticRenderFns }