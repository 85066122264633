<template>
    <v-container fluid>

        <v-simple-table class="elevation-1" style="margin-bottom: 30px;">
            <template v-slot:default>
            <thead>
                <tr>
                    <th rowspan="2" class="text-center"> Site </th>
                    <th rowspan="2" >Number of G2B Instruments</th>
                    <th v-for="(group, index) in sampleColumns" :colspan="group.subColumns.length" :class="group.style + ' text-center'">
                        <v-btn text  @click="viewRunsClicked(group.mainColumn, 'experiment_type')">
                            {{ group.mainColumn }}
                        </v-btn>
                    </th>
                </tr>
                <tr>
                    <template  v-for="(group, index) in sampleColumns">
                        <th v-for="(subColumn, subIndex) in sampleColumns[index].subColumns">
                            {{ subColumn }}
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in data" :key="index">
                    <td v-for="(value, key) in item" :key="key">
                        <v-btn text v-if="key==='site'" @click="viewRunsClicked(value, 'site_name')">{{ value }}</v-btn>
                        <template v-else-if="key.includes('nd_') || key.includes('ppa') || key.includes('npa')">{{ value }}%</template>
                        <template v-else>{{ value }}</template>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="enableDialog">
            <RunListDialog
            v-if="enableDialog"
            v-model="subsetSelectedRuns"
            :tableSetup="tableSetup"
            :tableItems="filteredRuns"
            :tableErrors="selectedErrors"
            @apply-runListDialog="runListDialogResponse('apply')"
            @close-runListDialog="runListDialogResponse('close')">
            </RunListDialog>
        </v-dialog>

    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
  import { VMenu } from 'vuetify/lib';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IRunTracker, IRunErrors, ICallout, IPpaNpa, IInternalCallouts, ISample, IComparatorMap, IClinicalRuntracker } from '@/interfaces/bcdb';
  import { Socket } from 'vue-socket.io-extended';
  
  import { subWeeks, startOfToday, parseISO, format, formatISO, isThisMinute, isThisHour } from 'date-fns';
  import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
  import buildObjectToExcel from '@/scripts/to-excel/object-to-excel';
  import {getUniqueField, alphabetically} from '@/scripts/support-functions';
  import {calcNPA, calcPPA} from '@/scripts/calculations'
  import { checkDuplicateComparatorCall } from '@/scripts/clinical-data-helpers'
  import { contrivedTypes, clinicalTypes } from '@/scripts/constants'
  import eventBus from '@/eventBus';
  
  import * as Plotly from 'plotly.js';
  import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';
  import { toDateWithOptions } from 'date-fns-tz/fp';
//   import { FunctionalComponentMountOptions } from '@vue/test-utils';
  import { internals } from '@azure/msal-browser';
  import RunListDialog from '../RunListDialog.vue';
  
  
  @Component({
    components: {
      RunListDialog,
    },
  })
  export default class SiteComparatorTable extends Vue {
    @ModelSync('value', 'change', { type: Array }) public runs!: IClinicalRuntracker[]
    @Prop({default:()=>[]}) public selectedRuns!: IClinicalRuntracker[]
    @Prop({default:()=>[]}) public readonly comparisons!: IPpaNpa[]
    @Prop({default:()=>'Callout'}) public readonly dataGroupBy!: string
    @Prop({default:()=>[]}) public readonly selectedErrors!: IRunErrors[]

    private formatTableHandler: (e: any) => void = this.handleComparatorTable.bind(this);
    private updatePpaNpaHandler: (e: any) => void = this.handlePpaNpa.bind(this);

    public unknownSiteName = 'N/A'

    public filteredRuns: IClinicalRuntracker[] = []
    public enableDialog = false

    public toggleSampleType: string = 'Clinical Samples'
    public sampleColumns = [
        {mainColumn: 'Control/Contrived Samples', subColumns:['Number of Runs','ND','PPA', 'NPA'], style:'contrived-column'},
        {mainColumn: 'Clinical Samples', subColumns:['Number of Runs', 'Runs w/ Medrio Data','ND','PPA', 'NPA'], style:'clinical-column'},
        {mainColumn: 'Combined Samples', subColumns:['Number of Runs','ND','PPA', 'NPA'], style:'combined-column'},
    ]

    public subsetSelectedRuns:IClinicalRuntracker[]=[]
    public tableSetup: any = {id: "RunTracker.id", headers: [
        { text: 'Date of Run', value: 'RunTracker.date_of_run', cellClass: 'col-date', align: 'center' },
        { text: 'Experiment', value: 'RunTracker.experiment', cellClass: 'col-experiment', align: 'left' },
        { text: 'Run', value: 'RunTracker.run', cellClass: 'col-run', align: 'center' },
        { text: 'Site', value: 'site_name', cellClass: 'col-panels', align: 'center' },
        { text: 'Panels', value: 'panels', cellClass: 'col-panels', align: 'center' },
        { text: 'Type', value: 'RunTracker.experiment_type', cellClass: 'col-experiment_type', align: 'center' },
        { text: 'Instrument', value: 'rig', cellClass: 'col-rig', align: 'center' },
        { text: 'Failure', value: 'RunTracker.failures', cellClass: 'col-failures', align: 'center' },
        { text: 'ADF Version', value: 'adf_version', cellClass: 'col-rig', align: 'center' },
        ], dialogTitle: 'Experiments', viewGroup: null, selectable:true, sortBy:'RunTracker.date_of_run'
    };

    public data: any[]= []

    public async updatePpaNpaMetrics(comparisons:IPpaNpa[]){
        let cum_tp_count = [0,0,0];
        let cum_tn_count = [0,0,0];
        let cum_fp_count = [0,0,0];
        let cum_fn_count = [0,0,0];
        for ( let i=0; i<this.data.length; i++) {
            if (comparisons.length==0){
                this.data[i].ppa_contrived = '-'
                this.data[i].npa_contrived = '-'
                this.data[i].ppa_clinical = '-'
                this.data[i].npa_clinical = '-'
                this.data[i].ppa_combined = '-'
                this.data[i].npa_combined = '-'
            } else if (this.data[i].site !== 'Total') {
                const site = this.data[i].site===this.unknownSiteName?null:this.data[i].site
                const filter_by_site = this.selectedRuns.filter((r)=> r.site_name===site)
                const site_run_names = filter_by_site.map((r)=>r.RunTracker.experiment)
                const contrived_run_names = filter_by_site.filter((r)=> contrivedTypes.includes(r.RunTracker.experiment_type)).map((r)=>r.RunTracker.experiment)
                const clinical_run_names = filter_by_site.filter((r)=> clinicalTypes.includes(r.RunTracker.experiment_type)).map((r)=>r.RunTracker.experiment)

                let accessedPanelCallouts: string[] = []
                let tp_count = [0,0,0];
                let tn_count = [0,0,0];
                let fp_count = [0,0,0];
                let fn_count = [0,0,0];
                for (const item of comparisons) {
                    const site_tp = item.tp.filter((tp)=>site_run_names.includes(tp.run_name))
                    const site_tn = item.tn.filter((tn)=>site_run_names.includes(tn.run_name))
                    const site_fp = item.fp.filter((fp)=>site_run_names.includes(fp.run_name))
                    const site_fn = item.fn.filter((fn)=>site_run_names.includes(fn.run_name))

                    if( this.dataGroupBy === 'Callout' ){
                        const sitePpaNpa: IPpaNpa = {group:item.group,  tp: site_tp, tn:site_tn, 
                            fp:site_fp, fn:site_fn, undetermined:[], 
                            ppa:0, npa:0, comparator_panel:item.comparator_panel}
                        const response = checkDuplicateComparatorCall(accessedPanelCallouts,sitePpaNpa)
                        if (response.accessed){
                            continue
                        } else{
                            accessedPanelCallouts = response.accessedPanelCallouts
                        }
                    }

                    tp_count[0]+=site_tp.filter((tp)=>contrived_run_names.includes(tp.run_name)).length
                    tn_count[0]+=site_tn.filter((tn)=>contrived_run_names.includes(tn.run_name)).length
                    fp_count[0]+=site_fp.filter((fp)=>contrived_run_names.includes(fp.run_name)).length
                    fn_count[0]+=site_fn.filter((fn)=>contrived_run_names.includes(fn.run_name)).length

                    tp_count[1]+=site_tp.filter((tp)=>clinical_run_names.includes(tp.run_name)).length
                    tn_count[1]+=site_tn.filter((tn)=>clinical_run_names.includes(tn.run_name)).length
                    fp_count[1]+=site_fp.filter((fp)=>clinical_run_names.includes(fp.run_name)).length
                    fn_count[1]+=site_fn.filter((fn)=>clinical_run_names.includes(fn.run_name)).length

                    tp_count[2]+=site_tp.length
                    tn_count[2]+=site_tn.length
                    fp_count[2]+=site_fp.length
                    fn_count[2]+=site_fn.length
                }
                for(let idx=0; idx<tp_count.length; idx++){
                    cum_tp_count[idx]+=tp_count[idx]
                    cum_tn_count[idx]+=tn_count[idx]
                    cum_fp_count[idx]+=fp_count[idx]
                    cum_fn_count[idx]+=fn_count[idx]
                }
                this.data[i].ppa_contrived = calcPPA(tp_count[0], fn_count[0])
                this.data[i].npa_contrived = calcNPA(tn_count[0], fp_count[0])
                this.data[i].ppa_clinical = calcPPA(tp_count[1], fn_count[1])
                this.data[i].npa_clinical = calcPPA(tn_count[1], fp_count[1])
                this.data[i].ppa_combined = calcPPA(tp_count[2], fn_count[2])
                this.data[i].npa_combined = calcPPA(tn_count[2], fp_count[2])
            } else {
                this.data[i].ppa_contrived = calcPPA(cum_tp_count[0], cum_fn_count[0])
                this.data[i].npa_contrived = calcNPA(cum_tn_count[0], cum_fp_count[0])
                this.data[i].ppa_clinical = calcPPA(cum_tp_count[1], cum_fn_count[1])
                this.data[i].npa_clinical = calcPPA(cum_tn_count[1], cum_fp_count[1])
                this.data[i].ppa_combined = calcPPA(cum_tp_count[2], cum_fn_count[2])
                this.data[i].npa_combined = calcPPA(cum_tn_count[2], cum_fp_count[2])
            }
        }
    }

    public async formatTable(){
        this.data = []
        if (this.selectedRuns !== undefined) {
            let unique_sites = getUniqueField(this.selectedRuns, 'site_name')
            unique_sites = unique_sites.sort(alphabetically(true))

            let total_nd = {contrived:0, clinical:0}
            let total_runs = {contrived:0, clinical:0}
            let total_w_medrio = 0

            for (let i = 0; i < unique_sites.length; i++){
                const filter_by_site = this.selectedRuns.filter((r)=> r.site_name===unique_sites[i])
                const filter_by_contrived = filter_by_site.filter((r)=> contrivedTypes.includes(r.RunTracker.experiment_type))
                const filter_by_clinical = filter_by_site.filter((r)=> clinicalTypes.includes(r.RunTracker.experiment_type))
                total_runs.contrived += filter_by_contrived.length
                total_runs.clinical += filter_by_clinical.length

                let nd_contrived = filter_by_contrived.filter((r)=> r.RunTracker.failures!== null).length
                total_nd.contrived += nd_contrived 
                nd_contrived = Math.round((nd_contrived/filter_by_contrived.length)*100)
                nd_contrived = Number.isNaN(nd_contrived)?0:nd_contrived

                let nd_clinical =filter_by_clinical.filter((r)=> r.RunTracker.failures!== null).length
                total_nd.clinical += nd_clinical 
                nd_clinical = Math.round((nd_clinical/filter_by_clinical.length)*100)
                nd_clinical = Number.isNaN(nd_clinical)?0:nd_clinical

                const clinical_with_medrio = filter_by_clinical.filter((r)=> r.panels[0]!==null).length
                total_w_medrio += clinical_with_medrio

                this.data.push(
                    { site: unique_sites[i]==null?this.unknownSiteName:unique_sites[i], instruments: getUniqueField(filter_by_site, 'rig').length, 
                    runs_contrived:filter_by_contrived.length, nd_contrived:nd_contrived, ppa_contrived:0, npa_contrived:0,
                    runs_clinical:filter_by_clinical.length, runs_with_medrio:clinical_with_medrio, nd_clinical:nd_clinical, ppa_clinical:0, npa_clinical:0,
                    runs_combined:filter_by_site.length, nd_combined:nd_contrived+nd_clinical, ppa_combined:0, npa_combined:0,
                    }
                )
                
            }
            const nd_total = {
                contrived: Math.round((total_nd.contrived/total_runs.contrived)*100),
                clinical: Math.round((total_nd.clinical/total_runs.clinical)*100),
                combined: Math.round(((total_nd.contrived+total_nd.clinical)/(total_runs.contrived+total_runs.clinical))*100)
            }
            this.data.push(
                { site: "Total", instruments: getUniqueField(this.selectedRuns, 'rig').length,
                    runs_contrived:total_runs.contrived, nd_contrived:Number.isNaN(nd_total.contrived)?0:nd_total.contrived, ppa_contrived:0, npa_contrived:0,
                    runs_clinical:total_runs.clinical, runs_with_medrio:total_w_medrio, nd_clinical:Number.isNaN(nd_total.clinical)?0:nd_total.clinical, ppa_clinical:0, npa_clinical:0,
                    runs_combined:total_runs.contrived+total_runs.clinical, nd_combined:Number.isNaN(nd_total.combined)?0:nd_total.combined, ppa_combined:0, npa_combined:0,
                }
            )
        }
    }

    public async viewRunsClicked(filter, field){
        this.filteredRuns = []
        if (field==='experiment_type') {
            let sampleTypeFilter: string[] = []
            switch(filter) {
                case this.sampleColumns[0].mainColumn: //'Control/Contrived Samples'
                    sampleTypeFilter = contrivedTypes
                    break;
                case this.sampleColumns[1].mainColumn: //'Clinical Samples'
                    sampleTypeFilter = clinicalTypes
                    break;
                case this.sampleColumns[2].mainColumn: //'Combined Samples'
                    sampleTypeFilter = clinicalTypes.concat(contrivedTypes)
                    break
            }
            this.filteredRuns = this.runs.filter((r) => sampleTypeFilter.includes(r.RunTracker.experiment_type))
        } else if (field === 'site_name') {
            if (filter ===this.unknownSiteName) {
                this.filteredRuns = this.runs.filter((r) => r.site_name===null)
            } else if ( filter === 'Total' ) {
                this.filteredRuns = this.runs
            } else {
                this.filteredRuns = this.runs.filter((r) => r.site_name===filter)
            }
        }
        this.subsetSelectedRuns = this.selectedRuns.filter((r)=> this.filteredRuns.includes(r))
        this.enableDialog = true
    }

    public async runListDialogResponse(msg:string){
        if (msg==='close') {
            //Don't do anything
        } else if (msg === 'apply') {
            const items_to_remove = this.filteredRuns.filter(e=> !this.subsetSelectedRuns.some((s => s.RunTracker.id===e.RunTracker.id)))
            const items_to_keep = this.runs.filter(e=> !items_to_remove.some((s => s.RunTracker.id===e.RunTracker.id)))
            this.$emit('selection-change-comparator-table', items_to_keep)
        }
        this.enableDialog = false
    }

    public mounted(){
        eventBus.on('siteComparatorTable', this.formatTableHandler )
        eventBus.on('updatePpaNpaComparatorTable', this.updatePpaNpaHandler )
        this.formatTable()
        this.updatePpaNpaMetrics(this.comparisons)
    }

    public beforeDestroy() {
        eventBus.off('siteComparatorTable', this.formatTableHandler )
        eventBus.off('updatePpaNpaComparatorTable', this.updatePpaNpaHandler )
    }

    private handleComparatorTable(e: any) {
        this.formatTable()
    }
    private handlePpaNpa(e: any) {
        this.updatePpaNpaMetrics(e)
    }
  }
</script>
  
<style scoped>
  .contrived-column {
  background-color: #aacaeb;
}
.clinical-column {
  background-color: #9cf0cd;
}
.combined-column {
  background-color: #ebaab9;
}
.column-borders {
  border-right: 2px solid black;
  border-left: 2px solid black;
}

</style>