<template>
  <v-dialog v-model="showDialog" width="1000" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Create New Probe
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Probe Code"
                    v-model="newProbe.panel_name"
                    v-validate="'required|uniqueProbeCode|alphaNumeric'"
                    data-vv-name="Probe Code"
                    :error-messages="errors.collect('Probe Code')"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="newProbe.order_name"
                    label="Order Name"
                    v-validate="'required|uniqueProbeOrder|alphaNumeric|initialDigits'"
                    data-vv-name="Order Name"
                    :error-messages="errors.collect('Order Name')"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    label="Function"
                    v-model="newProbe.function"
                    :items="probeFunctions"
                    v-validate="'required'"
                    data-vv-name="Function"
                    :error-messages="errors.collect('Function')"
                    dense>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combobox
                    label="Panel Association"
                    type="text"
                    :items="panels"
                    v-model="newProbe.panel_association"
                    v-validate="'required'"
                    data-vv-name="Panel Association"
                    :error-messages="errors.collect('Panel Association')"
                    clearable
                    dense>
                  </v-combobox>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="Callout"
                    :items="callouts"
                    v-model="newProbe.callout_id"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    v-validate=""
                    data-vv-name="Callout"
                    :error-messages="errors.collect('Callout')"
                    clearable
                    dense>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-select v-if="newProbe.function == EProbeFunction.discrimination"
                    label="Probe Type"
                    v-model="newProbe.probe_type"
                    :items="probeTypes2"
                    item-text="name"
                    item-value="value"
                    v-validate="'required'"
                    data-vv-name="Probe Type"
                    :error-messages="errors.collect('Probe Type')"
                    dense>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="newProbe.gene_region"
                    label="Gene Region"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                </v-col>
                <v-col>
                  <v-autocomplete v-if="newProbe.function == EProbeFunction.discrimination && newProbe.probe_type == EProbeType.mutant"
                    label="Wild Type Probe"
                    type="text"
                    v-model="newProbe.wild_type_probe_id"
                    :items="probes"
                    item-text="panel_name"
                    item-value="id"
                    v-validate="'required'"
                    data-vv-name="Wild Type Probe"
                    :error-messages="errors.collect('Wild Type Probe')"
                    dense>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newProbe.probe_sequence"
                    label="Sequence"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newProbe.probe_description"
                    label="Description"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel">Cancel</v-btn>
        <v-btn @click="handleSuccess" color="primary">Create</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>



<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { IProbe, IProbeCreate, ICallout } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';
import { dispatchCheckApiError } from '@/store/main/actions';
import { format, parseISO } from 'date-fns';
import { Validator } from 'vee-validate';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';

type onHandler = () => void;

@Component
export default class NewProbeDialog extends Vue {
  @ModelSync('value', 'change', { type: Object })
  public probe!: IProbe;
  @Prop() public readonly showDialog!: boolean;
  @Prop() public readonly onCancel!: onHandler;
  @Prop() public readonly onSuccess!: onHandler;

  public newProbe: IProbeCreate = this.emptyProbeCreate();
  public probes: IProbe[] = [];
  public callouts: ICallout[] = [];
  public panels: string[] = [];
  public probeFunctions: string[] = Object.values(EProbeFunction);
  public probeTypes: string[] = Object.keys(EProbeType)
      .filter(k => isNaN(Number(k)))
      .map(k => k[0].toUpperCase() + k.substring(1));
  public EProbeFunction = EProbeFunction;
  public EProbeType = EProbeType;
  public wtProbeId: number = 0;
  public probeTypes2 = Object.entries(EProbeType)
    .filter((t) => Number.isInteger(t[1]))
    .map((t) => { return( {name:t[0][0].toUpperCase() + t[0].substring(1), value:t[1]} ) });

  public valid = false;

  public mounted() {
    Validator.extend('initialDigits', {
      getMessage: (field) => 'The ' + field + ' field must start with 3 or 4 digits.',
      validate: (value) => value.match(/^\d{3,4}/) ? true : false
    });
    Validator.extend('alphaNumeric', {
      getMessage: (field) => 'The ' + field + ' field contains invalid characters.',
      validate: (value) => value.match(/^[\w\.]*$/) ? true : false
    });
    Validator.extend('uniqueProbeCode', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => !this.probes.find((pr) => 
        pr.panel_name === value ),
    });
    Validator.extend('uniqueProbeOrder', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => !this.probes.find((pr) => 
        pr.order_name === value ),
    });
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    this.newProbe = this.emptyProbeCreate();
  }

  public async loadProbes() {
    api.getProbes(readToken(this.$store)
    ).then((response) => {
      this.probes = response.data;
      this.panels = [
        ...new Set(
            this.probes
              .filter((p)=> p.panel_association)          // non-null panels
              .map((p) => p.panel_association as string)  // unique panels
        )
      ].sort();
    }).catch((error) => {
      dispatchCheckApiError(this.$store, error);
    });
  }

  public async loadCallouts() {
    api.getCallouts(readToken(this.$store)
    ).then((response) => {
      this.callouts = response.data.sort((a,b) => a.name.localeCompare(b.name));
    }).catch((error) => {
      dispatchCheckApiError(this.$store, error);
    });
  }

  @Watch('showDialog')
  public async onShowChanged(val: boolean, oldVal: boolean) {
    if( val ) {
      this.onOpen();
    } else {
      this.onClose();
    }
  }

  public onOpen() {
    this.loadProbes();
    this.loadCallouts();
  }

  public onClose() {
  }

  public handleCancel() {
    this.reset();
    if( this.onCancel )
      this.onCancel();
}

  public async handleSuccess() {
    if (await this.$validator.validateAll()) {
      api.createProbe(readToken(this.$store), this.newProbe).then((response) => {
        this.probe = response.data;
        this.onSuccess();
        this.reset();
      });
    }
  }

  public emptyProbeCreate() {
    return({
      panel_name: '',
      order_name: null,
      panel_association: null,
      callout_id: null,
      gene_region: null,
      function: null,
      probe_sequence: null,
      probe_type: null,
      wild_type_probe_id: null,
      analysis_code: null,
      probe_description: null,
    } as IProbeCreate);
  }
}
</script>

<style scoped>

</style>
