import axios from 'axios';
import { apiUrl } from '@/env';
import { IProductComparisonRulesMerged, IProductComparisonRule
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getProductComparisonByID(token: string, rule_set_id: number) {
    return axios.get<IProductComparisonRulesMerged>(`${apiUrl}/api/v1/product_comparison_rules/get_by_rule_id/${rule_set_id}`, authHeaders(token));
  },
  async getDistinctProductComparisonRules(token: string, product_name:string, include_other_created:boolean) {
    return axios.get<IProductComparisonRule[]>(`${apiUrl}/api/v1/product_comparison_rules/distinct_rules/{id}?product=${product_name}&include_other_created=${include_other_created}`, authHeaders(token));
  },
  async deleteProductComparison(token: string, rule_set_id:number) {
    return axios.delete<boolean>(`${apiUrl}/api/v1/product_comparison_rules/${rule_set_id}`, authHeaders(token));
  },
  async createUpdateProductComparison(token: string, product_comparison_rule:IProductComparisonRulesMerged) {
    return axios.post<IProductComparisonRulesMerged[]>(`${apiUrl}/api/v1/product_comparison_rules/`, product_comparison_rule,authHeaders(token));
  },
};
