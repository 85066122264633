<template>
  <v-dialog v-model="showDialog" width="1000" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Choose Probes
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="probeHeaders"
          :search="search"
          :items="showProbes"
          item-key="panel_name"
          show-select
          :single-select="!allowMultiple"
          loading="Loading..."
          :dense="true"
          class="elevation-1"
          v-model="localSelectedProbes"
          :custom-filter="customFilter"
          @click:row="onClickRow"
          height=352
        > 
          <template v-slot:item.callout_id="{ item }">
            {{ item.callout ? item.callout.name : "" }}
          </template>

          <template v-slot:item.date_added="{ value }">
            {{ value.formatDateTime(true, "America/Los_Angeles") }}
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <span
          v-if='localSelectedProbes.length > maxProbes'
          class='red--text'>
          Max # of probes is {{maxProbes}}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel">Cancel</v-btn>
        <v-btn
          @click="handleSuccess"
          color="primary"
          :disabled="localSelectedProbes.length > maxProbes"
        >
          Update
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>



<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { IProbe } from '@/interfaces/bcdb';
import { dispatchCheckApiError } from '@/store/main/actions';
import { format, parseISO } from 'date-fns';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';

type onCancelHandler = () => void;
type onSuccessHandler = () => void;

@Component
export default class ProbePicker extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public selectedProbes!: IProbe[];
  @Prop() public readonly showDialog!: boolean;
  @Prop() public readonly onCancel!: onCancelHandler;
  @Prop() public readonly onSuccess!: onSuccessHandler;
  @Prop() public readonly filter!: { [key:string]:string };
  @Prop({default:true}) public readonly allowMultiple!: boolean;
  @Prop() public readonly maxProbes!: number;

  public probes: IProbe[] = [];
  public showProbes: IProbe[] = [];
  public localSelectedProbes: IProbe[] = [];

  public probeHeaders: object[] = [
        { text: 'Panel Name', value: 'panel_name' },
        { text: 'Order Name', value: 'order_name', filterable: false },
        { text: 'Function', value: 'function', filterable: false },
        { text: 'Callout', value: 'callout_id', filterable: false },
        { text: 'Date Added', value: 'date_added', filterable: false, cellClass: 'col-date' },
      ];
  public search = '';

  public mounted() {
    // save these off for the cancel path 
    Object.assign(this.localSelectedProbes, this.selectedProbes);
  }

  public async loadProbes() {
    api.getProbes(readToken(this.$store)
    ).then((response) => {
      this.probes = response.data;
      this.localSelectedProbes = Array.from(this.selectedProbes)
      this.showProbes = this.probes.filter(this.probeFilter);
    }).catch((error) => {
      dispatchCheckApiError(this.$store, error);
    });
  }

  @Watch('showDialog')
  public async onShowChanged(val: boolean, oldVal: boolean) {
    if( val ) {
      this.onOpen();
    } else {
      this.onClose();
    }
  }

  // customFilter
  // find rows where at least one search term is in each record.
  public customFilter(value, search, item) {
    const searchTerms = search?.toLowerCase().trim().split(' ')
    return searchTerms.reduce((acc,cur) => {
        const inPanel = item.panel_name.toLowerCase().indexOf(cur) !== -1
        const inOrderName = item.order_name ? item.order_name.toLowerCase().indexOf(cur) !== -1 : false
        const inCallout = item.callout ? item.callout.name.toLowerCase().indexOf(cur) !== -1 : false
        const inFunction = item.function ? item.function.toLowerCase().indexOf(cur) !== -1 : false
        return((inPanel || inOrderName || inCallout || inFunction) && acc)
      }, true)    
  }

  public probeFilter(item) {
    if(  !this.filter
      || (this.filter.function && this.filter.function == item.function)
      || (this.filter.panel_name && this.filter.panel_name == item.panel_name)
      || (this.filter.panel_association && this.filter.panel_association == item.panel_association)
      || (this.filter.order_name && this.filter.order_name == item.order_name)
      || (!this.filter.function && !this.filter.panel_name && !this.filter.panel_association && !this.filter.order_name) ) {
      return true;
    }
    return false;
  }

  public onOpen() {
    this.loadProbes();
  }

  public onClose() {
    this.search = '';
  }

  public handleCancel() {
    this.localSelectedProbes = Array.from(this.selectedProbes)
    this.onCancel();
  }

  public handleSuccess() {
    this.selectedProbes = Array.from(this.localSelectedProbes)
    this.onSuccess();
  }

  public onClickRow(item, slot, event) {
    slot.select(!slot.isSelected);
  }
}
</script>

<style scoped>

</style>
