import { RunState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    runs: (state: RunState) => state.runs,
    run: (state: RunState) => (runId: number) => {
        const filteredRuns = state.runs.filter((run) => run.id === runId);
        if (filteredRuns.length > 0) {
            return { ...filteredRuns[0] };
        }
    },
    runs_count: (state: RunState) => state.runs_count,
    rpage: (state: RunState) => state.page,
    rperPage: (state: RunState) => state.perPage,
    rsortBy: (state: RunState) => state.sortBy,
    rsortDesc: (state: RunState) => state.sortDesc,
    roptions: (state: RunState) => (
        {page: state.page,
         perPage: state.perPage,
         sortBy: state.sortBy,
         sortDesc: state.sortDesc}
    ),

    runSummary: (state: RunState) => state.runSummary,
    signals: (state: RunState) => state.signals,
    ampfeas: (state: RunState) => state.ampfeas,
    static_thermal_analysis: (state: RunState) => state.static_thermal_analysis,
    thermal_signals: (state: RunState) => state.thermal_signals,
    thermal_stats: (state: RunState) => state.thermal_stats,
    transition_analysis: (state: RunState) => state.transition_analysis,
};

const { read } = getStoreAccessors<RunState, State>('');

export const readRun = read(getters.run);
export const readRuns = read(getters.runs);

export const readRunsCount = read(getters.runs_count);
export const readRunsPage = read(getters.rpage);
export const readRunsPerPage = read(getters.rperPage);
export const readRunsSortBy = read(getters.rsortBy);
export const readRunsOptions = read(getters.roptions);

export const readRunSummary = read(getters.runSummary);
export const readSignals = read(getters.signals);
export const readAmpfeas = read(getters.ampfeas);
export const readStaticThermalAnalysis = read(getters.static_thermal_analysis);
export const readThermalSignals = read(getters.thermal_signals);
export const readThermalStats = read(getters.thermal_stats);
export const readTransitionAnalysis = read(getters.transition_analysis);

