var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.printRuns,"item-key":"name","dense":true,"server-items-length":_vm.printRunCount,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [(item.id != null)?_c('router-link',{attrs:{"title":`${item.name}\n${item.purpose}`,"to":{ name: 'biochip-print-run-view', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.array_template_id",fn:function({ item }){return [(item.array_template != null)?_c('router-link',{attrs:{"to":{ name: 'biochip-array-template-view', params: { id: item.array_template.id }}}},[_vm._v(_vm._s(item.array_template.name))]):_c('span')]}},{key:"item.run_date",fn:function({ value }){return [_vm._v(" "+_vm._s(value?.formatDate())+" ")]}},{key:"item.created_on",fn:function({ value }){return [_vm._v(" "+_vm._s(value?.formatDateTime(true, "America/Los_Angeles"))+" ")]}},{key:"item.purpose",fn:function({ value }){return [_c('span',[_vm._v(" "+_vm._s(value?.length > 13 ? value.substring(0,13)+'...' : value)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g({},on),[(value?.length > 13)?_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"small":""}},[_vm._v("pending")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.id",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","to":{name: 'biochip-print-run-edit', params: {id: item.id}}}},[_c('v-icon',[_vm._v("edit")])],1)]}}]),model:{value:(_vm.selectedPrintRuns),callback:function ($$v) {_vm.selectedPrintRuns=$$v},expression:"selectedPrintRuns"}})
}
var staticRenderFns = []

export { render, staticRenderFns }