<template>
    <v-card>
      <v-card-title>
          <v-container>
          <v-row class="settings-text" >
              <v-col>
              <div class="title-font">Formula Generator</div>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
              <div class="description-text">
                  Formula generator provides the ability to create logical formulas. Select between variables, 
                  logical operators, arithmetic operators, and aggregate functions to append to the end of your formula. 
                  Or, manually edit the text field with approved parameters.
              </div>
              </v-col>
          </v-row>
          </v-container>
      </v-card-title>

      <v-container class="grey lighten-5">
        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Data
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="metricList.length">
                <v-select
                  v-model="selectedMetric"
                  :items="metricList"
                  label="Metrics"
                  style="max-width: 200px; margin-left: 10px;">
                </v-select>
              </v-row>

              <v-btn rounded color="primary" small dark class="action-buttons"
                v-for="(item, index) in itemList" :key="index"
                @click="insertText(item, true)">
                {{ item }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Logical Operators
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn rounded color="primary" small dark class="action-buttons"
              v-for="(item, index) in logicalOperators" :key="index"
              @click="insertText(item)">
                {{ item }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Arithmetic Operators
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn rounded color="primary" small dark class="action-buttons"
              v-for="(item, index) in arithmeticOperators" :key="index"
              @click="insertText(item)">
                {{ item }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Aggregate Functions
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn rounded color="primary" small dark class="action-buttons"
              v-for="(item, index) in aggregateFunctions" :key="index"
              @click="insertText(item)">
                {{ item }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-textarea
          ref="customTextarea"
          outlined
          name="input-7-1"
          placeholder="Enter a valid logical formula"
          auto-grow
          v-model="logicalString"
          :rules="rules"
          style="margin-top: 20px;"
        ></v-textarea>

        <v-row class="centeredRow" style="margin-bottom: 10px;">
              <v-btn color="primary" v-on:click="save" style="margin-right: 10px;">Save</v-btn>
              <v-btn color="primary" v-on:click="close">Cancel</v-btn>
        </v-row>

      </v-container>
    </v-card>
</template>

<script lang="ts">

import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { FormulaResponse } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import { filter } from 'vue/types/umd';

@Component({
  components: {
  },
  })

export default class FormulaGenerator extends Vue {
    @ModelSync('value', 'change') public logicalString!: string;
    @Prop() public readonly itemList!: string[];
    @Prop({ default: () => [] }) public readonly metricList!: string[];

    public selectedMetric = '';
    public panels: number[] = [];

    public logicalOperators = ['AND', 'OR', 'NOT', '<', '>', '≤', '≥'];
    public arithmeticOperators = ['+', '-', '*', '/', '(', ')', '='];
    public aggregateFunctions = ['LOG']

    public cursorPos = 0;
    public validFormula = true;
    public errorMessage = "";
    public rules: ((value) =>string | true)[] = [];

    public async save(){
      await this.validateString();
      if (this.validFormula) {
        this.$emit('save');}
    }

    public close(){
      this.$emit('close');
    }

    public async validateString() {
      await api.validateFormula(readToken(this.$store), [this.logicalString]).then((response) => {
        this.validFormula = response.data[0].valid;
          // Assuming a simple example where you check for a specific keyword in the formula
          if (this.validFormula) {
            this.errorMessage = "";
          } else {
            this.errorMessage = response.data[0].error.explain;
          }
      });
      this.rules = [(value) => !!this.validFormula || this.errorMessage];
    }

    //-------------NOT FUNCTIONAL FUTURE WORK-------------
    public handleTextareaClick(event) {
      const textarea = event.target;
      // Update the cursor position when the textarea is clicked
      this.cursorPos = textarea.selectionStart;
    }

    public updateCursorPos(event) {
      const textarea = event.target;
      // Update the cursor position when the textarea is clicked
      this.cursorPos = textarea.selectionStart;
      console.log(this.cursorPos);
    }

    public handleTextareaInput(event) {
      // Update the textarea value and the cursor position when text is inputted
      this.logicalString = event.target.value;
      this.cursorPos = event.target.selectionStart;
    }
    //----------------------------------------------------

    public insertText(text: string, dataField: boolean = false) {
      if(dataField) {
        text = this.formatItemName(text);
      }
      if( this.logicalString.length === 0) {
        this.logicalString = this.logicalString + text;
      } else {
        this.logicalString = this.logicalString + ' ' + text;
      }      
    }

    public formatItemName(item: string) {
      if (this.selectedMetric !== '') {
        item = '{' + item + '}:' + this.selectedMetric;
      } else {
        item = '{' + item + '}';
      }
      return(item);
    }

    public mounted() {
      this.selectedMetric = this.metricList[0]
    }
}
</script>


<style>
.title-font {
  font-size: medium;
}

.description-text {
  margin-top: 0px;
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}
.description-text, .v-row {
  line-height: 1em;
}

.centeredRow {
  justify-content: center;
}

.action-buttons{
  margin-right: 5px;
}
</style>
