<template>
  <v-card>
    <v-card-text>
      <v-row v-for="(filter, idx) in selectedFilters" :key="filter.id">
        <v-col cols="5">
          <v-select
            v-model="selectedFilters[idx].value"
            :items="availableFilters"
            item-text="name"
            item-value="value"
            label="Field"
            single-line
            dense
            v-on:change="filterTargetChange(idx, ...arguments)"
          >
            <template v-slot:prepend>
              <v-btn small icon plain color="primary" v-on:click="removeFilter(idx)"><v-icon>mdi-close</v-icon></v-btn>
            </template>

          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-if="filterOperators[filter.value]"
            v-model="selectedFilters[idx].selectedOperator"
            :items="filterOperators[filter.value]"
            item-text="name"
            item-value="name"
            label="Filter"
            single-line
            clearable
            dense
            @change="selectedFilters[idx].filterValue = undefined"
          ></v-select>
        </v-col>
        <v-col cols="5">
          <v-combobox
            dense
            v-if="filterOperators[filter.value] && filterTypes[filter.value] == 'text'"
            v-model="selectedFilters[idx].filterValue"
            :items="selectedFilters[idx].items"
            :return-object="false"
            item-value="value"
            v-on:update:search-input="filterValueChange(idx, ...arguments)"
            clearable
          >
            <!--<template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>-->


          </v-combobox>

          <v-menu
            v-model="selectedFilters[idx].showMenu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="selectedFilters[idx].selectedOperator && filterOperators[filter.value] && filterTypes[filter.value] == 'date'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedFilters[idx].filterValue"
                label="Select Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedFilters[idx].filterValue"
              :range="selectedFilters[idx].selectedOperator == 'between'"
              @change="selectedFilters[idx].showMenu = false; updatefilters();"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-btn
        rounded
        color="primary"
        dark
        v-on:click="addBlankFilter"
      >
        Add Filter
      </v-btn>
    </v-card-text>
  </v-card>
</template>



<script lang="ts">
import { Component, Vue, Watch, Model } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';

import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class ClinicalDataFilterPicker extends Vue {

  @Model('change', { type: Array })
  public readonly value!: IFilter[];

  public valueIndex: number = 0;

  public availableFilters: Array<any> = [
        { name: '', value: 'none'},
        { name: 'Sample ID', value: 'subjectId'},
        { name: 'Age', value: 'age'},
        { name: 'Sex', value: 'sex'},
        { name: 'Created On', value: 'created_on'},
      ];

  public filterOperators: object = {
    subjectId: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    age: [
      { name: 'is', op: '='},
      { name: 'contains', op: '%'},
    ],
    sex: [
      { name: 'is', op: '='},
    ],
    created_on: [
      { name: 'on', op: '='},
      { name: 'after', op: '>='},
      { name: 'before', op: '<='},
      { name: 'between', op: '><'},
    ],
  };

  public filterTypes: object = {
    subjectId: 'text',
    age: 'text',
    sex: 'text',
    created_on: 'date',
  };

  public selectedFilters: Array<{
    name?: string,
    value?: string,
    id: any,
    items?: IAutocompleteItem[],
    selectedOperator?: string,
    filterValue?: string,
  }> = [];

  public updatefilters() {
    const filters: IFilter[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const filter of this.selectedFilters) {
      if (filter.value && filter.selectedOperator && filter.filterValue) {
        filters.push(
          {field: filter.value, operator: filter.selectedOperator, value: filter.filterValue },
        );
      }
    }
    this.$emit('change', filters);
  }

  public async filterTargetChange(idx: number, item) {
    const items = await api.getClinicalDataFields(readToken(this.$store), {field: item, skip: 0, limit: 100, filters: []});
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.$set(this.selectedFilters[idx], 'filterValue', undefined );
    this.$set(this.selectedFilters[idx], 'selectedOperator', undefined );
  }

  public async filterValueChange(idx: number, item) {
    const filters = this.selectedFilters.map((f) => <IFilter> { field: f.value, operator: f.selectedOperator, value: f.filterValue })

    this.$set(this.selectedFilters[idx], 'filterValue', item );
    const items = await api.getClinicalDataFields(readToken(this.$store), {
      field: this.selectedFilters[idx].value || '',
      skip: 0,
      limit: 100,
      filters: filters,
    });
    this.$set(this.selectedFilters[idx], 'items', items.data );
    this.updatefilters();
  }

  public addBlankFilter() {
    const filter = {id: uuidv4(), ...this.availableFilters[0]};
    this.selectedFilters.push(filter);
  }

  public addFilter(field, operator, value) {
    const foundFilter = this.availableFilters.find((f) => f.value === field);
    if( foundFilter ) {
      const foundOperator = this.filterOperators[foundFilter.value].find((o) => o.op === operator);
      if( foundOperator ) {
        const filter = {id: uuidv4(), selectedOperator: foundOperator.name, filterValue: value, ...foundFilter};
        this.selectedFilters.push(filter);
      }
    }
  }

  public removeFilter(idx: number) {
    this.selectedFilters.splice(idx, 1);
    this.updatefilters();
  }

  public mounted() {
    Object.entries(this.$router.currentRoute.query).forEach((e) => {
      this.addFilter(e[0], '=', e[1]);
    });
  }
}
</script>

<style scoped>

</style>
