<template>
  <!-- All Probes -->

  <v-data-table
    :headers="computedHeaders"
    :items="arrayTemplateProbes"
    item-key="probe.panel_name"
    :dense="true"
    class="elevation-1"
    :sort-by="sortBy"
    :disable-sort="editMode"
    disable-pagination
    hide-default-footer
  > 
    <template v-slot:body="props">
      <draggable
        v-model="arrayTemplateProbes"
        tag="tbody"
        :move="onMove"
        @end="onReorder"
        :disabled="!editMode || !allowDrag">
        <tr
          v-for="(item, index) in props.items"
          :key="`row.${index}`"
        >
          <td class='col-drag' v-if="editMode">
            <v-icon v-if='allowDrag' small>mdi-arrow-all</v-icon>
          </td>
          <td class='col-template-order' v-if="editMode"> {{ item.template_order }} </td>
          <td class='col-probe-id' v-if="!editMode"> {{ item.probe_ref }} </td>
          <td class='col-panel-name'>
            <router-link
              :title="item.probe.panel_name"
              :to="{ name: 'biochip-probe-detail', params: { id: item.probe_id }}">
                {{ item.probe.panel_name }}
            </router-link>
          </td>
          <td class='col-order-name'> {{ item.probe.order_name }} </td>
          <td class='col-panel-association'> {{ item.probe.panel_association }} </td>
          <td class='col-callout'> {{ probeCalloutName(item) }} </td>
          <td class='col-function'> {{ item.probe.function }} </td>
          <td class='col-probe-sequence'>
            <div :title="item.probe.probe_sequence">
              {{ item.probe.probe_sequence ? item.probe.probe_sequence.substring(0,10)+'...' : '' }}
            </div>
         </td>
          <td class='col-probe-type'> {{ probeType(item.probe.probe_type) }} </td>
          <td class='col-wt-group' v-html="probeWTGroup(item.probe)"></td>
          <td class='col-probe-description'> {{ item.probe.probe_description }} </td>
          <td class='col-replicates text-center' v-if="!editMode && !draftMode"> {{ locations[item.probe.id] ? locations[item.probe.id].length : 0 }} </td>
          <td class='col-min-distance' v-if="!editMode && !draftMode"> {{ computeMinDistance(locations[item.probe.id]) }} </td>
          <td class='col-locations' v-if="!editMode && !draftMode"> {{ locations[item.probe.id] ? locations[item.probe.id].reduce((a,p) => a + ` (${p})`,'') : '' }} </td>
        </tr>
      </draggable>
    </template>

  </v-data-table>

</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { IArrayTemplateProbe, IArrayTemplateProbeReplicate } from '@/interfaces/bcdb';
import { IProbe, ICallout } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import Draggable from 'vuedraggable';
import { computed } from 'vue'


@Component({
  components: {
    Draggable,
  },
})
export default class ArrayProbeList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public arrayTemplateProbes!: IArrayTemplateProbe[];
  @Prop() public replicates!: IArrayTemplateProbeReplicate[];
  @Prop() public editMode!: boolean;
  @Prop() public draftMode!: boolean;
  @Prop({default: true}) public allowDrag!: boolean;
  public sortBy = this.draftMode ? 'template_order' : 'probe_ref';
  public callouts: ICallout[] = [];
  public locations: number[][][] = [];

  public headers = [
        { text: '', value: 'drag_handle', cellClass: 'col-drag', sortable: false },        // editMode
        { text: '#', value: 'template_order', cellClass: 'col-template-order' },           // editMode
        { text: 'ID', value: 'probe_ref', cellClass: 'col-probe-id' },                     // !editMode
        { text: 'Probe Code', value: 'probe.panel_name', cellClass: 'col-panel-name' },
        { text: 'Order Name', value: 'probe.order_name', cellClass: 'col-order-name' },
        { text: 'Panel Association', value: 'probe.panel_association', cellClass: 'col-panel-association' },
        { text: 'Callout', value: 'probe.callout', cellClass: 'col-callout' },
        { text: 'Function', value: 'probe.function', cellClass: 'col-function' },
        { text: 'Sequence', value: 'probe.probe_sequence', cellClass: 'col-probe-sequence' },
        { text: 'Type', value: 'probe.probe_type', cellClass: 'col-probe-type' },
        { text: 'WT Group', value: 'wt_group', cellClass: 'col-wt-group' },
        { text: 'Description', value: 'probe.probe_description', cellClass: 'col-probe-description' },
        { text: 'Replicates', value: 'replicates', cellClass: 'col-replicates' },           // !editMode && !draftMode
        { text: 'Minimum Distance', value: 'min_distance', cellClass: 'col-min-distance' }, // !editMode && !draftMode
        { text: 'Locations', value: 'locations', cellClass: 'col-locations' },              // !editMode && !draftMode
      ];

  public computedHeaders = computed(() => {
    return( this.headers.filter(h => {
        switch(h.value) {
          case 'drag_handle':     return(this.editMode);
          case 'template_order':  return(this.editMode);
          case 'probe_ref':       return(!this.editMode);
          case 'replicates': 
          case 'min_distance': 
          case 'locations':       return(!this.editMode && !this.draftMode);
          default:                return(true);
        }
    }));
  })

  public mounted() {
    this.loadCallouts();
    this.calculateLocations();
  }

  @Watch('replicates')
  public async onProbeReplicatesChanged(val: IArrayTemplateProbeReplicate[], oldVal: IArrayTemplateProbeReplicate[]) {
    this.calculateLocations();
  }

  public calculateLocations() {
    this.replicates.forEach((atpr) => {
      if( this.locations[atpr.probe_id] ) {
        this.locations[atpr.probe_id].push([atpr.x,atpr.y]);
      } else {
        this.locations[atpr.probe_id] = [[atpr.x,atpr.y]];
      }
    });
  }

  public async onReorder(event) {
    var probe_count = 1;

    this.arrayTemplateProbes.forEach(p => {
      const before = p.template_order;
      p.template_order = probe_count++
    })
  }

  public onMove(evt, originalEvent) {
    const src = evt.draggedContext.element.probe.function;
    const dst = evt.relatedContext.element.probe.function;
    if( !(src == EProbeFunction.normalization || src == EProbeFunction.control )
        && (dst == EProbeFunction.normalization || dst == EProbeFunction.control )) {
      return( false );
    }
    return( true );
  }

  public async loadCallouts() {
    api.getCallouts(readToken(this.$store)
    ).then((response) => {
      this.callouts = response.data;
    }).catch((error) => {
      dispatchCheckApiError(this.$store, error);
    });
  }

  public probeType(type) {
    return( EProbeType[type]?.capitalize() );
  }
  public probeWTGroup(probe) {
    if( probe?.probe_type && probe?.probe_type == EProbeType.mutant ) {
      if( probe.wild_type_probe_id ) {
        const wild_atp = this.arrayTemplateProbes.find((atp) => atp.probe_id == probe.wild_type_probe_id);
        if( wild_atp?.probe ) {
          return(wild_atp.probe.panel_name);
        } else {
          return('<span class="red--text">Not found in this template</span>')
        }
      } else {
        return('<span class="red--text">Not set</span>')
      }
    }
    return('');
  }

  public probeCalloutName(item) {
    return( this.callouts.find((co) => co.id == item.probe.callout_id)?.name );
  }

  public computeMinDistance(locs) {
    if( !locs ) { return ''; }
    let min = 1000;
    for( let i = 0; i < locs.length; i++ ) {
      for( let j = i+1; j < locs.length; j++ ) {
        min = Math.min(Math.sqrt((locs[i][0]-locs[j][0])**2 + (locs[i][1]-locs[j][1])**2),min);
      }
    }
    return Math.round(min);
  }

}

</script>

<style scoped>
.v-data-table >>> .v-data-table-header th,td {
  padding-right: 0 !important; 
  font-size: 11px !important;
}
.v-data-table >>> .col-drag {
  width: 32px;
  min-width: 32px;
}
.v-data-table >>> .col-template-order {
  width: 46px;
  min-width: 46px;
}
.v-data-table >>> .col-probe-id {
  width: 45px;
  min-width: 45px;
}
.v-data-table >>> .col-panel-name {
  width: 160px;
  min-width: 160px;
}
.v-data-table >>> .col-order-name {
  width: 165px;
  min-width: 165px;
}
.v-data-table >>> .col-panel-association {
  width: 210px;
  min-width: 210px;
}
.v-data-table >>> .col-callout {
  width: 100px;
  min-width: 100px;
}
.v-data-table >>> .col-function {
  width: 170px;
  min-width: 120px;
}
.v-data-table >>> .col-wt-group {
  width: 122px;
  min-width: 100px;
}
.v-data-table >>> .col-probe-sequence {
  min-width: 10em;
}
.v-data-table >>> .col-probe-type {
  min-width: 8em;
}
.v-data-table >>> .col-probe-description {
  min-width: 20em;
}
.v-data-table >>> .col-replicates {
  min-width: 5em;
}
.v-data-table >>> .col-locations {
  min-width: 10em;
}
.v-data-table >>> .col-min-distance {
  min-width: 5em;
}
  
</style>