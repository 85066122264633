import {  } from '@/interfaces/bcdb';

export function calcPPA(tp:number, fn: number, nanValue:any=0):number{
    const ppa = Math.round(tp/(tp+fn)*1000)/10
    return(Number.isNaN(ppa)?nanValue:ppa)
}

export function calcNPA(tn:number, fp: number, nanValue:any=0):number{
    const npa = Math.round(tn/(tn+fp)*1000)/10
    return(Number.isNaN(npa)?nanValue:npa)
}