<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Home</div>
      </v-card-title>
      <v-card-text>

        <div class="headline font-weight-light ma-5">Welcome {{greetedUser}}</div>

        <!-- TODO: Consider making this a component -->
        <!-- Duration/Experiment type dropdowns -->
        <v-row class="d-flex align-center fill-height">
          <v-col cols="1"></v-col>
          <v-col class="justify-right">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="dateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange"
                  small
                  label="Selected Date Range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange"
                no-title
                scrollable
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="durationChanged()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="justify-center">
            <v-autocomplete
              :items="testTypes"
              v-model="dataTestTypes"
              item-text='experiment_type'
              item-value='experiment_type'
              dense
              multiple
              clearable
              style="margin-top:18px ;"
              @change="expTypeChanged()"
            >
            <v-list
              slot="prepend-item"
              v-if="testTypes.length"
              style="padding: 0;"
            >
            <v-list-item 
              ripple
              @click="selectAllExpType()">
              <v-list-item-icon>
                <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Select All</v-list-item-content>
            </v-list-item>
            </v-list>

              <template #label>
                <label style="bottom: 100px;">Experiment Type</label>
              </template>
              <template v-slot:selection="{ attrs, parent, item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ dataTestTypes.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="justify-center">
            <v-autocomplete
              :items="testInstruments"
              v-model="dataInstruments"
              dense
              multiple
              clearable
              style="margin-top:18px ;"
              @change="expTypeChanged()"
            >
              <v-list
                slot="prepend-item"
                v-if="testInstruments.length"
                style="padding: 0;"
              >
                <v-list-item 
                  ripple
                  @click="selectAllInstruments()">
                  <v-list-item-icon>
                    <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Select All</v-list-item-content>
                </v-list-item>
              </v-list>

              <template #label>
                <label style="bottom: 100px;">Instrument</label>
              </template>
              <template v-slot:selection="{ attrs, parent, item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ dataInstruments.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="justify-left">
            <v-switch v-model="enablePC" dense :label="`Pre-clinical Data`" @change="enablePcPressed()" style="margin-bottom: 0px;" class="custom_font"></v-switch>
            <div class="d-flex align-center">
            <v-switch v-model="enableReRuns" dense @change="expTypeChanged()"></v-switch>
                <span class="custom_font">Include Re-Runs</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                    </template>
                    <span>Include all prior runs of the same cartridge</span>
                  </v-tooltip>
            </div>
          </v-col>
        </v-row>

        <v-simple-table class="elevation-1" style="margin-bottom: 30px;">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Selected Experiments
                </th>
                <th class="text-left">
                  Total Experiment Types
                </th>
                <th class="text-left">
                  Total Instruments
                </th>
                <th class="text-left">
                  ND Experiments
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                    </template>
                    <span>Failed, invalid, or experiments with errors logged in .nxx</span>
                  </v-tooltip>
                </th>
                <th class="text-left">
                  % ND Experiments
                </th>
                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ selectedExperiments.length }}/{{ filteredTestResults.length }}
                  <v-btn x-small 
                    outlined color="primary" 
                    class="viewButton"
                    v-if="filteredTestResults.length"
                    @click="viewPressed('filteredList')"> 
                    View
                  </v-btn>
                </td>
                <td>{{selectedTestTypeCount}} / {{ testTypes.length }}</td>
                <td>{{selectedInstCount}} / {{ testInstruments.length }}</td>
                <td>
                  {{ testsND.length }}
                  <v-btn x-small 
                    outlined color="primary" 
                    class="viewButton"
                    v-if="testsND.length"
                    @click="viewPressed('ndList')"> 
                    View 
                  </v-btn>
                </td>
                <td v-if="testsND.length && selectedExperiments.length">{{ Math.round((testsND.length/selectedExperiments.length)*100) }}%</td>
                <td v-else="">{{ 0 }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row>
          <v-col class="no_padding">
            <div id="dateChart"></div>
          </v-col>
          <v-col class="no_padding">
            <div id="ndRateChart"></div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="no_padding">
            <div id="errorChart"></div>
          </v-col>
          <v-col class="no_padding">
            <div id="sicoreErrorChart"></div>
          </v-col>
        </v-row>

        
        <v-dialog v-model="viewDialog" scrollable max-width="1200" max-height="600">
          <v-card>
            <v-card-title> {{dialogTitle}} </v-card-title>
            <v-card-text>
              <v-row v-if="viewGroup" class="d-flex align-center">
                <v-col cols="1">Group by:</v-col>
                <v-col cols="2"><v-checkbox v-model="tableGroupByVendorCallout" :label="`Vendor Callout`" @click="groupSelected()"></v-checkbox></v-col>
                <v-col cols="2"><v-checkbox v-model="tableGroupByExperimentName" :label="`Experiment Name`" @click="groupSelected()"></v-checkbox></v-col>
              </v-row>
              <v-data-table
                scrollable
                dense
                height="350"
                fixed-header
                :group-by="viewGroup"
                :headers="tableHeaders"
                :items="tableItems"
                item-key="id"
                :sort-by="sortBy"
                :sort-desc="true"
                class=""
                no-data-text="No Experiments"
                disable-pagination
                hide-default-footer
                :show-select="selectable"
                v-model="selectedExperiments"
                >
                <template v-slot:group.header="{isOpen, toggle, group}">
                  <td colspan="1" class='align-left blue-grey lighten-3'> 
                    <div @click="toggle">
                      <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </div>
                  </td>
                  <td colspan="7" class='align-center blue-grey lighten-3'>
                    <div>{{ group }}</div>
                  </td> 
                </template>

                <template v-slot:item.experiment="{ item }">
                  <!-- Split on _ for callout made IDs -->
                  <router-link
                    :title="`NAME: ${item.experiment}`"
                    v-if="item.id != null && typeof item.id === 'string'"
                    :to="{ name: 'biochip-run-view', params: { id: item.id.split('_')[0] }}"
                  >
                    {{item.experiment}}
                  </router-link>
                  <router-link
                    :title="`NAME: ${item.experiment}`"
                    v-else-if="item.id != null && typeof item.id === 'number'"
                    :to="{ name: 'biochip-run-view', params: { id: item.id }}"
                  >
                    {{item.experiment}}
                  </router-link>
                  <span v-else>{{ item.experiment }}</span>
                </template>

                <template v-slot:item.failures="{item}">
                  <span v-if="item.failures === 'INVALID'"> 
                    <v-chip small outlined class="ma-2">{{item.failures}}</v-chip> 
                  </span>
                  <span v-else-if="item.failures === 'NO RESULT'"> 
                    <v-chip small outlined color="#F18F01" class="ma-2">{{item.failures}}</v-chip> 
                  </span>
                  <span v-else-if="item.failures === 'ERROR'"> 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-chip 
                        small outlined color="#C73E1D" 
                        class="ma-2" 
                        v-on="on" 
                        @mouseenter="filterErrorsByRun(item)"
                        @mouseleave="clearErrorDetailsForRunVar()">
                        {{item.failures}}</v-chip>
                      </template>
                      <span>
                        <li v-for="errorDetail in errorDetailsForRun">
                          {{ errorDetail }}
                        </li></span>
                    </v-tooltip>
                  </span>
                  <span v-else-if="item.failures === 'CIC FAIL'"> 
                    <v-chip small outlined color="#3B1F2B" class="ma-2"> {{item.failures}} </v-chip> 
                  </span>
                  <span v-else-if="item.failures !== null">
                    <v-chip small outlined color="#6A3821" class="ma-2"> {{item.failures}} </v-chip>
                  </span>
                </template>

                <template v-slot:item.result_txt="{item}">
                  <span v-if="item.result_txt === 'DETECTED'"> 
                    <v-chip small outlined color="#C73E1D" class="ma-2">{{item.result_txt}}</v-chip> 
                  </span>
                  <span v-else-if="item.result_txt === 'NOT DETECTED'"> 
                    <v-chip small outlined color="#428959" class="ma-2">{{item.result_txt}}</v-chip> 
                  </span>
                </template>

                <template v-slot:item.vendor_result="{item}">
                  <span v-if="item.vendor_result === 'DETECTED'"> 
                    <v-chip small outlined color="#C73E1D" class="ma-2">{{item.vendor_result}}</v-chip> 
                  </span>
                  <span v-else-if="item.vendor_result === 'NOT DETECTED'"> 
                    <v-chip small outlined color="#428959" class="ma-2">{{item.vendor_result}}</v-chip> 
                  </span>
                </template>

              </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="downloadObject()"
              >
                Download
              </v-btn>
              <v-btn
                v-if="selectable"
                color="primary"
                @click="applySelected()"
              >
                Apply
              </v-btn>
              <v-btn
                color="primary"
                @click="closeSelected()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <!--<v-card-actions>
        <v-btn to="/main/profile/view">View Profile</v-btn>
        <v-btn to="/main/profile/edit">Edit Profile</v-btn>
        <v-btn to="/main/profile/password">Change Password</v-btn>
      </v-card-actions>-->
    </v-card>
  </v-container>
</template>

<script lang="ts">
import RunList from '@/components/RunList.vue';
import { Component, Vue } from 'vue-property-decorator';
import { VMenu } from 'vuetify/lib';
import { Store } from 'vuex';
import { readUserProfile, readToken } from '@/store/main/getters';
import { api } from '@/api';
import { IRunTracker, IRunErrors, ICallout, IPpaNpa, IInternalCallouts, ISample, IComparatorMap } from '@/interfaces/bcdb';
import { IFilter } from '@/interfaces';
import { Socket } from 'vue-socket.io-extended';

import { subWeeks, startOfToday, parseISO, format, formatISO, isThisMinute, isThisHour } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import buildObjectToExcel from '@/scripts/to-excel/object-to-excel';
import {alphabetically} from '@/scripts/support-functions'

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';
import { toDateWithOptions } from 'date-fns-tz/fp';
// import { FunctionalComponentMountOptions } from '@vue/test-utils';
import { internals } from '@azure/msal-browser';
import { IComparatorMatrix } from '@/interfaces/bcdb';


@Component({
  components: {
    RunList,
  },
})
export default class Dashboard extends Vue {
  public filteredTestResults: IRunTracker[] = [];
  public selectedExperiments: IRunTracker[] = [];
  public deSelectedExperiments: IRunTracker[] = [];
  public testResults: IRunTracker[] = [];
  public testsND: IRunTracker[] = [];
  public testCount: number = 0;
  public testCountWeek: number = 0;
  public testCountMonth: number = 0;
  public testTypes: string[] = [];
  public testInstruments: string[] = [];

  public selectedTestTypeCount = 0;
  public selectedInstCount = 0;

  public dateRange: string[] = [];
  public dateMenu:boolean = false;
  public viewDialog = false;
  public selectable = false;

  public runCountColor = '#D89B2B'
  public errorGraphColor = '#FF7518'
  public sicoreErrorColors = [this.errorGraphColor, '#f6de4b','#ff9248','#ffb38a','#ffe9a3']

  public tableGroupByVendorCallout: boolean = true
  public tableGroupByExperimentName: boolean = true
  public tableHeaders: object[] = []
  public tableItems: any[] = []
  public sortBy: string = ''
  public dialogTitle: string = ''
  public viewGroup: string|null = null
  public testListHeaders: object[] = [
    { text: 'Date of Run', value: 'date_of_run', cellClass: 'col-date', align: 'center' },
    { text: 'Experiment', value: 'experiment', cellClass: 'col-experiment', align: 'left' },
    { text: 'Run', value: 'run', cellClass: 'col-run', align: 'center' },
    { text: 'Type', value: 'experiment_type', cellClass: 'col-experiment_type', align: 'center' },
    { text: 'Instrument', value: 'rig', cellClass: 'col-rig', align: 'center' },
    { text: 'Failure', value: 'failures', cellClass: 'col-failures', align: 'center' },
    { text: 'SiCore Finish Status', value: 'scicore_finish_status', cellClass: 'col-scicore-error-code', align: 'center' },
    { text: 'SiCore Run Finish Msg', value: 'scicore_run_finish_m', cellClass: 'col-scicore-error-code', align: 'center' },
    { text: 'SiCore Cart Prep Msg', value: 'scicore_cart_prep_status', cellClass: 'col-scicore-error-code', align: 'center' },
    { text: 'SiCore Start Run Status', value: 'scicore_start_run_status', cellClass: 'col-scicore-error-code', align: 'center' },
    { text: 'Description', value: 'run_description', cellClass: 'col-description', align: 'left' },
      ];

  public startUp = true;
  public ndStrings = ['INVALID','ERROR','NO RESULT']
  public enablePC = false;
  public enableReRuns = false;

  public cicQueryParams = {
        run_ids: [0],
        callout_id: 186, 
        result_str: 'FAIL'
      }

  public runErrors: IRunErrors[] = [];
  public errorDetailsForRun: string[] = []

  public cicFails: number[] = [];
  public dataTestTypes: string[] = [];
  public dataInstruments: string[] = [];
  
  public durations = [
    { text: '1 day', value: 1 },
    { text: '2 days', value: 2 },
    { text: '3 days', value: 3 },
    { text: '7 days', value: 7 },
    { text: '2 weeks', value: 14 },
    { text: '1 month', value: 30 },
    { text: '3 months', value: 90 },
    { text: '6 months', value: 180 },
    { text: '9 months', value: 270 },
  ];

  public graphHeight = 400;
  public graphConfig = {
    displaylogo: false, 
    responsive: true,
    editable: true,
  };
  public runsChartLayout = {
      autosize: true,
      legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2
        // bgcolor:'rgba(0,0,0,0)' //transparent
      },
      height: this.graphHeight,
      title: 'Experiment Counts By Date',
      yaxis: {title: 'Count',
        automargin: true},
      yaxis2: {
        automargin: true,
        title: 'Cumulative Count',
        overlaying: 'y',
        showgrid: false,
        side: 'right',
      },
      xaxis: {automargin: true, type:'category', title: ''}
    } as Plotly.Layout;

  public ndBarChartLayout = {
    height: this.graphHeight,
      title: 'ND Rate',
      yaxis: {title: 'Count',
        automargin: true},
      xaxis: {automargin: true, title: ''}
    } as Plotly.Layout;

  public paretoChartLayout = {
      height: this.graphHeight,
      title: 'L2 Pareto: Errors',
      yaxis: {title: 'Count',
        automargin: true},
      yaxis2: {
        automargin: true,
        title: '% Contribution',
        overlaying: 'y',
        showgrid: false,
        side: 'right',
        range: [0, 102]
      },
      legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2
        // bgcolor:'rgba(0,0,0,0)' //transparent
      },
      xaxis: {automargin: true, type:'category', title: ''}
    } as Plotly.Layout;

  public scicoreErrorsLayout = {
      height: this.graphHeight,
      barmode: 'stack',
      title: 'L3 Pareto: SiCore Errors',
      yaxis: {title: 'Count',
        automargin: true},
      yaxis2: {
        automargin: true,
        title: '% Contribution',
        overlaying: 'y',
        showgrid: false,
        side: 'right',
        range: [0, 102]
      },
      legend: {orientation:'h', xanchor:'center', x:0.5, y:1.2
        // bgcolor:'rgba(0,0,0,0)' //transparent
      },
      xaxis: {automargin: true, type:'category', title: ''}
    } as Plotly.Layout;

  public experimentChartLayout = {
      height: this.graphHeight,
      title: 'Experiment Counts By Type',
      yaxis: {
          automargin: true,
          title: 'Experiment Type',
          showgrid: false,
          zeroline: false,
      },
      xaxis: {
          automargin: true,
          title: 'Count',
          showline: false,
          showgrid: false,
          zeroline: false,
      },
  } as Plotly.Layout;

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  get testsToday() {
    const tests = this.testResults.filter((t: IRunTracker) => parseISO(`${t.date_added}Z`) > startOfToday());
    return tests;
  }

  public async filterErrorsByRun(experiment: IRunTracker) {
    // Filter errors for a line item run
    let filteredErrors = this.runErrors.filter((runError) => runError.run_tracker_id == experiment.id);

    // Handle multiple errors for a single run
    filteredErrors.forEach((filteredError) => this.errorDetailsForRun.push(filteredError.details))
    
    // Formatting for large or no error results
    const nBound = 5
    let len = this.errorDetailsForRun.length
    if(len > nBound){
      this.errorDetailsForRun = this.errorDetailsForRun.slice(0,nBound)
      this.errorDetailsForRun.push(`See ${len - nBound - 1} more errors...`)}
    else if (len == 0){
        this.errorDetailsForRun.push('No error details found.')}
  }

  public async clearErrorDetailsForRunVar(){
    this.errorDetailsForRun = []
  }

  public async closeSelected() {
    if(this.selectable){
      this.selectedExperiments = this.filteredTestResults.filter(e => !this.deSelectedExperiments.some(de => de.id === e.id));
    }
    this.viewDialog=false
  }

  public async applySelected() {
    if(this.selectable){
      const notInSelected = this.deSelectedExperiments.filter(e => !this.selectedExperiments.some(s => s.id===e.id))
      const notInFiltered = this.filteredTestResults.filter(t => !this.selectedExperiments.some(e => e.id === t.id))
      const subset = [...notInSelected, ...notInFiltered];
      this.deSelectedExperiments = this.testResults.filter(t => subset.some(e => e.id === t.id))
      await this.loadFilteredResults()
      this.updateCharts()
    }
    this.viewDialog=false
  }

  public async downloadObject() {
    if(this.tableHeaders.length) {
      const sheetFields = this.tableHeaders.map((h:any)=>h.value)
      const sheetHeaders = this.tableHeaders
      const sheetTitle = this.dialogTitle
      buildObjectToExcel([this.tableItems], [sheetFields], this.dialogTitle, [sheetHeaders], [sheetTitle]);
    }
  }
  
  public async loadTestResults(duration) {
    const response = await api.postRunsFromRange(readToken(this.$store), duration);
    if (response) {
      this.testResults = response.data;

      //Adjust sicore error message to be a triplet
      this.testResults.forEach((t) => {
        if ( t.scicore_finish_status !== null && t.scicore_error_code1 !== null && t.scicore_error_code2 !== null){
          t.scicore_finish_status = `(${t.scicore_finish_status},${t.scicore_error_code1},${t.scicore_error_code2})`
        }
        if ( t.scicore_run_finish_m !== null && t.scicore_run_finish_m_code1 !== null && t.scicore_run_finish_m_code2 !== null){
          t.scicore_run_finish_m = `(${t.scicore_run_finish_m},${t.scicore_run_finish_m_code1},${t.scicore_run_finish_m_code2})`
        }
        if ( t.scicore_cart_prep_status !== null && t.scicore_cart_prep_status_opt1 !== null && t.scicore_cart_prep_status_opt2 !== null){
          t.scicore_cart_prep_status = `(${t.scicore_cart_prep_status},${t.scicore_cart_prep_status_opt1},${t.scicore_cart_prep_status_opt2})`
        }
      })

      //Update test type DropDown
      let adjustSelectedTypes: boolean = false
      if (this.testTypes.length === this.dataTestTypes.length || this.startUp || this.dataTestTypes.length === 0){
        adjustSelectedTypes = true
      }

      if ( this.enablePC ) {
        const pcExps = this.testResults.filter((t)=> t.sample_id !== null)
        this.testTypes = [...new Set(pcExps.map((t)=> t.experiment_type))];
      } else {
        this.testTypes = [...new Set(this.testResults.map((t)=>t.experiment_type))];
      }
      this.testTypes = this.testTypes.sort(alphabetically(true))

      if(adjustSelectedTypes){
        this.dataTestTypes = this.testTypes;
      }

      await this.loadFilteredResults()
      this.startUp = false;
    }
  }

  public async loadFilteredResults(){
    //Filter RunTracker to specific exp types
    this.filteredTestResults = this.testResults.filter((t)=> this.dataTestTypes.includes(t.experiment_type))

    if ( this.enablePC ) {
      this.filteredTestResults = this.filteredTestResults.filter((t)=> t.sample_id !== null)
    }

    if ( !this.enableReRuns ){
      this.filteredTestResults = this.filteredTestResults.filter((t)=> t.parent_run_id === null)
    }

    //Get instrument list
    let adjustInstruments = this.testInstruments.length === this.dataInstruments.length || this.startUp
    if (this.enablePC) {
      this.testInstruments = this.getUniqueInstruments(this.testResults.filter((t) => t.sample_id !== null))
    } else {
      this.testInstruments = this.getUniqueInstruments(this.testResults)
    }
    if (adjustInstruments){
      this.dataInstruments = this.testInstruments;
    }

    this.filteredTestResults = this.filteredTestResults.filter((t) => {
      const containsInst = t.rig !== undefined && this.dataInstruments.includes(t.rig)
      const isNAPresent = this.dataInstruments.includes('N/A');
      let isInstrumentNullOrUndefined = false;
      if( isNAPresent ) {
        isInstrumentNullOrUndefined = t.rig === null || t.rig === undefined;
      }
      return containsInst || isInstrumentNullOrUndefined;
    });

    this.deSelectedExperiments = this.deSelectedExperiments.filter(e => this.testResults.some(f => f.id === e.id));
    this.selectedExperiments = this.filteredTestResults.filter(e => !this.deSelectedExperiments.some(de => de.id === e.id));
    this.selectedTestTypeCount = (new Set(this.selectedExperiments.map(s => s.experiment_type))).size
    this.selectedInstCount = (new Set(this.selectedExperiments.map(s => s.rig))).size
    const run_ids: number[] = this.selectedExperiments.map((r)=> r.id)
    const sample_ids: number[] = this.selectedExperiments.filter((r) => r.sample_id !== null && r.sample_id !== undefined).map((r) => r.sample_id!);

    //Get all the errors associated with runs
    await api.getRunErrors(readToken(this.$store), run_ids).then((response_errors) => {
      this.runErrors = response_errors.data
    }).catch((error) => {
      console.log(error)
    });

    //Get exps with CIC FAIL
    this.cicQueryParams.run_ids = run_ids
    await api.getCalloutRunsEqual(readToken(this.$store), this.cicQueryParams).then((respons_CIC) => {
      this.cicFails = respons_CIC.data;
      this.selectedExperiments=this.formatNDRunList(this.selectedExperiments)
    }).catch((error) => {
      console.log(error)
    });

    this.testsND = this.selectedExperiments.filter((r)=> (r.failures !== null && this.ndStrings.includes(r.failures)) || this.cicFails.includes(r.id))
    this.testsND=this.formatNDRunList(this.testsND)
    const nd_ids = this.testsND.map((t) => t.id)
  }

  public getUniqueInstruments( runList: IRunTracker[] ):string[] {
    let uniqueInstruments: string[] = []
    const uniqueInstrumentsSet = new Set<string>();

    uniqueInstruments = runList.map((f) => {
      const instrumentRig = f.rig;
      if (instrumentRig !== undefined) {
        uniqueInstrumentsSet.add(instrumentRig);
        return instrumentRig;
      } else {
        uniqueInstrumentsSet.add('N/A');
      }
      return 'N/A';
    });
    uniqueInstruments = Array.from(uniqueInstrumentsSet);
    uniqueInstruments = uniqueInstruments.sort(alphabetically(true))
    return(uniqueInstruments)
  }

  public async getTestCount(filters: IFilter[] = []) {
    const response = await api.getRunsCount(readToken(this.$store), filters);
    if (response) {
      return response.data;
    }
    return -1;
  }

  public async durationChanged() {
    if (this.$refs.dateMenu !== undefined){
      const saveButton = this.$refs.dateMenu as any
      saveButton.save(this.dateRange)
    }
    if (this.dataInstruments.length == 0 && this.testInstruments.length > 0) {
      this.dataInstruments = this.testInstruments;
    }
    await this.loadTestResults(this.dateRange);
    this.updateCharts();
  }

  public async expTypeChanged() {
    await this.loadFilteredResults()
    this.updateCharts()
  }

  public async selectAllExpType(){
    this.dataTestTypes=this.testTypes;
    this.expTypeChanged();
    this.$forceUpdate()
  }

  public async selectAllInstruments(){
    this.dataInstruments=this.testInstruments;
    this.expTypeChanged();
    this.$forceUpdate()
  }

  public async enablePcPressed() {
    this.dataInstruments = this.testInstruments
    this.dataTestTypes = this.testTypes
    if (this.enablePC) {
      this.dataTestTypes = [];
      this.filteredTestResults = this.testResults.filter((t)=> t.sample_id !== null);
      this.testTypes = [...new Set(this.filteredTestResults.map((t)=>t.experiment_type))]
      this.dataTestTypes = this.testTypes
      this.expTypeChanged();
    } else {
      await this.loadTestResults(this.dateRange)
      this.updateCharts()
    }
  }

  public selectAllExpTypes() {
    this.dataTestTypes = this.testTypes
    this.expTypeChanged()
  }

  public async viewPressed(viewType: string, panel:string=''){
    if (viewType === 'filteredList') {
      this.selectable = true
      this.tableHeaders = this.testListHeaders
      this.tableItems = this.filteredTestResults
      this.viewGroup = null
      this.dialogTitle = 'Total Experiments'
      this.viewDialog = true
    } else if (viewType === 'ndList') {
      this.selectable = false
      this.tableHeaders = this.testListHeaders
      this.tableItems = this.testsND
      this.viewGroup = null
      this.viewDialog = true
      this.dialogTitle = 'Total ND Experiments'
    }
  }

  public formatNDRunList(results: IRunTracker[]): IRunTracker[] {
    // let runList: object[] = []
    results.forEach((r) => {
      if ( this.cicFails.includes(r.id) ) {
        r.failures = 'CIC FAIL'
      }
    })
    return results
  }

  public groupSelected(){
    this.tableItems.forEach((t) => {
      if(t.hasOwnProperty('group')){
        t.group = `${this.tableGroupByExperimentName ? t.experiment : ''}`
        t.group += `${this.tableGroupByVendorCallout ? (t.group=='' ? t.vendor_callout : ' | ' + t.vendor_callout) : ''}`
      }
    })
  }

  public async mounted() {
    const currentDay: Date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
    const pastWeek: Date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
    this.dateRange = [new Date(pastWeek.setDate(pastWeek.getDate()-7)).toISOString().substr(0, 10), currentDay.toISOString().substr(0, 10)]

    this.testCount = await this.getTestCount();
    this.testCountWeek = await this.getTestCount([{field: 'date_added',
                                                   operator: 'after',
                                                   value: formatISO(subWeeks(startOfToday(), 1))}]);
    this.testCountMonth = await this.getTestCount([{field: 'date_added',
                                                    operator: 'after',
                                                    value: formatISO(subWeeks(startOfToday(), 4))}]);
    await this.loadTestResults(this.dateRange);
    this.updateCharts();
  }

  public async updateCharts() {
    // await this.loadTestResults(this.dateRange);
    this.updateRunsChart();
    this.updateNDChart()
    this.updateErrorPareto()
    this.updateSicoreCharts()
  }

  public async updateRunsChart() {
    const sortedRuns = this.selectedExperiments.sort((a, b) => new Date(a.date_of_run).getTime() - new Date(b.date_of_run).getTime());
    const x: string[] = [];
    const testsByDate = [];
    for (const tr of sortedRuns) {
      const date: string = tr.date_of_run

      if (!x.includes(tr.date_of_run)) {
        x.push(tr.date_of_run)
      }
      if (testsByDate[date]) {
        testsByDate[date] += 1;
      } else {
        testsByDate[date] = 1;
      }
    }

    const runningSum: number[] = [];
    const y: number[] =[]
    for (const d of x) {
      y.push(testsByDate[d])
      runningSum.push(y.reduce((a, b) => a + b, 0))
    }

    const trace1 = {
      x: x as Plotly.Datum[],
      y: runningSum as Plotly.Datum[],
      type: 'scatter',
      yaxis: 'y2',
      name: 'Cumulative Count',
      marker: {
        color: this.runCountColor,
      },
    };
    const trace2 = {
      x: x as Plotly.Datum[],
      y: y as Plotly.Datum[],
      type: 'bar',
      name: 'Run Count',
      // marker: {
      //   color: '#3BC7E5'
      // }
    };

    const data = [trace2, trace1] as Plotly.Data[];
    Plotly.newPlot('dateChart', data, this.runsChartLayout, this.graphConfig);
  }

  public updateExperimentChart() {
    const sortedObject = this.getUniqueCounts(this.selectedExperiments, 'experiment_type')
    const data = [{
      y: Object.keys(sortedObject) as Plotly.Datum[],
      x: Object.values(sortedObject) as Plotly.Datum[],
      type: 'bar',
      orientation: 'h',
    }] as Plotly.Data[];
    Plotly.newPlot('experimentTypeChart', data, this.experimentChartLayout, this.graphConfig);
  }

  public async updateNDChart() {
    const xlabels = ['Pass', 'CIC Fail', 'Invalid', 'Error/No Results']
    const invalidCount = this.testsND.filter(obj => obj.failures === 'INVALID').length
    const noResultErrorCount = this.testsND.filter(obj => obj.failures === 'ERROR' || obj.failures === 'NO RESULT').length
    const cicFailsCount = this.cicFails.length
    const testsPassCount = this.selectedExperiments.length - this.testsND.length

    const data: Plotly.Data[] = [{
      x: xlabels as Plotly.Datum[],
      y: [testsPassCount, cicFailsCount, invalidCount, noResultErrorCount] as Plotly.Datum[],
      type: 'bar',
    }]
    Plotly.newPlot('ndRateChart', data, this.ndBarChartLayout, this.graphConfig);
  }

  public getUniqueCounts(objectToCount:Object[], field: string):Object {
    let counts = {}
    const typeCount: Set<string> = new Set();
    let total = 0;
    for (const re of objectToCount) {
      if( re[field] !== null ) {
        const experimentType = re[field];
        typeCount.add(experimentType);
        if (counts[experimentType]) {
          counts[experimentType] += 1;
        } else {
          counts[experimentType] = 1;
        }
        total += 1;
      }
    }
    counts = Object.fromEntries(Object.entries(counts).sort(([,a],[,b]) => (b as number)-(a as number)))
    return counts
  }

  public mergeObjectCounts(objs:Object[]): Object {
    if (objs.length == 0){
      return {}
    }
    const merged: Object = { ...objs[0] };
    for (let i = 1; i < objs.length; i++){
      for (const key in objs[i]) {
        if (objs[i].hasOwnProperty(key)) {
            const value = objs[i][key];
            merged[key] = merged[key] ? merged[key] + value : value;
        }
      }
    }
    return merged;
}

  public async updateSicoreCharts() {
    const runsWithErrors = this.selectedExperiments.filter((r) => r.scicore_finish_status !== null 
    && r.scicore_finish_status !== '(,0,0)' && r.scicore_finish_status !== '')
    const runsWithFinishMsg = this.selectedExperiments.filter((r) => r.scicore_run_finish_m !== null 
    && r.scicore_run_finish_m !== '(,0,0)' && r.scicore_run_finish_m !== '')
    const runsWithCartMsg = this.selectedExperiments.filter((r) => r.scicore_cart_prep_status !== null 
    && r.scicore_cart_prep_status !== '(,0,0)' && r.scicore_cart_prep_status !== '')
    const runsWithStartMsg = this.selectedExperiments.filter((r) => r.scicore_start_run_status !== null 
    && r.scicore_start_run_status !== '(,0,0)' && r.scicore_start_run_status !== '')

    const errorCounts = this.getUniqueCounts(runsWithErrors, 'scicore_finish_status')
    const finishCounts = this.getUniqueCounts(runsWithFinishMsg, 'scicore_run_finish_m')
    const cartCounts = this.getUniqueCounts(runsWithCartMsg, 'scicore_cart_prep_status')
    const startCounts = this.getUniqueCounts(runsWithStartMsg, 'scicore_start_run_status')
    
    const totalErrors = runsWithErrors.length + runsWithFinishMsg.length + runsWithCartMsg.length + runsWithStartMsg.length
    const mergedErrors = this.mergeObjectCounts([errorCounts,finishCounts,cartCounts,startCounts])

    const percentTrace: number[] = [];
    let pendingSum: number = 0;
    Object.keys(mergedErrors).forEach((c) => {
      const count:number = mergedErrors[c] as number
      pendingSum += count
      percentTrace.push((pendingSum)/totalErrors*100)
    })

    const trace1 = {
      x: Object.keys(mergedErrors) as Plotly.Datum[],
      y: percentTrace as Plotly.Datum[],
      type: 'scatter',
      yaxis: 'y2',
      name: '% Contribution',
    };
    const trace2 = {
      x: Object.keys(errorCounts) as Plotly.Datum[],
      y: Object.values(errorCounts) as Plotly.Datum[],
      type: 'bar',
      name: 'Finish_status',
      mode: 'markers',
      textposition: "none",
      marker: {
        color: this.sicoreErrorColors[0],
      },
    };
    const trace3 = {
      x: Object.keys(finishCounts) as Plotly.Datum[],
      y: Object.values(finishCounts) as Plotly.Datum[],
      type: 'bar',
      name: 'Finish_m',
      mode: 'markers',
      textposition: "none",
      marker: {
        color: this.sicoreErrorColors[1],
      },
    };
    const trace4 = {
      x: Object.keys(cartCounts) as Plotly.Datum[],
      y: Object.values(cartCounts) as Plotly.Datum[],
      type: 'bar',
      name: 'Prep_status',
      mode: 'markers',
      textposition: "none",
      marker: {
        color: this.sicoreErrorColors[2],
      },
    };
    const trace5 = {
      x: Object.keys(startCounts) as Plotly.Datum[],
      y: Object.values(startCounts) as Plotly.Datum[],
      type: 'bar',
      name: 'Run_status',
      mode: 'markers',
      textposition: "none",
      marker: {
        color: this.sicoreErrorColors[3],
      },
    };

    const data = [trace1, trace2, trace3, trace4, trace5] as Plotly.Data[];
    let sicoreLayout = this.deepClone(this.paretoChartLayout)
    // sicoreLayout.xaxis.tickfont = { size: 10 };
    sicoreLayout.title = 'L3 Pareto: SiCore Errors'
    Plotly.newPlot('sicoreErrorChart', data, this.scicoreErrorsLayout, this.graphConfig);
  }

  public async updateErrorPareto() {
    // sort the errors by count high -> low
    const sortedObject = this.getUniqueCounts(this.runErrors, 'error_full')
    // Object.fromEntries(Object.entries(errorCounts).sort(([,a],[,b]) => (b as number)-(a as number)))

    const percentTrace: number[] = [];
    let pendingSum: number = 0;
    const errorLabels: string[] = [];
    Object.keys(sortedObject).forEach((c) => {
      errorLabels.push(this.runErrors.filter((r)=>r.error_full===c)[0].details)
      const count:number = sortedObject[c] as number
      pendingSum += count
      percentTrace.push((pendingSum)/this.runErrors.length*100)
    })

    const trace1 = {
      x: Object.keys(sortedObject) as Plotly.Datum[],
      y: percentTrace as Plotly.Datum[],
      type: 'scatter',
      yaxis: 'y2',
      name: '% Contribution',
    };
    const trace2 = {
      x: Object.keys(sortedObject) as Plotly.Datum[],
      y: Object.values(sortedObject) as Plotly.Datum[],
      type: 'bar',
      name: 'Error Count',
      mode: 'markers',
      text: errorLabels,  // This is the custom label for each point
      textposition: "none",
      marker: {
        color: this.errorGraphColor
      },
    };

    const data = [trace1, trace2] as Plotly.Data[];

    Plotly.newPlot('errorChart', data, this.paretoChartLayout, this.graphConfig);
  }

  public async clearPlotly( plotlyIds: string[]) {
    plotlyIds.forEach((i) => {
      const graphContainer = document.getElementById(i);
      if(graphContainer!== null) {
        graphContainer.innerHTML = '';
      }
    });
  }

  // TODO Replace with shared function
  private deepClone<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const newArr: any[] = [];
      for (let i = 0; i < obj.length; i++) {
        newArr[i] = this.deepClone(obj[i]);
      }
      return newArr as T;
    }

    const newObj: Record<string, any> = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = this.deepClone(obj[key]);
      }
    }

    return newObj as T;
  }
}
</script>

<style scoped>
.duration {
  width: 7em;
  display: inline-block;
}
.exp_types {
  width: 19em;
  justify-content: center;
  display: inline-block;
}
.no_padding {
  padding: 0px;
}

.viewButton {
  margin: 10px;
}

.custom_font {
  font-size: 15px;
}

.comparator-experiment-col{ max-width: 7em; min-width: 6em;}
.comparator-col{ max-width: 5em; }
.comparator-total-callout-col{ max-width: 5em; }
.comparator-ppa-col{ max-width: 3em; }
.comparator-npa-col{ max-width: 3em; }
.comparator-stats-col{ max-width: 5em; }

.v-data-table >>> .col-experiment { max-width: 16em; padding: 0; font-size:small; }
.v-data-table >>> .col-run { width: 3em; padding: 0; font-size:small; }
.v-data-table >>> .col-experiment-type { max-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-rig { max-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-failures { min-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-descriprion { max-width: 10em; padding: 0; font-size:small; }
.v-data-table >>> .col-date { min-width: 7em; padding: 0; font-size:small; }
.v-data-table >>> .col-sicore-error-code { max-width: 8em; padding: 0; font-size:small; }
.v-data-table >>> .col-sicore-error { width: 4em; padding: 0; font-size:small; }
</style>