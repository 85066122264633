export interface IInstrument {
    id: number;
    created_on: string;
    rig: string;
    generation: string;
}

export interface IRunTracker {
    id: number;
    experiment: string;
    date_of_run: string;
    filter_type: string;
    chip_id: string;
    rig: string;
    cap: string;
    run_description: string;
    print_run_id: number;
    analysis_qc: string;
    comments: string;
    profile: string;
    frame_record_location: string;
    date_added: string;
    cooling_type: string;
    experiment_type: string;
    run: string|null
    instrument_id?: number;
    created_by_id: number;
    modified_by_id: number;
    scicore_finish_status: string|null;
    scicore_error_code1: number|null;
    scicore_error_code2: number|null;
    nxx_source: string|null;
    failures: string|null;
    gx_tests_id: number|null;
    array_templates_id: number|null;
    sample_id: number|null;
    instrument: IInstrument|null;
    array_template_profile_id: number|null;

    fw_version: string|null;
    total_ers_erros: number|null;

    scicore_run_finish_m: string|null;
    scicore_run_finish_m_code1: number|null;
    scicore_run_finish_m_code2: number|null;

    scicore_start_run_status: string|null;

    scicore_cart_prep_status: string|null;
    scicore_cart_prep_status_opt1: number|null;
    scicore_cart_prep_status_opt2: number|null;

    status: string|null;
    parent_run_id: number|null;
}

export interface IRunTrackerExtended extends IRunTracker {
    identifier: string|null;
    lot_code: string|null;
    array_template: IArrayTemplate | undefined;
    print_run: IArrayPrintRun | undefined;
    die_number: number|null;
    serial_number: string|null;
    barcode: string|null;
}

export interface IClinicalRuntracker{
    RunTracker: IRunTracker,
    rig: string,
    ref: string,
    subject_sex: string,
    subject_age: string,
    site_name: string,
    panels: string[],
    soc_test: string|null,
    soc_result: string|null,
    adf_version: string|null,
    soc_collection: string|null;
    soc_test_date: string|null;
}

export interface ISignal {
    id: number;
    order_name: string;
    metric: string;

    signals: number[][];
    x_axis: number[];

    panel_name_id: number;
    experiment_id: number;

    date_added: string;
    created_by_id: number;
    modified_by_id: number;
}
export interface ISignalWithProbe {
    Signal: ISignal;
    Probe: IProbe;
}

export interface IAmpfea {
    id: number;
    order_name: string;
    metric: string;
    replicate: number;
    location: string;
    result: number;

    experiment_id: number;
    panel_name_id: number;

    date_added: string;
    created_by_id: number;
    modified_by_id: number;
}
export interface IAmpfeaWithProbe {
    Ampfea: IAmpfea
    Probe: IProbe
}

export interface IAmpfeaStats {
    id: number;
    order_name: string;
    metric: string;
    replicate_count: number;
    mean: number;
    median: number;
    std: number;
    mad: number;
    max: number;

    experiment_id: number;
    panel_name_id: number;

    date_added: string;
    created_by_id: number;
}

export interface IConsumable {
    serial_number: string|null;
    barcode: string|null;
    check_sum: string|null;
    experiment_id: number;
    created_on: string|null;
    created_by_id: number
}

export interface IChip {
    id: number;
    filter_type: string;
    print_run: string;
    cap: string;
    chip_id: string;
    chip_slope: number;
    chip_intercept: number;
    consumable_id: number;
    die_num: string|null;
    wafer_pn: string|null;
    wafer_lot: string|null;
    wafer_number: string|null;

    date_added: string;
    created_by_id: number;
}

export interface IStaticThermalAnalysis {
    id: number;
    time: number;
    thermal_resistance: number;
    experiment_id: number;

    date_added: string;
    created_by_id: number;
}

export interface IThermalData {
    chip_time: number[];
    chip_temp: number[];
    chip_setpt: number[];
    chip_register: number[];
    chip_voltage: number[];
    chip_current: number[];
    chip_power: number[];
    heatsink_ambient: number[];
    module_ambient: number[];
    servo_pos: number[];
    servo_state: number[];
}
export interface IThermalSignals {
    id: number;
    data: IThermalData
    experiment_id: number;
    date_added: string;
    created_by_id: number;
}

export interface IOpticalSignals {
    id: number;
    chip_time: number;
    led_voltage: number;
    led_current: number;
    led_power: number;

    experiment_id: number;

    date_added: string;
    created_by_id: number;
}

export interface IThermalStats {
    id: number;
    mean_ramp: number;
    mean_cool: number;
    stdev_ramp: number;
    stdev_cool: number;
    mean_settling_denature: number;
    mean_settling_anneal: number;
    stdev_settling_denature: number;
    stdev_settling_anneal: number;
    mean_overshoot_denature: number;
    mean_overshoot_anneal: number;
    stdev_overshoot_denature: number;
    stdev_overshoot_anneal: number;
    mean_undershoot_denature: number;
    mean_undershoot_anneal: number;
    stdev_undershoot_denature: number;
    stdev_undershoot_anneal: number;
    mean_stable_denature: number;
    mean_stable_anneal: number;
    stdev_stable_denature: number;
    stdev_stable_anneal: number;
    mean_thermal_resistance: number;
    stdev_thermal_resistance: number;

    experiment_id: number;

    date_added: string;
    created_by_id: number;
}

export interface ITransitionAnalysis {
    id: number;
    cycle: number;
    ramp_rate: number;
    cool_rate: number;
    settling_time_denature: number;
    settling_time_anneal: number;
    overshoot_denature: number;
    overshoot_anneal: number;
    undershoot_denature: number;
    undershoot_anneal: number;
    stable_time_denature: number;
    stable_time_anneal: number;

    experiment_id: number;

    date_added: string;
    created_by_id: number;
}

export enum EProbeType {
    wild = 1,
    mutant = 2,
}
export enum EProbeFunction {
    control = 'Control Probe',
    depletion = 'Depletion',
    discrimination = 'Discrimination',
    normalization = 'Normalization',
    target = 'Target Identification',
}


export interface IProbeCreate {
    panel_name: string;
    order_name: string|null;
    panel_association: string|null;
    callout_id: number|null;
    gene_region: string|null;
    function: string|null;
    probe_sequence: string|null;
    probe_type: number|null;
    wild_type_probe_id: number|null;
    analysis_code: string|null;
    probe_description: string|null;
}

export interface IProbe extends IProbeCreate {
    id: number;

    date_added: string;
    created_by_id: number;
    modified_by_id: number;

    callout: ICallout|null;
    mutants?: IProbe|null;
}

export interface IArrayPrintRunCreate {
    name: string;
    run_date: string | null;
    project: string;
    substrate: string;
    printed_target_count: number | null;
    probe_count: number | null;
    filter: string | null;
    printed: string | null;
    purpose: string | null;
    status: string | null;
    array_template_id: number | null;
}

export interface IArrayPrintRun extends IArrayPrintRunCreate {
    id: number;

    created_on: string;
    created_by_id: number;
    modified_by_id: number;

    array_template: IArrayTemplate | null;
}

export enum EArrayTemplateState {
    draft = "draft",
    layout = "layout",
    complete = "complete",
}

export interface IArrayTemplateCreate {
    name: string;
    template_date: string;
    state: EArrayTemplateState;
    barcode: number[][] | null;
}

export interface IArrayTemplate extends IArrayTemplateCreate {
    id: number;

    probe_count?: number;
    print_runs?: IArrayPrintRun[] | null
    profiles?: IArrayTemplateProfile[]

    created_on?: string;
    created_by_id?: number;
    modified_by_id?: number;
}

export interface IArrayTemplateProbeCreate {
    array_template_id: number;
    probe_id: number;
    probe_ref: string|null;
    template_order: number;
}

export interface IArrayTemplateProbe extends IArrayTemplateProbeCreate {
    id: number;

    created_on?: string;
    created_by_id?: number;
    modified_by_id?: number;

    array_template?: IArrayTemplate;
    probe?: IProbe;
}

export enum EArrayTemplateProfileInstrument {
    p3plus = "P3+",
    p4 = "P4",
    g2b = "G2B",
}

export interface IArrayTemplateProfile {
    id?: number;
    array_template_id: number;
    annealing_temp: number;
    hybridization_temp: number;
    denaturation_temp: number;
    analysis_key: string;
    imaging_profile: string;
    isx_file_name: string;
    parent_id: number | null;
}

export interface IArrayTemplateProbeReplicate {
    id: number;
    array_template_id: number;
    probe_id: number;
    probe_ref: string|null;
    x: number;
    y: number;
    created_on: string;
    created_by_id: number;
    modified_by_id: number;

    panel_name: string;
    order_name: string;
    panel_association: string;
    callout_id: number;
    gene_region: string;
    function: string;
    probe_sequence: string;
    probe_type: number;
    wild_type_probe_id: number|null;
    analysis_code: string;
    probe_description: string;

    callout_name:string;
}

export interface IRuleSet {
    id: number;
    name: string;
    array_template_id: number;
    created_on: string;
    created_by_id: number;
    modified_by_id: number;
}
export interface IRuleSetCreate {
    name: string;
    array_template_id: number;
}

export interface IProbeRule {
    id: number;
    rule_set_id: number;
    probe_id: number|null;

    rr_method: string;

    use_pq: boolean|null;
    pq_th_low: number|null;
    pq_th_high: number|null;
    use_aq: boolean|null;
    aq_th_low: number|null;
    aq_th_high: number|null;
    use_tm: boolean|null;
    tm_th_low: number|null;
    tm_th_high: number|null;

    use_dpq: boolean|null;
    dpq_th_low: number|null;
    dpq_th_high: number|null;
    use_daq: boolean|null;
    daq_th_low: number|null;
    daq_th_high: number|null;
    use_dtm: boolean|null;
    dtm_th_low: number|null;
    dtm_th_high: number|null;

    use_ct: boolean|null;
    ct_th_low: number|null;
    ct_th_high: number|null;

    use_sbr: boolean|null;
    sbr_th_low: number|null;
    use_amplitude: boolean|null;
    amplitude_th_low: number|null;
    use_stability: boolean|null;
    stability_th_low: number|null;

    use_hybc1_pq: boolean|null;
    hybc1_pq_th_low: number|null;
    hybc1_pq_th_high: number|null;
    use_hybc1_aq: boolean|null;
    hybc1_aq_th_low: number|null;
    hybc1_aq_th_high: number|null;
    use_hybc1_tm: boolean|null;
    hybc1_tm_th_low: number|null;
    hybc1_tm_th_high: number|null;
    use_hybc2_pq: boolean|null;
    hybc2_pq_th_low: number|null;
    hybc2_pq_th_high: number|null;
    use_hybc2_aq: boolean|null;
    hybc2_aq_th_low: number|null;
    hybc2_aq_th_high: number|null;
    use_hybc2_tm: boolean|null;
    hybc2_tm_th_low: number|null;
    hybc2_tm_th_high: number|null;

    created_on: string;
    created_by_id: number;
    modified_by_id: number;
}

export interface IRulesTable {
    id: number;
    rule_set_id: number;
    probe_id: number|null;

    rr_method: string;

    use_metric1: boolean|null;
    metric1_low: number|null;
    metric1_high: number|null;
    metric1_text: string|null;

    use_metric2: boolean|null;
    metric2_low: number|null;
    metric2_high: number|null;
    metric2_text: string|null;

    use_metric3: boolean|null;
    metric3_low: number|null;
    metric3_high: number|null;
    metric3_text: string|null;

    use_metric4: boolean|null;
    metric4_low: number|null;
    metric4_high: number|null;
    metric4_text: string|null;

    l1_call: [number, number, number];

    panel_id: number;
    panel_association: string;
    panel_name: string;
    order_name: string;
    function: string;

    created_on: string;
    created_by_id: number;
    modified_by_id: number;

    callout_name: string|null;
    callout_id: number|null;
}

export interface IProbeRuleCreate {
    rule_set_id: number;
    probe_id: number|null;

    rr_method: string;

    use_pq: boolean|null;
    pq_th_low: number|null;
    pq_th_high: number|null;
    use_aq: boolean|null;
    aq_th_low: number|null;
    aq_th_high: number|null;
    use_tm: boolean|null;
    tm_th_low: number|null;
    tm_th_high: number|null;

    use_dpq: boolean|null;
    dpq_th_low: number|null;
    dpq_th_high: number|null;
    use_daq: boolean|null;
    daq_th_low: number|null;
    daq_th_high: number|null;
    use_dtm: boolean|null;
    dtm_th_low: number|null;
    dtm_th_high: number|null;

    use_ct: boolean|null;
    ct_th_low: number|null;
    ct_th_high: number|null;

    use_sbr: boolean|null;
    sbr_th_low: number|null;
    use_amplitude: boolean|null;
    amplitude_th_low: number|null;
    use_stability: boolean|null;
    stability_th_low: number|null;

    use_hybc1_pq: boolean|null;
    hybc1_pq_th_low: number|null;
    hybc1_pq_th_high: number|null;
    use_hybc1_aq: boolean|null;
    hybc1_aq_th_low: number|null;
    hybc1_aq_th_high: number|null;
    use_hybc1_tm: boolean|null;
    hybc1_tm_th_low: number|null;
    hybc1_tm_th_high: number|null;
    use_hybc2_pq: boolean|null;
    hybc2_pq_th_low: number|null;
    hybc2_pq_th_high: number|null;
    use_hybc2_aq: boolean|null;
    hybc2_aq_th_low: number|null;
    hybc2_aq_th_high: number|null;
    use_hybc2_tm: boolean|null;
    hybc2_tm_th_low: number|null;
    hybc2_tm_th_high: number|null;
}

export interface IProbeResults {
    probe_id: number;
    positive: number;
    negative: number;
    invalid: number;
}

export interface IPanelResults {
    panel_association: string;
    positive: number;
    negative: number;
    invalid: number;
    pqHist: IHistograms;
    aqHist: IHistograms;
    tmHist: IHistograms;
    ctHist: IHistograms;
}

export interface ICalloutResults {
    callout_id: number;
    positive: number;
    negative: number;
    invalid: number;
}

export interface IHistograms {
    metric: string;
    rr_method: string;
    bins: number[]|null;
    counts: number[]|null;
}

export interface Il2Results {
    panel_association: string;
    probe_id: number[];
    l1_call: IProbeResults[];
    l2_call: IPanelResults[];
    pqHist: IHistograms;
    aqHist: IHistograms;
    tmHist: IHistograms;
    ctHist: IHistograms;
}

export interface IComparatorMatrix {
    callout_id: number;
    tp: number;
    tn: number;
    fp: number;
    fn: number;
}

export interface IReductionErrors {
    group: string;
    error_msg: string;
    experiment_id: number[]|null;
    tier: string;
}

export interface IReductionResult{
  rule_set_id: number;
  experiment_ids: number[];
  missing_exp_ids: number[];
  errors: IReductionErrors[];

  calloutResults: ICalloutResults[];
  l2Results: Il2Results[];
  comparatorMatrix: IComparatorMatrix[];
}

export interface IFormulaEditor {
    rule_set_id: number;
    order: number;
    formula: string;
    result: string;
    otherwise_result: string;
}

export interface IPanelRuleCreate extends IFormulaEditor {
    panel_name: string;
}

export interface IPanelRule extends IPanelRuleCreate {
    id: number;

    created_on: string|null;
    created_by_id: number|null;
    modified_by_id: number|null;
}

export interface IVendor{
    id: number
    products: IProduct[]
}

export interface IProduct {
    id: number,
    name: string,
    description: string,
    internal: boolean,
    vendor_id: number,
    vendor: IVendor|null
    callouts: ICallout[]
    experiments: IRunTracker[]
}

export interface ICallout {
    id: number
    name: string
    active: boolean
    product_id: number|null
    created_on: string|null
    created_by_id: number|null
    modified_by_id: number|null
}

export interface ICalloutExtended extends ICallout {
    probes: IProbe[]
    vendor_callouts: IVendorCallout[]
    aliases: ICalloutAlias[]
    product: IProduct[]
    product_aliases: ICallout[]
}

export interface ICalloutRuleCreate extends IFormulaEditor {
    callout_id: number;
}

export interface ICalloutRule extends ICalloutRuleCreate {
    id: number;

    created_on: string|null;
    created_by_id: number|null;
    modified_by_id: number|null;
}

export interface IVendorCallout {
    id: number;
    name: string;
    panel_name: string;
    callout_id: number;
    callout: ICallout|null;
}

export interface ICalloutAlias {
    id: number;
    name: string;
    callout_id: number;
    created_on?: string;
    created_by_id?: number;
    modified_by_id?: number;
}

export interface IComparatorTestResult {
    id: number;
    panel_name: string;
    target_name: string;
    detected: boolean;
    site_name: string;
    test_date: string|null;

    sample_id: number|null;
    callout_id: number|null;
    vendor_callout_id: number|null;

    created_on?: string;
    created_by_id?: number;
    modified_by_id?: number;
}

export enum ESexType {
    male = "Male",
    female = "Female",
}
export interface ISample {
    id: number;

    ref: string
    source: string|null;
    type: string|null;
    media: string|null;
    collection_date: string|null;

    subject_ref: string|null;
    subject_age: number|null;
    subject_sex: ESexType|null;

    comparator_results: IComparatorTestResult[]|null;
    run_tracker: IRunTracker[]|null;

    created_on: string|null;
    created_by_id: number|null;
    modified_by_id: number|null;
}

export interface IClinicalDataPair {
    id: number;
    parent_id: number;
    parent: IClinicalData;
    key: string;
    value: string|null;
    type: string;
}

export interface IClinicalData {
    id: number;
    sample_ref: string;
    experiment_id: number|null;
    file_id: number|null;

    pairs: IClinicalDataPair[];
    samples: ISample[];

    created_on: string|null;
    created_by_id: number|null;
    modified_by_id: number|null;
}

export interface ParseException {
    line: number;
    column: number;
    explain: string;
    markup_line: string;
}

export interface FormulaResponse {
    formula: string;
    valid: boolean;
    result: boolean | number | null;
    error: ParseException;
}

export interface IWafer {
    id: number;
    identifier: string;
    fab_code: string;
    lot_code: string;

    dies: IDie[];
    process_steps: IProcessStep[];
}

export interface IWaferWithCounts extends IWafer {
    gross: number;
    ee_good: number;
    ee_reject: number;
    ee_yield: number;
}

export interface IDie {
    id: number;
    wafer_id: number;
    die_number: number;
    slope: number | null;
    intercept: number | null;

    wafer: IWafer | null;
    die_electrical_inspection: IDieElectricalInspection | null;
}

export interface IDieElectricalInspection {
    id: number;
    die_id: number;
    status: string;
    temp: string;
    tag: string;
    value: number;
    x: number;
    y: number;

    die: IDie | null;
}

export interface IProcessStep {
    id: number;
    wafer_id: number;
    process_step_type_id: number;

    equipment_id: string;
    equipment_calibration_date: string;
    equipment_calibration_status: string;

    operator: string;
    process_start: string;
    process_end: string;
    inspector: string;
    fixture_number: string;
    fixture_id: string;
    plate_number: string;
    site_number: string;
    sw_version: string;

    wafer: IWafer | null;
    process_files?: IProcessFile[];

}

export interface IProcessFile {
    id: number;
    process_step_id: number;
    file_id: number;
    tag?: string;

    file?: number;

    created_on?: string;
    created_by_id?: number;
    modified_by_id?: number;
}

export interface ISnackBars {
    message: string;
    color: string;
    timeout: number;
    enable: boolean;
    button: boolean|null;
    buttonTxt: string|null;
    infoButton: boolean|null;
}

export interface INXXCalloutResults {
    run_tracker_id: number;
    callout_id: number;
    result_str: string;
    date_added: string;
    created_by_id: number|null;
    modified_by_id: number|null;
}

export interface IRunErrors {
    run_tracker_id: number;
    error_code: number;
    error_type: number;
    error_full: string;
    details: string;
    severity: number;
    date_added: string;
}

export interface IInternalCallouts {
    run_name: string;
    callout_name: string;
    result_str: string;
}

export interface IExternalCallouts {
    product: string;
    callout_name: string;
    detected: boolean;
}

export interface IComparatorMap {
    run_name: string;
    internal_callouts: IInternalCallouts[];
    primary_join: string[];
    comparison_callouts: IExternalCallouts[];
    notes: string
}

export interface IPpaNpa {
    group: string;
    comparator_panel: string;
    fp: IComparatorMap[];
    fn: IComparatorMap[];
    tp: IComparatorMap[];
    tn: IComparatorMap[];
    undetermined: IComparatorMap[];
    ppa: number;
    npa: number;
}

export interface IQCResult {
    valid: boolean;
    majority_count: number[];
    at_least_one: number[];
    total_probes: number;
    np_stability_null: number;
    np_stability_min: number;
    np_stability_max: number;
    np_stability_mean: number;
    np_stability_median: number;
    np_sbr_null: number;
    np_sbr_min: number;
    np_sbr_max: number;
    np_sbr_mean: number;
    np_sbr_median: number;
    np_signal_null: number;
    np_signal_min: number;
    np_signal_max: number;
    np_signal_mean: number;
    np_signal_median: number;
    np_background_null: number;
    np_background_min: number;
    np_background_max: number;
    np_background_mean: number;
    np_background_median: number;
    hybc1_pq_null: number;
    hybc1_pq_min: number;
    hybc1_pq_max: number;
    hybc1_pq_mean: number;
    hybc1_pq_median: number;
    hybc2_pq_null: number;
    hybc2_pq_min: number;
    hybc2_pq_max: number;
    hybc2_pq_mean: number;
    hybc2_pq_median: number;
    pq_fail_count: number[];
    failed_pq_probes: Record<string, number[]>|null;
    replicate_signals: Record<string, number[]>|null;
}

export interface IProductComparisonRule {
    id: number
    created_on: string|null
    modified_on: string|null
    created_by_id: number|null
    modified_by_id: number|null

    product_id: number|null
    name: string
    share_status: string
}

export interface IReflexProduct {
    is_reflex_product: boolean|null
    product: IProduct
}
export interface IProductCalloutComparisonsMerged {
    product_comparison_rule_id: number|null
    callout: ICallout
    products: IReflexProduct[]
    comparison: string
}
export interface IProductComparisonRulesMerged {
    id: number|null
    name: string
    created_by_id: number|null
    product: IProduct
    share_status: string
    product_callout_comparisons: IProductCalloutComparisonsMerged[]
}