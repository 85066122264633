import axios from 'axios';
import { apiUrl } from '@/env';
import { FormulaResponse,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async validateFormula(token: string, formulas:string[]) {
    console.log('validateFormula: formulas=', formulas)
    return axios.post<FormulaResponse[]>(`${apiUrl}/api/v1/formula/validate`, { formulas: formulas }, authHeaders(token));
  },
  async evaluateFormula(token: string, formulas:string[], vars:object) {
    return axios.post<FormulaResponse[]>(`${apiUrl}/api/v1/formula/evaluate`, { formulas: formulas, vars:vars }, authHeaders(token));
  },
};
