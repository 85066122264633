<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{mode}} Print Run</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form :readonly="mode == 'View'" v-model="valid" ref="form" lazy-validation>
            <div>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Name"
                    v-model="pr.name"
                    v-validate="'required|uniqueName'"
                    data-vv-name="Name"
                    :error-messages="errors.collect('Name')"
                    dense
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    label="Project"
                    v-model="pr.project"
                    :items="projects"
                    v-validate="'required'"
                    data-vv-name="Project"
                    :error-messages="errors.collect('Project')"
                    dense>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select label="Substrate"
                    v-model="pr.substrate"
                    :items="substrates"
                    v-validate="'required'"
                    data-vv-name="Substrate"
                    :error-messages="errors.collect('Substrate')"
                    dense>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Run Date" v-model="pr.run_date" type="date" dense></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    label="Array Template"
                    v-model="pr.array_template_id"
                    :items="arrayTemplates"
                    item-text="name"
                    item-value="id"
                    clearable
                    dense>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2"><v-text-field label="Printed" v-model="pr.printed" dense></v-text-field></v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Printed Target Count"
                    v-model="pr.printed_target_count"
                    v-validate="'numeric'"
                    data-vv-name="Printed Target Count"
                    :error-messages="errors.collect('Printed Target Count')"
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Probe Count"
                    v-model="pr.probe_count"
                    v-validate="'numeric'"
                    data-vv-name="Probe Count"
                    :error-messages="errors.collect('Probe Count')"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
              </v-row>
              <v-row>
                <v-col cols="6"><v-textarea label="Purpose" v-model="pr.purpose" rows=1 auto-grow dense></v-textarea></v-col>
                <v-col cols="2">
                  <v-select label="Filter" v-model="pr.filter" :items="['F2', 'F2.3', 'F6']" dense></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    label="Status"
                    v-model="pr.status"
                    :items="statuses"
                    v-validate="'required'"
                    data-vv-name="Status"
                    :error-messages="errors.collect('Status')"
                    dense>
                  </v-select>
                </v-col>
              </v-row>
            </div>

          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="mode != 'View'" @click="cancel">Cancel</v-btn>
        <v-btn v-if="mode == 'Create'" @click="reset">Reset</v-btn>
        <v-btn v-if="mode == 'Create'" @click="submit" :disabled="!valid">Save</v-btn>
        <v-btn v-if="mode == 'Edit'" @click="update" :disabled="!valid">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IArrayPrintRun, IArrayPrintRunCreate, IArrayTemplate } from '@/interfaces/bcdb';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import { Validator } from 'vee-validate';
import { readHasAdminAccess } from '@/store/main/getters';


@Component
export default class ArrayPrintRunCreate extends Vue {
  public valid = false;
  public id: number = 0;
  public mode = '';
  public pr: IArrayPrintRunCreate = {
    name: '',
    run_date: null,
    project: '',
    substrate: '',
    printed_target_count: null,
    probe_count: null,
    filter: null,
    printed: null,
    purpose: null,
    status: '',
    array_template_id: null,
  };

  public substrates: string[] = ['Chip', 'Die', 'Slide', 'Blank Wafer', 'Wafer'];
  public projects: string[] = [ 'ERP', 'URP', 'BCP', 'ONC', 'BCID', 'URP-MP', 'TGF', 'MAS', 'PDV', 'FURP', 'AOP', 'SARS-COV2', 'MURP', 'URP20', 'ARD', 'TFG', 'XRP161', 'XRP', 'TST', 'HIV' ];
  public statuses: string[] = [ 'Draft', 'Complete', 'Cancelled', 'Quarentined' ];
  public printRuns: IArrayPrintRun[] = [];
  public arrayTemplates: IArrayTemplate[] = [];
  public hasAdminAccess = readHasAdminAccess(this.$store);

  public async mounted() {
    this.id = parseInt(this.$router.currentRoute.params.id);
    if( this.id ) { this.loadPrintRun(this.id) }

    this.mode = this.$router.currentRoute.name?.replace(/biochip-print-run-/,'').capitalize() || '';
    this.mode = !this.hasAdminAccess ? 'View' : this.mode;

    this.getPrintRuns()
    this.getArrayTemplates()
    Validator.extend('uniqueName', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => !this.printRuns.find((pr) => 
        pr.name === value && ( this.mode === 'Create' || pr.id !== this.id) ),
    });
    this.reset();
  }

  public loadPrintRun(id) {
    api.getArrayPrintRun(readToken(this.$store), id).then((response) => {
      this.pr = response.data;
    })
  }

  public async getPrintRuns() {
    api.getArrayPrintRuns(readToken(this.$store)).then((response) => {
      this.printRuns = response.data;
    });
  }

  public async getArrayTemplates() {
    api.getArrayTemplates(readToken(this.$store)).then((response) => {
      this.arrayTemplates = response.data;
    });
  }

  public reset() {
    this.$validator.reset();
    this.pr = {
      name: '',
      run_date: null,
      project: '',
      substrate: '',
      printed_target_count: null,
      probe_count: null,
      filter: null,
      printed: null,
      purpose: null,
      status: '',
      array_template_id: null,
    };
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      api.createArrayPrintRun(readToken(this.$store), this.pr).then((response) => {
        this.$router.push('/main/print-runs');
      });
    }
  }

  public async update() {
    if (await this.$validator.validateAll()) {
      api.updateArrayPrintRun(readToken(this.$store), this.id, this.pr).then((response) => {
        this.$router.push('/main/print-runs');
      });
    }
  }
}
</script>
