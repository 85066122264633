<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">PB Files</div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="archives"
          :expanded.sync="expanded"
          item-key="filename"
          show-expand
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <!--<template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Test Archives</v-toolbar-title>
            </v-toolbar>
          </template>-->
          <template v-slot:item.count="{ item }">
            {{ item.children.filter((c) => c.file_type == 'RNX').length }}
          </template>
          <template v-slot:item.download="{ item }">
            <v-btn
              color="primary"
              small
              v-on:click="downloadArchive(item)"
            >
              Download
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 2px;">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Sample
                      </th>
                      <th class="text-left">
                        Result
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(child, i) in item.children"
                      :key="i"
                      v-if="child.file_type == 'RNX'"
                    >
                      <td>{{ child.test_result.sample_id }}</td>
                      <td>{{ child.test_result.result }}</td>
                      <td>{{ child.test_result.created_at }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              
            </td>
          </template>
        </v-data-table>
        
      </v-card-text>
      <!--<v-card-actions>
        <v-btn to="/main/profile/view">View Profile</v-btn>
        <v-btn to="/main/profile/edit">Edit Profile</v-btn>
        <v-btn to="/main/profile/password">Change Password</v-btn>
      </v-card-actions>-->
    </v-card>
  </v-container>
</template>



<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readTestSummary, readTraces } from '@/store/tests/getters';
import { readToken } from '@/store/main/getters';
import { dispatchGetTestSummary, dispatchGetTraces, dispatchMakeArchive } from '@/store/tests/actions';

import { commitSetTestSummary } from '@/store/tests/mutations';
import { IFile } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { dispatchCheckApiError } from '@/store/main/actions';
import { commitSetFileNotifications } from '@/store/main/mutations';
import FileSaver from 'file-saver';



// instrument, module, cart, gx_username, status, result, start_date, reagent_lot, assay, upload_date

@Component
export default class Files extends Vue {

  public archives: IFile[] = [];

  public expanded: object[] = [];

  public headers: object[] = [
    { text: 'Filename', value: 'filename' },
    { text: 'Size', value: 'file_size' },
    { text: 'Count', value: 'count' },
    { text: 'Uploaded On', value: 'upload_date' },
    { text: 'Download', value: 'download' },
    { text: '', value: 'data-table-expand' },
  ];

  public async loadArchives() {
    const response = await api.getArchiveFiles(readToken(this.$store));
    if (response) {
      console.log('Loaded archives');
      this.archives = response.data;
    }
  }

  public async downloadArchive(file: IFile) {
      const response = await api.downloadFile(
        readToken(this.$store),
        file.id,
      );
      if (response) {
        console.log('Read file');
        console.log(response);
        FileSaver.saveAs(response.data, file.filename);
      }
  }

  public async mounted() {
    commitSetFileNotifications(this.$store, 0);
    await this.loadArchives();
    // dispatchGetTests(this.$store, this.selectedFilters);
  }

}
</script>

<style scoped>


</style>
