import { writeFile, utils } from 'xlsx-js-style';
import { format } from 'date-fns'
import {  } from '@/interfaces/bcdb';
import { STYLE_HEADING, STYLE_TITLE, STYLE_CENTER, STYLE_BORDER_RIGHT_THIN, STYLE_BORDER_BOTTOM_THIN} from '@/scripts/to-excel/excel-styles'
/*
Purpose: To translate a ts object into an excel spreadsheet.
fields can contain nested objects denoted by a '.'. Example passing in instrument.rig will write the rig value into a cell.
*/
type Column = { v?:any; t?:any; s?:any; }
type Sheet = { v?:any; t?:any; s?:any; }[][];


function buildObjectToExcel(data:object[][], fields:string[][], title:string, 
  sheetHeaders:any[][]|null=null, sheetTitles:string[]|null=null, tabNames:string[]|null=null) {
  const wb = utils.book_new();

  for( let i=0; i<data.length; i++ ) {
    let sheet:Sheet = []

    let ws_merges: {s:{r:number, c:number}, e:{r:number, c:number}}[] = []

    //Create page title
    if( sheetTitles!==null && sheetTitles[i] !==null ){
      const sheetTitle:Column[][] = [[{ v: sheetTitles[i], t: 's', s: STYLE_TITLE },],[]]
      sheet = sheet.concat(sheetTitle)
    }
  
    //Create column titles
    let header_titles: Column[] = []
    let header_titles2: Column[] = []
    let columsWithDividers: number[] = []
    if( sheetHeaders ===null ){
      fields[i].forEach((h) => {
        header_titles.push({ v: h, t: 's', s: STYLE_HEADING })
      })
    } else {
      const groupedHeaderFound = sheetHeaders[i].flatMap((h) => Object.keys(h)).includes('headers')
      let count = -1
      if(groupedHeaderFound){
        const current_row = sheet.length
        //Make major headers
        sheetHeaders[i].forEach((h) => {
          count += h.headers !== undefined && h.headers.length-1?h.headers.length:1

          header_titles = header_titles.concat(h.headers===undefined || h.headers.length==0?
            [{ v: '', t: 's', s: STYLE_HEADING }]: 
            [{ v: h.text, t: 's', s: STYLE_HEADING }].concat(Array(h.headers.length-1).fill({ v: '', t: 's', s: STYLE_HEADING })))
          header_titles2 = header_titles2.concat(h.headers===undefined || h.headers.length==0?
              [{ v: h.text, t: 's', s: STYLE_HEADING }]: 
              h.headers.map((h2)=>[{v: h2.text, t: 's', s: STYLE_HEADING}])).flat()
            
          if(h.headers !== undefined && h.headers.length){
            ws_merges.push({s:{r:current_row, c:header_titles.length-h.headers.length}, e:{r:current_row, c:header_titles.length-1}})
          }
          
          if(h.divider) {
            columsWithDividers.push(count)
            header_titles[header_titles.length-1].s = {...header_titles[header_titles.length-1].s, ...STYLE_BORDER_RIGHT_THIN}
            header_titles2[header_titles2.length-1].s = {...header_titles2[header_titles2.length-1].s, ...STYLE_BORDER_RIGHT_THIN}
          }
          })
        sheet.push(header_titles)
        sheet.push(header_titles2)
      }
      else{
        sheetHeaders[i].forEach((h) => {
          count +=1
          header_titles.push({ v: h.text, t: 's', s: STYLE_HEADING })
          if(h.divider) {
            columsWithDividers.push(count)
            header_titles[header_titles.length-1].s = {...header_titles[header_titles.length-1].s, ...STYLE_BORDER_RIGHT_THIN}
          }
        })
        sheet.push(header_titles)
      }
      
    }
    
    //Add data to the sheet
    const rows = convertObjectToExcelRow(data[i], fields[i])

    rows.forEach((r) => {
      columsWithDividers.forEach((cd)=>{
        r[cd].s = {...r[cd].s, ...STYLE_BORDER_RIGHT_THIN}
      })
      sheet.push(r)
    })

    //Create worksheet
    const ws1 = utils.aoa_to_sheet(sheet);
    ws1['!cols'] = [{width: 20}, {width: 50}];
    ws1['!merges']=ws_merges

    //append worksheet to workbook
    const page_name = (tabNames !== null)? adjustSheetName(tabNames[i]):`Sheet ${i+1}`
    utils.book_append_sheet(wb, ws1, page_name);
  }

  //Create output file
  const joinedString = ['BCDB', title].join('_');
  const title_no_spaces = joinedString.replace(/ /g, '_');
  const filename = title_no_spaces + '_' +format(new Date(),'yyMMddHHmmss') + '.xlsx';
  writeFile(wb, filename);
}

function adjustSheetName(title:string):string{
  //Worksheet can only be named < 31 character
  //Worksheet name cannont have these characters ['\\','/','?','*','[',']',':']
  let page_name = title.substring(0,title.length>=31?30:title.length)
  const invalid_strings = [ '\\','/','?','*','[',']',':']
  for (const s of invalid_strings) {
    page_name = page_name.replace(s, '')
  }
  return page_name
}

export function convertObjectToExcelRow(data, fields):Column[][]{
  let row: Column[][] = []
  data.forEach((o) => {
    let column: Column[] = []
    fields.forEach((f) => {
        const value = f.split('.').reduce((obj, key) => obj ? obj[key] : undefined, o);
        if( value === undefined || value === null) {
          column.push({ v:'' , t: 's', s: STYLE_CENTER })
        } else {
          column.push({ v:value , t: 's', s: STYLE_CENTER })
        }
    })
    row.push(column)
  })
  return(row)
}

export default buildObjectToExcel;