import axios from 'axios';
import { apiUrl } from '@/env';
import { ICalloutAlias } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getCalloutAliases(token: string) {
    return axios.get<ICalloutAlias[]>(`${apiUrl}/api/v1/callout_aliases/`, authHeaders(token));
  },
  async updateCalloutAlias(token: string, id: number, data: ICalloutAlias) {
    return axios.put<ICalloutAlias>(`${apiUrl}/api/v1/callout_aliases/${id}`, data, authHeaders(token));
  },
  async createCalloutAlias(token: string, data: ICalloutAlias) {
    return axios.post<ICalloutAlias>(`${apiUrl}/api/v1/callout_aliases/`, data, authHeaders(token));
  },
  async deleteCalloutAlias(token: string, id: number) {
    return axios.delete<ICalloutAlias>(`${apiUrl}/api/v1/callout_aliases/${id}`, authHeaders(token));
  },
};
