<template>
    <v-container fluid>
        <v-dialog v-model="viewDialog" scrollable max-width="1200" max-height="600">
        <v-card>
            <v-card-title> {{dialogTitle}} </v-card-title>
            <v-card-text>
            <v-row class="d-flex align-center">
                <v-col cols="1">Group by:</v-col>
                <v-col cols="2">
                    <v-checkbox v-model="tableGroupByExperimentName" :label="'Experiment'" @click="groupSelected()"></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox v-model="tableGroupByVendorCallout" :label="'Vendor Callout'" @click="groupSelected()"></v-checkbox>
                </v-col>
            </v-row>
            <v-data-table
                scrollable
                dense
                height="350"
                fixed-header
                :group-by="viewGroup"
                :headers="tableHeaders"
                :items="tableItems"
                :item-key="'id'"
                :sort-by="['experiment']"
                :sort-desc="true"
                class=""
                no-data-text="No Experiments"
                disable-pagination
                hide-default-footer
                hide-default-header
            >
                <!-- <template v-slot:no-data>
                    <div class="loading-indicator" v-if="loading">
                        Loading data, please wait...
                    </div>
                </template> -->

                <!-- Group by headers -->
                <template v-slot:group.header="{isOpen, toggle, group}">
                    <th colspan="1" class='align-left blue-grey lighten-3 sticky-headers'> 
                        <div @click="toggle">
                        <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                        </div>
                    </th>
                    <th :colspan="tableHeaders.length" class='align-center blue-grey lighten-3 sticky-headers'>
                        <div>{{ group }}</div>
                    </th>
                </template>

                <!-- Dyanamically Ajdust Main Headers -->
                 <!--TODO: This is temporary until we move to vue3. I believe vue3 can just take in headerOverride -->
                <template v-slot:header="{ props }">
                    <thead class="v-data-table-header">
                        <tr>
                            <template v-for="item in headerOverride">
                                <th rowspan="1" class="parent-header" v-if="item.headers=== undefined"></th>
                                <th :colspan="item.headers.length" class="text-center column-borders parent-header" v-else>{{ item.text }}</th>
                            </template>
                        </tr>
                        <tr>
                            <template v-for="item in tableHeaders">
                                <th :class="item.cellClass" @click="handleHeaderClick(props, item)">
                                    {{ item.text }}
                                    <!-- TODO can't figure out how to fix the sort by issue -->
                                    <!-- <v-icon x-small v-if="props.options.sortBy.includes(item.value)"
                                            class="sort-icon active">
                                        {{ props.options.sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                                    </v-icon> -->
                                </th>
                            </template>
                        </tr>
                    </thead>
                </template>

                <!-- Dynamically display result fields as v-chips -->
                <template v-for="(field) in detectionChipFields" v-slot:[`item.${field}`]="{ item }">
                    <span v-if="item[field] === 'DETECTED'"> 
                        <v-chip x-small outlined color="#C73E1D" class="ma-2">{{item[field]}}</v-chip> 
                    </span>
                    <span v-else-if="item[field] === 'NOT DETECTED'"> 
                        <v-chip x-small outlined color="#428959" class="ma-2">{{item[field]}}</v-chip> 
                    </span>
                </template>

                <template v-slot:item.experiment="{ item }">
                    <router-link
                        :title="`NAME: ${item.experiment}`"
                        v-if="item.id != null && typeof item.id === 'string'"
                        :to="{ name: 'biochip-run-view', params: { id: item.id.split('_')[0] }}"
                    >
                        {{item.experiment}}
                    </router-link>
                    <router-link
                        :title="`NAME: ${item.experiment}`"
                        v-else-if="item.id != null && typeof item.id === 'number'"
                        :to="{ name: 'biochip-run-view', params: { id: item.id }}"
                    >
                        {{item.experiment}}
                    </router-link>
                    <span v-else>{{ item.experiment }}</span>
                </template>

                <template v-slot:[`item.RunTracker.experiment`]="{ item }">
                    <router-link
                        :title="`NAME: ${item.RunTracker.experiment}`"
                        v-if="item.RunTracker.id != null && typeof item.RunTracker.id === 'string'"
                        :to="{ name: 'biochip-run-view', params: { id: item.RunTracker.id.split('_')[0] }}"
                    >
                        {{item.RunTracker.experiment}}
                    </router-link>
                    <router-link
                        :title="`NAME: ${item.RunTracker.experiment}`"
                        v-else-if="item.RunTracker.id != null && typeof item.RunTracker.id === 'number'"
                        :to="{ name: 'biochip-run-view', params: { id: item.RunTracker.id }}"
                    >
                        {{item.RunTracker.experiment}}
                    </router-link>
                    <span v-else>{{ item.RunTracker.experiment }}</span>
                </template>

                <template v-slot:item.failures="{item}">
                    <span v-if="item.failures === 'INVALID'"> 
                        <v-chip small outlined class="ma-2">{{item.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.failures === 'NO RESULT'"> 
                        <v-chip small outlined color="#F18F01" class="ma-2">{{item.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.failures === 'ERROR'"> 
                        <v-chip small outlined color="#C73E1D" class="ma-2"> {{item.failures}} </v-chip> 
                    </span>
                    <span v-else-if="item.failures === 'CIC FAIL'"> 
                        <v-chip small outlined color="#3B1F2B" class="ma-2"> {{item.failures}} </v-chip> 
                    </span>
                    <span v-else-if="item.failures !== null">
                        <v-chip small outlined color="#6A3821" class="ma-2"> {{item.failures}} </v-chip>
                    </span>
                </template>

                <template v-slot:[`item.RunTracker.failures`]="{item}">
                    <span v-if="item.RunTracker.failures === 'INVALID'"> 
                        <v-chip small outlined class="ma-2">{{item.RunTracker.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'NO RESULT'"> 
                        <v-chip small outlined color="#F18F01" class="ma-2">{{item.RunTracker.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'ERROR'"> 
                        <v-chip small outlined color="#C73E1D" class="ma-2"> {{item.RunTracker.failures}} </v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'CIC FAIL'"> 
                        <v-chip small outlined color="#3B1F2B" class="ma-2"> {{item.RunTracker.failures}} </v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures !== null">
                        <v-chip small outlined color="#6A3821" class="ma-2"> {{item.RunTracker.failures}} </v-chip>
                    </span>
                </template>

                <template v-slot:item.result_txt="{item}">
                    <span v-if="item.result_txt === 'DETECTED'"> 
                        <v-chip small outlined color="#C73E1D" class="ma-2">{{item.result_txt}}</v-chip> 
                    </span>
                    <span v-else-if="item.result_txt === 'NOT DETECTED'"> 
                        <v-chip small outlined color="#428959" class="ma-2">{{item.result_txt}}</v-chip> 
                    </span>
                </template>

                <template v-slot:item.vendor_result="{item}">
                    <span v-if="item.vendor_result === 'DETECTED'"> 
                        <v-chip small outlined color="#C73E1D" class="ma-2">{{item.vendor_result}}</v-chip> 
                    </span>
                    <span v-else-if="item.vendor_result === 'NOT DETECTED'"> 
                        <v-chip small outlined color="#428959" class="ma-2">{{item.vendor_result}}</v-chip> 
                    </span>
                </template>

                <template v-slot:item.panels="{item}">
                    <v-row>
                     <v-col v-for="(panel, index) in item.panels" :key=index>
                        <v-chip
                            v-if="panel!==null"
                            small
                            outlined
                        >{{ panel }}</v-chip>
                     </v-col>
                    </v-row>
                </template>

                <template v-slot:item.soc_test="{item}">
                    <span>{{ item.soc_test }}</span>
                </template>

                <template v-slot:item.soc_result="{item}">
                    <span>{{ item.soc_result }}</span>
                </template>

                <template v-slot:item.adf_version="{item}">
                    <span>{{ item.adf_version }}</span>
                </template>
          
            </v-data-table>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="downloadObject()"
                >
                    Download
                </v-btn>
                <v-btn
                    color="primary"
                    @click="viewDialog=false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IPpaNpa, IClinicalRuntracker } from '@/interfaces/bcdb';
  import { Socket } from 'vue-socket.io-extended';
  import eventBus from '@/eventBus';
  import buildObjectToExcel from '@/scripts/to-excel/object-to-excel';
  import { formatExpectedRunList, getMatrixTableHeaders } from '@/scripts/clinical-data-helpers'
  
  
  @Component
  export default class MappedComparatorResultsDialog extends Vue {
    @ModelSync('value', 'change', { type: Boolean }) viewDialog!: Boolean
    @Prop() public readonly viewType!: string
    @Prop() public readonly panel!: string
    @Prop() public readonly dataPpaNpaResults!: IPpaNpa[]
    @Prop() public readonly selectedExperiments!: IClinicalRuntracker[]
    //@Prop({ type: Boolean }) public loading!: boolean;

    //Consider a deselect of vendors for the composite table view
    public tableItems: any = []
    public viewGroup: string|null = null
    public tableGroupByExperimentName: boolean = true
    public tableGroupByVendorCallout: boolean = false
    public dialogTitle = ''
    public tableHeaders: object[] = []
    public headerOverride: any = []
    public detectionChipFields: string[] = []

    public handleHeaderClick(prop, item){
        prop.options.sortBy=[item.value]; 
        prop.options.sortDesc=!prop.options.sortDesc;
    }
    public async downloadObject() {
      if(this.tableHeaders.length) {
        const sheetFields = this.tableHeaders.map((h:any)=>h.value)
        const sheetHeaders = this.headerOverride
        const sheetTitle = this.dialogTitle
        buildObjectToExcel([this.tableItems], [sheetFields], this.dialogTitle, [sheetHeaders], [sheetTitle]);
      }
    }

    public viewPressed(viewType: string, panel:string=''){
        this.viewGroup = 'group'
        const titles = {
            tp:`Total True Positive Callouts: ${panel}`,
            tn:`Total True Negative Callouts: ${panel}`,
            fp:`Total False Positive Callouts: ${panel}`,
            fn:`Total False Negative Callouts: ${panel}`,
            undetermined:`Total Undetermined Callouts: ${panel}`
        }

        this.dialogTitle = titles.hasOwnProperty(viewType)? titles[viewType] : ''
        const response = formatExpectedRunList(this.dataPpaNpaResults, viewType, panel, 
        this.selectedExperiments, this.tableGroupByExperimentName, this.tableGroupByVendorCallout)
        this.tableItems = response.runList 
        const headerObj = getMatrixTableHeaders(response.createdFields)
        this.tableHeaders = headerObj.tableHeaders
        this.headerOverride = headerObj.headerOverride
        this.detectionChipFields = headerObj.detectionChipFields
        this.viewDialog = true
    }

    public groupSelected(){
      this.tableItems.forEach((t) => {
        if(t.hasOwnProperty('group')){
          t.group = `${this.tableGroupByExperimentName ? t.experiment : ''}`
          if(t.vendor_callout !== null){
            t.group += `${this.tableGroupByVendorCallout ? (t.group=='' ? t.external_callouts : ' | ' + t.external_callouts) : ''}`
          }
        }
      })
    }

    /*
    @Watch('loading')
    public async loadingUpdate(stillLoading: boolean) {
        if (!stillLoading) {
          this.viewPressed(this.viewType, this.panel);
        }
    }*/

    public mounted(){
        //this.loadingUpdate(this.loading);
        this.viewPressed(this.viewType, this.panel);
    }

  }
</script>
  
<style scoped>
.contrived-column {
  background-color: #aacaeb;
}
.clinical-column {
  background-color: #9cf0cd;
}
.combined-column {
  background-color: #ebaab9;
}
.loading-indicator {
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}
.column-borders {
    /* this matches the default v-data-table lines */
    border-right: thin solid rgba(0, 0, 0, .12);
    border-left: thin solid rgba(0, 0, 0, .12);
}

.sort-icon {
  margin-left: 5px;
  visibility: hidden;
}

.sort-icon.active {
  visibility: visible;
}

.sortable-header:hover .hover-icon {
  visibility: visible;
}

.sortable-header:hover .active {
  visibility: hidden;
}
</style>