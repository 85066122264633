<template>
    
    <!-- Stacked Snacks -->
    <!-- TODO: fix timeout. MUST use -1 currently. Enable for any time limit. Time limits currently take out all elements after them. -->
    <v-container fluid>

        <div v-for="(snack, idx) in snackObjs" :key="idx">
            <v-snackbar 
            v-model="snack.enable" 
            :timeout="snack.timeout"
            :color="snack.color"
            app
            :style="{'bottom': `${offset*idx}px`}"
            >
                {{ snack.message }}

                <template v-slot:action="{ attrs }">
                    <div
                    style="width: 76px"
                    class="text-center"
                    v-if="loading && (snack.message === 'Processing data...' || snack.button)"
                    >
                    <v-progress-circular
                        indeterminate
                        :width="2"
                        :size="25"
                    ></v-progress-circular>
                    </div>

                    <div v-else-if="!loading && snack.button || snack.infoButton">
                        <span v-if="snack.infoButton">
                            <v-btn small text @click="submit(idx, 'moreInfo')">{{"More info"}}</v-btn>
                            <v-btn small text @click="close(idx)">{{"Close"}}</v-btn>
                        </span>
                        
                        <span  v-else-if="snack.button">
                            <v-btn small text @click="submit(idx, 'submitted')" v-if="snack.buttonTxt !==null">{{snack.buttonTxt}}</v-btn>
                            <v-btn small text @click="submit(idx, 'submitted')" v-else>{{"Submit"}}</v-btn>
                        </span>
                    </div>

                    <v-btn small text @click="close(idx)" v-else>Close</v-btn>
                </template>

            </v-snackbar>
        </div>
    </v-container>
    
</template>

<script lang="ts">
import FormulaGenerator from '@/components/FormulaGenerator.vue';

import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { ISnackBars } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import { filter } from 'vue/types/umd';
import { Dictionary } from 'vue-router/types/router';
import draggable from 'vuedraggable'
import { toDateWithOptions } from 'date-fns-tz/fp';

@Component({
  components: {
  },
  })

export default class StackedSnacks extends Vue {
    @ModelSync('value', 'change') public snackObjs!: ISnackBars[];
    @Prop({ default: null }) public readonly loading!: boolean|null;
    enableSnack: boolean[] = []
    offset: number = 55; //px offset

    @Watch('snackObjs', {deep: true})
    public async updateSnacks(){
        for (let i = this.snackObjs.length - 1; i>=0; i--){
            if (!this.snackObjs[i].enable){
                this.snackObjs.splice(i,1);
            }
        }
    }

    public async close(idx){
        this.snackObjs.splice(idx,1);
    }

    public async submit(idx, emission){
        this.snackObjs.splice(idx,1);
        this.$emit(emission);
    }

    public mounted(){

    }
}

</script>

<style>
.title-font {
  font-size: medium;
}

.description-text {
  margin-top: 0px;
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}

.description-text, .v-row {
  line-height: 1em;
}

.v-banner{
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1em;
}

.v-text-field {
  font-size: small;
  max-width: 2000px;
}

.centeredRow {
  justify-content: center;
}
</style>