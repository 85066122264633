import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { 
  IInstrument
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {

    async getInstrumentFields(token: string, payload: {field: string, skip: number, limit: number, f: string, filters:IFilter[]}) {
        return axios.post<IAutocompleteItem[]>(`${apiUrl}/api/v1/instruments/field`,payload,authHeaders(token));
      },

}