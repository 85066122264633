<template>
  <!-- All PrintRuns -->
  <v-data-table
    :headers="headers"
    :items="printRuns"
    item-key="name"
    :dense="true"
    class="elevation-1"
    :server-items-length="printRunCount"
    v-on:update:options="updateOptions"
    v-model="selectedPrintRuns"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.name="{ item }">
      <router-link :title="`${item.name}\n${item.purpose}`" v-if="item.id != null" :to="{ name: 'biochip-print-run-view', params: { id: item.id }}">{{item.name}}</router-link>
      <span v-else>{{ item.name }}</span>
    </template>  

    <template v-slot:item.array_template_id="{ item }">
      <router-link v-if="item.array_template != null" :to="{ name: 'biochip-array-template-view', params: { id: item.array_template.id }}">{{item.array_template.name}}</router-link>
      <span v-else></span>
    </template>
    <template v-slot:item.run_date="{ value }">
      {{ value?.formatDate() }}
    </template>
    <template v-slot:item.created_on="{ value }">
      {{ value?.formatDateTime(true, "America/Los_Angeles") }}
    </template>
    <template v-slot:item.purpose="{ value }">
      <span>
        {{ value?.length > 13 ? value.substring(0,13)+'...' : value }}
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-on="on">
            <v-icon small v-if="value?.length > 13" class="material-icons-outlined">pending</v-icon>
          </span>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.id="{ item }">
        <v-btn icon :to="{name: 'biochip-print-run-edit', params: {id: item.id}}">
          <v-icon>edit</v-icon>
        </v-btn>
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { IFilter, ITableOptions } from '@/interfaces';
import { IArrayPrintRun } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class PrintRunList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedPrintRuns!: IArrayPrintRun[];

  public headers: object[] = [
        { text: 'Name', value: 'name', cellClass: 'col-name' },
        { text: 'Date', value: 'run_date', cellClass: 'col-date' },
        { text: 'Project', value: 'project' },
        { text: 'Substrate', value: 'substrate' },
        { text: 'Printed Targets', value: 'printed_target_count' },
        { text: 'Probes', value: 'probe_count' },
        { text: 'Filter', value: 'filter' },
        { text: 'Printed', value: 'printed', cellClass: 'col-printed' },
        { text: 'Purpose', value: 'purpose', cellClass: 'col-purpose' },
        { text: 'Status', value: 'status' },
        { text: 'Array Template', value: 'array_template_id' },
      ];

  @Prop() public readonly filters!: IFilter[];

  public printRuns: IArrayPrintRun[] = [];
  public printRunCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'run_date',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updatePrintRunView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getPrintRunCount() {
    try {
      const response = await api.getArrayPrintRunsCount(readToken(this.$store), this.filters || []);
      if (response) {
        this.printRunCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getPrintRuns() {
    try {
      const response = await api.searchArrayPrintRuns(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.printRuns = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async updatePrintRunView() {
    this.getPrintRunCount();
    this.getPrintRuns();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'run_date';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    // emit an event?
    this.updatePrintRunView();
  }

  public mounted() {
    if( readHasAdminAccess(this.$store) ) {
      this.headers.push(
        { text: 'Actions', value: 'id' },
      );
    }

    this.updatePrintRunView();
  }

  @Socket('new-printrun')
  public onNewPrintRun(printRunInfo) {
    console.log(JSON.parse(printRunInfo));
    this.updatePrintRunView();
  }

}
</script>

<style scoped>
.v-data-table >>> .col-name {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 8em;
}
.v-data-table >>> .col-purpose {
  min-width: 10em;
}
.v-data-table >>> .col-printed {
  min-width: 8em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-analysis-qc {
  min-width: 5em;
}

</style>
