var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.arrayTemplates,"item-key":"name","item-class":_vm.rowBackground,"dense":true,"server-items-length":_vm.arrayTemplateCount,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [(item.id != null)?_c('router-link',{attrs:{"title":`${item.name}`,"to":{ name: 'biochip-array-template-view', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.template_date",fn:function({ value }){return [_vm._v(" "+_vm._s(value?.formatDate())+" ")]}},{key:"item.created_on",fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true, "America/Los_Angeles"))+" ")]}},{key:"item.id",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clone(item.id)}}},[_c('v-icon',{attrs:{"title":"Clone"}},[_vm._v("mdi-content-duplicate")])],1)]}}]),model:{value:(_vm.selectedArrayTemplates),callback:function ($$v) {_vm.selectedArrayTemplates=$$v},expression:"selectedArrayTemplates"}})
}
var staticRenderFns = []

export { render, staticRenderFns }