<template>
  <v-container fluid>

    <v-card v-if="test"  class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{test.sample_id}}</div>
      </v-card-title>
      <v-card-text>
        <p>{{test.notes}}</p>

        <v-data-table
          hide-default-footer
          :headers="analyteHeaders"
          :items="analytes"
          :items-per-page="-1"
          class="elevation-1"
        ></v-data-table>

        <v-tabs vertical>
          <v-tab
          >
            Pressure
          </v-tab>
          <v-tab
          >
            Temperature
          </v-tab>
          <v-tab
          >
            Optical
          </v-tab>

          <v-tab-item eager>
            <v-card flat>
              <v-card-text>
                <div id="pressureGraph"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item eager>
            <v-card flat>
              <v-card-text>
                <div id="temperatureGraph"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item eager>
            <v-card flat>
              <v-card-text>
                <div id="opticalGraph"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        
        
        
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { ITestResult, IFile, ITrace, ITest, IAnalyteValue } from '@/interfaces';
import { dispatchGetSystems, dispatchUpdateUser } from '@/store/admin/actions';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readAdminOneSystem } from '@/store/admin/getters';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component
export default class ViewTest extends Vue {
  //@PropSync("assay", {type: Object}) public syncedAssay!: IAssay;
  public testResult: ITestResult = {} as ITestResult;
  public test: ITest = {} as ITest;

  public pressureData: ITrace[] = [];
  public opticalData: ITrace[] = [];
  public temperatureData: ITrace[] = [];

  public analyteValues: IAnalyteValue[] = [];

  public analytes: object[] = [];


  public analyteHeaders = [
    { text: 'Name', value: 'name' },
    { text: 'CT', value: 'ct' },
    { text: 'Endpoint', value: 'endpoint' },
    { text: 'PC1', value: 'pc1' },
    { text: 'PC2', value: 'pc2' },
    { text: 'PC3', value: 'pc3' },
  ];
  /*@ModelSync('value', 'change', { type: Object })
  public readonly assay!: IAssay;*/
  private pressureLayout = {
      title: 'Pressure',
      width: 1000,
      xaxis: {
          title: 'Time (s)',
          showgrid: false,
          zeroline: false,
      },
      yaxis: {
          title: 'PSI',
          showline: false,
          showgrid: false,
          zeroline: false,
      },
  };

  private opticalLayout = {
      title: 'Optical',
      width: 1000,
      xaxis: {
          title: 'Cycle',
          showgrid: false,
          zeroline: false,
      },
      yaxis: {
          title: 'DFU',
          showline: false,
          showgrid: false,
          zeroline: false,
      },
  };


  private temperatureLayout = {
      title: 'Temperature',
      autosize: true,
      width: 1000,
      xaxis: {
          title: 'Time (s)',
          showgrid: false,
          zeroline: false,
      },
      yaxis: {
          title: 'C',
          showline: false,
          showgrid: false,
          zeroline: false,
      },
  };

  public async relayout() {
    Plotly.relayout('pressureGraph', {});
    Plotly.relayout('opticalGraph', {});
    Plotly.relayout('temperatureGraph', {});
  }


  public async loadTest() {
    if (this.testResult !== undefined) {
      const response = await api.getTest(readToken(this.$store), this.testResult.test_id || -1);
      if (response) {
        console.log('Loaded test');
        this.test = response.data;
        this.loadTraces();
        this.loadAnalyteValues();
      }
    }
  }

  public async loadTestResult() {
    const response = await api.getTestResult(readToken(this.$store), +this.$router.currentRoute.params.id);
    if (response) {
      console.log('Loaded test result');
      this.testResult = response.data;
      this.loadTest();
    }
  }

  public async loadPressure() {
    const response = await api.getTraces(readToken(this.$store), [this.test.id], 'pressure');
    if (response) {
      this.pressureData = response.data;
      Plotly.newPlot('pressureGraph', this.pressureData, this.pressureLayout);
    }
  }

  public async loadOptical() {
    const response = await api.getTraces(readToken(this.$store), [this.test.id], 'optical');
    if (response) {
      this.opticalData = response.data;
      Plotly.newPlot('opticalGraph', this.opticalData, this.opticalLayout);
    }
  }

  public async loadTemperature() {
    const response = await api.getTraces(readToken(this.$store), [this.test.id], 'temperature');
    if (response) {
      this.temperatureData = response.data;
      Plotly.newPlot('temperatureGraph', this.temperatureData, this.temperatureLayout);
    }
  }

/* tslint:disable:no-string-literal */
  public async loadAnalyteValues() {
    const response = await api.getTestValues(readToken(this.$store), this.test.id);
    if (response) {
      this.analyteValues = response.data;
      const analytesByName = {};
      for (const an of this.analyteValues) {
        if (!analytesByName.hasOwnProperty(an.name)) {
          analytesByName[an.name] = {};
        }
        analytesByName[an.name][an.reading] = an.value;
      }
      Object.keys(analytesByName).forEach((name) => {
        this.analytes.push({
          name,
          ct: analytesByName[name]['ct'],
          endpoint: analytesByName[name]['endpoint'],
          pc1: analytesByName[name]['pc1'],
          pc2: analytesByName[name]['pc2'],
          pc3: analytesByName[name]['pc3'],
        });
      });
    }
  }
/* tslint:enable:no-string-literal */

  public async loadTraces() {
    this.loadPressure();
    this.loadOptical();
    this.loadTemperature();
  }

  public async mounted() {
    this.loadTestResult();
  }

  public cancel() {
    this.$router.back();
  }


}



/*
    name: string;
    guid: string;
    test_type: string;
    lock_comments: string;
    is_clia_waived: boolean;
    creation_time: string;
    modification_time: string;
    analysis_type?: number;
    assay_class?: number;
    assay_type: number;
    assay_version: number;
    company_code: number;
    product_code: number;
    assay_index?: number;
    command_sequence_format: number;
    data_reduction_algorithm: number;
    comments: string;
    ext_control_frq?: number;
    need_ext_control?: boolean;
    need_lsp: boolean;
    air_chamber?: number;
    overflow_chamber?: number;
    // picture: string;
    prep_cartridge_shelflife: number;
    use_background_subtraction: boolean;
    use_melt_all_peaks: boolean;
    cartridge_id: number;

    analytes: IAnalyte[];

    has_melt_analytes: boolean;

export interface IAnalyte {
    assay_id: number;
    name: string;
    reporter_name: string;
    assay_index: number;
    analyte_index: number;
    is_pcr: boolean;
    is_control: boolean;
    is_melt_target: boolean;
    is_probe: boolean;
}
*/


</script>


  