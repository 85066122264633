<template>
  <!-- All Profiles -->
  <v-data-table
    :headers="headers()"
    :items="profiles"
    item-key="Analysis Key"
    :dense="true"
    class="elevation-1"
    v-model="selectedProfiles"
    disable-pagination
    hide-default-footer
  > 
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Profile Request
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.analysis_key"
                      label="* Analysis Key"
                      v-validate="'required|regex:^([-a-zA-Z0-9]+)$|max:8|uniqueAnalysisKey'"
                      data-vv-name="Analysis Key"
                      :error-messages="errors.collect('Analysis Key')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete label="* Imaging Profile"
                      v-model="editedItem.imaging_profile"
                      :items="imaging"
                      data-vv-name="Imaging Profile"
                      :error-messages="errors.collect('Imaging Profile')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="* Annealing Temperature"
                      v-model="editedItem.annealing_temp"
                      v-validate.disable="'required|decimal'"
                      data-vv-name="* Annealing Temperature"
                      :error-messages="errors.collect('Annealing Temperature')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="* Hybridization Temperature"
                      v-model="editedItem.hybridization_temp"
                      v-validate.disable="'required|decimal'"
                      data-vv-name="Hybridization Temperature"
                      :error-messages="errors.collect('Hybridization Temperature')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.denaturation_temp"
                      label="* Denaturation Temperature"
                      v-validate.disable="'required|decimal'"
                      data-vv-name="Denaturation Temperature"
                      :error-messages="errors.collect('Denaturation Temperature')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.isx_file_name"
                      label="ISX File"
                      :placeholder="'entered by DSA Team'"
                      v-show="hasAdminAccess"
                      data-vv-name="ISX File"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="submit">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.ttr="{ value }">
      <v-icon v-if="value" small>mdi-check</v-icon>
      <v-icon v-else small>mdi-close</v-icon>
    </template>
    <template v-slot:item.created_on="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small @click="editItem(item)" class="mr-2" v-show="hasAdminAccess">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)" v-show="hasAdminAccess">mdi-delete</v-icon>
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { computed } from 'vue'
import { Store } from 'vuex';
import { Validator } from 'vee-validate';
import { readToken, readHasAdminAccess} from '@/store/main/getters';
import { IArrayTemplateProfile } from '@/interfaces/bcdb';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';


@Component
export default class ArrayTemplateProfileList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly profiles!: IArrayTemplateProfile[];
  @Prop() public arrayTemplateId!: number;

  public allProfiles: IArrayTemplateProfile[] = [];

  public selectedProfiles: IArrayTemplateProfile[] = [];
  public dialog = false;
  public dialogDelete = false;
  public newProfile = 0;
  public editedIndex = -1;
  public imaging: string[] = [];
  public editedItem: IArrayTemplateProfile = {
    id: 0,
    array_template_id: 0,
    annealing_temp: 0,
    hybridization_temp: 0,
    denaturation_temp: 0,
    analysis_key: '',
    imaging_profile: '',
    isx_file_name: '',
    parent_id: null,
  };
  public defaultItem: IArrayTemplateProfile = {
    id: 0,
    array_template_id: 0,
    annealing_temp: 0,
    hybridization_temp: 0,
    denaturation_temp: 0,
    analysis_key: '',
    imaging_profile: '',
    isx_file_name: '',
    parent_id: null,
  };

  public tableHeaders: object[] = [
    { text: 'Analysis Key', value: 'analysis_key', align: 'center', sortable: false },
    { text: 'Imaging Profile', value: 'imaging_profile', align: 'center' },
    { text: 'Annealing Temperature', value: 'annealing_temp', align: 'center', sortable: false },
    { text: 'Hybridization Temperature', value: 'hybridization_temp', align: 'center', sortable: false },
    { text: 'Denaturation Temperature', value: 'denaturation_temp', align: 'center', sortable: false },
    { text: 'ISX File', value: 'isx_file_name', align: 'center', sortable: false },
    { text: 'Created On', value: 'created_on', align: 'center', sortable: true },
  ];

  public headers() {
    if (this.hasAdminAccess) {
      return [...this.tableHeaders, { text: 'Actions', value: 'actions', cellClass: 'col-actions', sortable: false }];
    } else {
      return this.tableHeaders;
    }
  }
  

  public formTitle = computed(() => {
    return( this.editedIndex === -1 ? 'New Profile' : 'Edit Profile' );
  })

  public mounted() {
    this.reset();
    Validator.extend('uniqueAnalysisKey', {
      getMessage: (field) => 'The ' + field + ' field is not unique.',
      validate: (value) => !this.allProfiles.find((p) => 
        p.analysis_key.toLowerCase() === value.toLowerCase() && this.editedItem.id != p.id),
    });
}

  public reset() {
    this.$validator.reset();
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
    api.getArrayTemplateProfiles(readToken(this.$store)).then((response) => {
      this.allProfiles = response.data;
      const imgset = new Set<string>();
      for (const row of this.allProfiles) {
        imgset.add(row.imaging_profile);
      };
      this.imaging = Array.from(imgset);
      console.log("this.imaging", this.imaging);
    })
  }

  public editItem(item) {
    this.editedIndex = this.profiles.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  }

  public deleteItem(item) {
    this.editedIndex = this.profiles.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
  }

  public deleteItemConfirm() {
    api.deleteArrayTemplateProfile(readToken(this.$store), this.editedItem.id!).then((response) => {
      this.profiles.splice(this.editedIndex, 1)
      this.closeDelete()
    });
  }

  public close() {
    this.dialog = false
    this.reset();
  }

  public closeDelete() {
    this.dialogDelete = false
    this.reset();
  }

  public async submit() {
    await this.save();
    if (this.newProfile > 0) {
      // TODO: Call API to submit
      await api.submitArrayTemplateProfile(readToken(this.$store), this.newProfile)
        .then((response) => {
          console.log('submit response =', response.data); 
      });
    }  
  }

  public async save() {
    console.log("save");
    if (await this.$validator.validateAll()) {
      if( this.editedIndex > -1 ) {
        // update
        const index = this.editedIndex;
        const response = await api.updateArrayTemplateProfile(readToken(this.$store), this.editedItem.id!, this.editedItem)
          .then((response) => {
            Object.assign(this.profiles[index], response.data)
            this.close()
          });
      } else {
        // create
        this.editedItem.array_template_id = this.arrayTemplateId;
        const response = await api.createArrayTemplateProfile(readToken(this.$store), this.editedItem)
          .then((response) => {
            this.profiles.push(response.data)
            this.newProfile = response.data.id;
            this.close()
          });
      }
    }
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  @Watch('dialog')
  public async onDialog(val) {
    val || this.close()
  }

  @Watch('dialogDelete')
  public async onDialogDelete(val) {
    val || this.closeDelete()
  }

}
</script>

<style scoped>
.v-data-table >>> .col-actions {
  min-width: 58px;
}
</style>
