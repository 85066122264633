<template>
  <div>
    <v-toolbar light>
      <v-toolbar-title>
        Manage Systems
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table 
      :headers="headers"
      :items="systems"
      :single-select="true"
      :show-select="true"
      v-on:item-selected="selectSystem"
      v-model="selectedSystems"
      >
      <template v-slot:item.system_name="{ item }">
        <router-link :to="{ name: 'main-admin-system-view', params: { id: item.id }}">{{item.system_name}}</router-link>
      </template>
      <template v-slot:item.enabled="{ item }">
        <v-checkbox v-model="item.enabled" v-on:change="enableToggle(item, $event)"></v-checkbox>
      </template>
      <template v-slot:item.last_seen="{ item }">
        {{ humanTime(item.last_seen) }}
      </template>
    </v-data-table>

    <RightPanel :showPanel="selectedSystems.length">
      <template v-slot:header>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{selectedSystems[0].system_name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="selectedSystems.length" v-on:click="clearSelectedSystems">Clear</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

    </RightPanel>
  </div>
</template>

<script lang="ts">
import RightPanel from '@/components/RightPanel.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { ISystem, IFile } from '@/interfaces';
import { readAdminSystems } from '@/store/admin/getters';
import { dispatchGetSystems, dispatchUpdateSystem } from '@/store/admin/actions';

import { formatDistanceToNow, parseJSON } from 'date-fns';

import { utcToZonedTime } from 'date-fns-tz';

@Component({
  components: {
    RightPanel,
  },
})
export default class AdminSystems extends Vue {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'system_name',
      align: 'left',
    },
    {
      text: 'MAC Address',
      sortable: true,
      value: 'system_mac',
      align: 'left',
    },
    {
      text: 'Enabled',
      sortable: true,
      value: 'enabled',
      align: 'left',
    },
    {
      text: 'Last Seen',
      value: 'last_seen',
    },
  ];

  public logs: IFile[] = [];

  public selectedSystems: ISystem[] = [];


  get systems() {
    return readAdminSystems(this.$store);
  }

  public clearSelectedSystems() {
    this.selectedSystems = [];
  }

  public humanTime(t) {
    // console.log(t);
    if (t !== null) {
      const date = parseJSON(t);
      // console.log(date);
      const tz = 'UTC'; // "America/Los_Angeles";
      return formatDistanceToNow(parseJSON(t), {addSuffix: true});
    } else {
      return 'Never';
    }
  }

  public async mounted() {
    await dispatchGetSystems(this.$store);
  }

  public selectSystem(e) {
    // console.log(e.item);
    // console.log(e.value);
  }

  public enableToggle(item: ISystem, event) {
    // console.log(item);
    // console.log(event);
    dispatchUpdateSystem(this.$store, {id: item.id, system: {...item, enabled: event}});
  }
}
</script>
