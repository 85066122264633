<template>
  <div id="app">
    <v-app>
      <v-main v-if="loggedIn===null">
        <v-container fill-height>
          <v-layout align-center justify-center>
            <v-flex>
              <div class="text-xs-center">
                <div class="headline my-5">Loading...</div>
                <v-progress-circular size="100" indeterminate color="primary"></v-progress-circular>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
      <router-view v-else />
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';
import { readIsLoggedIn } from '@/store/main/getters';
import { dispatchCheckLoggedIn, dispatchGetAppInfo } from '@/store/main/actions';

// import { createClient, client } from '@hey-api/client-fetch';


// import { apiUrl } from '@/env';
// import { readToken } from '@/store/main/getters';
// console.log(apiUrl)
// import { createClient, client } from '@hey-api/client-fetch';

// createClient({
//   baseUrl: apiUrl,
// });



@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    
    await dispatchCheckLoggedIn(this.$store);
    

    // createClient({
    //   baseUrl: apiUrl,
    //   headers: {
    //     Authorization: `Bearer ${readToken(this.$store)}`,
    //   },
    // });
    // client.interceptors.request.use((request, options) => {
    //   request.headers.set('Authorization', `Bearer ${readToken(this.$store)}`);
    //   return request;
    // });
    
    // console.log(apiUrl);
  }
}
</script>
