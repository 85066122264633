import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IRuleSet, IProbeRule, IPanelRule, ICalloutRule, IRuleSetCreate,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getRuleSet(token: string, id: number) {
    return axios.get<IRuleSet>(`${apiUrl}/api/v1/rule_sets/${id}`, authHeaders(token));
  },
  async getRuleSetProbeRules(token: string, ruleSetId: number) {
    return axios.get<IProbeRule[]>(
      `${apiUrl}/api/v1/rule_sets/${ruleSetId}/probe_rules`,
      authHeaders(token));
  },
  async getRuleSetPanelRules(token: string, ruleSetId: number) {
    return axios.get<IPanelRule[]>(
      `${apiUrl}/api/v1/rule_sets/${ruleSetId}/panel_rules`,
      authHeaders(token));
  },
  async getRuleSetCalloutRules(token: string, ruleSetId: number) {
    return axios.get<ICalloutRule[]>(
      `${apiUrl}/api/v1/rule_sets/${ruleSetId}/callout_rules`,
      authHeaders(token));
  },
  async updateRuleSet(token: string, ruleSetId: number, data: IRuleSet) {
    return axios.put(`${apiUrl}/api/v1/rule_sets/${ruleSetId}`, data, authHeaders(token));
  },
  async createRuleSet(token: string, data: IRuleSetCreate) {
    return axios.post(`${apiUrl}/api/v1/rule_sets/`, data, authHeaders(token));
  },
  async deleteRuleSet(token: string, ruleSetId: number) {
    return axios.delete(`${apiUrl}/api/v1/rule_sets/${ruleSetId}`, authHeaders(token));
  },
  async cloneRuleSet(token: string, ruleSetId: number, data: IRuleSetCreate) {
    return axios.post(`${apiUrl}/api/v1/rule_sets/${ruleSetId}/clone`, data, authHeaders(token));
  },
};
