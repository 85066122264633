<template>
  <!-- All Runs -->
  <v-data-table
    :headers="headers"
    :items="runs"
    item-key="experiment"
    :dense="true"
    class="elevation-1"
    :server-items-length="runCount"
    show-select
    v-on:update:options="updateOptions"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    v-model="selectedRuns"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.experiment= "{ item }">
      <router-link
        :title="runTitle(item)"
        v-if="item.id != null"
        :to="{ name: 'biochip-run-view', params: { id: item.id }}"
      >
        {{item.experiment}}
      </router-link>
      <span v-else>{{ item.experiment }}</span>
    </template>  

    <template v-slot:[`item.date_added`]="{ value }">
      {{ value.formatDate(true) }}
    </template>
    <template v-slot:[`item.date_added`]="{ value }">
      {{ value.formatDateTime(true) }}
    </template>

    <!-- <template v-slot:item.print_run_id="{ value }">
      <router-link
        v-if="value != null"
        :title="printRunTitle(value)"
        :to="{ name: 'biochip-print-run-view', params: { id: value }}"
      >
        {{ printRunName(value) }}
      </router-link>
      <span v-else></span>
    </template> -->

    <template v-slot:[`item.array_template.name`]="{ value, item }">
      <router-link
        v-if="value != null"
        :title="`${value}`"
        :to="{ name: 'biochip-array-template-view', params: { id: item.array_template.id }}"
      >
        {{ value }}
      </router-link>
      <span v-else>{{value}}</span>
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, IFilter, ITableOptions } from '@/interfaces';
import { IRunTracker, IRunTrackerExtended } from '@/interfaces/bcdb';
import { IArrayPrintRun, IArrayTemplate } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class RunList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedRuns!: IRunTrackerExtended[];

  public headers: object[] = [
        { text: 'Date Added', value: 'date_added', cellClass: 'col-date' },
        { text: 'Experiment', value: 'experiment', cellClass: 'col-experiment' },
        { text: 'Type', value: 'experiment_type' },
        // { text: 'Print Run', value: 'array_template.name', sortable: false },
        { text: 'Array Template', value: 'array_template.name', sortable: false },
        { text: 'Wafer ID', value: 'identifier', sortable: false },
        { text: 'Wafer Lot Code', value: 'lot_code', sortable: false },
        { text: 'Die Number', value: 'die_number', sortable: false},
        // { text: 'Analysis QC', value: 'analysis_qc' },
        { text: 'Instrument', value: 'instrument.rig', sortable: false },
        { text: 'Date of Run', value: 'date_of_run', cellClass: 'col-date' },
        { text: 'Run Description', value: 'run_description', sortable: false },
      ];

  @Prop() public readonly filters!: IFilter[];

  public runs: IRunTrackerExtended[] = [];
  public runCount: number = 0;
  public printRuns: IArrayPrintRun[] = [];
  public arrayTemplates: IArrayTemplate[] = [];

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'date_added',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateRunView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getRunCount() {
    try {
      const response = await api.getRunsCount(readToken(this.$store), this.filters || []); // TODO: un-hack this fix
      if (response) {
        this.runCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async searchRuns() {
    try {
      const response = await api.searchRuns(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.runs = response.data
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public runTitle(run) {
    let title = 'NAME: '+run.experiment;
    title += run.run_description ? '\nDESC: '+run.run_description : '';
    title += run.filter_type ? '\nFILTER: '+run.filter_type : '';
    title += run.chip_id ? '\nCHIP ID: '+run.chip_id : '';
    title += run.rig ? '\nRIG: '+run.rig : '';
    title += run.cap ? '\nCAP: '+run.cap : '';
    title += run.profile ? '\nPROFILE: '+run.profile : '';
    title += run.cooling_type ? '\nCOOLING TYPE: '+run.cooling_type : '';
    title += run.instrument_id ? '\nINSTRUMENT: '+run.instrument_id : '';
    title += run.comments ? '\nCOMMENTS: '+run.comments : '';
    return(title);
  }

  public printRunName(printRunId) {
    const printRun = this.printRuns.find((pr) => pr.id === printRunId);
    if( printRun ) {
      const matches = printRun.name.match(/Run([^_]+)_/);
      if( matches ) {
        return(matches[1]);
      }
    }
    return('');
  }

  public printRunTitle(printRunId) {
    const printRun = this.printRuns.find((pr) => pr.id === printRunId);
    return( printRun ? printRun.name : '' );
  }

  public async updateRunView() {
    this.getRunCount();
    this.searchRuns();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'date_added';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    this.updateRunView();
  }

  public mounted() {
    this.updateRunView();
  }

  @Socket('new-run')
  public onNewRun(runInfo) {
    console.log(JSON.parse(runInfo));
    this.updateRunView();
  }

}
</script>

<style scoped>
.v-data-table >>> .col-experiment {
  min-width: 10em;
}
.v-data-table >>> .col-date {
  min-width: 8em;
}
.v-data-table >>> .col-filter-type {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-analysis-qc {
  min-width: 5em;
}

</style>
