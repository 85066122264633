import { writeFile, utils } from 'xlsx-js-style';
import { add, format } from 'date-fns'
import { IPpaNpa, IClinicalRuntracker } from '@/interfaces/bcdb';
import { STYLE_HEADING, STYLE_TITLE, STYLE_CENTER } from '@/scripts/to-excel/excel-styles'
import buildObjectToExcel, { convertObjectToExcelRow } from '@/scripts/to-excel/object-to-excel'
import { formatExpectedRunList, getGroupCounts, getMatrixTableHeaders } from '@/scripts/clinical-data-helpers'
import vendor_callouts from '@/api/vendor_callouts';

function buildPpaNpaExcel(ppaNpa:IPpaNpa[], experiments:IClinicalRuntracker[]) {
  const title = 'PpaNpa'
  let headers: any[][] = []
  let fields:string[][] = []
  let sheetNames:string[] = []
  let data: object[][] = []

  //Get group counts into an object format
  const groupCountHeaders = [{text:'Group'},{text:'Vendor'},{text:'TP'}, {text:'TN'}, {text:'FP'}, {text:'FN'}, {text:'PPA'}, {text:'NPA'}]
  const groupCountFields = ['group','vendor','tp', 'tn', 'fp', 'fn', 'ppa', 'npa']
  const groupCountSheetName = 'Counts By Group'
  const groupCounts = getGroupCounts(ppaNpa)
  let groupCountsObj: object[] = []
  for (const key in groupCounts) {
    for (let i=0; i<groupCounts[key].group.length; i++){
      groupCountsObj.push({
        vendor:key, group:groupCounts[key].group[i], 
        fn:groupCounts[key].fn[i], fp:groupCounts[key].fp[i], tp:groupCounts[key].tp[i], tn:groupCounts[key].tn[i],
        ppa:Math.round(groupCounts[key].ppa[i]*10)/10, npa:Math.round(groupCounts[key].npa[i]*10)/10
      })
    }
  }
  headers.push(groupCountHeaders)
  fields.push(groupCountFields)
  sheetNames.push(groupCountSheetName)
  data.push(groupCountsObj)

  //Format confusion matrix objects
  const confusionMatrixSheetNames:string[] = ['tp','tn','fp','fn','undetermined']
  confusionMatrixSheetNames.forEach((f) => {
    const mergedResults = formatExpectedRunList(ppaNpa, f, null, experiments, false, false)
    const tableHeaders = getMatrixTableHeaders(mergedResults.createdFields)
    headers.push(tableHeaders.headerOverride)
    fields.push(tableHeaders.tableHeaders.map((h)=>h.value))
    sheetNames.push(f)
    data.push(mergedResults.runList)
  })

  const experimentHeaders= [
    { text: 'Date of Run', value: 'RunTracker.date_of_run', cellClass: 'col-date', align: 'center' },
    { text: 'Experiment', value: 'RunTracker.experiment', cellClass: 'col-experiment', align: 'left' },
    { text: 'Run', value: 'RunTracker.run', cellClass: 'col-run', align: 'center' },
    { text: 'Site', value: 'site_name', cellClass: 'col-panels', align: 'center' },
    { text: 'Panels', value: 'panels', cellClass: 'col-panels', align: 'center' },
    { text: 'Type', value: 'RunTracker.experiment_type', cellClass: 'col-experiment_type', align: 'center' },
    { text: 'Instrument', value: 'rig', cellClass: 'col-rig', align: 'center' },
    { text: 'Failure', value: 'RunTracker.failures', cellClass: 'col-failures', align: 'center' },
    { text: 'ADF Version', value: 'adf_version', cellClass: 'col-rig', align: 'center' },
    ]
  headers.push(experimentHeaders)
  fields.push(experimentHeaders.map((h)=>h.value))
  sheetNames.push('Experiments')
  data.push(experiments)

  buildObjectToExcel(data, fields, title, headers, null, sheetNames)
}


export default buildPpaNpaExcel;