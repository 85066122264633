<template>
  <!-- All Tests -->
  <v-data-table
    :headers="headers"
    :items="tests"
    item-key="test_hash"
    :dense="true"
    class="elevation-1"
    :server-items-length="testCount"
    show-select
    v-on:update:options="updateOptions"
    v-model="selectedTests"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.assay_name="{ item }">
      <router-link :title="`GUID:${item.assay_guid}\nSHA256:${item.assay_hash}`" v-if="item.assay_id != null" :to="{ name: 'main-assay-view', params: { id: item.assay_id }}">{{item.assay_name}}</router-link>
      <span v-else>{{ item.assay_name }}</span>
    </template>  

    <template v-slot:item.sample_id="{ item }">
      <router-link :title="`GUID:${item.test_guid}\nSHA256:${item.test_hash}`" v-if="item.test_id != null" :to="{ name: 'main-test-view', params: { id: item.id }}">{{item.sample_id}}</router-link>
      <span v-else>{{ item.sample_id }}</span>
    </template>  


    <template v-slot:item.start_time="{ value }">
      {{ value.formatDateTime() }}
    </template>
    <template v-slot:item.created_at="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

    <template v-slot:item.result="{ value }">
      <span v-if="value.indexOf('|') == -1">{{ value }}</span>
      <v-menu v-if="value.indexOf('|') >= 0"
        open-on-hover
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-on="on">{{ splitResult(value)[0] }}...</v-chip>
        </template>

        <v-list>
          <v-list-item v-for="item in splitResult(value)" :key="item">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>


    <template v-slot:item.parse="{ item }">
      <v-icon color="green" v-if="item.assay_id && item.test_id">mdi-check-circle</v-icon>
      <v-icon v-if="!item.assay_id || !item.test_id">mdi-refresh</v-icon>
    </template>
    <template v-slot:item.assay_file="{ item }">
      <v-icon v-if="item.assay_id">mdi-download</v-icon>
    </template>
    <template v-slot:item.test_file="{ item }">
      <v-icon v-if="item.test_id">mdi-download</v-icon>
    </template>

    <!--<template v-slot:footer.prepend>
      <v-btn v-if="testSummary" v-on:click="removeSummary">Close Summary</v-btn>
      <v-btn v-if="showGraph" v-on:click="hideGraph">Close Graph</v-btn>
    </template>-->
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { ITestResult, IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter, ITableOptions } from '@/interfaces';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class TestList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedTests!: ITestResult[];

  public headers: object[] = [
        { text: 'Sample', value: 'sample_id', cellClass: 'col-sample-id' },
        { text: 'System Name', value: 'system_name' },
        { text: 'User', value: 'gx_username', cellClass: 'col-name' },
        { text: 'Status', value: 'status', cellClass: 'col-status' },
        { text: 'Result', value: 'result', cellClass: 'col-result' },
        { text: 'Start', value: 'start_time', cellClass: 'col-date' },
        { text: 'Assay Name', value: 'assay_name', cellClass: 'col-name' },
        { text: 'Assay Version', value: 'assay_version' },
        { text: 'Software Version', value: 'system_sw', cellClass: 'col-system' },
        { text: 'Upload Date', value: 'created_at', cellClass: 'col-date' },
        { text: 'Instrument', value: 'instrument_serial' },
        { text: 'Module', value: 'module_serial' },
        { text: 'Cart', value: 'cart_serial' },
        { text: 'Reagent Lot', value: 'reagent_lot' },
      ];

  @Prop() public readonly filters!: IFilter[];

  public tests: ITestResult[] = [];
  public testCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'created_at',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateTestView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public async getTestCount() {
    try {
      const response = await api.getTestsCount(readToken(this.$store), this.filters || []); // TODO: un-hack this fix
      if (response) {
        this.testCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getTests() {
    try {
      const response = await api.getTests(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.tests = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async updateTestView() {
    this.getTestCount();
    this.getTests();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'created_at';
    this.options.sortDesc = options.sortDesc[0];
    // emit an event?
    this.updateTestView();
  }

  public mounted() {
    if (readHasAdminAccess(this.$store)) {
      this.headers.push(
        { text: 'Parse', value: 'parse' },
        { text: 'Assay', value: 'assay_file' },
        { text: 'Test', value: 'test_file' },
      );
    }

    this.updateTestView();
  }

  @Socket('new-test')
  public onNewTest(testInfo) {
    // console.log(JSON.parse(testInfo));
    this.updateTestView();
  }

}
</script>

<style scoped>
.v-data-table >>> .col-sample-id {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-name {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-system {
  min-width: 5em;
}

</style>
