import axios from 'axios';
import { apiUrl } from '@/env';
import { IVendorCallout,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getVendorCalloutPanels(token: string) {
    return axios.get<string[]>(`${apiUrl}/api/v1/vendor_callouts/panels`, authHeaders(token));
  },
  async getVendorCalloutTargets(token: string) {
    return axios.get<string[]>(`${apiUrl}/api/v1/vendor_callouts/targets`, authHeaders(token));
  },
  async updateVendorCallout(token: string, id: number, data: IVendorCallout) {
    return axios.put<IVendorCallout>(`${apiUrl}/api/v1/vendor_callouts/${id}`, data, authHeaders(token));
  },
  async createVendorCallout(token: string, data: IVendorCallout) {
    return axios.post<IVendorCallout>(`${apiUrl}/api/v1/vendor_callouts/`, data, authHeaders(token));
  },
  async deleteVendorCallout(token: string, id: number) {
    return axios.delete<IVendorCallout>(`${apiUrl}/api/v1/vendor_callouts/${id}`, authHeaders(token));
  },
};
