import axios from 'axios';
import { apiUrl } from '@/env';
import { IProcessStep } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getProcessSteps(token: string, wafer_id: number) {
    return axios.get<IProcessStep[]>(`${apiUrl}/api/v1/process_steps/?wafer_id=${wafer_id}`, authHeaders(token));
  },
};
