var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.results,"item-key":"id","dense":true,"server-items-length":_vm.resultCount,"page":_vm.options.page,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions,"click:row":_vm.rowclick},scopedSlots:_vm._u([{key:"item.sample_id",fn:function({ value }){return [(value != null)?_c('router-link',{attrs:{"to":{ name: 'biochip-samples-view', params: { ref: _vm.sampleRef(value) }}}},[_vm._v(" "+_vm._s(_vm.sampleRef(value))+" ")]):_c('span')]}},{key:"item.callout_id",fn:function({ value }){return [(value != null)?_c('router-link',{attrs:{"to":{ name: 'biochip-callouts-detail', params: { id: value }}}},[_vm._v(" "+_vm._s(_vm.calloutName(value))+" ")]):_c('span')]}},{key:"item.test_date",fn:function({ value }){return [_vm._v(" "+_vm._s(value?.formatDate() || null)+" ")]}},{key:"item.created_on",fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true, "America/Los_Angeles"))+" ")]}}]),model:{value:(_vm.selectedResults),callback:function ($$v) {_vm.selectedResults=$$v},expression:"selectedResults"}})
}
var staticRenderFns = []

export { render, staticRenderFns }