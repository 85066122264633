var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.probes,"item-key":"panel_name","dense":true,"server-items-length":_vm.probeCount,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.panel_name",fn:function({ item }){return [(item.id != null)?_c('router-link',{attrs:{"title":`${item.panel_name}\n${item.probe_description}`,"to":{ name: 'biochip-probe-detail', params: { id: item.id }}}},[_vm._v(_vm._s(item.panel_name))]):_vm._e()]}},{key:"item.callout_id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.callout ? item.callout.name : "")+" ")]}},{key:"item.date_added",fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true, "America/Los_Angeles"))+" ")]}}]),model:{value:(_vm.selectedProbes),callback:function ($$v) {_vm.selectedProbes=$$v},expression:"selectedProbes"}})
}
var staticRenderFns = []

export { render, staticRenderFns }