<template>
  <!-- All ArrayTemplates -->
  <v-data-table
    :headers="headers"
    :items="arrayTemplates"
    item-key="name"
    :item-class="rowBackground"
    :dense="true"
    class="elevation-1"
    :server-items-length="arrayTemplateCount"
    v-on:update:options="updateOptions"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    v-model="selectedArrayTemplates"
    :page="options.page"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  > 
    <template v-slot:item.name="{ item }">
      <router-link :title="`${item.name}`" v-if="item.id != null" :to="{ name: 'biochip-array-template-view', params: { id: item.id }}">{{item.name}}</router-link>
      <span v-else>{{ item.name }}</span>
    </template>  

    <template v-slot:item.template_date="{ value }">
      {{ value?.formatDate() }}
    </template>
    <template v-slot:item.created_on="{ value }">
      {{ value.formatDateTime(true, "America/Los_Angeles") }}
    </template>

    <template v-slot:item.id="{ item }">
        <v-btn icon v-on:click="clone(item.id)">
          <v-icon title="Clone">mdi-content-duplicate</v-icon>
        </v-btn>
    </template>

  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { IFilter, ITableOptions } from '@/interfaces';
import { IArrayTemplate } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class ArrayTemplateList extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedArrayTemplates!: IArrayTemplate[];

  public headers: object[] = [
        { text: 'Name', value: 'name', cellClass: 'col-name' },
        { text: 'State', value: 'state', cellClass: 'col-name' },
        { text: 'Date', value: 'template_date', cellClass: 'col-date' },
        { text: 'Probe Count', value: 'probe_count' },
      ];

  @Prop() public readonly filters!: IFilter[];

  public arrayTemplates: IArrayTemplate[] = [];
  public arrayTemplateCount: number = 0;

  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'template_date',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateArrayTemplateView();
  }

  public splitResult(r: string) {
    return r.split('|').filter((v) => v.length > 0);
  }

  public clone(id) {
    api.cloneArrayTemplate(readToken(this.$store), id).then((response) => {
      this.$router.push('/main/array-templates/'+response.data.id);
    }).catch((error) => {
      console.log('ERROR: clone template', error);
      dispatchCheckApiError(this.$store, error);
    });

  }

  public async getArrayTemplateCount() {
    try {
      const response = await api.getArrayTemplatesCount(readToken(this.$store), this.filters || []);
      if (response) {
        this.arrayTemplateCount = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async getArrayTemplates() {
    try {
      const response = await api.searchArrayTemplates(
        readToken(this.$store),
        {filters: this.filters , ...this.options},
      );
      if (response) {
          this.arrayTemplates = response.data;
      }
    } catch (error: any) {
      await dispatchCheckApiError(this.$store, error);
    }
  }

  public async updateArrayTemplateView() {
    this.getArrayTemplateCount();
    this.getArrayTemplates();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'name';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    // emit an event?
    this.updateArrayTemplateView();
  }

  public rowBackground(item) {
    return( item.state == 'draft' ? 'lightYellowBackground' : '' );
  }

  public mounted() {
    if( readHasAdminAccess(this.$store) ) {
      this.headers.push(
        { text: 'Actions', value: 'id' },
      );
    }

    this.updateArrayTemplateView();
  }

  @Socket('new-array-template')
  public onNewArrayTemplate(arrayTemplateInfo) {
    console.log('New Array Template', JSON.parse(arrayTemplateInfo));
    this.updateArrayTemplateView();
    // TODO - Add notification
  }

  @Socket('array-template-exists')
  public onArrayTemplateExists(arrayTemplateInfo) {
    console.log('Array Template already exists', JSON.parse(arrayTemplateInfo));
    // TODO - Add notification
  }

}
</script>

<style scoped>
.v-data-table >>> .col-name {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-filter-type {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-analysis-qc {
  min-width: 5em;
}
.v-data-table >>> .lightYellowBackground {
  background-color: lightgoldenrodyellow;
}

</style>
