<template>
  <v-container fluid>


    <v-data-table
      :items="files"
      :headers="headers"
      >
      <!--<template v-slot:item.id="{ item }">
        <v-btn icon plain color="primary" v-on:click="deselectTest(item.id)"><v-icon>mdi-close</v-icon></v-btn>
      </template>-->
    </v-data-table>

  </v-container>
</template>



<script lang="ts">
import TestList from '@/components/TestList.vue';
import FilterPicker from '@/components/FilterPicker.vue';
import RightPanel from '@/components/RightPanel.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IFile } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

@Component
export default class Tests extends Vue {


  public headers: object[] = [
        { text: 'Filename', value: 'filename', cellClass: 'col-sample-id' },
        // { text: 'Patient', value: 'patient_id', cellClass: 'col-sample-id' },
        { text: 'Type', value: 'file_type', cellClass: 'col-result' },
        // { text: 'Specimen', value: 'specimen_type'},
        { text: 'Uploaded', value: 'upload_date' },
      ];

  public files: IFile[] = [];

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };


  public async mounted() {
    // dispatchGetTests(this.$store, this.selectedFilters);
    const result = await api.getArchiveFiles(readToken(this.$store));
    if (result) {
      this.files = result.data;
    }
  }

}
</script>

<style scoped>
.v-data-table >>> .col-sample-id {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-name {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-system {
  min-width: 5em;
}

</style>
