<template>
    <!-- TODO Need to decide what filters are needed -->
    <v-container fluid>
        <v-card>
            <v-card-title> DLL Download </v-card-title>
            <!-- Filter/Email Card -->
            <v-card-text v-if="!downloadSelected">
                <v-form
                    ref="form"
                    v-model="validForm"
                    lazy-validation>
                <v-text-field
                    v-if="!downloadSelected"
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    label="E-mail"
                    required
                ></v-text-field>
                </v-form>
            </v-card-text>

            <!-- Success Card -->
            <v-card-text v-if="downloadSelected && errorMsg===''">
                <v-row>
                    <v-col  class="d-flex justify-center align-center">
                        <v-icon color="green" size="200">mdi-check-circle-outline</v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  class="d-flex justify-center align-center">
                        <p>DLL will be sent to {{ email }}</p>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Error Card -->
            <v-card-text v-if="downloadSelected && errorMsg!==''">
                <v-row>
                    <v-col  class="d-flex justify-center align-center">
                        <v-icon color="red" size="200">mdi-alpha-x-circle-outline</v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  class="d-flex justify-center align-center">
                        <p>An error occured: {{ errorMsg }}</p>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!downloadSelected"
                    color="primary"
                    @click="downloadDLL()"
                >
                    Send
                </v-btn>
                <v-btn
                    v-if="downloadSelected"
                    color="primary"
                    @click="downloadSelected=false"
                >
                    Back
                </v-btn>
                <v-btn
                    color="primary"
                    @click="close()"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IClinicalRuntracker } from '@/interfaces/bcdb';
  import { Socket } from 'vue-socket.io-extended';
  import {getUniqueField, alphabetically} from '@/scripts/support-functions';
  import eventBus from '@/eventBus';
  
  import { toDateWithOptions } from 'date-fns-tz/fp';
//   import { FunctionalComponentMountOptions } from '@vue/test-utils';
  
  
  @Component({
    components: {},
  })
  export default class DllFilter extends Vue {
    @ModelSync('value', 'change', { type: Boolean }) public enable!: Boolean 
    @Prop({default:()=>[]}) public selectedRuns!: IClinicalRuntracker[]

    public email = ''
    public validForm = false
    public rules= {
        required: (v) => !!v || v=== 0 || 'Value required',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'}
    }
    public errorMsg = ''
    
    public downloadSelected = false
    public items: any[] = []
    public defaultSelectedMetrics: string[] = ['Pq', 'Aq', 'Ct']
    public cepheidCallouts:string[] =[]

    get greetedUser() {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
            return userProfile.email;
        }
        return ''
    }

    public async downloadDLL(){
        if ((this.$refs.form as any).validate()) {
            await api.emailDLL(readToken(this.$store), this.email, this.selectedRuns.map((r)=>r.RunTracker.id)).then((response) => {
                this.errorMsg = ''
            }).catch((error) => {
                this.errorMsg=error
            });
            this.downloadSelected = true
        }
    }

    public close(){
        this.enable = false
    }

    public async getuniqueCallouts(){
        const run_ids =  this.selectedRuns.map((r)=>r.RunTracker.id)
        await api.getUniqueCallouts(readToken(this.$store), run_ids).then((response) => {
            if (response.data){
                this.cepheidCallouts = response.data.map((c)=> c.name)
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    public async createSelectableItems(){
        //TODO: Add filter capabilities
        await this.getuniqueCallouts
        const biochip_metrics = []
        const biochip_probes = []
        const runtracker_keys = Object.keys(this.selectedRuns[0].RunTracker)
        const sites = getUniqueField(this.selectedRuns, 'site_name')
    }

    public mounted(){
        // this.createSelectableItems()
        this.email = this.greetedUser
    }
  }
</script>
  
<style scoped>

</style>