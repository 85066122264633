<template>
  <v-container fluid>
    <v-toolbar light>
      <v-toolbar-title>
        Wafers
      </v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn v-if="hasAdminAccess" color="primary" v-on:click="startEAGscan" :disabled="isScanning">Scan EAG</v-btn>
    </v-toolbar>

    <WaferFilterPicker
      v-model="selectedFilters">
    </WaferFilterPicker>

    <WaferList    
      :filters="selectedFilters">
    </WaferList>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Scan EAG Results
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr> <td>Lots Scanned</td>     <td>{{ scanResults.lotsScanned }}</td> </tr>
                <tr> <td>New KGDs found</td>   <td>{{ scanResults.newKgds }}</td> </tr>
                <tr> <td>New STDFs found</td>  <td>{{ scanResults.newStdfs }}</td> </tr>
                <tr> <td>Files Processing</td> <td>{{ scanResults.tasks?.length }}</td> </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script lang="ts">
import WaferList from '@/components/WaferList.vue';
import WaferFilterPicker from '@/components/WaferFilterPicker.vue';

import { Component, Vue } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { Store } from 'vuex';
import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';
import { IFilter } from '@/interfaces';
import { readHasAdminAccess } from '@/store/main/getters';


@Component({
  components: {
    WaferList,
    WaferFilterPicker,
  },
})
export default class Dashboard extends Vue {

  public hasAdminAccess = readHasAdminAccess(this.$store);
  public isScanning = false;
  public dialog = false;
  public scanResults = { lotsScanned:0, newKgds:0, newStdfs:0, tasksStarted:0 };
  public selectedFilters: IFilter[] = [];

  public async mounted() {
  }

  public async startEAGscan() {
    this.isScanning = true
    api.scanEAG(readToken(this.$store))
    .catch((error) => {
      console.log('scanEAG: error', error);
      this.isScanning = false
    });
  }

  @Socket('scan-eag-complete')
  public handleScanComplete(response) {
    this.scanResults = response.results
    this.dialog = true
    this.isScanning = false
  }

}
</script>

<style scoped>
</style>


