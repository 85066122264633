import { render, staticRenderFns } from "./ClinicalData.vue?vue&type=template&id=d39e0f50&scoped=true"
import script from "./ClinicalData.vue?vue&type=script&lang=ts"
export * from "./ClinicalData.vue?vue&type=script&lang=ts"
import style0 from "./ClinicalData.vue?vue&type=style&index=0&id=d39e0f50&prod&lang=css"
import style1 from "./ClinicalData.vue?vue&type=style&index=1&id=d39e0f50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d39e0f50",
  null
  
)

export default component.exports