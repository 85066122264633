import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IPanelRule, IPanelRuleCreate
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getPanelRule(token: string, id: number) {
    return axios.get<IPanelRule>(`${apiUrl}/api/v1/panel_rules/${id}`, authHeaders(token));
  },
  async updatePanelRule(token: string, id: number, data: IPanelRule) {
    return axios.put(`${apiUrl}/api/v1/panel_rules/${id}`, data, authHeaders(token));
  },
  async updatePanelRules(token: string, data: IPanelRule[]) {
    return axios.post(`${apiUrl}/api/v1/panel_rules/bulk`, data, authHeaders(token));
  },
  async createPanelRules(token: string, data: IPanelRuleCreate[]) {
    return axios.post(`${apiUrl}/api/v1/panel_rules/bulk`, data, authHeaders(token));
  },
  async createPanelRule(token: string, data: IPanelRuleCreate) {
    return axios.post(`${apiUrl}/api/v1/panel_rules/`, data, authHeaders(token));
  },
  async deletePanelRule(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/panel_rules/${id}`, authHeaders(token));
  },
};
