<template>
  <v-container fluid>
    <FilterPicker
      v-model="selectedFilters">
    </FilterPicker>

    <TestList
      :filters="selectedFilters"
      v-model="selectedTests">    
    </TestList>

    <v-card v-if="testSummary || showGraph">
      <v-btn v-if="testSummary" v-on:click="removeSummary">Close Summary</v-btn>
      <v-btn v-if="showGraph" v-on:click="hideGraph">Close Graph</v-btn>
    </v-card>

    <v-card v-html="testSummary" v-if="testSummary" class="test-summary">
    </v-card>

    <v-card class="graphs" v-if="showGraph">
      <div id="chartContainer"></div>
    </v-card>

    <RightPanel :showPanel="selectedTests.length">
      <template v-slot:header>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Selected Tests</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="selectedTests.length" v-on:click="clearSelectedTests">Clear</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-data-table
        :items="selectedTests"
        :headers="selectedTestsHeaders"
        group-by="assay_name"
        show-group-by
        >
        <template v-slot:item.id="{ item }">
          <v-btn icon plain color="primary" v-on:click="deselectTest(item.id)"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </v-data-table>

      <v-btn
        color="primary"
        
        v-on:click="getNXXArchive"
        :disabled="downloadDisabled"
      >
        Download NXX
      </v-btn>

      <!-- SUMMARY -->
      <v-menu
        open-on-hover
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            v-on:click="getHTMLSummary"
          >
            Show Summary
          </v-btn>
          
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title v-on:click="getExcelSummary"><v-btn >Download Excel</v-btn></v-list-item-title>
            <v-radio-group v-model="groupBy">
              <v-radio label="By Assay" value="PER_ASSAY"></v-radio>
              <v-radio label="By Analytes" value="BY_ANALYTES"></v-radio>
              <v-radio label="One Page" value="ONE_PAGE"></v-radio>
            </v-radio-group>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- GRAPHS -->
      <v-menu
        open-on-hover
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Graph
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title v-on:click="getPressure"><v-btn >Pressure</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title v-on:click="getOptical"><v-btn >Optical</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title v-on:click="getTemperature"><v-btn >Temp.</v-btn></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      

      <!-- Data Filtering -->
      <v-card
        class="mx-auto"
        max-width="380"
        v-for="assay in selectedAssays"
        :key="assay.guid"
      >
        <v-card-title>
          {{ assay.name }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="readingHeaders"
            :items="readingsForAssay(assay)"
            :dense="true"
            show-select
            item-key="name"
            hide-default-footer
            v-model="selectedReadings[assay.name]"
            :items-per-page="-1"
          >
          </v-data-table>
          <v-data-table
            :headers="analyteHeadersForAssay(assay)"
            :items="assay.analytes"
            :dense="true"
            show-select
            item-key="name"
            hide-default-footer
            v-model="selectedAnalytes[assay.name]"
            :items-per-page="-1"
          >
            <template v-slot:item.is_pcr="{ item }">
              <v-simple-checkbox
                v-model="item.is_pcr"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-if="assay.has_melt_analytes" v-slot:item.is_melt_target="{ item }">
              <v-simple-checkbox
                v-model="item.is_melt_target"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.is_control="{ item }">
              <v-simple-checkbox
                v-model="item.is_control"
                disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.is_probe="{ item }">
              <v-simple-checkbox
                v-model="item.is_probe"
                disabled
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </RightPanel>
  </v-container>
</template>



<script lang="ts">
import TestList from '@/components/TestList.vue';
import FilterPicker from '@/components/FilterPicker.vue';
import RightPanel from '@/components/RightPanel.vue';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readTestSummary, readTraces } from '@/store/tests/getters';
import { readToken } from '@/store/main/getters';
import { dispatchGetTestSummary, dispatchGetTraces, dispatchMakeArchive } from '@/store/tests/actions';
import { commitSetTestSummary } from '@/store/tests/mutations';
import { ITestResult, IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { readCreatingArchive } from '@/store/main/getters';
import { commitSetCreatingArchive } from '@/store/main/mutations';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';

interface IReading {
  name: string;
  value: string;
}

interface IAnalyteSet {
  assay_name: string;
  fields: string[];
}

interface IReadingSet {
  assay_name: string;
  fields: string[];
}

// instrument, module, cart, gx_username, status, result, start_date, reagent_lot, assay, upload_date

@Component({
  components: {
    TestList,
    FilterPicker,
    RightPanel,
  },
})
export default class Tests extends Vue {

  public analyteHeaders: Array<{ [key in 'text' | 'value']: string }> = [
        { text: 'Name', value: 'name'},
        { text: 'PCR', value: 'is_pcr'},
        { text: 'Melt', value: 'is_melt_target'},
        { text: 'Control', value: 'is_control'},
        { text: 'Probe', value: 'is_probe'},
      ];

  public readingHeaders: Array<{ [key in 'text' | 'value']: string }> = [
        { text: 'Reading', value: 'name'},
      ];

  public selectedTestsHeaders: object[] = [
        { text: 'Sample', value: 'sample_id', groupable: false },
        { text: 'Assay Name', value: 'assay_name', groupable: true },
        { text: '', value: 'id', groupable: false },
      ];

  public selectedFilters: IFilter[] = [];


  public groupBy = 'PER_ASSAY';
  public showGraph: boolean = false;
  public selectedAssays: IAssay[] = [];
  public selectedAnalytes: { [key: string]: IAnalyte[] } = {};
  public selectedReadings: { [key: string]: IReading[] } = {};

  public defaultReadings: IReading[] = [{name: 'CT', value: 'ct'}, {name: 'Endpoint', value: 'endpoint'}, {name: 'PrbChk 2', value: 'pc2'}];
  public defaultMeltReadings: IReading[] = [{name: 'Peak Height', value: 'peak_height'}, {name: 'Peak Temp.', value: 'peak_temp'}];

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['created_at'],
    sortDesc: [true],
  };

  public selectedTests: ITestResult[] = [];


  get selectedTestIds() {
    return this.selectedTests.map((t) => t.test_id as number);
  }

  get selectedTestResultIds() {
    return this.selectedTests.map((t) => t.id as number);
  }

  get selectedAssayIds() {
    return [...new Set(this.selectedTests.map((t) => t.assay_id as number))];
  }

  get testSummary() {
    return readTestSummary(this.$store);
  }

  get traces() {
    return readTraces(this.$store);
  }

  get downloadDisabled() {

    const creating = readCreatingArchive(this.$store);
    // if selectedTestResultIds in currentArchiveTasks

    const SwVersions = new Set();
    for (const tr of this.selectedTests) {
      SwVersions.add(tr.system_sw);
    }

    return creating || SwVersions.size !== 1 || !SwVersions.has('6.4');
  }

  /*get filters() {
    const filters: IFilter[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const filter of this.selectedFilters) {
      if (filter.value && filter.selectedOperator && filter.filterValue) {
        filters.push(
          {field: filter.value, operator: filter.selectedOperator, value: filter.filterValue },
        );
      }
    }
    return filters;
  }*/

  /*get filtersAsJson() {
    return JSON.stringify(this.filters);
  }*/

  get selectedAnalytesAsObject() {
    const analytes: IAnalyteSet[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const assay of this.selectedAssays) {
      analytes.push(
        {assay_name: assay.name, fields: this.selectedAnalytes[assay.name].map((an) => an.name)},
      );
    }
    return analytes;
  }

  get selectedReadingsAsObject() {
    const readings: IReadingSet[] = []; // this.selectedAnalytes.map((a) => a.name);
    for (const assay of this.selectedAssays) {
      readings.push(
        {assay_name: assay.name, fields: this.selectedReadings[assay.name].map((rd) => rd.value)},
      );
    }
    return readings;
  }

  public analyteHeadersForAssay(assay: IAssay) {
    if (assay.has_melt_analytes) {
      return this.analyteHeaders;
    } else {
      return this.analyteHeaders.filter((ah) => ah.value !== 'is_melt_target');
    }
  }

  public readingsForAssay(assay: IAssay) {
    if (assay.has_melt_analytes) {
      return this.defaultReadings.concat(this.defaultMeltReadings);
    } else {
      return this.defaultReadings;
    }
  }

  public clearSelectedTests() {
    this.selectedTests = [];
  }

  // For removing tests from the cart
  public deselectTest(testId) {
    this.selectedTests = this.selectedTests.filter((test: ITestResult) => test.id !== testId);
  }

  public getExcelSummary() {
    dispatchGetTestSummary(this.$store, {
      tests: this.selectedTestResultIds, format: 'EXCEL',
      analytes: this.selectedAnalytesAsObject, readings: this.selectedReadingsAsObject,
      groupBy: this.groupBy,
    });
  }

  public getHTMLSummary() {
    dispatchGetTestSummary(this.$store, {
      tests: this.selectedTestResultIds, format: 'HTML',
      analytes: this.selectedAnalytesAsObject, readings: this.selectedReadingsAsObject,
      groupBy: this.groupBy,
    });
  }

  public getNXXArchive() {
    commitSetCreatingArchive(this.$store, true);
    dispatchMakeArchive(this.$store, this.selectedTestResultIds);
  }

  public async getTraces(testIds: number[], kind: string) {
    await dispatchGetTraces(this.$store, {tests: testIds, kind});
  }

  public async getPressure() {
    this.showGraph = true;

    await this.getTraces(this.selectedTestIds, 'pressure');
    const layout = {
        title: 'Pressure',
        xaxis: {
            title: 'Time (s)',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'PSI',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.traces, layout);
  }

  public tracesForSelectedAnalytes() {
    const returnTraces: ITrace[] = [];
    // const selectedAssays = this.selectedAssays;
    // const selectedAnalytes = this.selectedAnalytes;
    for (const assay of this.selectedAssays) {
      // console.log(assay);
      for (const analyte of assay.analytes) {
        // console.log(this.selectedAnalytes[assay.name]);
        if ([...this.selectedAnalytes[assay.name]].map((an) => an.name).includes(analyte.name)) {
          // console.log(analyte.name);
          const analytes = this.traces.filter((t) => t.name.includes(analyte.name));
          // console.log(analytes);
          returnTraces.push(...analytes);
        }
      }
    }
    return returnTraces;
  }

  public async getOptical() {
    this.showGraph = true;
    await this.getTraces(this.selectedTestIds, 'optical');
    const layout = {
        title: 'Optical',
        xaxis: {
            title: 'Cycle',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'DFU',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.tracesForSelectedAnalytes(), layout);
  }

  public async getTemperature() {
    this.showGraph = true;
    await this.getTraces(this.selectedTestIds, 'temperature');
    const layout = {
        title: 'Temperature',
        xaxis: {
            title: 'Time (s)',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'C',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
    };
    Plotly.newPlot('chartContainer', this.traces, layout);
  }

  public removeSummary() {
    commitSetTestSummary(this.$store, '');
  }

  public hideGraph() {
    this.showGraph = false;
  }

  public mounted() {
    // dispatchGetTests(this.$store, this.selectedFilters);
  }

  @Watch('selectedTests')
  public async refreshAssays(newTests: ITestResult[], oldTests: ITestResult[]) {
    const currentAssayIds = new Set(newTests.map((t) => t.assay_id));
    if (newTests.length > 0 && currentAssayIds.size !== this.selectedAssays.length) {
      const previousAssayIds = new Set(oldTests.map((t) => t.assay_id));
      const newAssayIds = new Set(currentAssayIds);
      const removedAssayIds = new Set(previousAssayIds);
      for (const el of previousAssayIds) {
        newAssayIds.delete(el);
      }
      for (const el of currentAssayIds) {
        removedAssayIds.delete(el);
      }
      const assays = await api.getAssays(readToken(this.$store), this.selectedAssayIds);
      const newAssays = assays.data.filter(({id}) => newAssayIds.has(id));
      const removedAssays = this.selectedAssays.filter(({id}) => removedAssayIds.has(id));
      this.selectedAssays = assays.data;
      for (const a of newAssays) {
        if (this.selectedAnalytes[a.name] === undefined) {
          this.$set(this.selectedAnalytes, a.name, Array.from(a.analytes, (an) => an) );
        }
        if (this.selectedReadings[a.name] === undefined) {
          this.$set(this.selectedReadings, a.name, this.readingsForAssay(a) );
        }
      }
      for (const a of removedAssays) {
        this.$set(this.selectedAnalytes, a.name, []);
        this.$set(this.selectedReadings, a.name, []);
      }
    }
    if (newTests.length === 0) {
      this.selectedAssays = [];
    }
  }

}
</script>

<style scoped>
.v-data-table >>> .col-sample-id {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-name {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-system {
  min-width: 5em;
}


.test-summary {
  overflow-x: scroll;
}

.test-summary >>> .dataframe tbody td {
  text-align: right;
  padding-right: 0.2em;
  border-bottom: 1px solid #ccc;
  border-left:  1px solid #ccc;
}

.test-summary >>> .dataframe tbody tr:first-child td {
  border-top: 1px solid #ccc;
}

.test-summary >>> .dataframe tbody td:last-child {
  border-right: 1px solid #ccc;
}

.test-summary >>> .dataframe thead tr:last-child th {
  border-bottom: 1px solid #222;
  border-top: 1px solid #222;
  min-width: 5em;
}

.test-summary >>> .dataframe thead th:last-child {
  border-right: 1px solid #222;
}

.test-summary >>> .dataframe thead th {
  border-left: 1px solid #222;
  border-top: 1px solid #222;
}

.test-summary >>> .dataframe thead th:empty {
  border: 0;
}

.test-summary >>> .dataframe {
  /*border-collapse: collapse !important;*/
  border-spacing: 0 !important;
}


</style>
