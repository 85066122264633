import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { ISample,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getSample(token: string, sampleId: number) {
    return axios.get<ISample>(`${apiUrl}/api/v1/samples/${sampleId}`, authHeaders(token));
  },
  async getSamples(token: string) {
    return axios.get<ISample[]>(`${apiUrl}/api/v1/samples/`, authHeaders(token));
  },
  async updateSample(token: string, id: number, data: ISample) {
    return axios.put(`${apiUrl}/api/v1/samples/${id}`, data, authHeaders(token));
  },
  async createSample(token: string, data: ISample) {
    return axios.post(`${apiUrl}/api/v1/samples/`, data, authHeaders(token));
  },
  async deleteSample(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/samples/${id}`, authHeaders(token));
  },
  async searchSamples(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    /*if (params.filters.length > 0) {
      for (const filter of params.filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`)
      }
    }*/
    return axios.post<ISample[]>(`${apiUrl}/api/v1/samples/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getSampleCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/samples/stats`, filters, authHeaders(token));
  },
  async getSampleFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/samples/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },
  async getLatestSample(token: string, daysAgo: number = 1) {
    return axios.get<ISample[]>(`${apiUrl}/api/v1/samples/latest?days=${daysAgo}`, authHeaders(token));
  },

  async getBySampleIds(token: string, sampleIds: number[]) {
    return axios.post<ISample[]>(`${apiUrl}/api/v1/samples/getBySampleIDs`, sampleIds, authHeaders(token));
  },

  async getUniqueComparators(token: string, sampleIds: number[], source:undefined|string) {
    return axios.post<string[]>(`${apiUrl}/api/v1/samples/getUniqueComparators`, {sampleIds:sampleIds, source:source}, authHeaders(token));
  },

};
