
/* CLINICAL CONSTANTS */
export const contrivedTypes: string[] = ['NegControl','PosControlMix1','PosControlMix2','PosControlMix3','PosControlMix4','PosControlMix5'].concat(
    Array.from({ length: 99 }, (_, i) => `PC4P${(i + 1).toString().padStart(2, '0')}`)
)

export const clinicalTypes: string[] = ['PC3286', 'PC1286'].concat(
    Array.from({ length: 9 }, (_, i) => `PC4S${(i + 1).toString()}`)
)

export const samples: Record<string, {types:string[], color:string}> = {
    Contrived: {types:contrivedTypes, color:'#aacaeb'}, 
    Clinical: {types:clinicalTypes, color:'#9cf0cd'}, 
    Combined: {types:clinicalTypes.concat(contrivedTypes), color:'#ebaab9'}
};