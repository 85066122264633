import axios from 'axios';
import { apiUrl } from '@/env';
import { IRunTracker, IQCResult } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async qcReport(token: string, buffer_run_id: number, tgt_run_id: number) {
    return axios.get<IQCResult>(
      `${apiUrl}/api/v1/reports/qc?buffer_run_id=${buffer_run_id}&target_run_id=${tgt_run_id}`,
      authHeaders(token)
    ); 
  },
};