import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IWafer, IWaferWithCounts } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getWafer(token: string, id: number) {
    return axios.get<IWafer>(`${apiUrl}/api/v1/wafers/${id}`, authHeaders(token));
  },
  async getWafers(token: string) {
    return axios.get<IWafer[]>(`${apiUrl}/api/v1/wafers/`, authHeaders(token));
  },
  async getWafersWithCounts(token: string) {
    return axios.get<IWaferWithCounts[]>(`${apiUrl}/api/v1/wafers/?withCounts=true`, authHeaders(token));
  },
  async searchWafers(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = ["withCounts=true"];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    return axios.post<IWaferWithCounts[]>(`${apiUrl}/api/v1/wafers/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getWafersCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/wafers/stats`, filters, authHeaders(token));
  },
  async getWaferFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/wafers/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },

  async scanEAG(token: string) {
    return axios.get<string>(`${apiUrl}/api/v1/wafers/scan-eag`, authHeaders(token));
  },
};
