<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar flat height="96px">
        <v-toolbar-title>
          Array Templates
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div style='width: 300px;' v-show="hasAdminAccess">
          <file-pond
            name="upload_file"
            ref="pond"
            label-idle="Upload Array Template"
            v-bind:allow-multiple="false"
            v-bind:allow-revert="false"
            v-bind:allow-remove="true"
            accepted-file-types="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :server="serverUploadOptions"
            v-on:init="handleFilePondInit"
            v-on:processfile="handleFilePondProcess"
          />
        </div>
        <v-btn v-if="hasAdminAccess" color="primary" to="/main/array-templates/create">Create Array Template</v-btn>
      </v-toolbar>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          There was a Problem
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-html='errorMessage'>
        </v-card-text>

      </v-card>
    </v-dialog>

    <ArrayTemplateFilterPicker
      v-model="selectedFilters">
    </ArrayTemplateFilterPicker>

    <ArrayTemplateList
      :filters="selectedFilters"
      v-model="selectedArrayTemplates">    
    </ArrayTemplateList>

  </v-container>
</template>



<script lang="ts">
import ArrayTemplateList from '@/components/ArrayTemplateList.vue';
import ArrayTemplateFilterPicker from '@/components/ArrayTemplateFilterPicker.vue';

import { apiUrl } from '@/env';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IArrayTemplate } from '@/interfaces/bcdb';
import { readHasAdminAccess } from '@/store/main/getters';

import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

@Component({
  components: {
    FilePond,
    ArrayTemplateList,
    ArrayTemplateFilterPicker,
  },
})
export default class ArrayTemplates extends Vue {

  public selectedFilters: IFilter[] = [];
  public dialog = false;
  public file = null;
  public errorMessage = '';
  public hasAdminAccess = readHasAdminAccess(this.$store);

  public tableOptions: object = {
    itemsPerPage: 25,
    sortBy: ['template_date'],
    sortDesc: [true],
  };

  public selectedArrayTemplates: IArrayTemplate[] = [];

  // public mounted() {
  // }

  public handleFilePondInit() {
    // console.log('FilePond has initialized');
  }

  public handleFilePondProcess(error, file) {
    // console.log('handleFilePondProcess', typeof error, error, file);
    // Remove the file after 4 seconds.
    // setTimeout(() => this.$refs.pond.removeFile(), 4000) # TODO: This will not compile
    if( error ) {
      // console.log('handleFilePondProcess: error=', error);
      if (error && error.code === 415) {
        this.errorMessage = "Unable to upload the Array Template named '"+
          (file ? file.filename : "") + "' because of the following error:<br/><br/>"+
          "<span class='red--text'>"+error.body+"</span>"
        this.dialog = true;
      }
    }
  }

  public get serverUploadOptions() {
    return {
      url: `${apiUrl}/api/v1/array_templates/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
      process: {
        onerror: (res) => {
          return( JSON.parse(res).detail )
        },
      },
    };
  }

}
</script>

<style scoped>
.v-data-table >>> .col-sample-id {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-name {
  min-width:  8em;
}
.v-data-table >>> .col-status {
  min-width: 4em;
}
.v-data-table >>> .col-result {
  min-width: 4em;
}
.v-data-table >>> .col-system {
  min-width: 5em;
}


</style>
