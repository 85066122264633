<template>
  <v-container fluid>
    <v-toolbar light>
      <v-toolbar-title>
        Comparator Test Results
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="hasAdminAccess" color="primary" v-on:click="addTestResult()">New Comparator Test Result</v-btn>
    </v-toolbar>

<!--     <SampleFilterPicker
      v-model="selectedFilters">
    </SampleFilterPicker>
 -->
    <ComparatorTestResultList
      v-model="selectedResults"
      :filters="selectedFilters"
      :callouts="callouts"
      :samples="samples"
      :message="message"
      :rowclick="rowClicked"
      @click:row="rowClicked(item)">
    </ComparatorTestResultList>

    <v-card v-if="comparatorTestResultsDetail">
      <v-btn v-if="comparatorTestResultsDetail" v-on:click="removeDetail">Close Detail</v-btn>
    </v-card>

    <v-card v-html="comparatorTestResultsDetail" v-if="comparatorTestResultsDetail" class="ctr-detail">
    </v-card>

    <RightPanel :showPanel="selectedResults.length > 0">
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{selectedResults[0] ? selectedResults[0].target_name : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="subtitle-1">Comparator Test Result Details</p>
          <v-form @keyup.enter="submit" v-model="valid" ref="form" @submit.prevent="" >
            <v-autocomplete
              label="Sample"
              v-model="sampleId"
              :items="samples"
              item-text="ref"
              item-value="id"
              v-validate="'required'"
              data-vv-name="Sample"
              :error-messages="errors.collect('Sample')"
              clearable>
            </v-autocomplete>
            <v-combobox
              label="Panel"
              type="text"
              :items="vendorPanels"
              v-model="panelName"
              v-validate="'required'"
              data-vv-name="Panel Name"
              @change="getVendorTargets()"
              :error-messages="errors.collect('Panel Name')">
            </v-combobox>
            <v-autocomplete
              label="Target"
              type="text"
              :items="vendorTargets"
              v-model="targetName"
              v-validate="'required'"
              data-vv-name="Target"
              :error-messages="errors.collect('Target')">
            </v-autocomplete>
            <v-checkbox
              label="Detected"
              v-model="detected">
            </v-checkbox>
            <v-text-field
              label="Site"
              type="text"
              v-model="siteName"
              v-validate="'required'"
              data-vv-name="Site"
              :error-messages="errors.collect('Site')">
            </v-text-field>

            <v-menu
              v-model="testDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="testDate"
                  label="Test Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="testDate"
                @input="testDateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-select
              label="Callout"
              :items="callouts"
              item-text="name"
              item-value='id'
              v-model="calloutId"
              v-validate="'required'"
              data-vv-name="callout"
              :error-messages="errors.collect('callout')">
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <span class='red--text'>{{ errorMessage }}</span>
          <v-spacer></v-spacer>
          <v-btn v-if="mode != 'View'" @click="close">Cancel</v-btn>
          <v-btn v-if="mode == 'Edit'" @click="deleteTestResult" :disabled="!valid">Delete</v-btn>
          <v-btn v-if="mode == 'Create'" @click="reset">Reset</v-btn>
          <v-btn v-if="mode != 'View'" @click="submit" color="primary" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>

    </RightPanel>

  </v-container>
</template>



<script lang="ts">
import RightPanel from '@/components/RightPanel.vue';
import ComparatorTestResultList from '@/components/ComparatorTestResultList.vue';
// import SampleFilterPicker from '@/components/SampleFilterPicker.vue';

import { apiUrl } from '@/env';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { ISample, IComparatorTestResult, IRunTracker, ICallout } from '@/interfaces/bcdb';
import { ESexType } from '@/interfaces/bcdb';
import { Validator } from 'vee-validate';
import { readHasAdminAccess } from '@/store/main/getters';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';


@Component({
  components: {
    ComparatorTestResultList,
    // ComparatorTestResultFilterPicker,
    RightPanel,
  },
})
export default class ComparatorTestResults extends Vue {
  public mode = '';
  public selectedFilters: IFilter[] = [];
  public panelName: string = '';
  public targetName: string = '';
  public detected: boolean = false;
  public siteName: string = '';
  public testDate: string|null = null;
  public sampleId: number|null = null;
  public calloutId: number|null = null;
  public comparatorTestResultsDetail: boolean = false;
  public testDateMenu: boolean = false;
  public valid = false;
  public message: string = '';
  public vendorPanels: string[] = [];
  public callouts: ICallout[] = [];
  public samples: ISample[] = [];
  public hasAdminAccess = readHasAdminAccess(this.$store);
  public selectedResults: IComparatorTestResult[] = [];
  public errorMessage: string = '';
  public vendorTargetDict: Object[] = [];
  public vendorTargets: string[] = [];

  public rowClicked(row, slot) {
    console.log("selected", this.selectedResults[0]);
    if( this.selectedResults.length > 0 && this.selectedResults[0] == row ) {
      this.selectedResults = [];
    } else {
      this.mode = 'Edit';
      this.selectedResults = [row];
      this.panelName = this.selectedResults[0].panel_name;
      this.targetName = this.selectedResults[0].target_name;
      this.detected = this.selectedResults[0].detected;
      this.siteName = this.selectedResults[0].site_name;
      this.testDate = this.selectedResults[0].test_date;
      this.sampleId = this.selectedResults[0].sample_id;
      this.calloutId = this.selectedResults[0].callout_id;
    }
  }

  public addTestResult() {
    this.mode = this.hasAdminAccess ? 'Create' : 'View';
    const testResult: IComparatorTestResult = {
      id: 0,    // 0 indicates potientially new test result
      panel_name: '',
      target_name: '',
      detected: false,
      site_name: '',
      test_date: null,
      sample_id: null,
      callout_id: null,
      vendor_callout_id: null,
    };
    this.reset();
    this.selectedResults = [testResult];
  }

  public deleteTestResult() {
    api.deleteComparatorTestResult(readToken(this.$store), this.selectedResults[0].id)
    .then(response => {
      this.updateList()
    });
  }

  public close() {
    this.selectedResults = [];
    this.errorMessage = '';
  }

  public reset() {
    this.$validator.reset();
    this.panelName = '';
    this.targetName = '';
    this.detected = false;
    this.siteName = '';
    this.testDate = null;
    this.sampleId = null;
    this.calloutId = null;
    this.errorMessage = '';
  }

  public async submit(event) {
    this.errorMessage = '';
    if (await this.$validator.validateAll()) {
      this.selectedResults[0].detected = this.detected;
      this.selectedResults[0].site_name = this.siteName.trim();
      this.selectedResults[0].test_date = this.testDate;
      this.selectedResults[0].sample_id = this.sampleId;
      this.selectedResults[0].vendor_callout_id = this.vendorTargetDict[this.panelName][this.targetName];
      if( this.selectedResults[0].id == 0 ) {    // if id is not set, then it is a new sample
        api.createComparatorTestResult(readToken(this.$store), this.selectedResults[0])
          .then((response) => {
            this.updateList();
          })
          .catch((error) => {
            if( error.response && error.response.data.detail ) {
              this.errorMessage = error.response.data.detail;
            } else {
              this.errorMessage = `${error.message}: ${error.response.statusText}`;
            }
          });
      } else {
        api.updateComparatorTestResult(readToken(this.$store), this.selectedResults[0].id, this.selectedResults[0])
          .then((response) => {
            this.updateList();
          })
          .catch((error) => {
            if( error.response && error.response.data.detail ) {
              this.errorMessage = error.response.data.detail;
            } else {
              this.errorMessage = `${error.message}: ${error.response.statusText}`;
            }
          });
      }
    }
  }

  // send a message to the child to update
  public updateList() {
      this.selectedResults = []
      this.message = 'update'+Date.now()
  }

  public mounted() {
    this.mode = 'View';

    this.loadSamples();
    this.loadCallouts();
    this.loadVendorCallouts();
    this.loadVendorTargets();
  }

  public loadSamples() {
    api.getSamples(readToken(this.$store)).then((response) => {
      this.samples = response.data;
    });
  }

  public loadCallouts() {
    api.getCallouts(readToken(this.$store)).then((response) => {
      this.callouts = response.data;
    });
  }

  public loadVendorCallouts() {
    api.getVendorCalloutPanels(readToken(this.$store)).then((response) => {
      this.vendorPanels = response.data;
    });
  }

  public loadVendorTargets() {
    api.getVendorCalloutTargets(readToken(this.$store)).then((response) => {
      this.vendorTargetDict = response.data;
    });
  }

  public getVendorTargets() {
    if (this.panelName.length > 0){
      let target_dict = this.vendorTargetDict[this.panelName];
      this.vendorTargets = Object.keys(target_dict).sort();
    }
    return this.vendorTargets;
  }

}
</script>
