import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IFilter } from '@/interfaces';
import { IRunTracker,
         IStaticThermalAnalysis, IThermalSignals, IThermalStats, ITransitionAnalysis,
} from '@/interfaces/bcdb';
import { State } from '../state';
import { RunState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetRuns, commitSetRun, commitSetRunsCount, commitSetRunSummary,
    commitSetRunSignals, commitSetRunAmpfeas,
    commitSetRunStaticThermalAnalysis, commitSetRunThermalSignals,
    commitSetRunThermalStats, commitSetRunTransitionAnalysis,
    commitSetArchiveTask,
} from './mutations';
import { readRunsOptions } from './getters';
import { dispatchCheckApiError } from '../main/actions';
import FileSaver from 'file-saver';

type MainContext = ActionContext<RunState, State>;

export const actions = {
    async actionGetRuns(context: MainContext) {
        try {
            const response = await api.getRuns(
                context.rootState.main.token,
            );
            if (response) {
                commitSetRuns(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSearchRuns(context: MainContext, filters: IFilter[]) {
        try {
            const response = await api.searchRuns(
                context.rootState.main.token,
                {filters, ...readRunsOptions(context)},
            );
            if (response) {
                commitSetRuns(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunsCount(context: MainContext, filters: IFilter[]) {
        try {
            const response = await api.getRunsCount(
                context.rootState.main.token,
                filters || [],
            ); // TODO: un-hack this fix
            if (response) {
                commitSetRunsCount(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunSummary(context: MainContext, payload:
        {
            runs: number[], format: string, groupBy: string, extraData?: object[],
        },
    ) {
        try {
            const response = await api.getRunSummary(context.rootState.main.token, payload);
            if (response) {
                if (payload.format === 'EXCEL') {
                    const fileNameHeader = 'x-suggested-filename';
                    const suggestedFileName = response.headers[fileNameHeader];
                    const effectiveFileName = (suggestedFileName === undefined
                                ? 'GXdb_summary.xlsx'
                                : suggestedFileName);
                    FileSaver.saveAs(response.data, effectiveFileName);
                }
                if (payload.format === 'HTML') {
                    commitSetRunSummary(context, response.data);
                }
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunSignals(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunSignals: calling getRunSignals');
            const response = await api.getRunSignalsBySourceVersion(context.rootState.main.token, payload.runId);
            console.log('actionGetRunSignals: response =', response.data);
            if (response) {
                commitSetRunSignals(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunAmpfeas(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunAmpfeas: calling getRunAmpfeas');
            const response = await api.getRunAmpfeasBySourceVersion(context.rootState.main.token, payload.runId);
            console.log('actionGetRunAmpfeas: response =', response.data);
            if (response) {
                commitSetRunAmpfeas(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunStaticThermalAnalysis(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunStaticThermalAnalysis: calling getRunStaticThermalAnalysis');
            const response = await api.getRunStaticThermalAnalyses(context.rootState.main.token, payload.runId);
            console.log('actionGetRunStaticThermalAnalysis: response =', response.data);
            if (response) {
                commitSetRunStaticThermalAnalysis(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunThermalSignals(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunThermalSignals: calling getRunThermalSignals');
            const response = await api.getRunThermalSignals(context.rootState.main.token, payload.runId);
            console.log('actionGetRunThermalSignals: response =', response.data);
            if (response) {
                commitSetRunThermalSignals(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunThermalStats(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunThermalStats: calling getRunThermalStats');
            const response = await api.getRunThermalStats(context.rootState.main.token, payload.runId);
            console.log('actionGetRunThermalStats: response =', response.data);
            if (response) {
                commitSetRunThermalStats(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRunTransitionAnalysis(context: MainContext, payload: { runId: number }) {
        try {
            console.log('actionGetRunTransitionAnalysis: calling getRunTransitionAnalysis');
            const response = await api.getRunTransitionAnalyses(context.rootState.main.token, payload.runId);
            console.log('actionGetRunTransitionAnalysis: response =', response.data);
            if (response) {
                commitSetRunTransitionAnalysis(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMakeArchive(context: MainContext, runs: number[]) {
        try {
            const response = await api.makeArchive(context.rootState.main.token, runs);
            if (response) {
                commitSetArchiveTask(context, {taskId: response.data, runIds: runs});
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
};


const { dispatch } = getStoreAccessors<RunState, State>('');

export const dispatchGetRuns = dispatch(actions.actionGetRuns);
export const dispatchSearchRuns = dispatch(actions.actionSearchRuns);
export const dispatchGetRunsCount = dispatch(actions.actionGetRunsCount);
export const dispatchGetRunSummary = dispatch(actions.actionGetRunSummary);
export const dispatchGetRunSignals = dispatch(actions.actionGetRunSignals);
export const dispatchGetRunAmpfeas = dispatch(actions.actionGetRunAmpfeas);
export const dispatchGetRunStaticThermalAnalysis = dispatch(actions.actionGetRunStaticThermalAnalysis);
export const dispatchGetRunThermalSignals = dispatch(actions.actionGetRunThermalSignals);
export const dispatchGetRunThermalStats = dispatch(actions.actionGetRunThermalStats);
export const dispatchGetRunTransitionAnalysis = dispatch(actions.actionGetRunTransitionAnalysis);
export const dispatchMakeArchive = dispatch(actions.actionMakeArchive);
