<template>
    <v-container fluid>
        <v-card>
            <v-card-title> {{tableSetup.dialogTitle}} </v-card-title>
            <v-card-text>
            <!-- <v-row v-if="tableSetup.viewGroup" class="d-flex align-center">
                <v-col cols="1">Group by:</v-col>
                <v-col cols="2"><v-checkbox v-model="tableGroupByVendorCallout" :label="`Vendor Callout`" @click="groupSelected()"></v-checkbox></v-col>
                <v-col cols="2"><v-checkbox v-model="tableGroupByExperimentName" :label="`Experiment Name`" @click="groupSelected()"></v-checkbox></v-col>
            </v-row> -->
            <v-data-table
                scrollable
                dense
                height="350"
                fixed-header
                :group-by="tableSetup.viewGroup"
                :headers="tableSetup.headers"
                :items="tableItems"
                :item-key="tableSetup.id"
                :sort-by="tableSetup.sortBy"
                :sort-desc="true"
                class=""
                no-data-text="No Experiments"
                disable-pagination
                hide-default-footer
                :show-select="tableSetup.selectable"
                v-model="selectedExperiments"
                >
                <template v-slot:group.header="{isOpen, toggle, group}">
                <td colspan="1" class='align-left blue-grey lighten-3'> 
                    <div @click="toggle">
                    <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </div>
                </td>
                <td colspan="7" class='align-center blue-grey lighten-3'>
                    <div>{{ group }}</div>
                </td> 
                </template>

                <template v-slot:item.experiment="{ item }">
                <!-- Split on _ for callout made IDs -->
                <router-link
                    :title="`NAME: ${item.experiment}`"
                    v-if="item.id != null && typeof item.id === 'string'"
                    :to="{ name: 'biochip-run-view', params: { id: item.id.split('_')[0] }}"
                >
                    {{item.experiment}}
                </router-link>
                <router-link
                    :title="`NAME: ${item.experiment}`"
                    v-else-if="item.id != null && typeof item.id === 'number'"
                    :to="{ name: 'biochip-run-view', params: { id: item.id }}"
                >
                    {{item.experiment}}
                </router-link>
                <span v-else>{{ item.experiment }}</span>
                </template>

                <template v-slot:[`item.RunTracker.experiment`]="{ item }">
                    <router-link
                        :title="`NAME: ${item.RunTracker.experiment}`"
                        v-if="item.RunTracker.id != null && typeof item.RunTracker.id === 'string'"
                        :to="{ name: 'biochip-run-view', params: { id: item.RunTracker.id.split('_')[0] }}"
                    >
                        {{item.RunTracker.experiment}}
                    </router-link>
                    <router-link
                        :title="`NAME: ${item.RunTracker.experiment}`"
                        v-else-if="item.RunTracker.id != null && typeof item.RunTracker.id === 'number'"
                        :to="{ name: 'biochip-run-view', params: { id: item.RunTracker.id }}"
                    >
                        {{item.RunTracker.experiment}}
                    </router-link>
                    <span v-else>{{ item.RunTracker.experiment }}</span>
                </template>

                <template v-slot:item.failures="{item}">
                <span v-if="item.failures === 'INVALID'"> 
                    <v-chip small outlined class="ma-2">{{item.failures}}</v-chip> 
                </span>
                <span v-else-if="item.failures === 'NO RESULT'"> 
                    <v-chip small outlined color="#F18F01" class="ma-2">{{item.failures}}</v-chip> 
                </span>
                <span v-else-if="item.failures === 'ERROR'"> 
                    <v-chip small outlined color="#C73E1D" class="ma-2"> {{item.failures}} </v-chip> 
                </span>
                <span v-else-if="item.failures === 'CIC FAIL'"> 
                    <v-chip small outlined color="#3B1F2B" class="ma-2"> {{item.failures}} </v-chip> 
                </span>
                <span v-else-if="item.failures !== null">
                    <v-chip small outlined color="#6A3821" class="ma-2"> {{item.failures}} </v-chip>
                </span>
                </template>

                <template v-slot:[`item.RunTracker.failures`]="{item}">
                    <span v-if="item.RunTracker.failures === 'INVALID'"> 
                        <v-chip small outlined class="ma-2">{{item.RunTracker.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'NO RESULT'"> 
                        <v-chip small outlined color="#F18F01" class="ma-2">{{item.RunTracker.failures}}</v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'ERROR'"> 
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-chip 
                                small outlined color="#C73E1D" 
                                class="ma-2" 
                                v-on="on"
                                @mouseenter="filterErrorsByRun(item.RunTracker)"
                                @mouseleave="clearErrorDetailsForRunVar()"
                                > {{item.RunTracker.failures}} </v-chip>
                            </template>
                            <span>
                                <li v-for="errorDetail in errorDetailsForRun">
                                    {{errorDetail}}
                                </li>
                            </span>
                        </v-tooltip>    
                    </span>
                    <span v-else-if="item.RunTracker.failures === 'CIC FAIL'"> 
                        <v-chip small outlined color="#3B1F2B" class="ma-2"> {{item.RunTracker.failures}} </v-chip> 
                    </span>
                    <span v-else-if="item.RunTracker.failures !== null">
                        <v-chip small outlined color="#6A3821" class="ma-2"> {{item.RunTracker.failures}} </v-chip>
                    </span>
                </template>

                <template v-slot:item.panels="{item}">
                     <span v-for="(panel, index) in item.panels" :key=index>
                        <v-chip
                            v-if="panel!==null"
                            small
                            outlined
                            style="margin-top: auto;margin-bottom: auto;"
                        >{{ panel }}</v-chip>
                     </span>
                </template>

                <template v-slot:item.result_txt="{item}">
                <span v-if="item.result_txt === 'DETECTED'"> 
                    <v-chip small outlined color="#C73E1D" class="ma-2">{{item.result_txt}}</v-chip> 
                </span>
                <span v-else-if="item.result_txt === 'NOT DETECTED'"> 
                    <v-chip small outlined color="#428959" class="ma-2">{{item.result_txt}}</v-chip> 
                </span>
                </template>

                <template v-slot:item.vendor_result="{item}">
                <span v-if="item.vendor_result === 'DETECTED'"> 
                    <v-chip small outlined color="#C73E1D" class="ma-2">{{item.vendor_result}}</v-chip> 
                </span>
                <span v-else-if="item.vendor_result === 'NOT DETECTED'"> 
                    <v-chip small outlined color="#428959" class="ma-2">{{item.vendor_result}}</v-chip> 
                </span>
                </template>

                <template v-slot:item.adf_version="{item}">
                    <span>{{item.adf_version}}</span>
                </template>

            </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="downloadObject()"
            >
                Download
            </v-btn>
            <v-btn
                v-if="tableSetup.selectable"
                color="primary"
                @click="applySelected()"
            >
                Apply
            </v-btn>
            <v-btn
                color="primary"
                @click="closeSelected()"
            >
                Close
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
  import { VMenu } from 'vuetify/lib';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IRunTracker, IRunErrors, ICallout, IPpaNpa, IInternalCallouts, ISample, IComparatorMap, IClinicalRuntracker } from '@/interfaces/bcdb';
  import { Socket } from 'vue-socket.io-extended';
  
  import { subWeeks, startOfToday, parseISO, format, formatISO, isThisMinute, isThisHour } from 'date-fns';
  import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
  import buildObjectToExcel from '@/scripts/to-excel/object-to-excel';
  
  import * as Plotly from 'plotly.js';
  import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';
  import { toDateWithOptions } from 'date-fns-tz/fp';
//   import { FunctionalComponentMountOptions } from '@vue/test-utils';
  import { internals } from '@azure/msal-browser';
  
  
  @Component
  export default class RunListDialog extends Vue {
    @ModelSync('value', 'change', { type: Array }) selectedExperiments!: IClinicalRuntracker[]
    @Prop() public tableSetup!: {id: any, headers: any, dialogTitle: string, viewGroup: any, selectable:boolean, sortBy:string}
    @Prop() public tableItems!: IClinicalRuntracker[]
    @Prop({default:()=>[]}) public readonly tableErrors!: IRunErrors[]

    public selectedExperimentsCopy: IClinicalRuntracker[] = []
    public errorDetailsForRun: string[] = []

    public async filterErrorsByRun(experiment: IRunTracker) {
        // Filter errors for a line item run
        let filteredErrors = this.tableErrors.filter((runError) => runError.run_tracker_id == experiment.id);

        // Handle multiple errors for a single run
        filteredErrors.forEach((filteredError) => this.errorDetailsForRun.push(filteredError.details));
        
        // Formatting for large or no error results
        const nBound = 5;
        let len = this.errorDetailsForRun.length;
        if(len > nBound){
            this.errorDetailsForRun = this.errorDetailsForRun.slice(0,nBound)
            this.errorDetailsForRun.push(`See ${len - nBound - 1} more errors...`)}
        else if (len == 0){
            this.errorDetailsForRun.push('No error details found.')
        }
    }

    public async clearErrorDetailsForRunVar(){
        this.errorDetailsForRun = []
    }


    public async downloadObject() {
      if(this.tableSetup.headers.length) {
        const sheetFields = this.tableSetup.headers.map((h:any)=>h.value)
        const sheetHeaders = this.tableSetup.headers
        const sheetTitle = this.tableSetup.dialogTitle
        buildObjectToExcel([this.tableItems], [sheetFields], this.tableSetup.dialogTitle,[sheetHeaders], [sheetTitle]);
      }
    }

    public applySelected() {
      this.$emit('apply-runListDialog');
    }

    public async closeSelected() {
      if(this.tableSetup.selectable){
        this.selectedExperiments = this.tableItems
      }
      this.$emit('close-runListDialog');
    }

    public mounted(){
    }
  }
</script>
  
<style scoped>
  .contrived-column {
  background-color: #aacaeb;
}
.clinical-column {
  background-color: #9cf0cd;
}
.combined-column {
  background-color: #ebaab9;
}
  .column-borders {
  border-right: 2px solid black;
  border-left: 2px solid black;
}
.pressed {
    background-color: #bec0c3; /* Desired background color when pressed */
  }
</style>