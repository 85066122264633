import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { 
  IArrayTemplate, IArrayTemplateCreate, 
  IArrayTemplateProbe, IArrayTemplateProbeCreate,
  IArrayTemplateProbeReplicate,
  IRuleSet, IArrayTemplateProfile
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getArrayTemplate(token: string, arrayTemplateId: number) {
    return axios.get<IArrayTemplate>(`${apiUrl}/api/v1/array_templates/${arrayTemplateId}`, authHeaders(token));
  },
  async getArrayTemplates(token: string) {
    return axios.get<IArrayTemplate[]>(`${apiUrl}/api/v1/array_templates/`, authHeaders(token));
  },
  async searchArrayTemplates(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    /*if (params.filters.length > 0) {
      for (const filter of params.filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`)
      }
    }*/
    return axios.post<IArrayTemplate[]>(`${apiUrl}/api/v1/array_templates/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getArrayTemplatesCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/array_templates/stats`, filters, authHeaders(token));
  },
  async getArrayTemplateFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(
      `${apiUrl}/api/v1/array_templates/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`,
      authHeaders(token));
  },
  async getLatestArrayTemplates(token: string, daysAgo: number = 1) {
    return axios.get<IArrayTemplate[]>(`${apiUrl}/api/v1/array_templates/latest?days=${daysAgo}`, authHeaders(token));
  },
  async getArrayTemplateProbes(token: string, id: number) {
    return axios.get<IArrayTemplateProbe[]>(
      `${apiUrl}/api/v1/array_templates/${id}/probes`,
      authHeaders(token));
  },
  async updateArrayTemplateProbes(token: string, id: number, data: IArrayTemplateProbe[]) {
    return axios.put<IArrayTemplateProbe[]>(
      `${apiUrl}/api/v1/array_templates/${id}/probes`,
      data,
      authHeaders(token));
  },
  async getArrayTemplateProbeReplicates(token: string, arrayTemplateId: number) {
    return axios.get<IArrayTemplateProbeReplicate[]>(
      `${apiUrl}/api/v1/array_templates/${arrayTemplateId}/probe_replicates`,
      authHeaders(token));
  },

  async getArrayTemplateRuleSets(token: string, arrayTemplateId: number) {
    return axios.get<IRuleSet[]>(
      `${apiUrl}/api/v1/array_templates/${arrayTemplateId}/rule_sets`,
      authHeaders(token));
  },

  async getArrayTemplateExport(token: string, arrayTemplateId: number) {
    const { headers } = authHeaders(token);
    const responseType = 'blob';

    return axios.get(`${apiUrl}/api/v1/array_templates/${arrayTemplateId}/xlsx`, {responseType, headers});
  },
  async getArrayTemplateNXT(token: string, arrayTemplateId: number) {
    const { headers } = authHeaders(token);
    const responseType = 'blob';

    return axios.get(`${apiUrl}/api/v1/array_templates/${arrayTemplateId}/nxt`, {responseType, headers});
  },
  async createArrayTemplate(token: string, data: IArrayTemplateCreate) {
    return axios.post(`${apiUrl}/api/v1/array_templates/`, data, authHeaders(token));
  },
  async cloneArrayTemplate(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/array_templates/${id}/clone`, authHeaders(token));
  },
  async updateArrayTemplate(token: string, id: number, data: IArrayTemplateCreate) {
    return axios.put(`${apiUrl}/api/v1/array_templates/${id}`, data, authHeaders(token));
  },
  async deleteArrayTemplate(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/array_templates/${id}`, authHeaders(token));
  },
  async submitArrayTemplate(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/array_templates/${id}/submit`, {}, authHeaders(token));
  },
  async unsubmitArrayTemplate(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/array_templates/${id}/unsubmit`, {}, authHeaders(token));
  },

  async createArrayTemplateProbe(token: string, data: IArrayTemplateProbeCreate) {
    return axios.post(`${apiUrl}/api/v1/array_template_probes/`, data, authHeaders(token));
  },
  async updateArrayTemplateProbe(token: string, id: number, data: IArrayTemplateProbeCreate) {
    return axios.put(`${apiUrl}/api/v1/array_template_probes/${id}`, data, authHeaders(token));
  },
  async deleteArrayTemplateProbe(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/array_template_probes/${id}`, authHeaders(token));
  },

  async getArrayTemplateProfiles(token: string) {
    return axios.get(`${apiUrl}/api/v1/array_template_profiles/`, authHeaders(token));
  },
  async createArrayTemplateProfile(token: string, data: IArrayTemplateProfile) {
    return axios.post(`${apiUrl}/api/v1/array_template_profiles/`, data, authHeaders(token));
  },
  async submitArrayTemplateProfile(token: string, id: number) {
    return axios.post(`${apiUrl}/api/v1/array_template_profiles/${id}/submit`, {}, authHeaders(token));
  },
  async updateArrayTemplateProfile(token: string, id: number, data: IArrayTemplateProfile) {
    return axios.put(`${apiUrl}/api/v1/array_template_profiles/${id}`, data, authHeaders(token));
  },
  async deleteArrayTemplateProfile(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/array_template_profiles/${id}`, authHeaders(token));
  },

};
