import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IProbeRule, IProbeRuleCreate,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getProbeRule(token: string, id: number) {
    return axios.get<IProbeRule>(`${apiUrl}/api/v1/probe_rules/${id}`, authHeaders(token));
  },
  async updateProbeRule(token: string, id: number, data: IProbeRule) {
    return axios.put(`${apiUrl}/api/v1/probe_rules/${id}`, data, authHeaders(token));
  },
  async updateProbeRules(token: string, data: IProbeRule[]) {
    return axios.post(`${apiUrl}/api/v1/probe_rules/bulk`, data, authHeaders(token));
  },
  async createProbeRules(token: string, data: IProbeRule[]) {
    return axios.post(`${apiUrl}/api/v1/probe_rules/bulk`, data, authHeaders(token));
  },
  async createProbeRule(token: string, data: IProbeRuleCreate) {
    return axios.post(`${apiUrl}/api/v1/probe_rules/`, data, authHeaders(token));
  },
  async deleteProbeRule(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/probe_rules/${id}`, authHeaders(token));
  },
};
