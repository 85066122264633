import { render, staticRenderFns } from "./ClinicalDataFilterPicker.vue?vue&type=template&id=0bf1e90e&scoped=true"
import script from "./ClinicalDataFilterPicker.vue?vue&type=script&lang=ts"
export * from "./ClinicalDataFilterPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf1e90e",
  null
  
)

export default component.exports