var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.runs,"item-key":"experiment","dense":true,"server-items-length":_vm.runCount,"show-select":"","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"page":_vm.options.page,"footer-props":{
    showFirstLastPage: true,
    showCurrentPage: true,
    itemsPerPageOptions: [
      5, 10, 25, 50, 100
    ],
  }},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.experiment",fn:function({ item }){return [(item.id != null)?_c('router-link',{attrs:{"title":_vm.runTitle(item),"to":{ name: 'biochip-run-view', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.experiment)+" ")]):_c('span',[_vm._v(_vm._s(item.experiment))])]}},{key:`item.date_added`,fn:function({ value }){return [_vm._v(" "+_vm._s(value.formatDateTime(true))+" ")]}},{key:`item.array_template.name`,fn:function({ value, item }){return [(value != null)?_c('router-link',{attrs:{"title":`${value}`,"to":{ name: 'biochip-array-template-view', params: { id: item.array_template.id }}}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(_vm._s(value))])]}}],null,true),model:{value:(_vm.selectedRuns),callback:function ($$v) {_vm.selectedRuns=$$v},expression:"selectedRuns"}})
}
var staticRenderFns = []

export { render, staticRenderFns }