import axios from 'axios';
import { apiUrl } from '@/env';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async queryWorkers(token: string) {
    console.log('queryWorkers:');
    return axios.get<object[]>(`${apiUrl}/api/v1/worker/query`, authHeaders(token));
  },
  async terminateWorker(token: string, task_id:string) {
    console.log('terminateWorker: id:', task_id);
    return axios.get<boolean>(`${apiUrl}/api/v1/worker/${task_id}/terminate`, authHeaders(token));
  },
};
