<template>
    <v-container fluid>
      <v-row style="justify-content: center">
        <v-col cols="2">
          <v-autocomplete
            v-model="selectedProduct"
            :items="internalProducts"
            label="Product"
            item-text="name"
            disabled
            @change="fetchProductSpecificItems()"
          ></v-autocomplete>
        </v-col>
          
        <v-col cols="3">
          <v-autocomplete
            v-model="selectedRule"
            :items="ruleSets"
            label="Rule Name"
            item-text="name"
            auto-select-first
            @change="fetchMergedComparionRules()"
            return-object
          >
            <template v-slot:selection="data">
              <span style="justify-content: space-evenly;">
                {{ data.item.name }}
                <v-icon v-if="data.item.share_status === 'LOCKED'">mdi-lock-outline</v-icon>
                <v-icon v-if="userPermissions.userID === data.item.created_by_id">mdi-account-outline</v-icon>
              </span>
            </template>
            <template v-slot:item="data">
                <template v-if=" data.item.share_status === 'LOCKED'">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-lock-outline</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-else-if="userPermissions.userID === data.item.created_by_id">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-account-outline</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            v-model="productComparisonRule.share_status"
            :items="shareStatus"
            label="Share Status"
            :error-messages="shareStatusError"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-switch v-model="includeOthers" label="Include public rules" @change="fetchComparisonRules()"></v-switch>
        </v-col>
        
      </v-row>

      <v-row :align="'center'" v-for="(item,index) in uiComparisonRules" :key="index">
        <v-col cols="3">
          <v-autocomplete
            v-model="item.callout"
            :items="callouts"
            label="Callout"
            chips
            item-text="name"
            :error-messages="item.errorMessages.callout"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <v-divider vertical></v-divider>

        <v-col cols="2">
          <v-select
            v-model="item.comparison"
            :items="productComparison"
            label="Comparison"
            item-text="value"
            @change="itemChangedToReflex(index)"
          >
          </v-select>
        </v-col>

        <template v-if="item.comparison == 'REFLEX'">
          <v-col cols="3">
            <v-autocomplete
              v-model="item.products[0]"
              :items="comparators"
              label="Comparator"
              item-text="name"
              chips
              :error-messages="item.errorMessages.comparators[0]"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="item.products[1]"
              :items="comparators"
              label="Reflex Comparator"
              item-text="name"
              chips
              :error-messages="item.errorMessages.comparators[1]"
              return-object
            >
            </v-autocomplete>
          </v-col>
        </template>

        <template v-else>
          <v-col cols="6">
            <v-autocomplete
              v-model="item.products"
              :items="comparators"
              label="Comparator"
              item-text="name"
              chips
              clearable
              deletable-chips
              multiple
              :error-messages="item.errorMessages.comparators[0]"
              return-object
            >
            </v-autocomplete>
          </v-col>
        </template>
          
        <v-divider vertical></v-divider>
        <v-col cols="1">
          <v-icon @click="removeRuleEntry(index)">mdi-trash-can-outline</v-icon>
        </v-col>
      </v-row>

      <v-row style="justify-content: center">
        <v-btn block outlined class="ma-2" color="primary"  @click="addRuleEntry()">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-row>

      <v-row style="justify-content: center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" class="mx-1">Save</v-btn>
          </template>
          <v-list>
            <v-list-item  v-if="userPermissions.canUpdateDelete"><v-list-item-title @click="createOrUpdate('SAVE', ruleName)">Save</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-title @click="createOrUpdate('SAVE AS', null)">Save As</v-list-item-title></v-list-item>
          </v-list>
        </v-menu>
        <!-- TODO: send a response back to close this component or revert changes -->
        <v-btn medium color="primary" class="mx-1" @click="modifyBackendMergedForUI()">Revert</v-btn>
        <v-btn medium color="primary" class="mx-1" v-if="userPermissions.canUpdateDelete && selectedRule?.id" @click="deleteData()">Delete</v-btn>
      </v-row>

      <!-- TODO: dialog to enter run name -->
      <!-- TODO: handle successes and errors -->
       <v-dialog v-model="showNewRuleDialog">
        <v-card>
          <v-card-title> Composite Rule-Set </v-card-title>
            <!-- Filter/Email Card -->
            <v-card-text v-if="!createNewRuleSelected">
              <v-form
                  ref="form"
                  v-model="validForm"
                  @submit.prevent="createOrUpdate('SAVE AS', ruleName)"
                  lazy-validation>
              <v-text-field
                  v-if="!createNewRuleSelected"
                  v-model="ruleName"
                  :rules="[rules.required]"
                  label="Composite Rule Name"
                  required
              ></v-text-field>
              </v-form>
          </v-card-text>
          <!-- Success Card -->
          <v-card-text v-if="createNewRuleSelected && saveErrorMsg===''">
              <v-row>
                  <v-col  class="d-flex justify-center align-center">
                      <v-icon color="green" size="200">mdi-check-circle-outline</v-icon>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col  class="d-flex justify-center align-center">
                      <p>Your composite rule-set was successfully saved!</p>
                  </v-col>
              </v-row>
          </v-card-text>

          <!-- Error Card -->
          <v-card-text v-if="createNewRuleSelected && saveErrorMsg!==''">
              <v-row>
                  <v-col  class="d-flex justify-center align-center">
                      <v-icon color="red" size="200">mdi-alpha-x-circle-outline</v-icon>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col  class="d-flex justify-center align-center">
                      <p>An error occured: {{ saveErrorMsg }}</p>
                  </v-col>
              </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="!createNewRuleSelected" color="primary" @click="createOrUpdate('SAVE AS', ruleName)">Save</v-btn>
              <v-btn v-if="createNewRuleSelected && !disableBack && saveErrorMsg!==''" color="primary" @click="createNewRuleSelected=false">Back</v-btn>
              <v-btn color="primary" @click="showNewRuleDialog=false">Close</v-btn>
          </v-card-actions>
        </v-card>
       </v-dialog>
    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IProductComparisonRulesMerged, IProductComparisonRule, IProduct, ICallout} from '@/interfaces/bcdb';  
  
  @Component({
    components: {
    },
  })
  export default class CompositeReflexRules extends Vue {
    @Prop({default:()=>''}) public selectedProduct!: string

    //---UI Form items---
    public internalProducts: IProduct[] = []
    public callouts: ICallout[] = []
    public comparators: IProduct[] = []
    public includeOthers: boolean = false
    public ruleSets: IProductComparisonRule[] = []
    public selectedRule: IProductComparisonRule|null = null
    public shareStatus = ['PUBLIC','PRIVATE','LOCKED']
    public shareStatusError = ''
    public productComparison = ['MAJORITY','REFLEX', 'ALL MATCH', 'ANY MATCH']
    public uiComparisonRules: {
      product_comparison_rule_id:number|null,
      callout: ICallout|null,
      products: IProduct[],
      comparison: string,
      errorMessages:{callout:string, comparators:string[]}
    }[] = []

    //---Backend Response Obj---
    public productComparisonRule: IProductComparisonRulesMerged = {} as IProductComparisonRulesMerged

    //---User Permissions---
    public userPermissions = {isSuperUser:false, canUpdateDelete:false, userID:0}

    //---Rule dialog items---
    public showNewRuleDialog = false
    public createNewRuleSelected = false
    public validForm = false
    public disableBack = false //back is only for save as
    public rules= {
        required: (v) => !!v || v=== 0 || 'Value required'
    }
    public ruleName = ''
    public saveErrorMsg = ''


    public getUserPermissions(){
      const userProfile = readUserProfile(this.$store);
      if( userProfile ){
        this.userPermissions.isSuperUser = userProfile.is_superuser

        if( userProfile.is_superuser ){
          this.userPermissions.canUpdateDelete = true
        } else {
          this.userPermissions.canUpdateDelete = this.selectedRule?.share_status!='LOCKED' && userProfile.id == this.selectedRule?.created_by_id
        }
        this.userPermissions.userID = userProfile.id
      } else {
        this.userPermissions = {isSuperUser:false, canUpdateDelete:false, userID:0}
      }
    }

    public validateForm():boolean{
      const duplicateCalloutMsg = 'Callouts must be distinct accross a rule set.'
      const duplicateComparatortMsg = 'Comparators must be distinct for REFLEX.'
      const missingReflexMsg = 'REFLEX MUST include 2 comparators.'
      const missingComparatorMsg = 'Must select at least 1 comparator.'
      const missingCalloutMsg = 'Must select 1 callout'
      const unableToLockMsg = 'Locking is only available to super users. Please contact an admin.'

      let valid = true

      //Clear form
      this.uiComparisonRules.forEach((rule, index) => {
        this.uiComparisonRules[index].errorMessages.callout=''
        this.uiComparisonRules[index].errorMessages.comparators=[]
      })

      const calloutMap = new Map<number, number>();
      this.uiComparisonRules.forEach((rule, index) => {
        //Validate unique callouts
        if (rule.callout?.id != null) {
          const updatedEntry = calloutMap.get(rule.callout.id);
          if( updatedEntry!== undefined){
            this.uiComparisonRules[index].errorMessages.callout = duplicateCalloutMsg
            this.uiComparisonRules[updatedEntry].errorMessages.callout = duplicateCalloutMsg
            valid = false
          } else {
            calloutMap.set(rule.callout.id, index);
          }
        } else if (rule.callout === null || rule.callout === undefined) {
          this.uiComparisonRules[index].errorMessages.callout = missingCalloutMsg
          valid = false
        }
        
        //Validate distinct reflex comparators AND 2 selected
        if( rule.comparison == 'REFLEX' ){
          if( rule.products.length !==2 ){
            this.uiComparisonRules[index].errorMessages.comparators = [missingReflexMsg, missingReflexMsg]
            valid = false
          }else if( rule.products[0]===null || rule.products[1]===null ){
            this.uiComparisonRules[index].errorMessages.comparators = [missingReflexMsg, missingReflexMsg]
            valid = false
          } else if( rule.products.length === 2 && rule.products[0]===rule.products[1]){
            this.uiComparisonRules[index].errorMessages.comparators = [duplicateComparatortMsg, duplicateComparatortMsg]
            valid = false
          }
        } else{
          //Validate a comparator is selected
          if( rule.products.length===0 ){
            this.uiComparisonRules[index].errorMessages.comparators = [missingComparatorMsg]
            valid = false
          }
        }

      });

      this.shareStatusError = ''
      if( this.productComparisonRule.share_status === 'LOCKED'){
        this.shareStatusError = this.userPermissions.isSuperUser ? '': unableToLockMsg
        valid = this.shareStatusError==='' && valid
      }
      return(valid)
    }

    public async deleteData(){
      if( this.selectedRule ){
        await api.deleteProductComparison(readToken(this.$store), this.selectedRule.id).then((response) => {
          this.saveErrorMsg = ''
          this.ruleName = ''
          this.fetchComparisonRules()
          this.$emit('compositeRuleUpdate')
        }).catch((error) => {
          this.showNewRuleDialog=true
          this.createNewRuleSelected=true
          if( error.response && error.response.data ){
            this.saveErrorMsg=error.response.data.detail
          } else{
            this.saveErrorMsg=error
          }
          this.disableBack=true
        })
      }
    }

    public async saveData(rule:IProductComparisonRulesMerged){
      await api.createUpdateProductComparison(readToken(this.$store), rule).then((response) => {
          this.ruleName = response.data[0].name
          this.saveErrorMsg = ''
          this.fetchComparisonRules()
          this.$emit('compositeRuleUpdate')
        }).catch((error) => {
          this.showNewRuleDialog=true
          this.createNewRuleSelected=true
          if( error.response.data ){
              this.saveErrorMsg=error.response.data.detail
            } else{
              this.saveErrorMsg=error
            }
        });
    }

    public async createOrUpdate(state:string, ruleName:string|null=null){
      const validForm = this.validateForm()
      if( state==='SAVE' && validForm && this.selectedRule){
        //Updating and existing rule set
        const updatedrule = this.revertUiObjToBackendMerged(this.selectedRule?.id, this.selectedRule.name)
        await this.saveData(updatedrule)
        if( this.saveErrorMsg!=='' ){
          //allow dialog to show the error
          this.disableBack = true
          this.createNewRuleSelected = true 
        }
      } else if ( state==='SAVE AS' && ruleName==null && validForm){
        //Creating a new rule set. Still need to define the new name
        this.disableBack = false
        this.showNewRuleDialog=true
        this.createNewRuleSelected=false
        this.saveErrorMsg=''
      } else if( 'SAVE AS' && ruleName!==null && validForm){
        //Creating a new rule set
        if ((this.$refs.form as any).validate()) {
          const newRule = this.revertUiObjToBackendMerged(null, this.ruleName)
          await this.saveData(newRule)
          this.createNewRuleSelected = true
        }
      }
    }

    public addRuleEntry(){
      this.uiComparisonRules.push(
        {
          product_comparison_rule_id: this.selectedRule==null ? null: this.selectedRule.id,
          callout: null,
          products: [],
          comparison: this.productComparison[0],
          errorMessages: {callout:'', comparators:[]}
        }
      )
    }

    public removeRuleEntry(index:number){
      this.uiComparisonRules.splice(index, 1)
    }

    public itemChangedToReflex(index:number){
      if( index < this.uiComparisonRules.length && this.uiComparisonRules[index].comparison == 'REFLEX'){
        if( this.uiComparisonRules[index].products.length >= 2 ){
          this.uiComparisonRules[index].products = this.uiComparisonRules[index].products.slice(0,2)
        } else {
          this.uiComparisonRules[index].products = [this.comparators[0], this.comparators[1]]
        }
      }
    }

    public async fetchProductSpecificItems(){
      //Get callouts for selected product
      await api.getCalloutMapByProduct(readToken(this.$store), this.selectedProduct).then((response) => {
        this.callouts = response.data
      }).catch((error) => {
          console.log(error)
          this.callouts = []
      });
      await this.fetchComparisonRules()
    }

    public async fetchComparisonRules(){
      //Get rules for select product
      await api.getDistinctProductComparisonRules(readToken(this.$store), this.selectedProduct, this.includeOthers).then((response) => {
          this.ruleSets = response.data
          if( this.ruleName !== ''){
            const foundDefault = this.ruleSets.find((r)=>r.name==this.ruleName)
            this.selectedRule = foundDefault?foundDefault:null
          }
      }).catch((error) => {
          console.log(error)
          this.ruleSets = []
      });
      //Default select a rule set
      if( (this.selectedRule == null || !this.ruleSets.map((r)=>r.name).includes(this.selectedRule.name) ) && this.ruleSets.length){
        this.selectedRule = this.ruleSets[0]
      } else if (this.ruleSets.length == 0) {
        this.selectedRule = {} as IProductComparisonRule
      }
      await this.fetchMergedComparionRules()
    }

    public async fetchMergedComparionRules(){
      this.getUserPermissions()
      if( this.selectedRule ){
        await api.getProductComparisonByID(readToken(this.$store), this.selectedRule.id).then((response) => {
            if(response.data){
              this.productComparisonRule = response.data
            } else {
              this.productComparisonRule = {} as IProductComparisonRulesMerged
            }
        }).catch((error) => {
            console.log(error)
            this.productComparisonRule = {} as IProductComparisonRulesMerged
        });
      }
      this.modifyBackendMergedForUI()
    }

    public modifyBackendMergedForUI(){
      //REFLEX requires us to make modifications to the object since order matters only for that one
      this.uiComparisonRules = []
      if( !this.productComparisonRule.product_callout_comparisons ){
        return
      }
      this.productComparisonRule.product_callout_comparisons.forEach((p) =>{
        if ( p.comparison == 'REFLEX' ){
          let reflexProduct = p.products.find((p2)=>p2.is_reflex_product)?.product
          reflexProduct = reflexProduct===undefined?this.comparators[0]:reflexProduct
          let nonreflexProduct = p.products.find((p2)=>!p2.is_reflex_product)?.product
          nonreflexProduct = nonreflexProduct===undefined?this.comparators[1]:nonreflexProduct
          
          this.uiComparisonRules.push(
            {
              product_comparison_rule_id: p.product_comparison_rule_id,
              callout: p.callout,
              products: [nonreflexProduct, reflexProduct],
              comparison: p.comparison,
              errorMessages:{callout:'', comparators:[]}
            }
          )
        } else{
          this.uiComparisonRules.push(
            {
              product_comparison_rule_id: p.product_comparison_rule_id,
              callout: p.callout,
              products: p.products.map((p2)=>p2.product),
              comparison: p.comparison,
              errorMessages:{callout:'', comparators:[]}
            }
          )
        }
      })
    }

    public revertUiObjToBackendMerged(ruleId:number|null, ruleName:string):IProductComparisonRulesMerged{
      let duplicatedMergedRule = this.productComparisonRule
      duplicatedMergedRule.product_callout_comparisons = []
      duplicatedMergedRule.id = ruleId
      duplicatedMergedRule.name = ruleName
      duplicatedMergedRule.created_by_id = this.userPermissions.userID
      duplicatedMergedRule.share_status = this.productComparisonRule.share_status===undefined?'PRIVATE':this.productComparisonRule.share_status
      const currentProduct = this.internalProducts.find((p)=> p.name===this.selectedProduct)
      if( currentProduct ){
        duplicatedMergedRule.product = currentProduct
      }

      this.uiComparisonRules.forEach((p) =>{
        if ( p.comparison == 'REFLEX' ){
          if( p.callout ){
            duplicatedMergedRule.product_callout_comparisons.push(
              {
                product_comparison_rule_id: ruleId,
                callout: p.callout,
                products: [{is_reflex_product:false, product:p.products[0]},
                  {is_reflex_product:true, product:p.products[1]}
                ],
                comparison: p.comparison
              }
            )
          }
        } else{
          if( p.callout ){
            duplicatedMergedRule.product_callout_comparisons.push(
              {
                product_comparison_rule_id: ruleId,
                callout: p.callout,
                products: p.products.map((p2) => ({ is_reflex_product: null, product: p2 })),
                comparison: p.comparison,
              }
            )
          }
        }
      })
      return(duplicatedMergedRule)
    }

    public async mounted(){
      //Get internal products
      await api.getInternalProducts(readToken(this.$store), true).then((response) => {
          this.internalProducts = response.data
      }).catch((error) => {
          console.log(error)
          this.internalProducts = []
      });
      //Get external products
      await api.getInternalProducts(readToken(this.$store), false).then((response) => {
          this.comparators = response.data
      }).catch((error) => {
          console.log(error)
          this.comparators = []
      });
      await this.fetchProductSpecificItems()
    }
  }

</script>
  
<style scoped>

</style>