<template>
    <v-card>
      <v-card-title>
        <v-container>
          <v-row class="settings-text" >
            <v-col>
              <div class="title-font">General Settings</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="description-text">
                <ul>
                <li>General Settings are default rules that apply to all probes without a custom setting.</li>
                <li>General Settings include the replicate reduction method as well as quantification and identification thresholds.</li>
                <li>Normalization, HybC1 and HybC2 Probe settings are used for inspection (i.e., making valid or invalid calls).</li>
                <li>Target, Discrimination and Depletion Probe settings are used for panel level call outs.</li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-container>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                Replicate Reduction:
              </v-col>
            </v-row>
            <v-row class="row1" no-gutters>
              <v-col cols="3">
                <p>Define replicate reduction setting. This is common to all probes, and is applied to all replicates.</p>
              </v-col>
              <v-col cols="3">
              <v-select class="reduction-list"
                v-model="baseRules.rr_method"
                :items="reductionList"
                dense
              ></v-select>
            </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                Normalization Probe:
              </v-col>
            </v-row>
            <v-row class="row1" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define NP settings to make a VALID call.</p>
              </v-col>
              <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_sbr"></v-simple-checkbox>
                <v-text-field v-model="baseRules.sbr_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; SBR</p>
              </v-col>
              <v-col cols="2.25" class="row1">
              <v-simple-checkbox v-model="baseRules.use_amplitude"></v-simple-checkbox>
              <v-text-field v-model="baseRules.amplitude_th_low" type="number" :rules="numberRules" required></v-text-field>
              <p>&lE; Amplitude</p>
              </v-col>
              <v-col cols="2.25" class="row1">
              <v-simple-checkbox v-model="baseRules.use_stability"></v-simple-checkbox>
              <v-text-field v-model="baseRules.stability_th_low" type="number" :rules="numberRules" required></v-text-field>
              <p>&lE; Stability</p>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                HybC1 Probe:
              </v-col>
            </v-row>
            <v-row class="row1" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define HybC1 settings to make a VALID call.</p>
              </v-col>
              <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc1_pq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc1_pq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Pq &lE;</p>
                <v-text-field v-model="baseRules.hybc1_pq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
                <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc1_aq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc1_aq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Aq &lE;</p>
                <v-text-field v-model="baseRules.hybc1_aq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc1_tm"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc1_tm_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Tm &lE;</p>
                <v-text-field v-model="baseRules.hybc1_tm_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                HybC2 Probe:
              </v-col>
            </v-row>
            <v-row class="row1" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define HybC2 settings to make a VALID call.</p>
              </v-col>
              <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc2_pq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc2_pq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Pq &lE;</p>
                <v-text-field v-model="baseRules.hybc2_pq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
                <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc2_aq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc2_aq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Aq &lE;</p>
                <v-text-field v-model="baseRules.hybc2_aq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1">
                <v-simple-checkbox v-model="baseRules.use_hybc2_tm"></v-simple-checkbox>
                <v-text-field v-model="baseRules.hybc2_tm_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Tm &lE;</p>
                <v-text-field v-model="baseRules.hybc2_tm_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                Target Probes:
              </v-col>
            </v-row>
            <v-row class="row2" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define target probes settings to make a POSITIVE call.</p>
              </v-col>
              <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_pq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.pq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Pq &lE;</p>
                <v-text-field v-model="baseRules.pq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
                <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_aq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.aq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Aq &lE;</p>
                <v-text-field v-model="baseRules.aq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_tm"></v-simple-checkbox>
                <v-text-field v-model="baseRules.tm_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Tm &lE;</p>
                <v-text-field v-model="baseRules.tm_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                Discrimination Probes:
              </v-col>
            </v-row>
            <v-row class="row2" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define discrimination probes settings to make a POSITIVE call.</p>
              </v-col>
              <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_dpq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.dpq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; ΔPq &lE;</p>
                <v-text-field v-model="baseRules.dpq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
                <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_daq"></v-simple-checkbox>
                <v-text-field v-model="baseRules.daq_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; ΔAq &lE;</p>
                <v-text-field v-model="baseRules.daq_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row2">
                <v-simple-checkbox v-model="baseRules.use_dtm"></v-simple-checkbox>
                <v-text-field v-model="baseRules.dtm_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; ΔTm &lE;</p>
                <v-text-field v-model="baseRules.dtm_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="row-with-text">
              <v-col cols="12" class="reduction-header">
                Depletion Probes:
              </v-col>
            </v-row>
            <v-row class="row3" no-gutters>
              <v-col cols="3" class="row1">
                <p>Define depletion probes settings to make a POSITIVE call.</p>
              </v-col>
              <v-col cols="2.25" class="row1"></v-col>
              <v-col cols="2.25" class="row1"></v-col>
              <v-col cols="2.25" class="row1"></v-col>
              <v-col cols="2.25" class="row3">
                <v-simple-checkbox v-model="baseRules.use_ct"></v-simple-checkbox>
                <v-text-field v-model="baseRules.ct_th_low" type="number" :rules="numberRules" required></v-text-field>
                <p>&lE; Ct &lE;</p>
                <v-text-field v-model="baseRules.ct_th_high" type="number" :rules="numberRules" required></v-text-field>
              </v-col>
            </v-row>
            <v-row class="row4">
              <v-btn color="primary" :disabled="disable" v-on:click="updateTargets">Apply</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import {IProbeRule} from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

@Component
export default class RulesDefaultSelection extends Vue {
  @ModelSync('value', 'change') public baseRules!: IProbeRule;
  @Prop() public readonly disable!: boolean;
  // public baseRules: IProbeRule = this.baseRules; 
  public minValue: number = 0;
  public maxValue: number = 100;
  public reductionList = ['mean','median','max'];
  public validForm: boolean = false;
  public numberRules =  [(v) => !!v || v=== 0 || 'Value required'];

  public updateTargets() {
    if(this.validForm) {
      this.$emit('update-defaults');
    }
  }

  public mounted() {
    // this.baseRules = this.baseRules;
  }
}
</script>

<style scoped>
.v-divider {
  margin-bottom: 10px;
}
.v-text-field {
  font-size: small;
  max-width: 40px;
}
.row1, .row2, .row3, .row4 {
  display: flex;
  align-items: center;
  line-height: 1em;
}
.row1 > *,
.row2 > *,
.row3 > *,
.row4 > * {
  margin-right: 25px;
  padding: 0px;
  margin: 0px;
  font-size:small
}
.row4 {
  justify-content: center;
}
.row-with-text v-col {
  padding-left: 8px;
}
.reduction-list {
  font-size: 15px;
  max-width: 150px;
  margin-left: 15px;
  margin-top: 15px;
}
.description-text {
  margin-top: 0px;
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}
.reduction-header{
  font-weight: bold;
}

.title-font{
  font-size: medium;
  font-weight: bold;
}

.reduction-header, .settings-text, .description-text {
  line-height: 1em;
}
</style>