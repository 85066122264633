import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { ICallout, ICalloutExtended,
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getCallouts(token: string) {
    return axios.get<ICallout[]>(`${apiUrl}/api/v1/callouts/`, authHeaders(token));
  },
  async getCallout(token: string, id: number) {
    return axios.get<ICallout>(`${apiUrl}/api/v1/callouts/${id}`, authHeaders(token));
  },
  async updateCallout(token: string, id: number, data: ICallout) {
    return axios.put(`${apiUrl}/api/v1/callouts/${id}`, data, authHeaders(token));
  },
  async createCallout(token: string, data: ICallout) {
    return axios.post(`${apiUrl}/api/v1/callouts/`, data, authHeaders(token));
  },
  async deleteCallout(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/callouts/${id}`, authHeaders(token));
  },
  async getCalloutFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/callouts/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },
  async getCalloutMapByProduct(token: string, product: string) {
    return axios.get<ICalloutExtended[]>(`${apiUrl}/api/v1/callouts/product/${product}`, authHeaders(token));
  },
};
