<template>
  <!-- All Wafers -->

  <v-data-table
    :headers="headers"
    :items="wafers"
    item-key="id"
    :dense="true"
    class="elevation-1"
    no-data-text="No Wafers"
    show-expand
    :server-items-length="waferCount"
    v-on:update:options="updateOptions"
    v-on:click:row="rowClicked"
    :page="options.page"
    :sort-by="options.sortBy"
    :sort-desc="options.sortDesc"
    :footer-props="{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [
        5, 10, 25, 50, 100
      ],
    }"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-container dense fluid>
          <v-row dense>
            <v-col><span class='step-heading'>Electrical Inspection:</span></v-col>
          </v-row>
          <v-row v-if="item.process_steps.length > 0" class='step-detail-row' dense>
            <v-col>
              <span class='step-sub-heading'>Start:</span>
              <span class='step-text'>{{ item.process_steps[0].process_start.formatDateTime() }}</span>
            </v-col>
            <v-col>
              <span class='step-sub-heading'>End:</span>
              <span class='step-text'>{{ item.process_steps[0].process_end.formatDateTime() }}</span>
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Site #:</span>
              <span class='step-text'>{{ item.process_steps[0].site_number }}</span>
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Fixture ID:</span>
              <span class='step-text'>{{ item.process_steps[0].fixture_id }}</span>
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Fixture #:</span>
              <span class='step-text'>{{ item.process_steps[0].fixture_number }}</span>
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Plate #:</span>
              <span class='step-text'>{{ item.process_steps[0].plate_number }}</span>
            </v-col>
          </v-row>
          <v-row v-if="item.process_steps.length > 0" class='step-detail-row' dense>
            <v-col>
              <span class='step-sub-heading'>Operator:</span>
              {{ item.process_steps[0].operator }}
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Inspector:</span>
              {{ item.process_steps[0].inspector }}
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Equipment ID:</span>
              {{ item.process_steps[0].equipment_id }}
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Equipment Cal Date:</span>
              {{ item.process_steps[0].equipment_calibration_date?.formatDateTime() }}
            </v-col>
            <v-col>
              <span class='step-sub-heading'>Equipment Cal Status:</span> 
              {{ item.process_steps[0].equipment_calibration_status }}
            </v-col>
            <v-col>
              <span class='step-sub-heading'>SW Version:</span>
              {{ item.process_steps[0].sw_version }}
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>

    <template v-slot:item.ee_yield="{ item }">
      {{ item.ee_yield+"%" }}
    </template>
    <template v-slot:item.created_on="{ value }">
      {{ value.formatDateTime(true) }}
    </template>
  </v-data-table>

</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IFilter, ITableOptions } from '@/interfaces';
import { IWafer, IWaferWithCounts, IDie, IProcessStep } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';


@Component
export default class WaferList extends Vue {
  @Prop() public readonly filters!: IFilter[];

  public expanded = []
  public headers: object[] = [
        { text: 'Lot', value: 'lot_code', cellClass: 'col-lot', class: '' },
        { text: 'Wafer Number', value: 'identifier', cellClass: 'col-identifier', class: '', groupable: false },
        { text: 'Fab', value: 'fab_code', cellClass: 'col-fab', class: '' },
        { text: 'Gross', value: 'gross', cellClass: 'col-gross-dies', class: 'col-elec-insp', sortable: false },
        { text: 'EI Yield', value: 'ee_yield', cellClass: 'col-yield', class: 'col-elec-insp', sortable: false },
        { text: 'Date Added', value: 'created_on', cellClass: 'col-date' },
      ];
  public wafers: IWaferWithCounts[] = [];
  public waferCount: number = 0;
  public options: ITableOptions = {
    page: 1,
    perPage: 10,
    sortBy: 'created_on',
    sortDesc: true,
  };

  @Watch('filters')
  public async onFiltersChanged(val: IFilter[], oldVal: IFilter[]) {
    this.options.page = 1;
    this.updateView();
  }

  public updateOptions(options) {
    this.options.page = options.page || 1;
    this.options.perPage = options.itemsPerPage || 10;
    this.options.sortBy = options.sortBy[0] || 'created_on';
    this.options.sortDesc = options.sortBy.length > 0 ? options.sortDesc[0] : true;
    this.updateView();
  }

  public async updateView() {
    this.getWafersCount()
    this.getWafers()
  }

  public rowClicked(row, slot) {
    this.$router.push('/main/wafers/'+row.id);
}

  public mounted() {
  }

  public getWafersCount() {
    api.getWafersCount(readToken(this.$store), this.filters || [])
      .then((response) => {
        this.waferCount = response.data;
      })
        .catch((error) => {
          console.log(error)
      })
}

  public getWafers() {
    api.searchWafers(readToken(this.$store), {filters: this.filters , ...this.options})
      .then((response) => {
        // console.log('wafers', response.data)
        this.wafers = response.data
      })
      .catch((error) => {
          console.log(error)
      })
  }

  @Socket('new-wafer')
  public onNewWafer(wafer) {
    this.updateView();
  }

}
</script>

<style scoped>
.v-data-table >>> .col-identifier {
  min-width: 8em;
}
.v-data-table >>> .col-fab {
  min-width: 8em;
}
.v-data-table >>> .col-lot {
  min-width: 8em;
}
.v-data-table >>> .col-date {
  min-width: 14em;
}
.v-data-table >>> .col-gross-dies {
  background-color: #DDFFFF;
}
.v-data-table >>> .col-yield {
  background-color: #DDFFFF;
}
.v-data-table >>> .col-elec-insp {
  background-color: #DDFFFF;
}
.step-heading {
  text-transform: uppercase;
}
.step-sub-heading {
/*  text-decoration: underline;*/
  font-size: small;
  color: gray;
}
.step-text {
  font-size: small;
  padding: 5px;
}
.step-detail-row {
  padding-left: 10px;
}
</style>
