import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { IArrayPrintRun, IArrayPrintRunCreate
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getArrayPrintRun(token: string, arrayPrintRunId: number) {
    return axios.get<IArrayPrintRun>(`${apiUrl}/api/v1/array_print_runs/${arrayPrintRunId}`, authHeaders(token));
  },
  async getArrayPrintRuns(token: string) {
    return axios.get<IArrayPrintRun[]>(`${apiUrl}/api/v1/array_print_runs/`, authHeaders(token));
  },
  async searchArrayPrintRuns(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    /*if (params.filters.length > 0) {
      for (const filter of params.filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`)
      }
    }*/
    return axios.post<IArrayPrintRun[]>(`${apiUrl}/api/v1/array_print_runs/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getArrayPrintRunsCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/array_print_runs/stats`, filters, authHeaders(token));
  },
  async getArrayPrintRunFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/array_print_runs/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },
  async getLatestArrayPrintRuns(token: string, daysAgo: number = 1) {
    return axios.get<IArrayPrintRun[]>(`${apiUrl}/api/v1/array_print_runs/latest?days=${daysAgo}`, authHeaders(token));
  },

  async getArrayPrintRunSummary(token: string, payload: {
    arrayPrintRuns: number[], format: string,
    groupBy: string,
    extraData?: object[],
  }) {
    // console.log(payload);
    const arrayPrintRuns = payload.arrayPrintRuns.join('&t=');
    const { headers } = authHeaders(token);
    let responseFormat;

    const data = {
       extra_data: payload.extraData || [],
    };

    if (payload.format === 'EXCEL') {
      responseFormat = 'blob';
    }
    const responseType = responseFormat || 'text';
    return axios.post(
      `${apiUrl}/api/v1/array_print_runs/summary?out_format=${payload.format}&t=${arrayPrintRuns}&group_by=${payload.groupBy}`,
      data,
      {responseType, headers},
    );
  },
  async createArrayPrintRun(token: string, data: IArrayPrintRunCreate) {
    return axios.post(`${apiUrl}/api/v1/array_print_runs/`, data, authHeaders(token));
  },
  async updateArrayPrintRun(token: string, id: number, data: IArrayPrintRunCreate) {
    return axios.put(`${apiUrl}/api/v1/array_print_runs/${id}`, data, authHeaders(token));
  },
};
