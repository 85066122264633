<template>
  <v-container fluid>

    <v-card v-if="run"  class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{run.experiment}}</div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row dense>
            <v-col cols="1"><div class="text-right">Experiment:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.experiment}}</div></v-col>
            <v-col cols="1"><div class="text-right">Date of Upload:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.date_added}}</div></v-col>
            <v-col cols="1"><div class="text-right">Date of Run:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.date_of_run}}</div></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1"><div class="text-right">Rig:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.instrument!=undefined?run.instrument.rig:''}}</div></v-col>
            <v-col cols="1"><div class="text-right">Experiment Type:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.experiment_type}}</div></v-col>
            <v-col cols="1"><div class="text-right">Print Run:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.print_run!=undefined?run.print_run.name:''}}</div></v-col>
          </v-row>
          <v-row dense>          
            <v-col cols="1"><div class="text-right">Wafer Lot:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.lot_code}}</div></v-col>
            <v-col cols="1"><div class="text-right">Wafer ID:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.identifier}}</div></v-col>
            <v-col cols="1"><div class="text-right">Die Number:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.die_number}}</div></v-col>  
          </v-row>
          <v-row dense>
            <v-col cols="1"><div class="text-right">Array Template:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.array_template!=undefined?run.array_template.name:''}}</div></v-col>
            <v-col cols="1"><div class="text-right">Cartridge Serial Number:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.serial_number}}</div></v-col>
            <v-col cols="1"><div class="text-right">FW Version:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.fw_version}}</div></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1"><div class="text-right">Comments:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.comments}}</div></v-col>
            <v-col cols="1"><div class="text-right">Description:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.run_description}}</div></v-col>
            <v-col cols="1"><div class="text-right">Analysis QC:</div></v-col>
            <v-col cols="3"><div class="font-weight-bold">{{run.analysis_qc}}</div></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1"><div class="text-right">Barcode:</div></v-col>
            <v-col cols="11"><div class="font-weight-bold">{{run.barcode}}</div></v-col>
          </v-row>
        </div>

      </v-card-text>
    </v-card>
    <v-card v-if="run"  class="ma-3 pa-3">
      <v-card-text>

        <v-tabs horizontal>
          <v-tab>Replicates</v-tab>
          <v-tab>Instrument</v-tab>
          <v-tab v-if="selectedRuns.length > 0">Results</v-tab>

          <!-- Replicates tab -->
          <v-tab-item eager>
            <v-card flat>
              <v-card-text>

                <v-tabs vertical>
                  <v-tab v-for="(probeType) in uniqueProbeType" :key="probeType">
                    {{ probeType}}
                  </v-tab>
                  <v-tab>Signals</v-tab>
                  <!-- Ampfea graphs -->
                  <v-tab-item eager v-for="(probeType, idx) in uniqueProbeType" :key="idx">
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <v-row>
                            <v-col>
                              <v-select
                                label="Metric"
                                :items="ampfeaMetricList[idx]"
                                v-model="ampfeaSelectedMetric[idx]"
                                dense
                                v-on:change="selectAmpfeaTargetChange(idx)"
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                label="Probe"
                                :items="ampfeaProbeList[idx]"
                                v-model="ampfeaSelectedProbe[idx]"
                                dense
                                v-on:change="selectAmpfeaTargetChange(idx)"
                                multiple
                                clearable
                              >
                                <v-list
                                  slot="prepend-item"
                                  v-if="ampfeaProbeList[idx].length"
                                  style="padding: 0;"
                                >
                                <v-list-item 
                                  ripple
                                  @click="selectAllAmpfea(idx)">
                                  <v-list-item-icon>
                                    <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>Select All</v-list-item-content>
                                </v-list-item>
                                </v-list>

                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ ampfeaSelectedProbe[idx].length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- <v-skeleton-loader
                          v-if="loadingAmpfea[idx]"
                          class="mx-auto"
                          height="500"
                          type="card, image"
                        ></v-skeleton-loader> -->
                        <div :id="'ampfeaGraph' + probeType.replace(/ /g, '')"></div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item> 
                  <!-- Signals graph -->
                  <v-tab-item eager>
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <v-row>
                            <v-col>
                              <v-select
                                label="Metric"
                                :items="signalMetricList"
                                v-model="signalSelectedMetric"
                                dense
                                v-on:change="selectSignalTargetChange(...arguments)"
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                label="Probe"
                                :items="signalProbeList"
                                v-model="signalSelectedProbe"
                                dense
                                v-on:change="selectSignalTargetChange(...arguments)"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <v-skeleton-loader v-if="loadingSignals" type="graph" :height="400" />
                        <div id="signalsGraph"></div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>

                <StackedSnacks
                  v-model="errorMSGAmpfea">
                </StackedSnacks>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Instrument Tab -->
          <v-tab-item eager>
            <v-card flat>
              <v-card-text>
                <v-tabs vertical>
                  <v-tab>Thermal</v-tab>
                  <v-tab>Optics</v-tab>
                  <v-tab>Pressure</v-tab>
                  
                  <!-- Thermal -->
                  <v-tab-item eager>
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <v-row>
                            <v-col>
                              <v-select
                                label="Metric Left"
                                :items="thermalSignalMetricList"
                                v-model="thermalSignalSelectedMetric[0]"
                                dense
                                multiple
                                clearable
                                v-on:change="selectThermalSignalsTargetChange(...arguments)"
                              >
                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ thermalSignalSelectedMetric[0].length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                label="Metric Right"
                                :items="thermalSignalMetricList"
                                v-model="thermalSignalSelectedMetric[1]"
                                dense
                                multiple
                                clearable
                                v-on:change="selectThermalSignalsTargetChange(...arguments)"
                              >
                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ thermalSignalSelectedMetric[1].length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div id="thermalGraph"></div>

                        <div>
                          <v-row>
                            <v-col>
                              <v-select
                                label="Metric"
                                :items="transitionMetricList"
                                v-model="transitionsSelectedMetric"
                                dense
                                multiple
                                clearable
                                v-on:change="selectTransitionsTargetChange(...arguments)"
                              >
                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ transitionsSelectedMetric.length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div id="transitionGraph"></div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  
                  <!-- Optics -->
                  <v-tab-item eager>
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <v-row>
                            <v-col>
                              <v-select
                                label="Metric Left"
                                :items="opticsMetricList"
                                v-model="opticsSelectedMetric[0]"
                                dense
                                multiple
                                clearable
                                v-on:change="selectOpticsTargetChange(...arguments)"
                              >
                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ opticsSelectedMetric[0].length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                label="Metric Right"
                                :items="opticsMetricList"
                                v-model="opticsSelectedMetric[1]"
                                dense
                                multiple
                                clearable
                                v-on:change="selectOpticsTargetChange(...arguments)"
                              >
                                <template v-slot:selection="{ attrs, parent, item, index }">
                                  <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ opticsSelectedMetric[1].length - 1 }} others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <div id="opticsGraph"></div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- Pressure -->
                  <v-tab-item eager>
                    <v-card flat>
                      <v-card-text>
                        <div id="pressureGraph">
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
                <StackedSnacks
                  v-model="errorMSGInst">
                </StackedSnacks>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Results tab -->
          <v-tab-item eager v-if="selectedRuns.length > 0">
            <v-card flat>
              <v-card-text>
                    <v-card flat>
                      <v-card-text>
                        <div id="results"></div>
                        <RulesTable
                          v-model="selectedRuns"
                          v-if="selectedRuns.length > 0">
                        </RulesTable>
                      </v-card-text>
                    </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs>  
        
      </v-card-text>
    </v-card>
    
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IRunTracker, IRunTrackerExtended, ISignal, ISignalWithProbe, IAmpfea, IAmpfeaWithProbe,
  IThermalSignals, IOpticalSignals, ITransitionAnalysis, ISnackBars } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';
import { ITrace, IFilter, ITableOptions } from '@/interfaces';
import { dispatchCheckApiError } from '@/store/main/actions';

import RulesTable from '@/components/RulesTable.vue';
import StackedSnacks from '@/components/StackedSnacks.vue';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template, Data, AxisName } from 'plotly.js';

import VSnackbars from "v-snackbars"

import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import {deepClone} from '@/scripts/support-functions'
import { appName } from '@/env';
import { toDateWithOptions } from 'date-fns-tz/fp';
import { colors } from 'vuetify/lib';

@Component({
  components: {
    RulesTable,
    StackedSnacks,
    "v-snackbars": VSnackbars,
  },
  })
export default class ViewRun extends Vue {
  public run: IRunTrackerExtended = {} as IRunTrackerExtended;
  public selectedRuns: IRunTrackerExtended[] = [];

  //TODO: Add error messages to specific tabs
  public errorMSG: ISnackBars[] = [];
  public errorMSGInst: ISnackBars[] = [];
  public errorMSGAmpfea: ISnackBars[] = [];
  public snackTime = -1;

  public uniqueProbeType: string[] = Object.values(EProbeFunction);

  public signalSelectedProbe: string = '';
  public signalSelectedMetric: string = '';
  public ampfeaSelectedProbe: string[][] = Array.from({length: this.uniqueProbeType.length}, () => []);
  public ampfeaSelectedMetric: string[] = Array.from({length: this.uniqueProbeType.length}, () => '');
  public thermalSignalSelectedMetric: string[][] = [[],[]];
  public transitionsSelectedMetric: string[] = [];
  public opticsSelectedMetric: string[][] = [[],[]];

  private signalProbeList: string[] = [];   // used for a select list
  private signalMetricList: string[] = [];   // used for a select list
  private ampfeaProbeList: string[][] = Array.from({length: this.uniqueProbeType.length}, () => []);   // used for a select list
  private ampfeaMetricList: string[][] = Array.from({length: this.uniqueProbeType.length}, () => []);   // used for a select list
  private thermalSignalMetricList : string[] = [];
  private transitionMetricList: string[] = [];
  private opticsMetricList: string[] = [];
  
  private signalData: ISignalWithProbe[] = [];
  private ampfeaWithProbeData: IAmpfeaWithProbe[] = [];
  private thermalSignalData: IThermalSignals = {} as IThermalSignals;
  private transitionData: ITransitionAnalysis[] = [];
  private opticsData: IOpticalSignals[] = [];
  private pressureData: ITrace = {} as ITrace;

  // TODO: skeleton loaders
  public loadingAmpfea: boolean[] = Array.from({length: this.uniqueProbeType.length}, () => true);
  public loadingSignals: boolean = true;

  private graphConfig = {editable: true, displaylogo: false};

  private timeTempGraphLayout = {
      title: '',
      autosize: true,
      width: 1000,
      xaxis: {
          automargin: true,
          title: {
            text:'Time (s)',
            standoff: 20
          },
          showline: true,
          showgrid: true,
          zeroline: false,
      },
      yaxis: {
          automargin: true,
          title: 'C',
          showline: true,
          showgrid: true,
          zeroline: false,
      },
  };
  private signalsGraphLayout = deepClone(this.timeTempGraphLayout);
  private ampfeaGraphLayout =  deepClone(this.timeTempGraphLayout);
  private thermalSignalsGraphLayout =  {
    title: 'Thermal Signals',
    autosize: true,
    width: 1000,
    xaxis: {
      automargin: true,
      title: {
        text: 'Time (s)',
        standoff: 20,
      },
      showline: true,
      showgrid: true,
      zeroline: false,
    },
    yaxis: {
      automargin: true,
      title: 'C',
      showline: true,
      showgrid: true,
      zeroline: false,
    },
    yaxis2: {
      automargin: true,
      title: '',
      showline: false,
      showgrid: false,
      zeroline: false,
      overlaying: 'y' as "free" | AxisName | undefined,
      side: 'right' as "clockwise" | "counterclockwise" | "right" | "top" | "bottom" | "left" | undefined,
    },
  };
  private transitionGraphLayout = {
      title: 'Transitions',
      autosize: true,
      width: 1000,
      barmode: 'group' as "stack" | "group" | "overlay" | "relative" | undefined,
      xaxis: {
          automargin: true,
          title: {
            text:'Cycle',
            standoff: 20
          },
          showline: true,
          showgrid: true,
          zeroline: false,
      },
      yaxis: {
          automargin: true,
          title: 'C/s',
          showline: true,
          showgrid: true,
          zeroline: false,
      },
  };
  private opticsGraphLayout = deepClone(this.thermalSignalsGraphLayout);
  private pressureGraphLayout = deepClone(this.thermalSignalsGraphLayout);

  public async relayout() {
      console.log('run - relayout');
  }

  public async loadRun() {
    try {
      const filter: IFilter[] = [{field:'id', operator:'=', value:this.$router.currentRoute.params.id}]
      const options: ITableOptions = {
        page: 1,
        perPage: 1,
        sortBy: 'date_added',
        sortDesc: true,
      };

      const run_response = await api.searchRuns(readToken(this.$store),{filters: filter , ...options},);
      if (run_response) {
        this.run = run_response.data[0]
        if (this.run.array_template !== undefined){
          this.selectedRuns = [this.run]
        }
      }
    } catch (error: any) {
      this.errorMSGAmpfea.push({message:'Error: Failed to load run.',
        color: 'red',
        timeout:100,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
      await dispatchCheckApiError(this.$store, error);
    }
    this.loadTraces();
  }

  public async loadSignals() {
    api.getRunSignalsWithProbeBySourceVersion(readToken(this.$store), this.run.id).then((response) => {
      if (response.data.length>0) {
        this.signalData = response.data;
        this.signalProbeList = response.data.map((s) => s.Probe.panel_name).sort();
        this.signalMetricList = response.data.map((s) => s.Signal.metric).sort();
        this.signalSelectedProbe = this.signalProbeList[0];     // default to first one in list
        this.signalSelectedMetric = this.signalMetricList[0];   // default to first one in list
        this.selectSignalTargetChange(arguments);
      } else {
        this.errorMSGAmpfea.push({message:'Error: Failed to load replicate signals.',
        color: 'red',
        timeout: this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
        console.log('loadSignals: no response');
      }
    })
    .catch((error) => {
      this.errorMSGAmpfea.push({message:'Error: Failed to load replicate signals.',
        color: 'red',
        timeout: this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
      console.log('loadSignals: error', error);
    });
  }

  public async loadAmpfea() {
    api.getRunAmpfeasWithProbeBySourceVersion(readToken(this.$store), this.run.id).then((response) => {
      if( response ) {
        this.ampfeaWithProbeData = response.data;

        this.ampfeaProbeList = Array.from({length: this.uniqueProbeType.length}, () => []);
        this.ampfeaSelectedProbe = Array.from({length: this.uniqueProbeType.length}, () => []);
        this.ampfeaSelectedMetric = Array.from({length: this.uniqueProbeType.length}, () => '');

        for (let idx = 0; idx < this.uniqueProbeType.length; idx++) {
          const filteredProbes = this.ampfeaWithProbeData.filter((a) => a.Probe.function === this.uniqueProbeType[idx]);

          this.ampfeaMetricList[idx] = Array.from(new Set(filteredProbes.map((a) => a.Ampfea.metric).sort()));
          this.ampfeaProbeList[idx] = Array.from(new Set(filteredProbes.map((a) => a.Probe.panel_name).sort()));
          this.ampfeaSelectedProbe[idx] = this.ampfeaProbeList[idx];     // default to ALL
          this.ampfeaSelectedMetric[idx] = this.ampfeaMetricList[idx][0]   // default to first one in list
          this.selectAmpfeaTargetChange(idx);
        }
        
      } else {
        this.errorMSG.push({message:'Error: Failed to load replicate data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
        console.log('getRunAmpfeas: no response');
      }
    })
    .catch((error) => {
      this.errorMSGAmpfea.push({message:'Error: Failed to load replicate data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
      console.log('loadAmpfea: error', error);
    });

    // const probe_ids = [1092, 1103, 1113, 1114, 1115, 1118, 1120, 1121, 1130, 1133, 1136, 1139, 1145, 1147, 1151, 1158, 1161, 1163, 1168, 1175, 1180, 1197, 1198, 211, 2326, 2327, 2328, 2333, 2337, 2501, 2502, 2503, 2506, 2507, 2508, 2509, 2510, 2511, 2512, 2513, 2516, 2517, 2519, 2520, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2649, 2650, 2651, 2652, 2653, 2654, 2655, 2656, 2657];

    // const params = {runIds: [this.run.id], probeIds: probe_ids, metrics: ['Pq']};
    // api.getRunsProbesMetricsAmpfeaStatsBySourceVersion(readToken(this.$store), params).then((response) => {
    //   // console.log('getRunAmpfeaStats: response', response.data);
    // })
    // .catch((error) => {
    //   this.errorMSGAmpfea.push({message:'Error: Failed to load ampfea_stats.',
    //     color: 'red',
    //     timeout:this.snackTime,
    //     enable:true,
    //     button:null,
    //     buttonTxt:null,
    //     infoButton:null});
    //   console.log('loadAmpfeaStats: error', error);
    // });

  }

  public async loadPressure() {
    api.getPressure(readToken(this.$store), this.run.id).then((response) => {
      if (response) {
        this.pressureData = response.data;
        this.updatePressureGraph();
      }
    })
    .catch((error) => {
      this.errorMSGInst.push({message:'Error: Failed to load pressure data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
      console.log('loadPressure: error', error);
    });
  }

  public async loadThermal() {
    // api.getRunStaticThermalAnalysesVersionSource(readToken(this.$store), this.run.id).then((response) => {
    //   console.log('getRunStaticThermalAnalysesVersionSource: response', response.data);
    // });

    
    api.getRunThermalSignals(readToken(this.$store), this.run.id).then((response) => {
      if (response) {
        this.thermalSignalData = response.data;

        //Sort all fields by time
        let indices = Array.from(this.thermalSignalData.data.chip_time.keys()).sort((a,b) => 
        this.thermalSignalData.data.chip_time[a]-this.thermalSignalData.data.chip_time[b]);
        Object.keys(this.thermalSignalData.data).forEach(k => this.thermalSignalData.data[k] = indices.map(i => this.thermalSignalData.data[k][i]))

        const itemsToRemove = ['id', 'chip_time', 'experiment_id', 'date_added', 'created_by_id']
        this.thermalSignalMetricList = Object.keys(this.thermalSignalData.data).filter((t) => 
        !itemsToRemove.includes(t));
        this.thermalSignalSelectedMetric = [['chip_temp', 'chip_setpt'],['chip_power']];
        this.updateThermalSignalsGraph(this.thermalSignalSelectedMetric);
      }
    })
    .catch((error) => {
      this.errorMSGInst.push({message:'Error: Failed to load thermal signals data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
      console.log('getRunThermalSignals: error', error);
    });

    api.getRunOpticalSignals(readToken(this.$store), this.run.id).then((response) => {
      if (response) {
        this.opticsData = response.data;
        this.opticsData.sort((a, b) => a.chip_time - b.chip_time);

        const itemsToRemove = ['id', 'chip_time', 'experiment_id', 'date_added', 'created_by_id']
        this.opticsMetricList = Object.keys(this.opticsData[0]).filter((o) => 
        !itemsToRemove.includes(o));
        this.opticsSelectedMetric = [['led_power'],[]];
        this.updateOpticsGraph(this.opticsSelectedMetric);
      }
    })
    .catch((error) => {
      this.errorMSGInst.push({message:'Error: Failed to load LED data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
      console.log('getRunOpticalSignals: error', error);
    });

    // api.getRunThermalStatsVersionSource(readToken(this.$store), this.run.id).then((response) => {
    //   console.log('getRunThermalStats: response', response.data);
    // }).catch((error) => {
    //   console.log('theraml stats: error', error);
    // }
    // );
    api.getRunTransitionAnalysesVersionSource(readToken(this.$store), this.run.id).then((response) => {
      if (response) {
        this.transitionData = response.data;
        this.transitionData.sort((a, b) => a.cycle - b.cycle);

        const itemsToRemove = ['id', 'cycle', 'experiment_id', 'date_added', 'created_by_id'];
        this.transitionMetricList = Object.keys(this.transitionData[0]).filter((t) => 
        !itemsToRemove.includes(t));
        this.transitionsSelectedMetric = ['cool_rate', 'ramp_rate'];
        this.updateTransitionsGraph(this.transitionsSelectedMetric);
      }
    })
    .catch((error) => {
      this.errorMSGInst.push({message:'Error: Failed to load thermal transistion data.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton:null});
      console.log('getRunTransitionAnalysesVersionSource: error', error);
    });
  }

  public async loadTraces() {
    this.loadSignals();
    this.loadAmpfea();
    this.loadThermal();
    this.loadPressure();
  }

  public async selectSignalTargetChange(item) {
    this.loadingSignals = true;
    this.updateSignalsGraph(this.signalSelectedMetric, this.signalSelectedProbe);
    this.loadingSignals = false;
  }

  public async selectAmpfeaTargetChange(index) {
    this.loadingAmpfea[index] = true;
    this.updateAmpfeasGraph(this.ampfeaSelectedMetric[index], this.ampfeaSelectedProbe[index], this.uniqueProbeType[index]);
    this.loadingAmpfea[index] = false;
  }

  public async selectOpticsTargetChange(item) {
    this.updateOpticsGraph(this.opticsSelectedMetric);
  } 

  public async selectThermalSignalsTargetChange(item) {
    this.updateThermalSignalsGraph(this.thermalSignalSelectedMetric);
  } 

  public async selectTransitionsTargetChange(item) {
    this.updateTransitionsGraph(this.transitionsSelectedMetric);
  } 

  public async mounted() {
    this.loadRun();
  }

  public cancel() {
    this.$router.back();
  }

  private async updatePressureGraph(){
    const data: Data[] = [{
        x:this.pressureData.x,
        y:this.pressureData.y,
        name: 'Pressure',
        type:'scatter'
      }];
    
    this.pressureGraphLayout.title = 'Pressure';
    this.pressureGraphLayout.yaxis.title = 'PSI'
    this.pressureGraphLayout.xaxis.title.text = 'Time (s)'
    Plotly.newPlot('pressureGraph', data, this.pressureGraphLayout, this.graphConfig);
  }

  private async updateSignalsGraph(metricName, probeName) {
    let data: Data[] = [];
    const filteredData: ISignalWithProbe =
                this.signalData.filter((s) => s.Probe.panel_name === probeName && s.Signal.metric === metricName)[0];
    if( filteredData && filteredData.Signal.signals.length > 0 ) {
      data = filteredData.Signal.signals[0].map((_, colIndex) => (
        { x:filteredData.Signal.x_axis,
          y:filteredData.Signal.signals.map((row) => row[colIndex]),
          name:'replicate '+(colIndex+1),
          type:'scatter' }
      ));
    } else {
      this.errorMSGAmpfea.push({message:'No signals data found.',
        color: 'red',
        timeout:this.snackTime,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
    }
    this.signalsGraphLayout.title = this.run.experiment + ' - ' + probeName;
    this.signalsGraphLayout.yaxis.title = metricName;
    Plotly.newPlot('signalsGraph', data, this.signalsGraphLayout, this.graphConfig);

  }

  private async updateAmpfeasGraph(metricName, probeName, probeFnc) {
    let data: Data[] = [];
    probeName.forEach((selected) => {
      const filteredData: IAmpfeaWithProbe[] =
                this.ampfeaWithProbeData.filter((s) => s.Probe.panel_name === selected && s.Ampfea.metric === metricName);

      if( filteredData.length > 0 ) {
        const y: number[] = filteredData.map((f) => f.Ampfea.result);
        data.push({
          y: y,
          type: 'box',
          name: selected
        });
      }
    });
    this.ampfeaGraphLayout.title = this.run.experiment + " - " + probeFnc;
    this.ampfeaGraphLayout.yaxis.title = metricName;
    this.ampfeaGraphLayout.xaxis.title.text = "Probe";

    const sanitizedFunction = probeFnc.replace(/ /g, ''); // Remove spaces from callout
    Plotly.newPlot('ampfeaGraph'+sanitizedFunction, data, this.ampfeaGraphLayout, this.graphConfig);

  }

  private async updateThermalSignalsGraph(metrics) {
    let data: Data[] = [];
    const time = this.thermalSignalData.data.chip_time;

    metrics[0].forEach(m => {
      data.push({
        x:time,
        y:this.thermalSignalData.data[m],
        name: m,
        type:'scatter'
      });
    });
    metrics[1].forEach(m => {
      data.push({
        x:time,
        y:this.thermalSignalData.data[m],
        name: m,
        type:'scatter',
        yaxis: 'y2'
      });
    });

    if( this.thermalSignalsGraphLayout.yaxis2 ) {
      if (metrics[1].length > 0) {
        this.thermalSignalsGraphLayout.yaxis2.showgrid = true;
        this.thermalSignalsGraphLayout.yaxis2.showline = true;
        this.thermalSignalsGraphLayout.yaxis2.automargin = true;
        this.thermalSignalsGraphLayout.yaxis2.title = "";
      } else {
        this.thermalSignalsGraphLayout.yaxis2.showgrid = false;
        this.thermalSignalsGraphLayout.yaxis2.showline = false;
        this.thermalSignalsGraphLayout.yaxis2.automargin = false;
      }
    }

    this.signalsGraphLayout.title = 'Thermal Signals';
    this.thermalSignalsGraphLayout.yaxis.title = 'Temperature (C)'
    this.thermalSignalsGraphLayout.xaxis.title.text = 'Time (s)'
    Plotly.newPlot('thermalGraph', data, this.thermalSignalsGraphLayout, this.graphConfig);
  }

  private async updateTransitionsGraph(metrics) {
    let data: Data[] = [];
    const cycle = this.transitionData.map((t) => t.cycle);
    metrics.forEach((m) => {
      data.push({
        x: cycle,
        y: this.transitionData.map((t) => Math.abs(t[m])),
        name: m,
        type: 'bar'
      })
    });
    Plotly.newPlot('transitionGraph', data, this.transitionGraphLayout, this.graphConfig);
  }

  private async updateOpticsGraph(metrics) {
    let data: Data[] = [];
    const time = this.opticsData.map((o) => o.chip_time);

    metrics[0].forEach(m => {
      data.push({
        x:time,
        y:this.opticsData.map((o) => o[m]),
        name: m,
        type:'scatter'
      });
    });
    metrics[1].forEach(m => {
      data.push({
        x:time,
        y:this.opticsData.map((o) => o[m]),
        name: m,
        type:'scatter',
        yaxis: 'y2'
      });
    });

    if (metrics[1].length > 0) {
      this.opticsGraphLayout.yaxis2.showgrid = true;
      this.opticsGraphLayout.yaxis2.showline = true;
      this.opticsGraphLayout.yaxis2.automargin = true;
      this.opticsGraphLayout.yaxis2.title = "";
    } else {
      this.opticsGraphLayout.yaxis2.showgrid = false;
      this.opticsGraphLayout.yaxis2.showline = false;
      this.opticsGraphLayout.yaxis2.automargin = false;
    }

    this.opticsGraphLayout.title = 'Optical Signals';
    this.opticsGraphLayout.yaxis.title = ''
    this.opticsGraphLayout.xaxis.title.text = 'Time (s)'
    Plotly.newPlot('opticsGraph', data, this.opticsGraphLayout, this.graphConfig);
  }

  // TODO: Alllow select all option for probes
  public async selectAllAmpfea(idx){
    this.ampfeaSelectedProbe[idx] = this.ampfeaProbeList[idx];
    this.selectAmpfeaTargetChange(idx);
    this.$forceUpdate()
  }
}

</script>


  