<template>
  <v-card style="margin-bottom: 25px;">
    <!-- Template/Rule Selections, Queue/Save/Downlooad Buttons -->
    <v-row>
      <v-col cols="3.5">
        <v-select
          style="padding-left: 20px;"
          v-model="selectedTemplate"
          :items="templateList"
          item-text="name"
          item-value="id"
          label="Template"
          :disabled="loadingProbes"
          @change="buildNewTemplate()"
          dense
        ></v-select> 
      </v-col>

      <v-col cols="3.5">
        <v-select
          style="padding-left: 10px;"
          v-model="ruleName"
          :items="ruleList"
          item-text="name"
          item-value="name"
          label="Rule-set"
          :disabled="loadingProbes"
          @change="buildExistingRules()"
          dense
        ></v-select>
      </v-col>

      <v-col cols="3">
        <div class="text-right">
          <v-fab-transition>
            <v-btn color="primary" v-on:click="computeQueue()" v-show="queuedRuns.length>0" :disabled="loadingProbes" small>
              Add Experiments
            </v-btn>
          </v-fab-transition>
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="text-right" style="padding-right: 20px;">
          <v-menu right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item v-on:click="downloadResults()" :disabled="loadingProbes">
                  <v-list-item-title>Download</v-list-item-title>
                </v-list-item>
                <v-list-item v-on:click="saveRuleDialog = true">
                  <v-list-item-title>Save</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <!-- Save Rule-Set Dialog -->
    <v-row justify="center">
      <v-dialog v-model="saveRuleDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Save Rule Set</span>
          </v-card-title>
          <v-form v-model="validForm">
            <v-container>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="createName"
                      placeholder="Rule-Set Name"
                      :rules="rules()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="saveRuleDialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveNewRuleSet()">
                Save
              </v-btn>
            </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Default Rules -->
    <v-card class="panel-space">
      <RulesDefaultSelection
        v-model="clonedBaseRules"
        :disable=loadingProbes
        @update-defaults="updateDefaults">
      </RulesDefaultSelection>
    </v-card>

    <!-- Color Key -->
    <v-card-title class="title-margin title-font">
      <v-container>
      Results Color:
      <span class="pr-2 key-text">
        <v-chip :color="getColor(3)" dark class="color-key">&nbsp;&nbsp;</v-chip>
        Valid
        <v-chip :color="getColor(2)" dark class="color-key">&nbsp;&nbsp;</v-chip>
        Invalid
        <v-chip :color="getColor(1)" dark class="color-key">&nbsp;&nbsp;</v-chip>
        Negative
        <v-chip :color="getColor(0)" dark class="color-key">&nbsp;&nbsp;</v-chip>
        Positive
      </span>
      </v-container> 
    </v-card-title>
    <!-- Table toggling button & Graph Selecion Icons -->
    <v-card-text style="margin: 0px; padding-bottom: 0px; padding-top: 0px;">
      <v-row justify="space-between" align="center">
        <v-col cols="auto">
          <div class="d-flex align-center">
            <v-btn small @click="toggleGroups()">Toggle Callouts</v-btn>
          </div>
        </v-col>

        <v-col cols="auto">
          <v-row>
            <v-select
              v-model="comparatorVal"
              :items="comparatorItems"
              item-text="panel_name"
              label="Comparator"
              multiple
              v-if="figureToggle===1 && comparatorItems.length>0"
              style="max-width: 200px; padding-right: 10px;"
              @blur="comparatorChange()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" x-small style="max-width: 100px;">
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ comparatorVal.length - 1 }})
                </span>
              </template>
            </v-select>
            <v-btn-toggle v-model="figureToggle" style="padding-top: 16px;" @change="updateTableGraphs()">
                <v-btn small>
                    <v-icon>mdi-chart-bar</v-icon>
                </v-btn>
                <v-btn small>
                    <v-icon>mdi-matrix</v-icon>
                </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-col>
      </v-row> 
    </v-card-text>

    <!-- Callout Table -->
    <v-card-title class="title-font" style="padding-top: 0px;">
      Panel Call Outs
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      ref="panelTable"
      group-by="callout_name"
      :headers="calloutHeader"
      :search="search"
      :items="calloutTable"
      :expanded.sync="expanded"
      item-key="panel_id"
      :dense="true"
      :sort-by="sortBy"
      :sort-desc="true"
      class="elevation-1 blue-grey lighten-5"
      no-data-text="No Probes"
      disable-pagination
      hide-default-footer
      :loading="loadingProbes"
      loading-text="Loading..."
      show-expand
    >
      <!-- Callout Group -->
      <template v-slot:group.header="{isOpen, toggle, group}">
        <td colspan="1" class='align-left blue-grey lighten-3'> 
          <div @click="toggle">
            <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
          </div>
        </td>
        <td colspan="1" class='align-center blue-grey lighten-3'>
          <div>{{ group }}</div>
        </td>        
        <td colspan="2" class='blue-grey lighten-3' style="margin-left: auto; margin-right: 10; padding-left: 0;">
          <div v-if="group !== ''" style="text-alight: left">
            <v-chip :color="getColor(0)" dark medium>
              {{ getL3Rule(group, "positive") }}
            </v-chip>
            <v-chip :color="getColor(1)" dark>
              {{ getL3Rule(group, "negative") }}
            </v-chip>
            <v-chip :color="getColor(2)" dark>
              {{ getL3Rule(group, "invalid") }}
            </v-chip>
          </div>
        </td>
        <td colspan="3" class='align-top blue-grey lighten-3'>
          <span class="graph-container" >
            <span :id="'matrixGraph' + group.replace(/ /g, '')"></span>
          </span>
        </td>
        <td colspan="1" class='align-center blue-grey lighten-3'>
          <div style="display: flex; justify-content: flex-end">
            <v-icon small class="mr-2" @click="editFormula(group, 3)" v-if="!loadingProbes && group!==''">mdi-pencil-box-multiple-outline</v-icon>
            <v-progress-circular color="primary" indeterminate v-if="loadingProbes && group!==''"></v-progress-circular>
          </div>
        </td>
      </template>

      <!-- Panel Association Modifications -->

      <!-- Panel result chips -->
      <template v-slot:item.callout_result="{ item }">
        <v-chip :color="getColor(0)" dark v-if="getL2Rule(item.panel_association, 'positive')>0 && item.panel_association!=='Controls'" small>
          {{ getL2Rule(item.panel_association, "positive") }}
        </v-chip>
        <v-chip :color="getColor(3)" dark v-if="getL2Rule(item.panel_association, 'positive')>0 && item.panel_association==='Controls'" small>
          {{ getL2Rule(item.panel_association, "positive") }}
        </v-chip>
        <v-chip :color="getColor(1)" dark v-if="getL2Rule(item.panel_association, 'negative')>0" small>
          {{ getL2Rule(item.panel_association, "negative") }}
        </v-chip>
        <v-chip :color="getColor(2)" dark v-if="getL2Rule(item.panel_association, 'invalid')>0" small>
          {{ getL2Rule(item.panel_association, "invalid") }}
        </v-chip>
      </template>

      <!-- Action Buttons -->
      <template v-slot:item.actions="{ item }">
        <td colspan="1">
          <div style="display: flex; justify-content: flex-end">
            <v-icon small class="mr-2"  @click="editFormula(item.panel_association, 2)" v-if="!loadingProbes">mdi-square-edit-outline</v-icon>
            <v-progress-circular color="primary" indeterminate v-if="loadingProbes"></v-progress-circular>
          </div>
        </td>
      </template>

      <!-- Histograms -->
      <template v-slot:item.pq-graph="{ item }">
        <span class="graph-container" >
          <span :id="'pqGraph' + item.panel_association.replace(/ /g, '')"></span>
        </span>
      </template>
      <template v-slot:item.aq-graph="{ item }">
        <span class="graph-container" >
          <span :id="'aqGraph' + item.panel_association.replace(/ /g, '')"></span>
        </span>
      </template>
      <template v-slot:item.tm-graph="{ item }">
        <span class="graph-container" >
          <span :id="'tmGraph' + item.panel_association.replace(/ /g, '')"></span>
        </span>
      </template>
      <template v-slot:item.ct-graph="{ item }">
        <span class="graph-container" >
          <span :id="'ctGraph' + item.panel_association.replace(/ /g, '')"></span>
        </span>
      </template>

      <!-- Expandable Items -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="ruleHeader"
            :search="search"
            :items="getPanelProbes(item.panel_association)"
            item-key="panel_id"
            :dense="true"
            class="elevation-1"
            no-data-text="No Probes"
            disable-pagination
            hide-default-footer
            :loading="loadingProbes"
            loading-text="Loading..."
          >
            <!-- Check boxes -->
            <template v-slot:item.use_metric1="{ item }">
              <v-simple-checkbox
              v-model="item.use_metric1"
              disabled
              v-if="item.use_metric1 !== undefined"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.use_metric2="{ item }">
              <v-simple-checkbox
              v-model="item.use_metric2"
              disabled
              v-if="item.use_metric2 !== undefined"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.use_metric3="{ item }">
              <v-simple-checkbox
              v-model="item.use_metric3"
              disabled
              v-if="item.use_metric3 !== undefined"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.use_metric4="{ item }">
              <v-simple-checkbox
              v-model="item.use_metric4"
              disabled
              v-if="item.use_metric4 !== undefined"
              ></v-simple-checkbox>
            </template>

            <!-- L1 bubbles -->
            <template v-slot:item.l1_call="{ item }">
              <v-chip :color="getColor(0)" dark v-if="item.l1_call[0] && item.panel_association !== 'Controls'" x-small>
                {{ item.l1_call[0] }}
              </v-chip>
              <v-chip :color="getColor(3)" dark v-if="item.l1_call[0] && item.panel_association === 'Controls'" x-small>
                {{ item.l1_call[0] }}
              </v-chip>
              <v-chip :color="getColor(1)" dark v-if="item.l1_call[1]" x-small>
                {{ item.l1_call[1] }}
              </v-chip>
              <v-chip :color="getColor(2)" dark v-if="item.l1_call[2]" x-small>
                {{ item.l1_call[2] }}
              </v-chip>
            </template>

            <!-- Row edit icons -->
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" v-on:click="editItem(item)" v-if="!loadingProbes">
                mdi-pencil
              </v-icon>
              <v-icon small v-on:click="stageDelete(item)" v-if="item.probe_id!=null && !loadingProbes">
                mdi-delete
              </v-icon>
              <v-progress-circular color="primary" indeterminate v-if="loadingProbes"></v-progress-circular>
            </template>

          </v-data-table>
        </td>
      </template>

    </v-data-table>
    
    <!-- Row Edit Dialog -->
    <v-dialog v-model="dialog" persistent max-width="300">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{"Edit Rule: " + editedItem.panel_name }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm">
            <v-container>
              <template v-for="i in 4">
                <v-row v-if="editedItem['use_metric' + i] !== undefined">
                  <v-col cols="12" sm="6" md="4">
                    <v-simple-checkbox v-model="editedItem['use_metric' + i]"></v-simple-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem['metric' + i + '_low']"
                      v-if="editTextLow.length > (i - 1)"
                      :label="editTextLow[i - 1]"
                      type="number"
                      :rules="numberRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem['metric' + i + '_high']"
                      v-if="editTextHigh.length > (i - 1)"
                      :label="editTextHigh[i - 1]"
                      type="number"
                      :rules="numberRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-form>
          <v-spacer></v-spacer>
          <v-row class="centeredRow">
            <v-btn color="primary" @click="save" style="margin-right: 10px;">Save</v-btn>
            <v-btn color="primary" @click="close">Cancel</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Mask Delete Dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="300">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{"Revert to defaults: " + editedItem.panel_name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="centeredRow">
              <v-btn color="primary" @click="deleteItem(editedItem)" style="margin-right: 10px;"> Apply </v-btn> 
              <v-btn color="primary" @click="close()" > Cancel </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- L2 edit modal -->
    <v-dialog v-model="dialogFormula" persistent max-width="1000">
      <LogicGenerator
        v-model="editedFormula"
        :itemList="panelProbes"
        :callouts="callouts"
        :metricList="metricSelection"
        v-if="dialogFormula"
        @save="saveL2Formula()"
        @close="close()"
        >
      </LogicGenerator>
    </v-dialog>

    <!-- Error v-snackbars -->
    <StackedSnacks
      v-model="snackMSG"
      :loading="loadingProbes"
      @submitted="computeQueue()"
      @moreInfo="errorInfoBtn()">
    </StackedSnacks>
  
  </v-card>

</template>

<script lang="ts">
import RulesDefaultSelection from '@/components/RulesDefaultSelection.vue';
import LogicGenerator from '@/components/LogicGenerator.vue';
import StackedSnacks from '@/components/StackedSnacks.vue';

import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile, readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { IArrayTemplateProbeReplicate, IProbe, IRunTrackerExtended,
  IRuleSetCreate, IRuleSet, IArrayTemplate, IProbeRuleCreate,
  IProbeRule, IProbeResults, IRulesTable, IReductionResult, IPanelResults,
  ICalloutRuleCreate, IPanelRuleCreate, IFormulaEditor, Il2Results, ICalloutResults, ISample,
  IComparatorMatrix, IHistograms, IReductionErrors, ISnackBars } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import { filter } from 'vue/types/umd';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template, Data } from 'plotly.js';

import VSnackbars from "v-snackbars"

import buildDataReductionExcel from '@/scripts/to-excel/results-to-excel';
import { toDateWithOptions } from 'date-fns-tz/fp';
import { AuthError } from '@azure/msal-browser';

@Component({
  components: {
    RulesDefaultSelection,
    LogicGenerator,
    StackedSnacks,
    "v-snackbars": VSnackbars,
  },
  })

export default class RulesTable extends Vue {
  @ModelSync('value', 'change', { type: Array }) public selectedRuns!: IRunTrackerExtended[];
  public workerID: number = 0;
  public errorList: IReductionErrors[] = [];
  public snackMSG: ISnackBars[] = [];

  //Template runs are actively displayed in table
  public templateRuns: IRunTrackerExtended[] = [];
  //Queued runs need to be loaded but have the same template as template runs
  public queuedRuns: IRunTrackerExtended[] = [];

  //Dropdown for rule selection
  public ruleName: string = '';
  public ruleList: IRuleSet[] = [];
  public saveRuleDialog = false;

  //Dropdown for template selection
  public selectedTemplate: number|null = null;
  public templateList: IArrayTemplate[] = [];

  //Dropdown for comparator matrices
  public comparatorVal: string[] = [];
  public comparatorItems: string[] = [];
  public prevComparatorVal: string[] = [];

  //Save name for new rule-set
  public createName: string = '';

  //REMOVE after graphs complete. Stored in rules no need
  public selectedReduction = 'mean';

  //Controlling user actions and displaying loading feedback
  public loadingProbes: boolean = true;
  public downloading: boolean = false;

  public figureToggle: null|number = null;
  public sortBy = 'panel_association';

  // Tables
  public rulesTable: IRulesTable[] = [];
  public calloutTable: IRulesTable[] = [];
  public controlsTable: IRulesTable[] = [];
  public expanded = [];

  //Graphs
  public comparatorMatrix: IComparatorMatrix[] = []

  //Handling table row edits
  public editedIndex = -1;
  public editedItem: IRulesTable = {} as IRulesTable;
  public editTextLow: string[] = [];
  public editTextHigh: string[] = [];

  //Handling table panel rule edits
  public dialogFormula = false;
  public formulaLevel: number = 0;
  public edittedPanel: string = '';
  public edittedCalloutID: number = 0;
  public stringIndexes: number[] = [];
  public editedFormula: IFormulaEditor[] = [];
  public panelProbes: (string|undefined)[] = [];
  public metricSelection: string[] = [];
  public callouts: string[] = [];

  // Dialogs for row edits
  public dialog = false;
  public dialogControl = false;
  public deleteDialog = false;
  public deleteDialogControl = false;
  public search: string = '';

  //Form validation items
  public validForm: boolean = false;
  public numberRules =  [(v) => !!v || v=== 0 || 'Value required'];

  //Rules for select rule-set
  public ruleSetCreate: IRuleSetCreate = {} as IRuleSetCreate;
  public probeRules: IProbeRule[] = [];
  public panelRules: IPanelRuleCreate[] = [];
  public calloutRules: ICalloutRuleCreate[] = [];

  //Panel level results
  public l2Results: IPanelResults[] = [];

  //Callout level results
  public calloutResults: ICalloutResults[] = [];

  //Probes for selected template
  public uniqueProbes: IArrayTemplateProbeReplicate[] = [];
  public probes: IArrayTemplateProbeReplicate[] = [];

  //Used to ID hyb probes to add to a "Controls" panel
  public hybc1Regex: RegExp = /hybc1/i;
  public hybc2Regex: RegExp = /hybc2/i;

  //Base rules for creating defualt ruleset and applying general rules across probes
  public probeRulesBase: IProbeRuleCreate = {
    rule_set_id: 0,
    //default values = null
    probe_id: null,
    //All or nothing method. Can't mix/match methods
    rr_method: 'mean',
    //for target probes
    use_pq: true,
    pq_th_low: 5,
    pq_th_high: 100,
    use_aq: false,
    aq_th_low: 0.5,
    aq_th_high: 100,
    use_tm: false,
    tm_th_low: 58,
    tm_th_high: 70,
    //for discrimination probes
    use_dpq: false,
    dpq_th_low: -100,
    dpq_th_high: -0.5,
    use_daq: true,
    daq_th_low: -100,
    daq_th_high: -0.5,
    use_dtm: false,
    dtm_th_low: -100,
    dtm_th_high: -0.5,
    //for depletion probes
    use_ct: true,
    ct_th_low: 15,
    ct_th_high: 40,
    //for controls
    use_sbr: true,
    sbr_th_low: 0.5,
    use_amplitude: true,
    amplitude_th_low: 500,
    use_stability: true,
    stability_th_low: 0.2,
    use_hybc1_pq: true,
    hybc1_pq_th_low: 70,
    hybc1_pq_th_high: 100,
    use_hybc1_aq: false,
    hybc1_aq_th_low: 0,
    hybc1_aq_th_high: 100,
    use_hybc1_tm: false,
    hybc1_tm_th_low: 0,
    hybc1_tm_th_high: 100,
    use_hybc2_pq: true,
    hybc2_pq_th_low: 85,
    hybc2_pq_th_high: 100,
    use_hybc2_aq: false,
    hybc2_aq_th_low: 0,
    hybc2_aq_th_high: 100,
    use_hybc2_tm: false,
    hybc2_tm_th_low: 0,
    hybc2_tm_th_high: 100,
  };
  //Clone of base rules. Manipulated in default rule component.
  public clonedBaseRules: IProbeRuleCreate = this.probeRulesBase;

  //Table headers
  public ruleHeader: object[] = [
        { text: 'Panel Name', value: 'panel_name', cellClass: 'col-callout-name', align: 'left' },
        { text: 'Result', value: 'l1_call', cellClass: 'col-l1-call', sortable:false, align: 'left'  },
        { text: '', value: 'use_metric1', cellClass: 'col-use-metric1', sortable:false, align: 'center' },
        { text: '', value: 'metric1_low', cellClass: 'col-metric1-low', sortable:false, align: 'right' },
        { text: '', value: 'metric1_text', cellClass: 'col-metric1-text', sortable:false, align: 'center' },
        { text: '', value: 'metric1_high', cellClass: 'col-metric1-high', sortable:false, align: 'left' },
        { text: '', value: 'use_metric2', cellClass: 'col-use-metric2', sortable:false, align: 'center' },
        { text: '', value: 'metric2_low', cellClass: 'col-metric2-low', sortable:false, align: 'right' },
        { text: '', value: 'metric2_text', cellClass: 'col-metric2-text', sortable:false, align: 'center' },
        { text: '', value: 'metric2_high', cellClass: 'col-metric2-high', sortable:false, align: 'left' },
        { text: '', value: 'use_metric3', cellClass: 'col-use-metric3', sortable:false, align: 'center' },
        { text: '', value: 'metric3_low', cellClass: 'col-metric3-low', sortable:false, align: 'right' },
        { text: '', value: 'metric3_text', cellClass: 'col-metric3-text', sortable:false, align: 'center' },
        { text: '', value: 'metric3_high', cellClass: 'col-metric3-high', sortable:false, align: 'left' },
        { text: '', value: 'use_metric4', cellClass: 'col-use-metric4', sortable:false, align: 'center' },
        { text: '', value: 'metric4_low', cellClass: 'col-metric4-low', sortable:false, align: 'right' },
        { text: '', value: 'metric4_text', cellClass: 'col-metric4-text', sortable:false, align: 'center' },
        { text: '', value: 'metric4_high', cellClass: 'col-metric4-high', sortable:false, align: 'left' },
        { text: 'Actions', value: 'actions', cellClass: 'col-actions', sortable:false, align: 'center' },
  ];
  public calloutHeader: object[] = [
      { text: 'Callout', value: 'panel_association', cellClass: 'col-panel-name', sortable:false, align: 'left' },
      { text: 'Result', value: 'callout_result', cellClass: 'col-callout-call', sortable:false, align: 'left' },
      { text: '', value: 'pq-graph', cellClass: 'pq-graph', sortable:false, align: 'center' },
      { text: '', value: 'aq-graph', cellClass: 'aq-graph', sortable:false, align: 'center' },
      { text: '', value: 'tm-graph', cellClass: 'tm-graph', sortable:false, align: 'center' },
      { text: '', value: 'ct-graph', cellClass: 'ct-graph', sortable:false, align: 'center' },
      { text: 'Actions', value: 'actions', cellClass: 'col-actions', sortable:false, align: 'right' },
  ];

  public greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  public async downloadResults() {
    const runIds = this.selectedRuns.map((r) => r.id)
    api.getRunsAmpfeasBySourceVersion(readToken(this.$store), runIds).then((response) => {

      const params = {
        templateName: this.templateList.find((at) => at.id === this.selectedTemplate)?.name,
        defaultRule: this.probeRules.find((r) => r.probe_id === null),
        sortedProbes: this.rulesTable.sort((a, b) => (parseInt(a.panel_association) < parseInt(b.panel_association) ? -1 : 1)),
        uniqueProbes: this.uniqueProbes,
        username: this.greetedUser(),
        ruleName: this.ruleName,
        templateRuns: this.templateRuns,
        rulesTable: this.rulesTable,
        rawData: response.data.filter((a) => ['Aq', 'Pq', 'Tm', 'Ct'].indexOf(a.metric) > -1),
        runNames: Object.assign({}, ...this.selectedRuns.map((r) => ({[r.id]: r.experiment}))),
        l2Results: this.l2Results,
        panelRules: this.panelRules
      }

      buildDataReductionExcel(params);
      console.log('buildDataReductionExcel called');
    });

  }

  @Watch('baseRules', {deep: true})
  public async updateClonedRules() {
    this.clonedBaseRules = this.probeRulesBase;
  }

  //   const id = await api.queueRuleApplication(readToken(this.$store), params);

  @Socket('reduce-complete')
  public onReduceComplete(payload) {
    if( payload.task_id === this.workerID ){
      if(payload.results) {
        let result: IReductionResult = {} as IReductionResult;
        payload.results.forEach(r => {
          const parsedArray: IReductionResult = JSON.parse(r);
          result = parsedArray;
        });

        this.comparatorMatrix = result.comparatorMatrix;
        this.errorList = result.errors;

        const l2Obj: Il2Results[] = result.l2Results;
        const probeResults: IProbeResults[][] = l2Obj.map((r)=>r.l1_call);
        const untangledProbeResults: IProbeResults[] = probeResults.reduce((acc, curr) => acc.concat(curr), []);

        const l2Tangled: IPanelResults[][] = l2Obj.map((r)=>r.l2_call);
        this.l2Results = l2Tangled.reduce((acc, curr) => acc.concat(curr), []);
        for (let i = 0; i < this.l2Results.length; i++) {
          const res = result.l2Results.filter((r) => r.panel_association===this.l2Results[i].panel_association)
          if (res.length>0){
            const pqHist = res[0].pqHist;
            const aqHist = res[0].aqHist;
            const tmHist = res[0].tmHist;
            const ctHist = res[0].ctHist;
            this.l2Results[i].pqHist = pqHist;
            this.l2Results[i].aqHist = aqHist;
            this.l2Results[i].tmHist = tmHist;
            this.l2Results[i].ctHist = ctHist;
          }
        }

        const failedExps = result.missing_exp_ids;

        this.calloutResults = result.calloutResults;

        this.rulesTable = this.updateProbeTableCalls(this.rulesTable, untangledProbeResults);
        this.updateTableGraphs();
      }
      this.loadingProbes = false;
      const msgIdx = this.snackMSG.findIndex((m) => m.message === 'Processing data...');

      if (this.errorList.length>0){
        //TODO: add additional details for errors
        this.snackMSG.push({message:`(${this.errorList.length}) Errors/Warnings`,
          color: 'red',
          timeout:-1,
          enable:true,
          button:null,
          buttonTxt:null,
          infoButton: null});
      }

      if (msgIdx !== -1){
        this.$set(this.snackMSG[msgIdx], 'message', 'Processing complete!')
        this.$set(this.snackMSG[msgIdx], 'color', 'green')
      }
    }
  }

  @Socket('reduce-error')
  public onReduceError(payload) {
    if( payload.task_id === this.workerID ){
      console.log('onReduceError', payload);
      const msgIdx = this.snackMSG.findIndex((m) => m.message === 'Processing data...');
      this.loadingProbes = false;
      if (msgIdx !== -1){
        this.snackMSG.splice(msgIdx, 1);
      }
      this.snackMSG.push({message:`Error: ${payload.message}`,
          color: 'red',
          timeout:-1,
          enable: true,
          button:null,
          buttonTxt:null,
          infoButton: null});
    }
  }

  @Socket('reduce-progress')
  public onReduceProgress(payload) {
    // console.log('onReduceProgress', payload);
  }

  //TODO: Add snackbar button to compute
  public async computeQueue() {
    this.loadingProbes = true;
    this.snackMSG.push({message:'Processing data...',
        color: 'blue',
        timeout:-1,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
    this.templateRuns = [...this.templateRuns, ...this.queuedRuns];
    this.queuedRuns = [];
    const sampleIDs = this.templateRuns.filter((r) => r.sample_id !== null).map((r) => r.sample_id as number);
    const responseVendor = await api.getUniqueComparators(readToken(this.$store), sampleIDs, 'SOC_EXTERNAL');
    if (responseVendor){
      this.comparatorItems = responseVendor.data;
      if (this.comparatorVal.length === 0) {
        this.comparatorVal = this.comparatorItems;
        this.prevComparatorVal = this.comparatorVal;
      }
    }
    this.computeResults();
  }

  public async computeResults() {
    this.loadingProbes = true;
    this.snackMSG = [];
    let msgIdx = this.snackMSG.findIndex((m) => m.message === 'Processing data...');
    if (msgIdx === -1){
      this.snackMSG.push({message:'Processing data...',
        color: 'blue',
        timeout:-1,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
      msgIdx = this.snackMSG.length;
    }

    const ruleSet = this.ruleList.find((r)=>r.name===this.ruleName);
    if( !ruleSet ) {
      this.loadingProbes = false;
      this.snackMSG.splice(msgIdx, 1);
      this.snackMSG.push({message:'Error: rule set not found',
        color: 'red',
        timeout:-1,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
      return;
    }

    let tableRules: IProbeRuleCreate[] = [this.probeRulesBase]
    this.rulesTable.forEach((t) => {
      if(t.probe_id!==null) {
        tableRules = tableRules.concat(this.asProbeRuleCreate(t))
      }
    })
    if( tableRules.length === 0 ) {
      this.loadingProbes = false;
      this.snackMSG.splice(msgIdx, 1);
      this.snackMSG.push({message:'Error: table rules not found',
        color: 'red',
        timeout:-1,
        enable:true,
        button:null,
        buttonTxt:null,
        infoButton: null});
      return;
    }

    const params = {
      runIds: this.templateRuns.map((r) => r.id),
      ruleSet: ruleSet,
      rules: tableRules,
      panelRules: this.panelRules,
      calloutRules: this.calloutRules,
      comparators: this.comparatorVal,
    }

    const id = await api.queueDataReduction(readToken(this.$store), params);
    if( id ){
      this.workerID=id.data
    }
  }

  public async comparatorChange(){
    if (this.comparatorVal !== this.prevComparatorVal) {
      console.log("Comparator change");
      this.prevComparatorVal = this.comparatorVal;
      this.computeResults();
    }
  }

  @Watch('selectedRuns', {deep: true})
  public async onRunChanged(newVal: IRunTrackerExtended[], oldVal: IRunTrackerExtended[]) {
    const orginalTemplate = this.selectedTemplate;
    await this.getDistinctTemplate();
    if(orginalTemplate === null && this.selectedTemplate !== null) {
      this.buildNewTemplate();
    } else {
      let addedExp = newVal.filter((item) => oldVal.indexOf(item) < 0);
      let removedExp = oldVal.filter((item) => newVal.indexOf(item) < 0);
      if(addedExp.length) {
        //add items to existing
        addedExp = addedExp.filter((r)=> r?.array_template?.id === this.selectedTemplate);
        this.queuedRuns = [...this.queuedRuns,...addedExp];
      } else if(removedExp.length) {
        //remove items from existing
        removedExp = removedExp.filter((r)=> r?.array_template?.id === this.selectedTemplate);
        const expIds = removedExp.map((r)=> r.id);
        this.templateRuns = this.templateRuns.filter((r)=> !expIds.includes(r.id));
        this.queuedRuns = this.queuedRuns.filter((r)=> !expIds.includes(r.id));

        this.updateTableGraphs();
      }
    }
  }

  public rules() {
    const saveNameRules =  [(v) => !!v || 'Value required',
    (v) => this.ruleList.map((r)=>r.name).indexOf(v)===-1 || 'Unique name required'];
    return(saveNameRules);
  }

  public async errorInfoBtn(){
    //TODO: add additional details for errors
  }

  public async updateDefaults() {
    this.probeRulesBase = this.clonedBaseRules;
    const ruleIDX = this.probeRules.findIndex((rule)=> rule.probe_id === null);
    if (ruleIDX !== null) {
      this.probeRules.forEach((r) => {
        r.rr_method = this.probeRulesBase.rr_method;
      });

      this.selectedReduction = this.probeRulesBase.rr_method;

      const newRules: IProbeRule = {...this.probeRules[ruleIDX], ...this.probeRulesBase};
      if (newRules) {
        this.updateRulesTable();
        this.computeResults();
      }
    }
  }

  public editFormula(panel, level: number) {
    this.stringIndexes = [];
    this.editedFormula = [];
    this.formulaLevel = level;
    
    if( level === 2){
      this.edittedPanel = panel;
      const mergedTables = this.rulesTable;//.concat(this.controlsTable);
      for (let i = this.panelRules.length - 1; i >= 0; i--) {
          if (this.panelRules[i].panel_name === panel) {
              this.stringIndexes.push(i);
              this.editedFormula.push(this.panelRules[i])
          }
      }
      this.panelProbes = mergedTables.map((t) => {
        if (t.panel_association === panel) {
          return t.panel_name;
        }
      });
      const panelFunctions = mergedTables.map((t) => {
        if (t.panel_association === panel) {
          return t.function;
        }
      });
      this.panelProbes = this.panelProbes.filter((p)=> p !== undefined);

      if (this.stringIndexes.length > 0 && this.panelProbes.length > 0) {
        this.dialogFormula = true;
        if (panel === 'Controls') {
          this.callouts = ['Valid', 'Invalid'];
        } else {
          this.callouts = ['Positive', 'Negative'];
        }
      }
      this.metricSelection = ['Call', 'Pq', 'Aq', 'Tm'];
      if (panelFunctions.includes(EProbeFunction.depletion)) {
        this.metricSelection = this.metricSelection.concat(['Ct']);
      }
      if (panel === 'Controls') {
        this.metricSelection = this.metricSelection.concat(['SBR-PCR_cycle16', 'Signal-PCR_cycle16', 'stability40']);
      }
    } else if(level === 3 ){
      panel = this.calloutTable.find((p) => p.callout_name === panel)?.callout_id;
      this.edittedCalloutID = panel;
      for (let i = this.calloutRules.length - 1; i >= 0; i--) {
          if (this.calloutRules[i].callout_id === panel) {
              this.stringIndexes.push(i);
              this.editedFormula.push(this.calloutRules[i])
          }
      }
      this.panelProbes = this.calloutTable.map((t) => {
        if (t.callout_id === panel) {
          return t.panel_association;
        }
      });
      this.panelProbes = this.panelProbes.filter((p)=> p !== undefined);
      if (this.stringIndexes.length > 0 && this.panelProbes.length > 0) {
        this.dialogFormula = true;
          this.callouts = ['Positive', 'Negative'];
      }
      this.metricSelection = ['Call'];
    }
  }

  public saveL2Formula() {
    if( this.formulaLevel === 2 ){
      this.panelRules = this.panelRules.filter((_, index) => !this.stringIndexes.includes(index));
      const panelRuleCreateList: IPanelRuleCreate[] = this.editedFormula.map((f) => ({
          ...f,
          panel_name: this.edittedPanel,
      }));
      this.panelRules = this.panelRules.concat(panelRuleCreateList);
    } else if (this.formulaLevel === 3 ){
      this.calloutRules = this.calloutRules.filter((_, index) => !this.stringIndexes.includes(index));
      const calloutRuleCreateList: ICalloutRuleCreate[] = this.editedFormula.map((f) => ({
          ...f,
          callout_id: this.edittedCalloutID,
      }));
      this.calloutRules = this.calloutRules.concat(calloutRuleCreateList);
    }
    
    this.dialogFormula = false;
    this.computeResults();
  }

  public editItem(item) {
    const table = this.rulesTable //item.panel_association === 'Controls' ? this.controlsTable : this.rulesTable;
    this.editedIndex = table.findIndex((x) => x.panel_name === item.panel_name);
    this.editedItem = Object.assign({}, item);

    this.dialog = true;

    const targetList = [['pq_low:', 'aq_low:', 'tm_low:'],['pq_high:', 'aq_high:', 'tm_high:']];
    const depletionList = [['','','','ct_low:'],['','','','ct_high:']];
    const discriminationList = [['\u0394pq_low:', '\u0394aq_low:', '\u0394tm_low:'],
    ['\u0394pq_high:', '\u0394aq_high:', '\u0394tm_high:']];
    const controlList = [['pq_low:', 'aq_low:', 'tm_low:'],['pq_high:', 'aq_high:', 'tm_high:']];
    const normalizationList = [['SBR_low:', 'Amplitude_low:', 'Stabilization_low:']];

    switch(this.editedItem.function) {
      case(EProbeFunction.target) :
        this.editTextLow = targetList[0];
        this.editTextHigh = targetList[1];
        break;
      case(EProbeFunction.depletion) :
        this.editTextLow = depletionList[0];
        this.editTextHigh = depletionList[1];
        break;
      case(EProbeFunction.discrimination) :
        this.editTextLow = discriminationList[0];
        this.editTextHigh = discriminationList[1];
        break;
      case(EProbeFunction.control) :
        this.editTextLow = controlList[0];
        this.editTextHigh = controlList[1];
        break;
      case(EProbeFunction.normalization) :
        this.editTextLow = normalizationList[0];
        this.editTextHigh = [];
    }
  }

  public stageDelete(item) {
    const table = this.rulesTable; //item.panel_association === 'Controls' ? this.controlsTable : this.rulesTable;
    this.editedIndex = table.findIndex((x) => x.panel_name === item.panel_name);
    this.editedItem = Object.assign({}, item);

    this.deleteDialog = true;
    
  }

  public async deleteItem(item) {
    this.deleteDialog = false;
    this.probeRules = this.probeRules.filter((r) => (r.probe_id !== this.editedItem.panel_id));
    let updateTable = this.rulesTable; 
    updateTable[this.editedIndex].probe_id = null;
    this.rulesTable = updateTable;
    this.updateRulesTable();
    this.computeResults();
  }

  public close() {
    this.dialog = false;
    this.dialogFormula = false;
  }

  public async save() {
    if (this.validForm) {
      this.dialog = false;
      this.dialogControl = false;

      const updateTable = this.rulesTable; //this.editedItem.panel_association === 'Controls' ? this.controlsTable : this.rulesTable;
      const editedIndex = this.editedIndex;

      const metricProps = ['metric1', 'metric2', 'metric3', 'metric4'];

      metricProps.forEach((metric) => {
        const useKey = `use_${metric}`;
        const lowKey = `${metric}_low`;
        const highKey = `${metric}_high`;

        updateTable[editedIndex][useKey] = this.editedItem[useKey];
        updateTable[editedIndex][lowKey] = this.editedItem[lowKey];
        updateTable[editedIndex][highKey] = this.editedItem[highKey];
      });

      if(updateTable[editedIndex].probe_id === null) {
        //create new mask
        updateTable[editedIndex].probe_id = this.editedItem.panel_id;
      }
      // if (this.editedItem.panel_association === 'Controls') {
      //   this.controlsTable = updateTable;
      // } else {
      this.rulesTable = updateTable;
      // }
      this.computeResults();
    }
  }

  public toggleGroups() {
    if (this.$refs.panelTable) {
      const panelTable = this.$refs.panelTable as any;
      if (panelTable.openCache) {
        Object.keys(panelTable.openCache).forEach((g) =>
          panelTable.openCache[g] = !panelTable.openCache[g]);
      }
    }
  }

  public getColor(itemNumber) {
    if (itemNumber === 0) {
      return 'red';
    } else if(itemNumber === 1) {
      return 'green';
    } else if(itemNumber ===2 ) {
      return 'grey';
    } else {
      return '#ADD8E6';
    }
  }

  public async updateTableGraphs() {
    // this.clearPlotly(this.controlsTable);
    this.clearPlotly(this.rulesTable);
    if(this.figureToggle===0) {
      this.updateHistograms(this.rulesTable);
    } else if(this.figureToggle===1) {
      // this.updateConfusion(this.controlsTable);
      this.updateConfusion(this.calloutTable);
    }
  }

  public async clearPlotly(table: IRulesTable[]) {
    const metric = ['pq', 'aq', 'tm', 'ct'];
    const panelList = table.map((r)=> r.panel_association);
    const uniquePanels = panelList.filter((n, i) => panelList.indexOf(n) === i);
    uniquePanels.forEach((panel) => {
      metric.forEach((m) => {
        const sanitizedPanel = panel.replace(/ /g, ''); // Remove spaces from panel
        const graphContainer = document.getElementById(m+'Graph'+sanitizedPanel);
        if(graphContainer!== null) {
          graphContainer.innerHTML = '';
        }
      })
    })

    const calloutList = table.map((r)=> r.callout_name);
    const uniqueCallouts = calloutList.filter((n, i) => calloutList.indexOf(n) === i);
    uniqueCallouts.forEach((callout) => {
      if (callout !== null && callout !== '') {
        const sanitizedCallout = callout.replace(/ /g, ''); // Remove spaces from panel
        const graphContainer = document.getElementById('matrixGraph'+sanitizedCallout);
        if(graphContainer!== null) {
          graphContainer.innerHTML = '';
        }
      }
    })
  }

  public updateHistograms(table: IRulesTable[]) {
    const config = { displayModeBar: false, displaylogo: false };
    const layout: Partial<Layout> = {
      autosize: true,
      width: 150,
      height: 75,
      bargap: 0.05,
      margin:{t:10, l:30,r:10,b:30},
      font:{size:8},
      shapes: [],
      xaxis: {
      },
      yaxis: {
      },
    };
    const metric = ['pq', 'aq', 'tm', 'ct'];
    const panelList = table.map((r)=> r.panel_association);
    const uniquePanels = panelList.filter((n, i) => panelList.indexOf(n) === i);
    uniquePanels.forEach((panel) => {
      metric.forEach((m) => {
        let data: Data[] = [];
        let filteredData: IHistograms = {} as IHistograms;
        let filteredTable: IRulesTable[] = table.filter((t) => t.panel_association === panel);
        let low: number|null = 0;
        let high: number|null = 0;
        const mCase = m.charAt(0).toUpperCase() + m.slice(1);
        if(['pq', 'aq', 'tm'].includes(m)) {
          const targetCheck = filteredTable.filter((t) => t.function === EProbeFunction.target);
          const discrimCheck = filteredTable.filter((t) => t.function === EProbeFunction.discrimination);
          const controlCheck = filteredTable.filter((t) => t.function === EProbeFunction.control);
          if(targetCheck.length>0) {
            filteredTable = targetCheck;
            switch(m) {
              case('pq'):
                low = this.probeRulesBase.pq_th_low;
                high = this.probeRulesBase.pq_th_high;
                break;
              case('aq'):
                low = this.probeRulesBase.aq_th_low;
                high = this.probeRulesBase.aq_th_high;
                break;
              default:
                low = this.probeRulesBase.tm_th_low;
                high = this.probeRulesBase.tm_th_high;
                break;
            }
            layout.yaxis = {
              title: {
                text: 'Count',
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
            layout.xaxis = {
              range: [0,105],
              fixedrange: true,
              dtick:25,
              title: {
                text: mCase,
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
          } else if (controlCheck.length>0) {
            filteredTable = controlCheck;
            switch(m) {
              case('pq'):
                low = this.probeRulesBase.hybc1_pq_th_low;
                high = this.probeRulesBase.hybc1_pq_th_high;
                break;
              case('aq'):
                low = this.probeRulesBase.hybc1_pq_th_low;
                high = this.probeRulesBase.hybc1_pq_th_high;
                break;
              default:
                low = this.probeRulesBase.hybc1_tm_th_low;
                high = this.probeRulesBase.hybc1_tm_th_high;
                break;
            }
            layout.yaxis = {
              title: {
                text: 'Count',
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
            layout.xaxis = {
              range: [0,105],
              fixedrange: true,
              dtick:25,
              title: {
                text: mCase,
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
          } else {
            filteredTable = discrimCheck;
            switch(m) {
              case('pq'):
                low = this.probeRulesBase.dpq_th_low;
                high = this.probeRulesBase.dpq_th_high;
                break;
              case('aq'):
                low = this.probeRulesBase.daq_th_low;
                high = this.probeRulesBase.daq_th_high;
                break;
              default:
                low = this.probeRulesBase.dtm_th_low;
                high = this.probeRulesBase.dtm_th_high;
                break;
            }
            layout.yaxis = {
              title: {
                text: 'Count',
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
            layout.xaxis = {
              range: [-100,100],
              fixedrange: true,
              dtick:25,
              title: {
                text: '\u0394'+mCase,
                font: {
                  size: 8,
                  color: '#7f7f7f',
                },
              },
            };
          }
        } else if(['ct'].includes(m)) {
          low = this.probeRulesBase.ct_th_low;
          high = this.probeRulesBase.ct_th_high;

          filteredTable = filteredTable.filter((t) => t.function === EProbeFunction.depletion);
          layout.yaxis = {
            title: {
              text: 'Count',
              font: {
                size: 8,
                color: '#7f7f7f',
              },
            },
          };
          layout.xaxis = {
            range: [0,45],
            fixedrange: true,
            dtick:25,
            title: {
              text: mCase,
              font: {
                size: 8,
                color: '#7f7f7f',
              },
            },
          };
        }
        if(['Controls'].includes(panel)) {
          layout.shapes = [];
        } else {
        layout.shapes = [{
            type:'line',
            x0: low,
            x1: low,
            y0: 0,
            y1: 1,
            yref: 'paper',
            line: {
              color: 'red',
              width: 2,
              dash: 'dash',
            },
          },
          {
            type:'line',
            x0: high,
            x1: high,
            y0: 0,
            y1: 1,
            yref: 'paper',
            line: {
              color: 'red',
              width: 2,
              dash: 'dash',
            },
          }];
        }
        const tableIds = filteredTable.map((t)=>t.panel_id);

        let panelData: IPanelResults[] = [];
        try {
          switch (m) {
            case('pq'):
              panelData = this.l2Results.filter((r) => r.panel_association === panel);
              filteredData = panelData[0].pqHist;
              break;
            case('aq'):
              panelData = this.l2Results.filter((r) => r.panel_association === panel);
              filteredData = panelData[0].aqHist;
              break;
            case('tm'):
              panelData = this.l2Results.filter((r) => r.panel_association === panel);
              filteredData = panelData[0].tmHist;
              break;
            case('ct'):
              panelData = this.l2Results.filter((r) => r.panel_association === panel);
              filteredData = panelData[0].ctHist;
              break;
          }
        } catch (e) {
          // TODO: send error msg no data
        }
        
        if (filteredData !== null && filteredData.bins !== undefined && filteredData.counts !== undefined &&
        filteredData.bins !== null && filteredData.counts !== null) {
          const bins = filteredData.bins as number[];
          const counts = filteredData.counts as number[];
          const widths =  Array(counts.length).fill(5);

          data = [
            {
              x: bins.map((bin, index) => bin + 2.5), // Cast to number[] to remove null
              y: counts, // Cast to number[] to remove null
              type: 'bar',
              width: widths,
              name: m + ' ' + this.selectedReduction,
              hoverinfo: 'text',
              text: '',
              hovertext: bins.map((bin, index) => `${bin}-${bin + 5} Count: ${counts[index]}`),//binText,
            },
          ];
        } else {
          data = []
        }

        const sanitizedPanel = panel.replace(/ /g, ''); // Remove spaces from panel
        if(this.figureToggle!==0) {
          const graphContainer = document.getElementById(m+'Graph'+sanitizedPanel);
          if(graphContainer!== null) {
            graphContainer.innerHTML = '';
          }
        } else if(data.length>0) {
          try {
            Plotly.newPlot(m+'Graph'+sanitizedPanel, data, layout, config);
          } catch (error) {
            //failed to find plotly graph id
          }
        }
      });
    });
  }

  public updateConfusion(table: IRulesTable[]) {
    const config = { displayModeBar: false, displaylogo: false };
    const uniqueCallouts = this.getUniqueObjects(table, 'callout_name');
    const confusionMatrix = [
      [0, 4],
      [4, 0]
    ];

    const classNames = ['Positive', 'Negative'];

    uniqueCallouts.forEach((callout) => {
      const annotations: any[] = [];
      let comparator = this.comparatorMatrix.find((m) => m.callout_id === callout.callout_id);
      if (comparator === undefined){
        comparator = {callout_id: callout.callout_id, tp:0, tn:0, fp:0, fn:0};
      }
      for (let i = 0; i < confusionMatrix.length; i++) {
        for (let j = 0; j < confusionMatrix[i].length; j++) {

          //const text = i === 0 ? (j === 0 ? `TP: ${confusionMatrix[i][j]}` : `FP: ${confusionMatrix[i][j]}`) : (j === 0 ? `FN: ${confusionMatrix[i][j]}` : `TN: ${confusionMatrix[i][j]}`); // Corrected placement
          const text = i === 0 ? (j === 0 ? `TP: ${comparator.tp}` : `FP: ${comparator.fp}`) : (j === 0 ? `FN: ${comparator.fn}` : `TN: ${comparator.tn}`);
          
          annotations.push({
            x: classNames[j],
            y: classNames[i],
            text: text,
            xref: 'x1',
            yref: 'y1',
            showarrow: false,
            font: {
              color: 'white'
            }
          });
        }
      }

      const flattened = confusionMatrix.reduce((accumulator, value) => accumulator.concat(value), []);
      // Create a heatmap
      const data: Data[] = [{
        z: confusionMatrix,
        x: classNames,
        y: classNames.slice().reverse(),
        type: 'heatmap',
        colorscale: [[0, 'red'], [0.5, 'white'], [1, 'green']],
        showscale: false,
        text: flattened.map(String),
      }];
      // Set layout options
      const layout = {
        autosize: true,
        width: 150,
        height: 75,
        bargap: 0.05,
        margin:{t:10, l:10,r:10,b:10},
        font:{size:8},
        xaxis: {
          tickvals: [],
          title: ''
        },
        yaxis: {
          tickvals: [],
          title: ''
        },
        annotations: annotations
      };

      if (callout.callout_name !== null && callout.callout_name !== ''){
        const sanitizedCallout = callout.callout_name.replace(/ /g, ''); // Remove spaces from callout
        Plotly.newPlot('matrixGraph' + sanitizedCallout, data, layout, config);
      }

    })
  }

  public async updateRulesView() {
    if (this.selectedTemplate) {
      if (this.probes) {
        this.updateRulesTable();
        this.computeQueue();
      }
    }
  }

  public async buildNewTemplate() {
    await this.getDistinctTemplate();
    await this.getDistinctRules();
    if(this.selectedTemplate!==null) {
      const responseProbes = await api.getArrayTemplateProbeReplicates(readToken(this.$store), this.selectedTemplate);
      if (responseProbes) {
        this.probes = (responseProbes.data);
        await this.computeUniqueProbes();
      }
    }
    this.queuedRuns = this.selectedRuns.filter((r)=> r?.array_template?.id === this.selectedTemplate);
    // this.queuedRuns = this.templateRuns;

    if((this.ruleList.length < 1 || this.ruleList === null) && this.selectedTemplate!=null) {
      this.buildNewRules('Default');
    } else if(this.selectedTemplate===null) {
      this.loadingProbes = false;
    } else {
      //build existing rules
      this.buildExistingRules();
    }
  }

  public async buildExistingRules() {
    const rule = this.ruleList.find((r) => r.name === this.ruleName);
    if(rule) {
      //Get Probe Rules
      const responseProbeRules = await api.getRuleSetProbeRules(readToken(this.$store), rule.id);
      if (responseProbeRules) {
        this.probeRules = responseProbeRules.data;

        //Get Panel Rules if they exist. Otherwise create a base or panel-rule set
        const response_panelRules = await api.getRuleSetPanelRules(readToken(this.$store), rule.id);
        if (response_panelRules.data.length > 0) {
          this.panelRules = response_panelRules.data;
        } else {
          //Create and update panel rules
          await this.buildNewPanelStr(rule);
        }

        //Get Callout Rules if they exist. Otherwise create a base or callout-rule set
        const response_callRules = await api.getRuleSetCalloutRules(readToken(this.$store), rule.id);
        if (response_callRules.data.length > 0) {
          this.calloutRules = response_callRules.data;
        } else {
          //Create and update panel rules
          await this.buildNewCalloutStr(rule);
        }

        const baseRules = this.probeRules.find((r)=> r.probe_id===null);
        if(baseRules) {
          this.probeRulesBase = this.asDefaults(baseRules);
          this.selectedReduction = baseRules.rr_method;
          this.updateRulesView();
        }
      }
    }
  }

  public async buildNewRules(name: string) {
    //Verify template selected AND a name was entered
    if (name.length>0 && this.selectedTemplate !== null) {
      this.ruleSetCreate.name = name;
      this.ruleSetCreate.array_template_id = this.selectedTemplate;
      const responseRuleSet = await api.createRuleSet(readToken(this.$store), this.ruleSetCreate);
      if (responseRuleSet) {
        const ruleSet = responseRuleSet.data;
        this.ruleList.push(ruleSet);
        this.ruleName = ruleSet.name;
        this.probeRulesBase.rule_set_id = ruleSet.id;

        //Create and update probe rules
        const responseProbeRules = await api.createProbeRule(readToken(this.$store), this.probeRulesBase);
        if (responseProbeRules) {
          this.probeRules.push(responseProbeRules.data);
        }

        //Create and update panel rules
        await this.buildNewPanelStr(ruleSet);

        //Create and update callout rules
        await this.buildNewCalloutStr(ruleSet);


        this.updateRulesView();
      }
    }
  }

  public async buildNewPanelStr(rule: IRuleSet){
    //Create and update panel rules
    const panelRuleCreate = this.buildBasePanelRules(rule.id);
    for (const p of panelRuleCreate) {
      try {
        const responsePanelRules = await api.createPanelRule(readToken(this.$store), p);
        if (responsePanelRules) {
          this.panelRules.push(responsePanelRules.data);
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
      }
    }
  }

  public async buildNewCalloutStr(rule: IRuleSet){
    //Create and update panel rules
    const calloutRuleCreate = this.buildBaseCalloutRules(rule.id);
    for (const p of calloutRuleCreate) {
      try {
        const responseCalloutRules = await api.createCalloutRule(readToken(this.$store), p);
        if (responseCalloutRules) {
          this.calloutRules.push(responseCalloutRules.data);
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
      }
    }
  }

  public buildBasePanelRules(ruleId: number):IPanelRuleCreate[] {
    let panelRuleCreate: IPanelRuleCreate[] = [];
    const updatedProbes: IArrayTemplateProbeReplicate[] = [];
    this.uniqueProbes.forEach((p)=> {
      if(p.panel_name.match(this.hybc1Regex) || p.panel_name.match(this.hybc2Regex) || p.function === EProbeFunction.normalization) {
        p.panel_association = 'Controls';
        updatedProbes.push(p);
      } else {
        updatedProbes.push(p);
      }
    });
    let panels =updatedProbes.map((p)=> p.panel_association)
    panels = [...new Set(panels)];
    
    panels.forEach((p)=> {
      const panelProbesObj = this.uniqueProbes.filter((uP)=> uP.panel_association === p);
      const panelProbes = panelProbesObj.map((p)=> p.panel_name);
      let result: string = '';
      let otherwise: string = '';
      if( p === 'Controls') {
        result = 'Valid';
        otherwise = 'Invalid';
      } else {
        result = 'Positive';
        otherwise = 'Negative';
      }

      const panelStr: string[] = [];
      const dpProbe: string[] = []
      panelProbesObj.forEach((pP) => {
        if (pP.function === EProbeFunction.depletion) {
          dpProbe.push('{'+pP.panel_name+'}:Call');
        } else {
          panelStr.push('{'+pP.panel_name+'}:Call');
        }
      })

      let joinedFormula: string = '';
      if (dpProbe.length > 0 && panelStr.length > 0){
        joinedFormula = `(${panelStr.join(' OR ')}) AND (${dpProbe.join(' AND ')})`;
      } else if (dpProbe.length > 0 && panelStr.length === 0){
        joinedFormula = dpProbe.join(' AND ');
      } else if (p === 'Controls') {
        joinedFormula = panelStr.join(' AND ');
      } else {
        joinedFormula = panelStr.join(' OR ');
      }

      const panelRule: IPanelRuleCreate = {rule_set_id: ruleId,
        panel_name: p,
        order: 1,
        formula: joinedFormula,
        result: result,
        otherwise_result: otherwise};

      panelRuleCreate.push(panelRule);
    })
    return(panelRuleCreate);
  }

  public buildBaseCalloutRules(ruleId: number):ICalloutRuleCreate[] {
    let calloutRuleCreate: ICalloutRuleCreate[] = [];
    const updatedProbes: IArrayTemplateProbeReplicate[] = [];
    this.uniqueProbes.forEach((p)=> {
      if(p.panel_name.match(this.hybc1Regex) || p.panel_name.match(this.hybc2Regex) || p.function === EProbeFunction.normalization) {
        p.panel_association = 'Controls';
        updatedProbes.push(p);
      } else {
        updatedProbes.push(p);
      }
    });
    let callout =updatedProbes.map((p)=> p.callout_id)
    callout = [...new Set(callout)];
    
    callout.forEach((c)=> {
      if (c !== undefined && c !== null){
        const callPanelObj = this.uniqueProbes.filter((uP)=> uP.callout_id === c);
        let panels =callPanelObj.map((p)=> p.panel_association)
        panels = [...new Set(panels)];

        let result: string = 'Positive';
        let otherwise: string = 'Negative';

        const panelStr: string[] = [];
        panels.forEach((p) => {
            panelStr.push('{'+p+'}:Call');
        })

        let joinedFormula: string =  panelStr.join(' OR ');

        const calloutRule: ICalloutRuleCreate = {
          rule_set_id: ruleId,
          callout_id: c,
          order: 1,
          formula: joinedFormula,
          result: result,
          otherwise_result: otherwise};

          calloutRuleCreate.push(calloutRule);
      }
    })
    return(calloutRuleCreate);
  }

  public async saveNewRuleSet() {
    if (this.validForm) {
      this.saveRuleDialog = false;
      let tableRules: IProbeRuleCreate[] = [this.probeRulesBase]
      // this.controlsTable.forEach((t) => {
      //   if(t.probe_id!==null) {
      //     tableRules = tableRules.concat(this.asProbeRuleCreate(t))
      //   }
      // })
      this.rulesTable.forEach((t) => {
        if(t.probe_id!==null) {
          tableRules = tableRules.concat(this.asProbeRuleCreate(t))
        }
      })

      //Verify template selected AND a name was entered
      if (this.createName.length>0 && this.selectedTemplate !== null) {
        this.ruleSetCreate.name = this.createName;
        this.ruleSetCreate.array_template_id = this.selectedTemplate;
        const responseRuleSet = await api.createRuleSet(readToken(this.$store), this.ruleSetCreate);
        if (responseRuleSet) {
          const ruleSet = responseRuleSet.data;
          this.ruleList.push(ruleSet);
          this.ruleName = ruleSet.name;
          
          //Save Probe Rules
          this.probeRules = [];
          for (const t of tableRules) {
            t.rule_set_id = ruleSet.id;
            try {
              const responseProbeRules = await api.createProbeRule(readToken(this.$store), t);
              if (responseProbeRules) {
                this.probeRules.push(responseProbeRules.data);
              }
            } catch (error) {
              // Handle any errors that occur during the API call
              console.error(error);
            }
          }

          //Save Panel Rules
          let newPanelRule: IPanelRuleCreate[] = []
          for (let i = 0; i < this.panelRules.length; i++){
            newPanelRule.push({rule_set_id: ruleSet.id,
              panel_name: this.panelRules[i].panel_name,
              order: this.panelRules[i].order,
              formula: this.panelRules[i].formula,
              result: this.panelRules[i].result,
              otherwise_result: this.panelRules[i].otherwise_result
            });
          }
          const responsePanel = await api.createPanelRules(readToken(this.$store), newPanelRule);
          if (responsePanel){
            this.panelRules = responsePanel.data;
          }

          //Save Callout Rules
          let newCalloutRules: ICalloutRuleCreate[] = []
          // Save Panel Rules
          for (let i = 0; i < this.calloutRules.length; i++){
            newCalloutRules.push({rule_set_id: ruleSet.id,
              callout_id: this.calloutRules[i].callout_id,
              order: this.calloutRules[i].order,
              formula: this.calloutRules[i].formula,
              result: this.calloutRules[i].result,
              otherwise_result: this.calloutRules[i].otherwise_result
            });
          }
          const reponseCallout = await api.createCalloutRules(readToken(this.$store), newCalloutRules);
          if (reponseCallout){
            this.calloutRules = reponseCallout.data;
          }

        }
      }
    }
  }

  public async getDistinctTemplate() {
    this.templateList = [];
    const allTemplates: IArrayTemplate[] = [];
    const currentSelection = this.selectedTemplate;
    //get array template id for all runs selected
    await Promise.all(
      this.selectedRuns.map(async (run) => {
        if (run.array_template) {
          allTemplates.push(run.array_template);
        }
      }),
    );
    const uniqueIds = new Set();
    // Grab unique keys
    for (const obj of allTemplates) {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        this.templateList.push(obj);
      }
    }
    if (this.templateList.length>0 && this.templateList.length!=null && this.selectedTemplate === null) {
      this.selectedTemplate = this.templateList[0].id;
    }
  }

  public async getDistinctRules() {
    this.ruleList = [];
    //get array all rule sets associated with template id
    if (this.selectedTemplate != null) {
      try {
        const response = await api.getArrayTemplateRuleSets(readToken(this.$store), this.selectedTemplate);
        if (response) {
          this.ruleList = response.data;
          this.ruleName = this.ruleList[0].name;
        }
      } catch (error) {
        //No rules with a template
      }
    }
  }

  public getL3Rule(callout: string, field: string):number {
    const tableEntry = this.calloutTable.find((t) => t.callout_name === callout);
    
    if (!tableEntry) {
        // If the callout is not found in calloutTable, return 0
        return 0;
    }
    
    const resultEntry = this.calloutResults.find((r) => tableEntry.callout_id === r.callout_id);
    
    if (resultEntry) {
        // If a matching result entry is found, return the field value
        return resultEntry[field] || 0; // Return 0 if the field value is undefined or null
    } else {
        // If no matching result entry is found, return 0
        return 0;
    }
  }

  public getL2Rule(panel: string, field: string):number {
    let result: number = -1;
    this.l2Results.forEach((r) => {
      if (r.panel_association === panel) {
        result = r[field];
      }

    })
    if (result !== -1) {
      return(result)
    } else {
      return(0)
    }
  }

  public getPanelProbes(panel:string): IRulesTable[]{
    let panelProbes: IRulesTable[]=[];
    panelProbes = this.rulesTable.filter((p)=> p.panel_association===panel);
    return(panelProbes)
  }

  public getUniqueObjects(data: any[], field: string) {
    const seen = new Set();
    return data.filter(item => {
        const fieldValue = item[field];
        if (!seen.has(fieldValue)) {
            seen.add(fieldValue);
            return true;
        }
        return false;
    });
  }

  public async computeUniqueProbes() {
    this.uniqueProbes = [];
    this.uniqueProbes = Array.from(new Set(this.probes.map((atp) => atp.panel_name)))
        .map((panelName) => this.probes.find((atp) => atp.panel_name === panelName))
        .filter((atp): atp is IArrayTemplateProbeReplicate => atp !== undefined);
    const pairedDiscrim = this.updateDiscriminationPairs();
    this.uniqueProbes = this.uniqueProbes.filter((p) => p.function!==EProbeFunction.discrimination);
    this.uniqueProbes = [...this.uniqueProbes,...pairedDiscrim];
  }

  public updateProbeTableCalls(table: IRulesTable[], results: IProbeResults[]): IRulesTable[] {
    table.forEach((item) => {
      const filtered = results.filter((r) =>
        r.probe_id === item.panel_id);
      if (filtered) {
        item.l1_call = [filtered[0].positive, filtered[0].negative, filtered[0].invalid];
      } else {
        item.l1_call = [0,0,0];
      }
    });
    return table;
  }

  public async updateRulesTable() {
    let masks: IRulesTable[] = [];
    const concatTables: IRulesTable[] = this.rulesTable;//.concat(this.controlsTable);
    concatTables.forEach((t) => {
      if (t.probe_id !== null) {
        masks.push(t)
      }
    });

    this.rulesTable = [];
    // this.controlsTable = [];
    const baseRules: IProbeRule = {
      id: 123, // Assign placeholder for typing
      ...this.probeRulesBase,
      created_on: 'placeholder',
      created_by_id: 0,
      modified_by_id: 0
    };

    this.uniqueProbes.forEach((probe) => {
      const probeFound = this.probeRules.find((p) => p.probe_id === probe.probe_id);
      const tableMaskFound = masks.find((t) => t.probe_id === probe.probe_id);
      let updatedRules: IRulesTable = {} as IRulesTable;
      if( tableMaskFound) {
        updatedRules = tableMaskFound;
      } else if( probeFound ) {
        //if ID found we override defaults
        updatedRules = this.asProbeRules(probe, probeFound);
      } else if (baseRules) {
        //store unique probe
        updatedRules = this.asProbeRules(probe, baseRules);
      }
      // if (Object.keys(updatedRules).length>0 && updatedRules.panel_association !== 'Controls') {
      this.rulesTable.push(updatedRules);
      // } else if(updatedRules.panel_association === 'Controls') {
      //   this.controlsTable.push(updatedRules);
      // }
    });
    this.calloutTable = this.getUniqueObjects(this.rulesTable, 'panel_association');
  }

  public updateDiscriminationPairs(): IArrayTemplateProbeReplicate[] {
    const discrimProbes = this.uniqueProbes.filter((p) => p.function===EProbeFunction.discrimination);
    const groupedDiscrim: Array<[string, boolean, number]> = [];
    const discrimPairedATP: IArrayTemplateProbeReplicate[] = [];
    //assign group and wt to probes based on names
    if(discrimProbes !== undefined) {
      discrimProbes.forEach((p) => {
        const textExtracted = this.parseDiscriminationStr(p.panel_name, p.panel_association);
        if (textExtracted[0][0] && textExtracted[1][0]) {
            const group = textExtracted[0][0];
            const isWT = textExtracted[1][0].includes('WT');
            const probeID = p.probe_id;
            groupedDiscrim.push([group, isWT, probeID]);
        }
      });
      //grab unique group names
      const groupNames = Array.from(new Set(groupedDiscrim.map((item) => item[0])));

      //make wt pairs and update rules table
      groupNames.forEach((g) => {
        const filteredGroup = groupedDiscrim.filter((d) => d[0] === g);
        const filteredWt = filteredGroup.filter((d) => d[1] === true)[0];
        if(filteredWt) {
          //assuming only 1 wt per group
          const wildTypeATP = discrimProbes.find((p) => p.probe_id === filteredWt[2]);
          if(wildTypeATP !== undefined) {
            filteredGroup.forEach((d) => {
              if(d[2] !== wildTypeATP.probe_id) {
                const joinedATP = discrimProbes.filter((p) => p.probe_id === d[2])[0];
                if (joinedATP) {
                  joinedATP.panel_name = wildTypeATP.panel_name + '-' + joinedATP.panel_name;
                  discrimPairedATP.push(joinedATP);
                }
              }
            });
          }
        }
      });
    }
    return(discrimPairedATP);
  }

  public parseDiscriminationStr(text: string, panel: string): string[][] {
    const returnTxt: string[][] = [];
    const pattern = /[pP]\d{2}|[MmWw][Tt](\d+)?/g;
    const matches = text.match(pattern);

    if (matches) {
      const pNumber = matches.filter((match) => /^p\d{2}$/i.test(match)).map((match) => match.toUpperCase());
      const wtTag = matches.filter((match) => ['M', 'W','m','w'].includes(match[0])).map((match) =>
      match.toUpperCase());
      let groupNumber: RegExpMatchArray|null = null;
      if (wtTag!=null) {
        groupNumber = wtTag[0].match(/(\d+)/g);
      }
      //Equal to out group pairing
      returnTxt[0] = [panel + pNumber[0] + (groupNumber==null?'' : groupNumber[0])];
      //Indicating WT or MT
      returnTxt[1] = [wtTag[0]];
    }
    return(returnTxt);
  }

  public asProbeRules(atp: IArrayTemplateProbeReplicate, rule: IProbeRule ): IRulesTable {
    let probeRule: IRulesTable = {} as IRulesTable;
    probeRule.id = rule.id;
    probeRule.rule_set_id = rule.rule_set_id;
    probeRule.probe_id = rule.probe_id;
    probeRule.rr_method = rule.rr_method;
    probeRule.created_on = rule.created_on;
    probeRule.created_by_id = rule.created_by_id;
    probeRule.modified_by_id = rule.modified_by_id;
    probeRule.panel_id = atp.probe_id;
    probeRule.panel_name = atp.panel_name;
    probeRule.order_name = atp.order_name;
    probeRule.panel_association = atp.panel_association;
    probeRule.function = atp.function;
    probeRule.callout_id = atp.callout_id;
    probeRule.callout_name = atp.callout_name;

    //positive_count, negative_count, invalid_count
    probeRule.l1_call = [0, 0 ,0];

    //Create the probe rules table object
    if( atp.function === EProbeFunction.depletion ) {
      probeRule.use_metric4 = rule.use_ct;
      probeRule.metric4_low = rule.ct_th_low;
      probeRule.metric4_high = rule.ct_th_high;
      probeRule.metric4_text = '\u2264 Ct \u2264';

    } else if( atp.function === EProbeFunction.discrimination ) {
      probeRule.use_metric1 = rule.use_dpq;
      probeRule.metric1_low = rule.dpq_th_low;
      probeRule.metric1_high = rule.dpq_th_high;
      probeRule.metric1_text = '\u2264 \u0394Pq \u2264';
      probeRule.use_metric2 = rule.use_daq;
      probeRule.metric2_low = rule.daq_th_low;
      probeRule.metric2_high = rule.daq_th_high;
      probeRule.metric2_text = '\u2264 \u0394Aq \u2264';
      probeRule.use_metric3 = rule.use_dtm;
      probeRule.metric3_low = rule.dtm_th_low;
      probeRule.metric3_high = rule.dtm_th_high;
      probeRule.metric3_text = '\u2264 \u0394Tm \u2264';

    } else if( atp.function === EProbeFunction.target ) {
      probeRule.use_metric1 = rule.use_pq;
      probeRule.metric1_low = rule.pq_th_low;
      probeRule.metric1_high = rule.pq_th_high;
      probeRule.metric1_text = '\u2264 Pq \u2264';
      probeRule.use_metric2 = rule.use_aq;
      probeRule.metric2_low = rule.aq_th_low;
      probeRule.metric2_high = rule.aq_th_high;
      probeRule.metric2_text = '\u2264 Aq \u2264';
      probeRule.use_metric3 = rule.use_tm;
      probeRule.metric3_low = rule.tm_th_low;
      probeRule.metric3_high = rule.tm_th_high;
      probeRule.metric3_text = '\u2264 Tm \u2264';

    } else if( atp.function === EProbeFunction.control && atp.panel_name.match(this.hybc1Regex)) {
      probeRule.panel_association = 'Controls';
      probeRule.use_metric1 = rule.use_hybc1_pq;
      probeRule.metric1_low = rule.hybc1_pq_th_low;
      probeRule.metric1_high = rule.hybc1_pq_th_high;
      probeRule.metric1_text = '\u2264 Pq \u2264';
      probeRule.use_metric2 = rule.use_hybc1_aq;
      probeRule.metric2_low = rule.hybc1_aq_th_low;
      probeRule.metric2_high = rule.hybc1_aq_th_high;
      probeRule.metric2_text = '\u2264 Aq \u2264';
      probeRule.use_metric3 = rule.use_hybc1_tm;
      probeRule.metric3_low = rule.hybc1_tm_th_low;
      probeRule.metric3_high = rule.hybc1_tm_th_high;
      probeRule.metric3_text = '\u2264 Tm \u2264';

    } else if( atp.function === EProbeFunction.control && atp.panel_name.match(this.hybc2Regex)) {
      probeRule.panel_association = 'Controls';
      probeRule.use_metric1 = rule.use_hybc2_pq;
      probeRule.metric1_low = rule.hybc2_pq_th_low;
      probeRule.metric1_high = rule.hybc2_pq_th_high;
      probeRule.metric1_text = '\u2264 Pq \u2264';
      probeRule.use_metric2 = rule.use_hybc2_aq;
      probeRule.metric2_low = rule.hybc2_aq_th_low;
      probeRule.metric2_high = rule.hybc2_aq_th_high;
      probeRule.metric2_text = '\u2264 Aq \u2264';
      probeRule.use_metric3 = rule.use_hybc2_tm;
      probeRule.metric3_low = rule.hybc2_tm_th_high;
      probeRule.metric3_high = rule.hybc2_tm_th_high;
      probeRule.metric3_text = '\u2264 Tm \u2264';

    } else if( atp.function === EProbeFunction.normalization ) {
      probeRule.panel_association = 'Controls';
      probeRule.use_metric1 = rule.use_sbr;
      probeRule.metric1_low = rule.sbr_th_low;
      probeRule.metric1_text = '\u2264 SBR';
      probeRule.use_metric2 = rule.use_amplitude;
      probeRule.metric2_low = rule.amplitude_th_low;
      probeRule.metric2_text = '\u2264 Amplitude';
      probeRule.use_metric3 = rule.use_stability;
      probeRule.metric3_low = rule.stability_th_low;
      probeRule.metric3_text = '\u2264 Stability';

    } else {
      probeRule = {} as IRulesTable;
    }
    return(probeRule);
  }

  public asProbe(atp: IArrayTemplateProbeReplicate): IProbe {
    return({
      id: atp.probe_id,
      panel_name: atp.panel_name,
      order_name: atp.order_name,
      panel_association: atp.panel_association,
      callout_id: atp.callout_id,
      callout: null,
      gene_region: atp.gene_region,
      function: atp.function,
      probe_sequence: atp.probe_sequence,
      probe_type: atp.probe_type,
      wild_type_probe_id: atp.wild_type_probe_id,
      analysis_code: atp.analysis_code,
      probe_description: atp.probe_description,
      date_added: '',
      created_by_id: 0,
      modified_by_id: 0,
    });
  }

  public asTableToProbeRule(r: IRulesTable, probeRule: IProbeRule): IProbeRule {
    if (r.function === EProbeFunction.target) {
        probeRule.use_pq = r.use_metric1;
        probeRule.pq_th_low = r.metric1_low;
        probeRule.pq_th_high = r.metric1_high;
        probeRule.use_aq = r.use_metric2;
        probeRule.aq_th_low = r.metric2_low;
        probeRule.aq_th_high = r.metric2_high;
        probeRule.use_tm = r.use_metric3;
        probeRule.tm_th_low = r.metric3_low;
        probeRule.tm_th_high = r.metric3_high;
    } else if (r.function === EProbeFunction.discrimination) {
        probeRule.use_dpq = r.use_metric1;
        probeRule.dpq_th_low = r.metric1_low;
        probeRule.dpq_th_high = r.metric1_high;
        probeRule.use_daq = r.use_metric2;
        probeRule.daq_th_low = r.metric2_low;
        probeRule.daq_th_high = r.metric2_high;
        probeRule.use_dtm = r.use_metric3;
        probeRule.dtm_th_low = r.metric3_low;
        probeRule.dtm_th_high = r.metric3_high;
    } else if (r.function === EProbeFunction.depletion) {
        probeRule.use_ct = r.use_metric4;
        probeRule.ct_th_low = r.metric4_low;
        probeRule.ct_th_high = r.metric4_high;
    } else if (r.function === EProbeFunction.control && r.panel_name.match(this.hybc1Regex)) {
        probeRule.use_hybc1_pq = r.use_metric1;
        probeRule.hybc1_pq_th_low = r.metric1_low;
        probeRule.hybc1_pq_th_high = r.metric1_high;
        probeRule.use_hybc1_aq = r.use_metric2;
        probeRule.hybc1_aq_th_low = r.metric2_low;
        probeRule.hybc1_aq_th_low = r.metric2_high;
        probeRule.use_hybc1_tm = r.use_metric3;
        probeRule.hybc1_tm_th_low = r.metric3_low;
        probeRule.hybc1_tm_th_low = r.metric3_high;
    } else if (r.function === EProbeFunction.control && r.panel_name.match(this.hybc2Regex)) {
        probeRule.use_hybc2_pq = r.use_metric1;
        probeRule.hybc2_pq_th_low = r.metric1_low;
        probeRule.hybc2_pq_th_high = r.metric1_high;
        probeRule.use_hybc2_aq = r.use_metric2;
        probeRule.hybc2_aq_th_low = r.metric2_low;
        probeRule.hybc2_aq_th_low = r.metric2_high;
        probeRule.use_hybc2_tm = r.use_metric3;
        probeRule.hybc2_tm_th_low = r.metric3_low;
        probeRule.hybc2_tm_th_low = r.metric3_high;
    } else if (r.function === EProbeFunction.normalization) {
        probeRule.use_sbr = r.use_metric1;
        probeRule.sbr_th_low = r.metric1_low;
        probeRule.use_amplitude = r.use_metric2;
        probeRule.amplitude_th_low = r.metric2_low;
        probeRule.use_stability = r.use_metric3;
        probeRule.stability_th_low = r.metric3_low;
    }
    return(probeRule);
  }

  public asProbeRuleCreate(r: IRulesTable): IProbeRuleCreate {
    const probeRule: IProbeRuleCreate = {
        rule_set_id: r.rule_set_id,
        probe_id: r.probe_id,
        rr_method: r.rr_method,

        use_pq: false,
        pq_th_low: 0,
        pq_th_high: 0,
        use_aq: false,
        aq_th_low: 0,
        aq_th_high: 0,
        use_tm: false,
        tm_th_low: 0,
        tm_th_high: 0,

        use_dpq: false,
        dpq_th_low: 0,
        dpq_th_high: 0,
        use_daq: false,
        daq_th_low: 0,
        daq_th_high: 0,
        use_dtm: false,
        dtm_th_low: 0,
        dtm_th_high: 0,

        use_ct: false,
        ct_th_low: 0,
        ct_th_high: 0,

        use_sbr: false,
        sbr_th_low: 0,
        use_amplitude: false,
        amplitude_th_low: 0,
        use_stability: false,
        stability_th_low: 0,

        use_hybc1_pq: false,
        hybc1_pq_th_low: 0,
        hybc1_pq_th_high: 0,
        use_hybc1_aq: false,
        hybc1_aq_th_low: 0,
        hybc1_aq_th_high: 0,
        use_hybc1_tm: false,
        hybc1_tm_th_low: 0,
        hybc1_tm_th_high: 0,
        use_hybc2_pq: false,
        hybc2_pq_th_low: 0,
        hybc2_pq_th_high: 0,
        use_hybc2_aq: false,
        hybc2_aq_th_low: 0,
        hybc2_aq_th_high: 0,
        use_hybc2_tm: false,
        hybc2_tm_th_low: 0,
        hybc2_tm_th_high: 0,
    };

    if (r.function === EProbeFunction.target) {
        probeRule.use_pq = r.use_metric1;
        probeRule.pq_th_low = r.metric1_low;
        probeRule.pq_th_high = r.metric1_high;
        probeRule.use_aq = r.use_metric2;
        probeRule.aq_th_low = r.metric2_low;
        probeRule.aq_th_high = r.metric2_high;
        probeRule.use_tm = r.use_metric3;
        probeRule.tm_th_low = r.metric3_low;
        probeRule.tm_th_high = r.metric3_high;
    } else if (r.function === EProbeFunction.depletion) {
        probeRule.use_ct = r.use_metric4;
        probeRule.ct_th_low = r.metric4_low;
        probeRule.ct_th_high = r.metric4_high;
    } else if (r.function === EProbeFunction.control && r.panel_name.match(this.hybc1Regex)) {
        probeRule.use_hybc1_pq = r.use_metric1;
        probeRule.hybc1_pq_th_low = r.metric1_low;
        probeRule.hybc1_pq_th_high = r.metric1_high;
        probeRule.use_hybc1_aq = r.use_metric2;
        probeRule.hybc1_aq_th_low = r.metric2_low;
        probeRule.hybc1_aq_th_low = r.metric2_high;
        probeRule.use_hybc1_tm = r.use_metric3;
        probeRule.hybc1_tm_th_low = r.metric3_low;
        probeRule.hybc1_tm_th_low = r.metric3_high;
    } else if (r.function === EProbeFunction.control && r.panel_name.match(this.hybc2Regex)) {
        probeRule.use_hybc2_pq = r.use_metric1;
        probeRule.hybc2_pq_th_low = r.metric1_low;
        probeRule.hybc2_pq_th_high = r.metric1_high;
        probeRule.use_hybc2_aq = r.use_metric2;
        probeRule.hybc2_aq_th_low = r.metric2_low;
        probeRule.hybc2_aq_th_low = r.metric2_high;
        probeRule.use_hybc2_tm = r.use_metric3;
        probeRule.hybc2_tm_th_low = r.metric3_low;
        probeRule.hybc2_tm_th_low = r.metric3_high;
    } else if (r.function === EProbeFunction.normalization) {
        probeRule.use_sbr = r.use_metric1;
        probeRule.sbr_th_low = r.metric1_low;
        probeRule.use_amplitude = r.use_metric2;
        probeRule.amplitude_th_low = r.metric2_low;
        probeRule.use_stability = r.use_metric3;
        probeRule.stability_th_low = r.metric3_low;
    }

    return probeRule;
  }

  public asDefaults(r: IProbeRule): IProbeRuleCreate {
    return({
      rule_set_id: r.rule_set_id,
    //default values = null
    probe_id: r.probe_id,
    //All or nothing method. Can't mix/match methods
    rr_method: r.rr_method,
    //for target probes
    use_pq: r.use_pq,
    pq_th_low: r.pq_th_low,
    pq_th_high: r.pq_th_high,
    use_aq: r.use_aq,
    aq_th_low: r.aq_th_low,
    aq_th_high: r.aq_th_high,
    use_tm: r.use_tm,
    tm_th_low: r.tm_th_low,
    tm_th_high: r.tm_th_high,
    //for discrimination probes
    use_dpq: r.use_dpq,
    dpq_th_low: r.dpq_th_low,
    dpq_th_high: r.dpq_th_high,
    use_daq: r.use_daq,
    daq_th_low: r.daq_th_low,
    daq_th_high: r.daq_th_high,
    use_dtm: r.use_dtm,
    dtm_th_low: r.dtm_th_low,
    dtm_th_high: r.dtm_th_high,
    //for depletion probes
    use_ct: r.use_ct,
    ct_th_low: r.ct_th_low,
    ct_th_high: r.ct_th_high,
    //for controls
    use_sbr: r.use_sbr,
    sbr_th_low: r.sbr_th_low,
    use_amplitude: r.use_amplitude,
    amplitude_th_low: r.amplitude_th_low,
    use_stability: r.use_stability,
    stability_th_low: r.stability_th_low,
    use_hybc1_pq: r.use_hybc1_pq,
    hybc1_pq_th_low: r.hybc1_pq_th_low,
    hybc1_pq_th_high: r.hybc1_pq_th_high,
    use_hybc1_aq: r.use_hybc1_aq,
    hybc1_aq_th_low: r.hybc1_aq_th_low,
    hybc1_aq_th_high: r.hybc1_aq_th_high,
    use_hybc1_tm: r.use_hybc1_tm,
    hybc1_tm_th_low: r.hybc1_tm_th_low,
    hybc1_tm_th_high: r.hybc1_tm_th_high,
    use_hybc2_pq: r.use_hybc2_pq,
    hybc2_pq_th_low: r.hybc2_pq_th_low,
    hybc2_pq_th_high: r.hybc2_pq_th_high,
    use_hybc2_aq: r.use_hybc2_aq,
    hybc2_aq_th_low: r.hybc2_aq_th_low,
    hybc2_aq_th_high: r.hybc2_aq_th_high,
    use_hybc2_tm: r.use_hybc2_tm,
    hybc2_tm_th_low: r.hybc2_tm_th_low,
    hybc2_tm_th_high: r.hybc2_tm_th_high,
    });
  }

  public mounted() {
    this.loadingProbes = true
    this.buildNewTemplate();
  }

}
</script>

<style scoped>
.saveRow {
  display: flex;
  align-items: center;
  line-height: 1em;
}
.graph-container {
  display: flex;
  padding: 0px;
}
.graph-container span {
  flex: 1;
}
.panel-space {
  margin-bottom: 20px;
}

.align-top {
  vertical-align: top;
}
.color-key {
  margin-left: 10px;
}
.key-text{
  font-size: small;
}

.title-font{
  font-size: medium;
  font-weight:bold;
}
.title-margin{
  margin-bottom: 0px;
  padding: 0px;
}

.centeredRow {
  justify-content: center;
}

.v-data-table >>> .col-panel-name { min-width: 12em; width: 12em; padding-right: 0; font-size:small; }
.v-data-table >>> .col-callout-name { min-width: 14em; width: 14em; padding-right: 0; font-size:small; }
.v-data-table >>> .pq-graph { min-width: 14em; width: 14em; padding-right: 0; font-size:small; }
.v-data-table >>> .aq-graph { min-width: 14em; width: 14em; padding-right: 0; font-size:small; }
.v-data-table >>> .tm-graph { min-width: 14em; width: 14em; padding-right: 0; font-size:small; }
.v-data-table >>> .ct-graph { min-width: 14em; width: 14em; padding-right: 0; font-size:small; }
.v-data-table >>> .col-l1-call { min-width: 8em; width: 8em; padding-right: 0;}
.v-data-table >>> .col-callout-call { padding-left: 0; min-width: 7em; padding: 0;}
.v-data-table >>> .col-use-metric1 { min-width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric1-low { width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric1-text { min-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric1-high { width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-use-metric2 { min-width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric2-low { width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric2-text { min-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric2-high { width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-use-metric3 { min-width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric3-low { width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric3-text { min-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric3-high { width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-use-metric4 { min-width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric4-low { width: 2em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric4-text { min-width: 4em; padding: 0; font-size:small; }
.v-data-table >>> .col-metric4-high { width: 4em; padding: 0; font-size:small; }

</style>
