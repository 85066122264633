import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { IComparatorTestResult } from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getComparatorTestResult(token: string, id: number) {
    return axios.get<IComparatorTestResult>(`${apiUrl}/api/v1/comparator_test_results/${id}`, authHeaders(token));
  },
  async getComparatorTestResults(token: string) {
    return axios.get<IComparatorTestResult[]>(`${apiUrl}/api/v1/comparator_test_results/`, authHeaders(token));
  },
  async updateComparatorTestResult(token: string, id: number, data: IComparatorTestResult) {
    return axios.put(`${apiUrl}/api/v1/comparator_test_results/${id}`, data, authHeaders(token));
  },
  async createComparatorTestResult(token: string, data: IComparatorTestResult) {
    return axios.post(`${apiUrl}/api/v1/comparator_test_results/`, data, authHeaders(token));
  },
  async deleteComparatorTestResult(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/comparator_test_results/${id}`, authHeaders(token));
  },
  async getComparatorTestResultsBySampleId(token: string, sampleId: number) {
    return axios.get<IComparatorTestResult[]>(`${apiUrl}/api/v1/comparator_test_results/?sample_id=${sampleId}`, authHeaders(token));
  },
  async searchComparatorTestResults(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    /*if (params.filters.length > 0) {
      for (const filter of params.filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`)
      }
    }*/
    return axios.post<IComparatorTestResult[]>(`${apiUrl}/api/v1/comparator_test_results/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getComparatorTestResultsCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/comparator_test_results/stats`, filters, authHeaders(token));
  },
  async getComparatorTestResultsFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/comparator_test_results/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },

};
