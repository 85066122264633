import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
function authHeaders(token: string) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  
  export default {
    async getAssayFields(token: string, payload: {field: string, skip: number, limit: number, f: string, filters:IFilter[]}) {
        return axios.post<IAutocompleteItem[]>(`${apiUrl}/api/v1/assays/field`, payload, authHeaders(token));
    },
}