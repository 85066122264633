<template>
  <div>
    <v-dialog
      v-model="enableFileDialog"
      width="700"
      scrollable
      max-heigh="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ fileDialogTitle }}
        </v-card-title>

        <v-card-text style="white-space: pre-line;">
          {{ fileDialogStr }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="enableFileDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer persistent :mini-variant="miniDrawer" v-model="showDrawer" fixed app>
      <v-layout column fill-height>
        <v-list>
          <v-subheader>Main menu</v-subheader>
          <v-list-item to="/main/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/wafer-dashboard" v-show="hasAdminAccess">
            <v-list-item-icon>
              <v-icon>web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Wafer Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/preclinical-dashboard">
            <v-list-item-icon>
              <v-icon>web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pre-clinical Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/profile/view">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item to="/main/runs">
            <v-list-item-icon>
              <v-icon>biotech</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Experiments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/probes">
            <v-list-item-icon>
              <v-icon>colorize</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Probes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/samples">
            <v-list-item-icon>
              <v-icon>hub</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Samples</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/callouts">
            <v-list-item-icon>
              <v-icon>dataset</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Callouts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!--
          <v-list-item to="/main/files">
            <v-list-item-icon>
              <v-icon>inventory</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-badge
                  :content="fileNotifications"
                  :value="fileNotifications > 0"
                  inline
                  class="mt-0"
                >
                  Files
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
 -->
        </v-list>
        <v-divider></v-divider>
        <v-list subheader>
          <v-subheader>Array</v-subheader>
          <v-list-item to="/main/print-runs">
            <v-list-item-icon>
              <v-icon>memory</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Print Runs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/array-templates">
            <v-list-item-icon>
              <v-icon>grid_4x4</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Array Templates</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list subheader v-show="hasAdminAccess">
          <v-subheader>Admin</v-subheader>
          <v-list-item to="/main/admin/comparatortestresults">
            <v-list-item-icon>
              <v-icon>dataset</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Comparator Results</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/admin/clinicaldata">
            <v-list-item-icon>
              <v-icon>table_view</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Clinical Data</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/admin/files">
            <v-list-item-icon>
              <v-icon>inventory</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Files</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/admin/users">
            <v-list-item-icon>
              <v-icon>group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/main/admin/users/create">
            <v-list-item-icon>
              <v-icon>person_add</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Create User</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <v-list>
          <v-list-item v-show="hasAdminAccess">
            <v-list-item-content>
              <file-pond
                name="test_upload"
                ref="pond"
                label-idle="Click here to upload .gxx or .nxx archives"
                v-bind:allow-multiple="true"
                :server="serverUploadOptions"
                v-on:init="handleFilePondInit"
                v-on:processfile="handleFilePondProcess"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-show="hasAdminAccess">
            <v-list-item-content>
              <file-pond
                name="upload_file"
                ref="pond"
                label-idle="Click here to upload a .pb logfile"
                v-bind:allow-multiple="true"
                :server="serverPbUploadOptions"
                v-on:init="handleFilePondInit"
                v-on:processfile="handleFilePondProcess"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <file-pond
                name="test_upload"
                ref="pond"
                label-idle="Drag/drop a .nxx or .nxa file to verify an ADF"
                v-bind:allow-multiple="true"
                :server="serverVerifyADF"
                v-on:init="handleFilePondInit"
                v-on:processfile="handleFilePondProcessADF"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="switchMiniDrawer">
            <v-list-item-icon>
              <v-icon v-html="miniDrawer ? 'chevron_right' : 'chevron_left'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Collapse</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar dark color="primary" app clipped-right>
      <v-app-bar-nav-icon @click.stop="switchShowDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title v-text="appName"></v-app-bar-title>
      
      <v-breadcrumbs
        :items="navItems"
        large
        light>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :to="item.to" active-class="">
            <span :style="`color: white`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        
        <v-list>
          <v-list-item to="/main/profile">
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>close</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="pa-3" app inset>
      <v-spacer></v-spacer>
      <span style="font-variant: small-caps;">api v{{ appInfo?.app_version }} ui v{{ version }}</span>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAppInfo } from '@/interfaces';

import { appName, apiUrl } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess, readAppInfo } from '@/store/main/getters';
import { readNavItems, readFileNotifications } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer, commitAddNotification } from '@/store/main/mutations';
import { commitSetFileNotifications, commitSetCreatingArchive } from '@/store/main/mutations';
import { dispatchUserLogOut, dispatchGetAppInfo } from '@/store/main/actions';
import { Socket } from 'vue-socket.io-extended';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType);



const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

interface IBreadcrumb {
    path: string;
    to: string;
    text: string;
    disabled: boolean;
}

@Component({
  components: {
    FilePond,
  },
})
export default class Main extends Vue {
  public appName = appName;
  public enableFileDialog = false;
  public fileDialogStr = ''
  public fileDialogTitle = ''
  public version = require('../../../package.json').version
  public appInfo: IAppInfo | null = {} as IAppInfo

  public async created() {
    await dispatchGetAppInfo(this.$store);
    this.appInfo = readAppInfo(this.$store);
  }

  @Socket() // --> listens to the event by method name, e.g. `connect`
  public connect() {
    // console.log('connection established');
    // Join a room here?

  }

  @Socket('file-upload')
  public onFileUpload(fileInfo) {
    // console.log(fileInfo);
    commitAddNotification(this.$store, { content: `${fileInfo.file_type}, ${fileInfo.test_count} tests, ${fileInfo.new_files} new`, color: 'success' });
    // this.updateTestView();
    // commitSetTest(this.$store, JSON.parse(testInfo));
  }

  @Socket('file-exists')
  public onFileExists() {
    commitAddNotification(this.$store, { content: 'This file was already uploaded', color: 'error' });
    // this.updateTestView();
    // commitSetTest(this.$store, JSON.parse(testInfo));
  }

  @Socket('created-archive')
  public onNewArchive(fileInfo) {
    // console.log(JSON.parse(testInfo));
    commitAddNotification(this.$store, { content: `Your archive ${fileInfo.filename} is ready to download`, color: 'success' });

    const count = readFileNotifications(this.$store);
    commitSetFileNotifications(this.$store, count + 1);
    commitSetCreatingArchive(this.$store, false);
  }

  get fileNotifications() {
    return readFileNotifications(this.$store);
  }

  public handleFilePondInit() {
    // console.log('FilePond has initialized');
  }

  public handleFilePondProcess(error, file) {
    if( error ) {
      console.log(error);
      if (error && error.code === 415) {
        commitAddNotification(this.$store, { content: 'Not a valid GeneXpert Dx File', color: 'error' });
      }
    }
  }

  public handleFilePondProcessADF(error, file) {
    if( error ) {
      console.log(error);
      if (error && error.code === 415) {
        commitAddNotification(this.$store, { content: 'Not a valid GeneXpert Dx File', color: 'error' });
      } else if (error && error.code === 406) {
        this.enableFileDialog = true
        this.fileDialogStr = error.body
        this.fileDialogTitle = 'ADF Criteria Not Met'
      }
    }
  }

  public get serverPbUploadOptions() {
    return {
      url: `${apiUrl}/api/v1/files/pb/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
    };
  }

  public get serverUploadOptions() {
    return {
      url: `${apiUrl}/api/v1/files/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
    };
  }

  public get serverVerifyADF() {
    return {
      url: `${apiUrl}/api/v1/files/verify_biochip_adf/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
      process: {
          onerror: (response) => JSON.parse(response).detail,
        },
    };
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get navItems() {
    const pathArray: string[] = this.$route.path.split('/');
    pathArray.shift();
    // console.log(this.$route.matched);
    const breadcrumbs = pathArray.reduce((breadcrumbArray: IBreadcrumb[], path, idx) => {
      const breadcrumb = {
        path,
        to: breadcrumbArray[idx - 1]
          ? '/' + breadcrumbArray.map((e) => e.path).join('/') + '/' + path
          : '/' + path,
        text: idx === pathArray.length - 1
          ? this.$route.matched[this.$route.matched.length - 1].meta.breadCrumb || path
          : path,
        disabled: false,
      };
      // console.log(`${idx} == ${pathArray.length - 1}`);
      // console.log(this.$route.matched[idx].meta.breadCrumb);
      breadcrumbArray.push(breadcrumb);
      return breadcrumbArray;
    }, []);

    return breadcrumbs;
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
</script>
