<template>
    <!-- TODO Dynamically create charts and format for when this supports different chart types -->
    <div id="crossRunChart"></div>
</template>

<script lang="ts">

import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { IAutocompleteItem, ITableOptions } from '@/interfaces';
import { IRunTrackerExtended } from '@/interfaces/bcdb';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';
import { filter } from 'vue/types/umd';
import { Dictionary } from 'vue-router/types/router';
import eventBus from '@/eventBus';

import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';


@Component({components: {},})
export default class CrossRunGraphs extends Vue {
    @ModelSync('value', 'change') public loading!: boolean;
    @Prop() public readonly selectedAnalysis!: string;
    @Prop() public readonly selectedRuns!: IRunTrackerExtended[];
    private pressureOverlayHandler: (e: any) => void = this.handlePressureOverlay.bind(this);

    public async generatePressureCurve(){
        const graphConfig = {
        displaylogo: false, 
        responsive: true,
        editable: true,
        };
        const chartLayout = {
        title: this.selectedAnalysis,
        yaxis: {title: 'PSI',
            automargin: true},
        xaxis: {automargin: true, title: 'Time'}
        } as Plotly.Layout;
        const traces: Plotly.Data[] = []

        await api.getPressureCurves(readToken(this.$store), this.selectedRuns.map((r) => r.id)).then((response) => {
        if (response) {
            console.log(response)
            response.data.forEach((d) =>{
            traces.push({
                x: d.x as Plotly.Datum[],
                y: d.y as Plotly.Datum[],
                name: d.name
            })
            })
        }
        })
        .catch((error) => {
        console.log('loadPressure: error', error);
        });

        Plotly.newPlot('crossRunChart', traces, chartLayout, graphConfig);
        this.loading=false
    }

    public mounted(){
        this.generatePressureCurve()
        eventBus.on('pressureOverlay', this.pressureOverlayHandler )
    }
    
    public beforeDestroy() {
        eventBus.off('pressureOverlay', this.pressureOverlayHandler )
    }

    private handlePressureOverlay(e: any) {
        this.generatePressureCurve();
    }
}

</script>

<style>
.title-font {
  font-size: medium;
}

.description-text {
  margin-top: 0px;
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: small;
  font-weight: 400;
}

.description-text, .v-row {
  line-height: 1em;
}

.v-banner{
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1em;
}

.v-text-field {
  font-size: small;
  max-width: 2000px;
}

.centeredRow {
  justify-content: center;
}
</style>