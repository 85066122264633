<template>
    <v-container fluid>
        <v-simple-table  class="elevation-1" style="margin-bottom: 30px;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left comparator-experiment-col">
                    Selected Experiments
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                        </template>
                        <span>Calculations are based on inline results from .nxx</span>
                      </v-tooltip>
                  </th>
                  <th class="text-left comparator-col">
                    Comparator
                  </th>
                  <th class="text-left comparator-stats-col">
                    True Positives
                  </th>
                  <th class="text-left comparator-stats-col">
                    True Negatives
                  </th>
                  <th class="text-left comparator-stats-col">
                    False Positives
                  </th>
                  <th class="text-left comparator-stats-col">
                    False Negatives
                  </th>
                  <th class="text-left comparator-stats-col">
                    Undetermined
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                      </template>
                      <div>Callouts that do not have a matching callouts in the comparator panels</div>
                      <div>These are excluded from PPA/NPA calculations</div>
                    </v-tooltip>
                  </th>
                  <th class="comparator-total-callout-col"> Total Callouts </th>
                  <th class="text-left comparator-ppa-col">
                    PPA
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                      </template>
                      <div>Positive Percent Agreement</div>
                      <div>PPA = TP/(TP+FN)</div>
                    </v-tooltip>
                  </th>
                  <th class="text-left comparator-npa-col">
                    NPA
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="primary">mdi-information-outline</v-icon>
                      </template>
                      <div>Negative Percent Agreement</div>
                      <div>NPA = TN/(TN+FP)</div>
                    </v-tooltip>
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(count, panel) in panel_counts" :key="panel">
                  <td>{{ count.experiments.size }}/{{ testResults.length }}</td>
                  <td>{{ panel }}</td>
                  <td>
                    {{ count.tp }}
                    <v-btn x-small 
                      outlined color="primary" 
                      class="viewButton"
                      v-if="count.tp"
                      @click="viewPressed('tp',panel)"> 
                      View 
                    </v-btn>
                  </td>
                  <td>
                    {{ count.tn }}
                    <v-btn x-small 
                      outlined color="primary" 
                      class="viewButton"
                      v-if="count.tn"
                      @click="viewPressed('tn',panel)"> 
                      View 
                    </v-btn>
                  </td>
                  <td>
                    {{ count.fp }}
                    <v-btn x-small 
                      outlined color="primary" 
                      class="viewButton"
                      v-if="count.fp"
                      @click="viewPressed('fp',panel)"> 
                      View 
                    </v-btn>
                  </td>
                  <td>
                    {{ count.fn }}
                    <v-btn x-small 
                      outlined color="primary" 
                      class="viewButton"
                      v-if="count.fn"
                      @click="viewPressed('fn',panel)"> 
                      View 
                    </v-btn>
                  </td>
                  <td>
                    {{ count.undetermined }}
                    <v-btn x-small
                      outlined color="primary" 
                      class="viewButton"
                      v-if="count.undetermined"
                      @click="viewPressed('undetermined',panel)"> 
                      View 
                    </v-btn>
                  </td>
                  <td>{{ count.tp+count.tn+count.fp+count.fn+count.undetermined }}</td>
                  <td v-if="count.tp + count.fn">{{ Math.round((count.tp/(count.tp + count.fn))*1000)/10 }}%</td>
                  <td v-else>0%</td>
                  <td v-if="count.tn + count.fp">{{ Math.round((count.tn/(count.tn + count.fp))*1000)/10 }}%</td>
                  <td v-else>0%</td>
                </tr>
              </tbody>
            </template>
        </v-simple-table>

        <MappedComparatorResultsDialog
        v-model="viewDialog"
        :viewType="viewType"
        :panel="viewPanel"
        :dataPpaNpaResults="dataPpaNpaResults"
        :selectedExperiments="testResults"
        :loading="loading"
        v-if="viewDialog">
        </MappedComparatorResultsDialog>

    </v-container>
</template>

<script lang="ts">
  import { Component, Vue, Watch, Prop, Model, ModelSync, VModel } from 'vue-property-decorator';
  import { IPpaNpa,IClinicalRuntracker } from '@/interfaces/bcdb';

  import MappedComparatorResultsDialog from '../MappedComparatorResultsDialog.vue';
  
  
  @Component({
    components: {
      MappedComparatorResultsDialog
    },
  })
  export default class ComparatorSummaryTable extends Vue {
    // @ModelSync('value', 'change', { type: Array }) public runs!: IClinicalRuntracker[]
    @Prop({default:()=>{}}) public panel_counts!: Record<string, {fp:number,fn:number,tp:number,tn:number,undetermined:number, experiments:Set<string>}>
    @Prop({default:()=>[]}) public readonly testResults!: IClinicalRuntracker[]
    @Prop({default:()=>[]}) public readonly dataPpaNpaResults!: IPpaNpa[]
    @Prop({ type: Boolean }) public loading!: boolean;
    
    public viewDialog: boolean= false
    public viewType = ''
    public viewPanel = ''

    public async viewPressed(viewType: string, panel:string=''){
      this.viewType=viewType
      this.viewPanel=panel
      this.viewDialog = true
    }

    public mounted() {
      //console.log("comparator mounted", this.loading);
    }
  }
</script>
  
<style scoped>

</style>