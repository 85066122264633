import { IComparatorMap, IClinicalRuntracker, IInternalCallouts, IPpaNpa, IExternalCallouts } from '@/interfaces/bcdb';
import { moveFieldToEnd } from './support-functions';

/* 
-----Clinical Data Helper-----
Purpose: to help with working with/manipulating data relating to clinicals.
*/

export function createPanelCounts(dataPpaNpaResults:IPpaNpa[], dataGroupBy:string, composite_rules:string[]){
    let panel_counts = {}
    let accessedPanelCallouts:string[] = []
    for (const item of dataPpaNpaResults) {
        if( dataGroupBy === 'Callout' && !composite_rules.includes(item.comparator_panel) ){
            const response = checkDuplicateComparatorCall(accessedPanelCallouts,item)
            if (response.accessed){
                continue
            } else{
                accessedPanelCallouts = response.accessedPanelCallouts
            }
        }

        const panel = item.comparator_panel
        if (!panel_counts[panel]) {
            panel_counts[panel] = { fp: 0, fn: 0, tp: 0, tn: 0, undetermined: 0, experiments: new Set([])};
        }
        panel_counts[panel].fp += item.fp.length;
        panel_counts[panel].fn += item.fn.length;
        panel_counts[panel].tp += item.tp.length;
        panel_counts[panel].tn += item.tn.length;
        panel_counts[panel].undetermined += item.undetermined.length;

        let panel_exps = item.tn.map((r) => r.run_name)
        panel_exps = panel_exps.concat(item.tp.map((r) => r.run_name))
        panel_exps = panel_exps.concat(item.fp.map((r) => r.run_name))
        panel_exps = panel_exps.concat(item.fn.map((r) => r.run_name))
        panel_exps = panel_exps.concat(item.undetermined.map((r) => r.run_name))

        panel_counts[panel].experiments = new Set([...panel_counts[panel].experiments, ...panel_exps])
    }
    //Sort panel counts by panel name
    const entries = Object.entries(panel_counts);
    entries.sort(([keyA], [keyB]) => {
        return keyA.localeCompare(keyB);
    });
    panel_counts = Object.fromEntries(entries);

    Object.keys(panel_counts).forEach((e)=>{
        if (composite_rules.includes(e)){
            moveFieldToEnd(panel_counts, e)
        }
    })
    return(panel_counts)
}


export function getGroupCounts(dataPpaNpaResults:IPpaNpa[]):Record<string, {fn:number[], fp:number[], tn:number[], tp:number[], ppa:number[], npa:number[], group:string[]}>{
    let panel_group_count: Record<string, {fn:number[], fp:number[], tn:number[], tp:number[], ppa:number[], npa:number[], group:string[]}> = {}  
    for (const item of dataPpaNpaResults) {
    const panel = item.comparator_panel
    if (!panel_group_count[panel]) {
        panel_group_count[panel] = { fp: [], fn: [], tn: [], tp: [], ppa: [], npa: [], group: [] };
    }
    panel_group_count[panel].fp.push(item.fp.length)
    panel_group_count[panel].fn.push(item.fn.length)
    panel_group_count[panel].tn.push(item.tn.length)
    panel_group_count[panel].tp.push(item.tp.length)
    panel_group_count[panel].ppa.push(item.ppa)
    panel_group_count[panel].npa.push(item.npa)
    panel_group_count[panel].group.push(item.group)
    }
    return(panel_group_count)
}


export function checkDuplicateComparatorCall(accessedPanelCallouts:string[], item:IPpaNpa):{accessed:boolean, accessedPanelCallouts:string[]}{
    /* 
    This function is intended to help keep track of comparator panels and callout combinations accessed in IPpaNpa.
    Primary use case is to avoid double counting callouts that have shared comparator callouts.
    */
    let comparatorCall = item.tn.map((r) => `${item.comparator_panel} ${r.primary_join.join(',')}`)
    comparatorCall = comparatorCall.concat(item.tp.map((r) => `${item.comparator_panel} ${r.primary_join.join(',')}`))
    comparatorCall = comparatorCall.concat(item.fp.map((r) => `${item.comparator_panel} ${r.primary_join.join(',')}`))
    comparatorCall = comparatorCall.concat(item.fn.map((r) => `${item.comparator_panel} ${r.primary_join.join(',')}`))
    if (item.undetermined.flatMap((r) => r.primary_join).length){
        comparatorCall = comparatorCall.concat(item.undetermined.map((r) => `${item.comparator_panel} ${r.primary_join.join(',')}`))
    }
    if( comparatorCall.some(c => accessedPanelCallouts.includes(c)) ){
        //Don't double count items with shared vendor callouts
        return( {accessed:true, accessedPanelCallouts:accessedPanelCallouts} )
    } else {
        accessedPanelCallouts = accessedPanelCallouts.concat(comparatorCall)
        return( {accessed:false, accessedPanelCallouts:accessedPanelCallouts} )
    }
}


export function getMatrixTableHeaders(addedHeaders: any):{tableHeaders:any, headerOverride:any, detectionChipFields:string[]}{
    let detectionChipFields:string[]=[]
    let tableHeaders=[
        { text: 'Date of Run', value: 'date_of_run', cellClass: 'col-date', align: 'center' },
        { text: 'Experiment', value: 'experiment', cellClass: 'col-experiment', align: 'left' },
        { text: 'Type', value: 'experiment_type', cellClass: 'col-experiment_type', align: 'center' },
        { text: 'Comparison', value: 'comparison', cellClass: 'col-experiment_type', align: 'center', divider:true },
    ];
    let headerOverride = JSON.parse(JSON.stringify(tableHeaders)); //deep copy
    let baseFields ={
        callout:{ text: 'Callout', value: '', cellClass: 'col-callout', align: 'center' },
        result_text:{ text: 'Result Text', value: '', cellClass: 'col-result-text', align: 'center' },
        vendor_callout:{ text: 'Vendor Callout', value: '', cellClass: 'col-result-text', align: 'center' },
        vendor_result:{ text: 'Vendor Result', value: '', cellClass: 'col-rig', align: 'center' },
    }
    Object.keys(addedHeaders).forEach((fields)=> {
        headerOverride.push({text: fields, value: fields, cellClass: 'col-result-text', align: 'center', headers:[], divider:true})
        addedHeaders[fields].forEach((f:string) => {
            let result:any = null
            if(fields !== 'Internal' && f.includes('result_text')){
                detectionChipFields.push(f)
                result = {...baseFields.vendor_result}
            }
            if(fields !== 'Internal' && f.includes('callout')){
                result = {...baseFields.vendor_callout}
            }
            if(fields === 'Internal' && f.includes('result_text')){
                detectionChipFields.push(f)
                result = {...baseFields.result_text}
            }
            if(fields === 'Internal' && f.includes('callout')){
                result = {...baseFields.callout}
            }

            if (result!==null){
                result.value = f
                tableHeaders.push(result)
                headerOverride[headerOverride.length-1].headers.push(result)
            }
        });
        tableHeaders[tableHeaders.length-1].divider=true
    })
    tableHeaders = tableHeaders.concat([{ text: 'SOC Test', value: 'soc_test', cellClass: 'col-rig', align: 'center' },
        { text: 'SOC Result', value: 'soc_result', cellClass: 'col-rig', align: 'center' },
        { text: 'Collection Date', value: 'soc_collection', cellClass: 'col-rig', align: 'center' },
        { text: 'Test Date', value: 'soc_test_date', cellClass: 'col-rig', align: 'center' },
        { text: 'Notes', value: 'notes', cellClass: 'col-experiment_type', align: 'center' },]
    )
    headerOverride = headerOverride.concat([{ text: 'SOC Test', value: 'soc_test', cellClass: 'col-rig', align: 'center' },
        { text: 'SOC Result', value: 'soc_result', cellClass: 'col-rig', align: 'center' },
        { text: 'Collection Date', value: 'soc_collection', cellClass: 'col-rig', align: 'center' },
        { text: 'Test Date', value: 'soc_test_date', cellClass: 'col-rig', align: 'center' },
        { text: 'Notes', value: 'notes', cellClass: 'col-experiment_type', align: 'center' },]
    )
    return {tableHeaders:tableHeaders, headerOverride:headerOverride, detectionChipFields:detectionChipFields}
}


export function formatExpectedRunList(dataPpaNpaResults: IPpaNpa[], field:string, 
    panel:string|null, selectedExperiments:IClinicalRuntracker[],
    tableGroupByExperimentName:boolean, tableGroupByVendorCallout:boolean
): {createdFields:any, runList:Object[]} {
    /* Creates a formatted run list linking vendor data, internal callouts, and run tracker information together. */
    let formattedResults: {createdFields:any, runList:Object[]} = {createdFields : {Internal:[]}, runList:[]}
    if( dataPpaNpaResults.length==0 || !dataPpaNpaResults[0].hasOwnProperty(field) ){
        return(formattedResults)
    }

    let results:any[] = []
    if( panel!==null ) { 
        results = dataPpaNpaResults.filter((d) => d.comparator_panel==panel).map((d) => d[field])

        let collectedIDs: string[] = []
        results.forEach((r) => {
            r.forEach((v_callout) =>{
                const vendorPanels = v_callout.comparison_callouts
                const test = selectedExperiments.filter((f) => f.RunTracker.experiment === v_callout.run_name)
                const response = formatInternalCallouts(v_callout, test, vendorPanels, 
                    collectedIDs, tableGroupByExperimentName, tableGroupByVendorCallout, formattedResults.createdFields, panel)
                
                formattedResults.runList = formattedResults.runList.concat(response.runList)
                collectedIDs = collectedIDs.concat(response.runList.map((fr)=> fr.id))
                formattedResults.createdFields = response.createdFields
            })
        })
    } else {
        //Collect for all panels **NOTE: There may be some dupliacte IDS**
        const panels = new Set(dataPpaNpaResults.map((d) => d.comparator_panel))
        panels.forEach((p)=>{
            const response = formatExpectedRunList(dataPpaNpaResults, field, p, selectedExperiments, tableGroupByExperimentName, tableGroupByVendorCallout)
            formattedResults.runList=formattedResults.runList.concat(response.runList)
            Object.keys(response.createdFields).forEach((cf)=>{
                if(Object.keys(formattedResults.createdFields).includes(cf)){
                    formattedResults.createdFields[cf] = [...new Set([...formattedResults.createdFields[cf], ...response.createdFields[cf]])]
                } else {
                    formattedResults.createdFields[cf] = response.createdFields[cf]
                }
            })
        })
    }
    return formattedResults
}


export function formatInternalCallouts(comparatorMap: IComparatorMap, test:IClinicalRuntracker[], 
    vendor:IExternalCallouts[]|null=null, collectedIDs:string[]|null=null, tableGroupByExperimentName:boolean, 
    tableGroupByVendorCallout:boolean, createdFields:any, panel:string):{createdFields:any, runList:any} {
    const internal_callouts = comparatorMap.internal_callouts
    let runList: object[] = []

    let group = `${tableGroupByExperimentName ? test[0].RunTracker.experiment : ''}`
    if(vendor!==null){
        group += `${tableGroupByVendorCallout ? (group=='' ? vendor.map((v)=>v.callout_name).join(', ') : ' | ' + vendor.map((v)=>v.callout_name).join(', ')) : ''}`
    }
    const uniqueID = test[0].RunTracker.id + comparatorMap.primary_join.join('')
    // const uniqueID = test[0].RunTracker.id+'_'+internal_callouts.map((ic)=> ic.callout_name).sort().join('|')+'_'+vendor?.map((v)=>v.product).join('|')+comparatorMap.notes
    if( collectedIDs===null || !collectedIDs.includes(uniqueID)) {
        runList.push({
            id: uniqueID,
            experiment: test[0].RunTracker.experiment,
            experiment_type: test[0].RunTracker.experiment_type,
            date_of_run: test[0].RunTracker.date_of_run,
            soc_test: test[0].soc_test,
            soc_result: test[0].soc_result,
            soc_collection: test[0].soc_collection,
            soc_test_date: test[0].soc_test_date,
            instrument: test[0].rig,
            adf_version: test[0].adf_version,
            group: group,
            comparison: panel,
            notes: comparatorMap.notes,
            external_callouts: vendor?.map((v)=>v.callout_name).join('|')
        })
        let increment = 1
        if(!internal_callouts){
            console.log(internal_callouts)
        }
        internal_callouts.forEach((ic) => {
            const calloutField = `callout${increment}`
            const resultField = `result_text${increment}`
            if(!createdFields.Internal.includes(calloutField)){
                createdFields.Internal = createdFields.Internal.concat([calloutField,resultField])   
            }
            runList[runList.length-1][calloutField]= ic.callout_name
            runList[runList.length-1][resultField]= ic.result_str
            increment += 1
        })
        let incrementVendor = {}
        vendor?.forEach((v)=>{
            if(!Object.keys(createdFields).includes(v.product)){
                createdFields[v.product]=[]
            }
            if(!Object.keys(incrementVendor).includes(v.product)){
                incrementVendor[v.product]=1
            }
            const calloutField = `${v.product}callout${incrementVendor[v.product]}`.replace(".", "")
            const resultField = `${v.product}result_text${incrementVendor[v.product]}`.replace(".", "")
            if(!createdFields[v.product].includes(calloutField)){
                createdFields[v.product] = createdFields[v.product].concat([calloutField,resultField])  
            }
            runList[runList.length-1][`vendor${incrementVendor[v.product]}`]= v.product
            runList[runList.length-1][calloutField]= v.callout_name
            runList[runList.length-1][resultField]= v.detected ? 'DETECTED' : (v.detected!==false ? '' : 'NOT DETECTED')
            incrementVendor[v.product] += 1
        })
    }
    return({createdFields:createdFields, runList: runList})
}

export function formatSocData(sampleIDs:string[], pairs:Record<string, any>[]) {
    let soc_data: Record<string, {soc_test:string[], soc_result:string[], soc_collection:string, soc_test_date:string}> = {};
    sampleIDs.forEach(ref => {
        const match = pairs.find(pair => pair.ref === ref);
        if (match && match.data.length > 0) {
            soc_data[ref] = {soc_test: [], soc_result: [], soc_collection:"", soc_test_date:""}
            for (var y in match.data) {
                const key = match.data[y].key.toLowerCase();
                if (key.includes("_pos") || key.includes("specify")) {
                    soc_data[ref].soc_result.push(match.data[y].value);
                } else if (key.includes("neg")) {
                    soc_data[ref].soc_result.push("Negative");
                } else if (key.includes("collection")) {
                    soc_data[ref].soc_collection = match.data[y].value;
                } else if ((key.includes("date") && key.includes("test")) || (key.includes("dt_soc"))) {
                    soc_data[ref].soc_test_date = match.data[y].value;
                } else {
                    soc_data[ref].soc_test.push(match.data[y].value)
                }
            }
        }
    });
    return (soc_data)
}  
