<template>
  <v-container fluid>
    <v-toolbar light>
      <v-toolbar-title>
        Sample Data
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="hasAdminAccess" color="primary" v-on:click="addSample()">New Sample</v-btn>
    </v-toolbar>

    <SampleFilterPicker
      v-model="selectedFilters">
    </SampleFilterPicker>

    <SampleList
      v-model="selectedSamples"
      :filters="selectedFilters"
      :message="message"
      :rowclick="rowClicked"
      @click:row="rowClicked(item)">
    </SampleList>

    <!-- <v-select
        v-model="select"
        :items="items"
        item-text="state"
        item-value="abbr"
        label="Select"
        return-object
        single-line
      ></v-select> -->

    <!-- <v-divider></v-divider>
    <v-card>
      <v-card-title>PC3 Contrived Sample Management</v-card-title>
      <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card style="width: 40em;">
            <v-card-text>
              <v-text-field
                :rules="[panelRule]"
                label="Panel 1-99"
                maxlength="2"
              ></v-text-field>
              <v-autocomplete
                item-text="name"
                return-object
                multiple
                :items="callouts"
                label="Included Callouts"
                v-model="selectedCallouts"
              ></v-autocomplete>
              <div v-if="selectedCallouts.length > 0">
                <h4>Concentration</h4>
                <v-text-field

                v-for="callout in selectedCallouts" v-bind:key="callout.id"
                suffix="nm"
                :label="callout.name"
              ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn>Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card style="width: 40em;">
            <v-data-table
              :headers="[{'text': 'Panel', 'value': 'panel'},{'text': 'Callouts', 'value': 'callouts'},{'text': '', 'value': 'actions'}]"
              :items="[{'panel': 'P01'}]"
              @click:row="selectPanel"
            >
              <template v-slot:item.callouts="{ item }">
                <v-menu
                  open-on-hover
                  bottom
                  offset-y
                  width="400"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on">3 Callouts</v-chip>
                  </template>

                  <v-list > !-- v-for="item in splitResult(value)" :key="item" --
                    <v-list-item two-line>
                      <v-list-item-title>PIV 2</v-list-item-title>
                      <v-list-item-subtitle>Conc: 200nm</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-title>B. parapertussis</v-list-item-title>
                      <v-list-item-subtitle>Conc: 200nm</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-title>Flu B</v-list-item-title>
                      <v-list-item-subtitle>Conc: 200nm</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn>Edit</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card style="width: 40em;">
            <v-card-title>PC3 Contrived Sample Management</v-card-title>
            <v-card-text>
              !--v-model="select"--
              <v-select
                :items="['CEP - Cepheid', 'Q - Quiagen']"
                label="SOC"
                single-line
              ></v-select>
              <v-select
                :items="['STD - Stanford', 'MTE - Montefiore']"
                label="Site"
                single-line
              ></v-select>
              !-- <v-text-field
                :rules="[panelRule]"
                label="Panel 1-99"
                maxlength="2"
              ></v-text-field> --
              <v-autocomplete
                :items="panels"
                label="Panel"
                item-text="name"
                item-value="name"
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.callouts.join('<br />')"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-text-field
                :rules="[replicateRule]"
                label="Replicates 0-999"
                maxlength="3"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn>Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card style="width: 40em;">
            <v-data-table
              :headers="[{'text': 'Barcode', 'value': 'barcode'},{'text': '', 'value': 'selection'},{'text': '', 'value': 'actions'}]"
              :items="[{'barcode': 'PC3CSTDP0'}]"
              @click:row="selectPanel"
            >
              <template v-slot:item.selection="{ item }">
                <v-text-field
                  :rules="[replicateRule]"
                  label="Replicate 0-999"
                  maxlength="3"
                  
                ></v-text-field>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn>Download Barcodes</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
    </v-card> -->
   

    <v-card>

    </v-card>

    <v-card v-if="sampleDetail">
      <v-btn v-if="sampleDetail" v-on:click="removeDetail">Close Detail</v-btn>
    </v-card>

    <v-card v-html="sampleDetail" v-if="sampleDetail" class="sample-detail">
    </v-card>

    <RightPanel v-if="hasAdminAccess" :showPanel="selectedSamples.length > 0">
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{selectedSamples[0] ? selectedSamples[0].target_name : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="subtitle-1">Sample Details</p>
          <v-form @keyup.enter="submit" v-model="valid" ref="form" @submit.prevent="" >
            <v-text-field
              label="Reference"
              type="text"
              v-model="sampleRef"
              v-validate="'required|alpha_num'"
              data-vv-name="Reference"
              :error-messages="errors.collect('Reference')">
            </v-text-field>
            <v-text-field
              label="Source"
              type="text"
              v-model="sampleSource"
              v-validate=""
              data-vv-name="Source"
              :error-messages="errors.collect('Source')">
            </v-text-field>
            <v-text-field
              label="Type"
              type="text"
              v-model="sampleType"
              v-validate=""
              data-vv-name="Type"
              :error-messages="errors.collect('Type')">
            </v-text-field>
            <v-text-field
              label="Media"
              type="text"
              v-model="sampleMedia"
              v-validate=""
              data-vv-name="Media"
              :error-messages="errors.collect('Media')">
            </v-text-field>

            <v-menu
              v-model="collectionDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="sampleCollectionDate"
                  label="Collection Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="sampleCollectionDate"
                @input="collectionDateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              label="Subject Reference"
              type="text"
              v-model="subjectRef"
              v-validate="'alpha_num'"
              data-vv-name="Subject Reference"
              :error-messages="errors.collect('Subject Reference')">
            </v-text-field>
            <v-text-field
              label="Subject Age"
              type="text"
              v-model="subjectAge"
              v-validate="'numeric'"
              data-vv-name="Subject Age"
              :error-messages="errors.collect('Subject Age')">
            </v-text-field>
            <v-select
              label="Subject Sex"
              type="text"
              :items="sexTypes"
              v-model="subjectSex"
              v-validate=""
              data-vv-name="Subject Sex"
              :error-messages="errors.collect('Subject Sex')">
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <span class='red--text'>{{ errorMessage }}</span>
          <v-spacer></v-spacer>
          <v-btn v-if="mode != 'View'" @click="close">Cancel</v-btn>
          <v-btn v-if="mode == 'Edit'" @click="deleteSample" :disabled="!valid">Delete</v-btn>
          <v-btn v-if="mode == 'Create'" @click="reset">Reset</v-btn>
          <v-btn v-if="mode != 'View'" @click="submit" color="primary" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>

    </RightPanel>

  </v-container>
</template>



<script lang="ts">
import RightPanel from '@/components/RightPanel.vue';
import SampleList from '@/components/SampleList.vue';
import SampleFilterPicker from '@/components/SampleFilterPicker.vue';

import { apiUrl } from '@/env';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { IAutocompleteItem, IFilter } from '@/interfaces';
import { ISample, IRunTracker, ICallout } from '@/interfaces/bcdb';
import { ESexType } from '@/interfaces/bcdb';
import { Validator } from 'vee-validate';
import { readHasAdminAccess } from '@/store/main/getters';


import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { v4 as uuidv4 } from 'uuid';

// import { Callout, readCallouts } from '@/client';

// type ICallout = {
//   name?: string;
//   id?: number;
// };


@Component({
  components: {
    SampleList,
    SampleFilterPicker,
    RightPanel,
  },
})
export default class Samples extends Vue {

  public mode = '';
  public selectedFilters: IFilter[] = [];
  public sampleRef: string = '';
  public sampleSource: string|null = null;
  public sampleType: string|null = null;
  public sampleMedia: string|null = null;
  public sampleCollectionDate: string|null = null;
  public subjectRef: string|null = null;
  public subjectAge: number|null = null;
  public subjectSex: ESexType|null = null;
  public sampleDetail: boolean = false;
  public collectionDateMenu: boolean = false;
  public valid = false;
  public message: string = '';
  public hasAdminAccess = readHasAdminAccess(this.$store);
  public selectedSamples: ISample[] = [];
  public errorMessage: string = '';
  public sexTypes = Object.values(ESexType)

  // public selectedCallouts: Callout[] = [];

  // public panels = [
  //   {
  //       "name": "P01",
  //       "callouts": [
  //           "PIV 2",
  //           "B. parapertussis",
  //           "Flu B"
  //       ]
  //   },
  //   {
  //       "name": "P02",
  //       "callouts": [
  //           "PIV 1",
  //           "Adv A",
  //           "RSV A"
  //       ]
  //   },
  //   {
  //       "name": "P03",
  //       "callouts": [
  //           "SARS-CoV-2",
  //           "M. pneumoniae",
  //           "Rhinovirus"
  //       ]
  //   }
  // ]

  // public callouts: Callout[] = [
  //   {"name":"Adenovirus", id: 1},
  //   {"name":"Bordetella parapertussis", id: 2 },
  //   {"name":"Bordetella pertussis", id: 3 },
  //   {"name":"Chlamydia pneumoniae", id: 4 },
  //   {"name":"SARS-CoV-2", id: 5 },
  //   {"name":"Coronavirus 229E", id: 6 },
  //   {"name":"Coronavirus HKU1", id: 7 },
  //   {"name":"Coronavirus NL63", id: 8},
  //   {"name":"Coronavirus OC43", id: 9},
  //   {"name":"Enterovirus", id: 10},
  //   {"name":"Enterovirus D68", id: 11},
  //   {"name":"Enterovirus, Rhinovirus", id: 12},
  //   {"name":"Flu Antiviral R292K", id: 13},
  //   {"name":"Flu Antiviral H275Y", id: 14},
  //   {"name":"Human Metapneumovirus", id: 15},
  //   {"name":"Influenza A H1 non pandemic", id: 16},
  //   {"name":"Influenza A", id: 17},
  //   {"name":"Influenza A H1 pdm09", id: 18},
  //   {"name":"Influenza A H3", id: 19},
  //   {"name":"Influenza A H5", id: 20},
  //   {"name":"Influenza A H7", id: 21},
  //   {"name":"Influenza B", id: 22},
  //   {"name":"Klebsiella pneumoniae carbapenemase", id: 23},
  //   {"name":"M.pn resistance A2063G", id: 24},
  //   {"name":"MERS", id: 25},
  //   {"name":"Mycoplasma pneumoniae", id: 26},
  //   {"name":"Parainfluenza Virus 1", id: 27},
  //   {"name":"Parainfluenza Virus 2", id: 28},
  //   {"name":"Parainfluenza Virus 3", id: 29},
  //   {"name":"Parainfluenza Virus 4", id: 30},
  //   {"name":"Pseudomonas aeruginosa", id: 31},
  //   {"name":"Respiratory Syncytial Virus A, Respiratory Syncytial Virus B", id: 32},
  //   {"name":"Respiratory Syncytial Virus A", id: 33},
  //   {"name":"Respiratory Syncytial Virus B", id: 34},
  //   {"name":"Rhinovirus", id: 35},
  //   {"name":"SCCmec", id: 36},
  // ];

  // public selectPanel = (row) => {
  //   console.log(row.barcode);
  // }

  // public panelRule = (v: string) => {
  //   if (v === undefined) return true;
  //   if (!v.trim()) return true;
  //   const parsedVal = parseFloat(v)
  //   if (!isNaN(parsedVal) && parsedVal >= 1 && parsedVal <= 99) return true;
  //   return 'Panel has to be between 1 and 99';
  // }

  // public replicateRule = (v: string) => {
  //   if (v === undefined) return true;
  //   if (!v.trim()) return true;
  //   const parsedVal = parseFloat(v)
  //   if (!isNaN(parsedVal) && parsedVal >= 1 && parsedVal <= 999) return true;
  //   return 'Replicate count has to be between 1 and 99';
  // }

  public rowClicked(row, slot) {
    if( this.selectedSamples.length > 0 && this.selectedSamples[0] == row ) {
      this.selectedSamples = [];
    } else {
      this.mode = 'Edit';
      this.selectedSamples = [row];
      this.sampleRef = this.selectedSamples[0].ref;
      this.sampleSource = this.selectedSamples[0].source;
      this.sampleType = this.selectedSamples[0].type;
      this.sampleMedia = this.selectedSamples[0].media;
      this.sampleCollectionDate = this.selectedSamples[0].collection_date;
      this.subjectRef = this.selectedSamples[0].subject_ref;
      this.subjectAge = this.selectedSamples[0].subject_age;
      this.subjectSex = this.selectedSamples[0].subject_sex;
    }
  }

  public addSample() {
    this.mode = this.hasAdminAccess ? 'Create' : 'View';
    const sample: ISample = {
      id: 0,    // 0 indicates potientially new sample
      ref: '',
      source: null,
      type: null,
      media: null,
      collection_date: null,
      subject_ref: null,
      subject_age: null,
      subject_sex: null,
      comparator_results: null,
      run_tracker: null,
      created_on: null,
      created_by_id: null,
      modified_by_id: null,
    };
    this.sampleRef = '';
    this.sampleSource = null;
    this.sampleType = null;
    this.sampleMedia = null;
    this.sampleCollectionDate = null;
    this.subjectRef = null;
    this.subjectAge = null;
    this.subjectSex = null;
    this.errorMessage = '';
    this.selectedSamples = [sample];
  }

  public deleteSample() {
    api.deleteSample(readToken(this.$store), this.selectedSamples[0].id)
    .then(response => {
      this.updateList()
    });
  }

  public close() {
    this.selectedSamples = [];
    this.errorMessage = '';
  }

  public reset() {
    this.$validator.reset();
    this.sampleRef = '';
    this.sampleSource = null;
    this.sampleType = null;
    this.sampleMedia = null;
    this.sampleCollectionDate = null;
    this.subjectRef= null;
    this.subjectAge= null;
    this.subjectSex= null;
    this.errorMessage = '';
  }

  public async submit(event) {
    this.errorMessage = '';
    if (await this.$validator.validateAll()) {
      this.selectedSamples[0].ref = this.sampleRef.trim();
      this.selectedSamples[0].source = this.sampleSource?.trim() || null;
      this.selectedSamples[0].type = this.sampleType?.trim() || null;
      this.selectedSamples[0].media = this.sampleMedia?.trim() || null;
      this.selectedSamples[0].collection_date = this.sampleCollectionDate;
      this.selectedSamples[0].subject_ref = this.subjectRef?.trim() || null;
      this.selectedSamples[0].subject_age = this.subjectAge;
      this.selectedSamples[0].subject_sex = this.subjectSex;
      if( this.selectedSamples[0].id == 0 ) {    // if id is not set, then it is a new sample
        api.createSample(readToken(this.$store), this.selectedSamples[0])
          .then((response) => {
            this.updateList();
          })
          .catch((error) => {
            if( error.response && error.response.data.detail ) {
              this.errorMessage = error.response.data.detail;
            } else {
              this.errorMessage = `${error.message}: ${error.response.statusText}`;
            }
          });
      } else {
        api.updateSample(readToken(this.$store), this.selectedSamples[0].id, this.selectedSamples[0])
          .then((response) => {
            this.updateList();
          })
          .catch((error) => {
            if( error.response && error.response.data.detail ) {
              this.errorMessage = error.response.data.detail;
            } else {
              this.errorMessage = `${error.message}: ${error.response.statusText}`;
            }
          });
      }
    }
  }

  // send a message to the child to update
  public updateList() {
      this.selectedSamples = []
      this.message = 'update'+Date.now()
  }

  public async mounted() {
    const ref = this.$router.currentRoute.params.ref;
    this.mode = 'View';
    if( ref ) {
      this.selectedFilters.push(
          { field: 'ref',
            table: undefined,
            operator: 'is',
            value: ref },
        );
    }

    // this.callouts = await readCallouts();

  }

}
</script>
