<template>
  <v-container fluid>

    <v-card v-if="probe"  class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{probe.panel_name}}</div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Panel Name:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.panel_name}}</div></v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Order Name:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.order_name}}</div></v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Panel Association:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.panel_association}}</div></v-col>
              </v-row>
            </v-col>

            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Function:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.function}}</div></v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Callout:</div></v-col>
                <v-col cols="6">
                  <div class="font-weight-bold">
                    <router-link
                      v-if="probe.callout_id"
                      :to="{ name: 'biochip-callouts-detail', params: { id: probe.callout_id }}">
                        {{ probe.callout ? probe.callout.name : "" }}
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Gene Region:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.gene_region}}</div></v-col>
              </v-row>
            </v-col>

            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Probe Sequence:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.probe_sequence}}</div></v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">Probe Type:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probeType(probe.probe_type)}}</div></v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="6" lg="4">
              <v-row>
                <v-col cols="6"><div class="text-right">WT Group:</div></v-col>
                <v-col cols="6">
                  <div class="font-weight-bold">
                    <router-link
                      v-if="probe.wild_type_probe_id"
                      :to="{ name: 'biochip-probe-detail', params: { id: probe.wild_type_probe_id }}">
                        {{ wtGroup }}
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col sm="6" md="3" lg="2"><div class="text-right">Probe Description:</div></v-col>
                <v-col sm="6" md="9" lg="10"><div class="font-weight-bold">{{probe.probe_description}}</div></v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col sm="6" md="3" lg="2"><div class="text-right">Date Added:</div></v-col>
                <v-col cols="6"><div class="font-weight-bold">{{probe.date_added}}</div></v-col>
              </v-row>
            </v-col>

          </v-row>
        </div>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IProbe } from '@/interfaces/bcdb';
import { EProbeType, EProbeFunction } from '@/interfaces/bcdb';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component
export default class ViewProbe extends Vue {
  public probe: IProbe = {} as IProbe;
  public wtGroup = "";

  public loadProbe() {
    api.getProbe(readToken(this.$store), +this.$router.currentRoute.params.id).then((response) => {
      this.probe = response.data;
      this.loadWTGroup()
    });
  }

  public mounted() {
    this.loadProbe();
  }

  public cancel() {
    this.$router.back();
  }

  public probeType(type) {
    return( EProbeType[type]?.capitalize() );
  }
  public loadWTGroup() {
    if( this.probe?.probe_type == EProbeType.mutant && this.probe.wild_type_probe_id ) {
      api.getProbe(readToken(this.$store), this.probe.wild_type_probe_id).then((response) => {
        this.wtGroup = response.data.panel_name
      });
    }
  }

}


</script>


  