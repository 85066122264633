import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { ICalloutRule, ICalloutRuleCreate
} from '@/interfaces/bcdb';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getCalloutRule(token: string, id: number) {
    return axios.get<ICalloutRule>(`${apiUrl}/api/v1/callout_rules/${id}`, authHeaders(token));
  },
  async updateCalloutRule(token: string, id: number, data: ICalloutRule) {
    return axios.put(`${apiUrl}/api/v1/callout_rules/${id}`, data, authHeaders(token));
  },
  async updateCalloutRules(token: string, data: ICalloutRule[]) {
    return axios.post(`${apiUrl}/api/v1/callout_rules/bulk`, data, authHeaders(token));
  },
  async createCalloutRules(token: string, data: ICalloutRuleCreate[]) {
    return axios.post(`${apiUrl}/api/v1/callout_rules/bulk`, data, authHeaders(token));
  },
  async createCalloutRule(token: string, data: ICalloutRuleCreate) {
    return axios.post(`${apiUrl}/api/v1/callout_rules/`, data, authHeaders(token));
  },
  async deleteCalloutRule(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/callout_rules/${id}`, authHeaders(token));
  },
};
