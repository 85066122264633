<template>
    <!-- TODO Need to decide what filters are needed -->
    <v-container fluid>
        <v-expansion-panels v-model="expansionPanel">
            <v-expansion-panel>
            <v-expansion-panel-header ref="expansionPanel" v-slot="{ open }">
                <v-row no-gutters :align="'center'" style="justify-content: space-evenly">
                    <v-col cols="4">{{ selectedProduct.name }} Data Filters</v-col>
                    <v-col cols="4" class="text--secondary">
                        <v-fade-transition leave-absolute>
                            <span v-if="!open"> Date Range: {{ filters.dateRange.join('-') }}</span>
                        </v-fade-transition>
                    </v-col>
                    <v-col cols="4" class="text--secondary">
                        <v-fade-transition leave-absolute>
                            <v-checkbox 
                            v-model="filters.ppaNpaParams.includeReport" 
                            label="Include PPA NPA Report" 
                            disabled
                            v-if="!open"></v-checkbox>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <!-- Filters for querying run trackers -->
                <v-row :align="'center'" style="justify-content: center" no-gutters>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="selectedProduct"
                            :items="internalProducts"
                            label="Product"
                            item-text="name"
                            disabled
                            @change=""
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-menu
                            ref="dateMenu"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="unSubmittedFilters.dateRange"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="unSubmittedFilters.dateRange"
                                small
                                label="Selected Date Range"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="unSubmittedFilters.dateRange"
                            no-title
                            scrollable
                            range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="dateMenu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="dateUpdated()"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-checkbox 
                            v-model="unSubmittedFilters.ppaNpaParams.includeReport" 
                            label="Include PPA NPA Report"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                        <v-row :align="'center'" no-gutters>
                            <v-col cols="7">
                                <v-checkbox 
                                    v-model="includeReRuns" 
                                    label="Include Re-Runs"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols=1 style="justify-content: left;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" class="ml-2">mdi-information-outline</v-icon>
                                    </template>
                                    <span>Include all prior runs of the same cartridge</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row :align="'center'" style="justify-content: space-evenly">
                    <v-col>
                        <v-autocomplete
                            :items="experimentTypes"
                            v-model="selectedExperimentType"
                            dense
                            multiple
                            clearable
                            style="margin-top:18px ;"
                        >
                        <v-list
                            slot="prepend-item"
                            v-if="experimentTypes.length"
                            style="padding: 0;"
                        >
                        <v-list-item 
                            ripple
                            @click="selectAll('experimentType')">
                            <v-list-item-icon>
                            <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>Select All</v-list-item-content>
                        </v-list-item>
                        </v-list>
            
                            <template #label>
                            <label style="bottom: 100px;">Experiment Type</label>
                            </template>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedExperimentType.length - 1 }} others)
                            </span>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col>
                        <v-autocomplete
                            :items="instruments"
                            v-model="selectedInstruments"
                            dense
                            multiple
                            clearable
                            style="margin-top:18px;"
                            item-text="rig"
                            return-object
                        >
                            <v-list
                            slot="prepend-item"
                            v-if="instruments.length"
                            style="padding: 0;"
                            >
                            <v-list-item 
                                ripple
                                @click="selectAll('instruments')">
                                <v-list-item-icon>
                                <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>Select All</v-list-item-content>
                            </v-list-item>
                            </v-list>
            
                            <template #label>
                            <label style="bottom: 100px;">Instrument</label>
                            </template>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedInstruments.length - 1 }} others)
                            </span>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col>
                        <v-autocomplete
                            :items="adfs"
                            v-model="selectedAdfs"
                            dense
                            multiple
                            clearable
                            style="margin-top:18px;"
                        >
                            <v-list
                            slot="prepend-item"
                            v-if="adfs.length"
                            style="padding: 0;"
                            >
                            <v-list-item 
                                ripple
                                @click="selectAll('adfName')">
                                <v-list-item-icon>
                                <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>Select All</v-list-item-content>
                            </v-list-item>
                            </v-list>
            
                            <template #label>
                            <label style="bottom: 100px;">ADF Name</label>
                            </template>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <div class="d-flex align-center">
                            <v-chip small v-if="index === 0" class="d-flex align-center">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedAdfs.length - 1 }} others)
                            </span>
                            </div>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="2">
                        <v-autocomplete
                            :items="adfVersions"
                            v-model="selectedAdfVersions"
                            dense
                            multiple
                            clearable
                            style="margin-top:18px;"
                        >
                            <v-list
                            slot="prepend-item"
                            v-if="adfVersions.length"
                            style="padding: 0;"
                            >
                            <v-list-item 
                                ripple
                                @click="selectAll('adfVersion')">
                                <v-list-item-icon>
                                <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>Select All</v-list-item-content>
                            </v-list-item>
                            </v-list>
            
                            <template #label>
                            <label style="bottom: 100px;">ADF Version</label>
                            </template>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <div class="d-flex align-center">
                            <v-chip small v-if="index === 0" class="d-flex align-center">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedAdfVersions.length - 1 }} others)
                            </span>
                            </div>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    
                </v-row>

                <!-- Filters for modifying ppa npa report -->
                <v-row :align="'center'" class="py-4"><v-divider></v-divider></v-row>
                <v-row v-if="unSubmittedFilters.ppaNpaParams.includeReport">PPA and NPA Settings</v-row>
                <v-row :align="'center'" style="justify-content: space-evenly" v-if="unSubmittedFilters.ppaNpaParams.includeReport">
                    <v-col cols="2">
                        <v-select
                            :items="groups"
                            v-model="selectedGroup"
                            label="Group By"
                            dense
                            disabled
                            class=''>
                        </v-select>
                    </v-col>

                    <v-col>
                        <v-autocomplete
                            :items="callouts"
                            v-model="selectedCallouts"
                            label="Callouts"
                            item-text='name'
                            dense
                            multiple
                            clearable
                            class=''
                            return-object
                            :disabled="!unSubmittedFilters.ppaNpaParams.includeReport"
                        >
                            <v-list
                                slot="prepend-item"
                                v-if="callouts.length"
                                style="padding: 0;"
                            >
                                <v-list-item 
                                ripple
                                @click="selectAll('callouts')">
                                <v-list-item-icon>
                                    <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>Select All</v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedCallouts.length - 1 }} others)
                            </span>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col>
                        <v-autocomplete
                            :items="comparators"
                            v-model="selectedComparators"
                            label="Comparators"
                            item-text='name'
                            dense
                            multiple
                            clearable
                            class=''
                            return-object
                            :disabled="!unSubmittedFilters.ppaNpaParams.includeReport"
                        >
                            <v-list
                                slot="prepend-item"
                                v-if="comparators.length"
                                style="padding: 0;"
                                >
                                <v-list-item 
                                    ripple
                                    @click="selectAll('comparators')">
                                    <v-list-item-icon>
                                    <v-icon :color="'indigo darken-4'">mdi-check-all</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>Select All</v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <template v-slot:selection="{ attrs, parent, item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedComparators.length - 1 }} others)
                            </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row :align="'center'" v-if="unSubmittedFilters.ppaNpaParams.includeReport">
                    <v-col cols="5">
                        <v-autocomplete
                            v-model="selectedCompositeRules"
                            :items="compositeRules"
                            label="Rules"
                            item-text="name"
                            clearable
                            chips
                            multiple
                            return-object
                            :disabled="!unSubmittedFilters.ppaNpaParams.includeReport"
                        >
                            <template v-slot:selection="data">
                            <v-chip v-bind="data.attrs"
                                :input-value="data.selected"
                                small
                                close
                                @click="data.select"
                                @click:close="removeRule(data.item)">
                                {{ data.item.name }}
                                <v-icon v-if="data.item.share_status === 'LOCKED'">mdi-lock-outline</v-icon>
                                <v-icon v-if="currentUserID === data.item.created_by_id">mdi-account-outline</v-icon>
                            </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <template v-if=" data.item.share_status === 'LOCKED'">
                                <v-list-item-content>
                                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon>mdi-lock-outline</v-icon>
                                </v-list-item-icon>
                                </template>
                                <template v-else-if="currentUserID === data.item.created_by_id">
                                <v-list-item-content>
                                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-outline</v-icon>
                                </v-list-item-icon>
                                </template>
                                <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="2">
                        <v-switch v-model="includePublicRules" label="Include public rules" :disabled="!unSubmittedFilters.ppaNpaParams.includeReport" @change="fetchComparisonRules()"></v-switch>
                    </v-col>
                </v-row>
                
                <v-row :align="'center'" style="padding-bottom: 2em; justify-content: center">
                    <v-btn
                        color="primary"
                        :disabled="false"
                        :loading="false"
                        @click="submitFilters()">
                        Submit
                    </v-btn>
                </v-row>

                <!-- Rule Modifications for Composite -->
                <v-expansion-panels v-if="unSubmittedFilters.ppaNpaParams.includeReport">
                    <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{ open }">
                        Composite and Reflex Rules Definition
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <CompositeReflexRules
                            :selectedProduct="'ERP'"
                            @compositeRuleUpdate="fetchComparisonRules()"
                        ></CompositeReflexRules>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script lang="ts">
  import CompositeReflexRules from '@/components/clinical/CompositeReflexRules.vue';
  import { Component, Vue, ModelSync } from 'vue-property-decorator';
  import { Store } from 'vuex';
  import { readUserProfile, readToken } from '@/store/main/getters';
  import { api } from '@/api';
  import { IClinicalRuntracker, IProduct, IInstrument, ICallout, IProductComparisonRule } from '@/interfaces/bcdb';
  import { IFilter } from '@/interfaces/index';
  import {getUniqueField, alphabetically, deepClone} from '@/scripts/support-functions';
  import { samples } from '@/scripts/constants'
  
  import { toDateWithOptions } from 'date-fns-tz/fp';
//   import { FunctionalComponentMountOptions } from '@vue/test-utils';
  
  
  @Component({
    components: {
        CompositeReflexRules,},
  })
  export default class PreclinicalFilters extends Vue {
    @ModelSync('value', 'change', { type: Object }) public filters!: {dateRange:string[] , filters:IFilter[], ppaNpaParams:{
        includeReport:boolean, callouts:number[], comparators:string[], rules:string[]}}
    public unSubmittedFilters:{dateRange:string[] , filters:IFilter[], ppaNpaParams:{
        includeReport:boolean, callouts:number[], comparators:string[], rules:string[]}} = {dateRange:[] , filters:[], ppaNpaParams:{
            includeReport:true, callouts:[], comparators:[], rules:[]}}
    
    public expansionPanel = []
    public startup = true
    public defaultDateRange = 7
    public defaultFilters: IFilter[] = [
        {field: 'experiment_type', table: 'run_tracker', operator: 'in', value: samples['Combined'].types}
    ]
    public dateMenu = false

    public currentUserID = -1

    public defaultProduct = 'ERP'
    public selectedProduct:IProduct = {} as IProduct
    public internalProducts: IProduct[] = []

    public selectedAdfs:string[] = []
    public adfs:string[] = []
    public selectedAdfVersions:string[] = []
    public adfVersions:string[] = []

    public selectedInstruments:string[] = []
    public instruments:string[] = []

    public selectedExperimentType: string[] = []
    public experimentTypes: string[] = []

    public selectedCallouts:ICallout[] = []
    public callouts:ICallout[] = []

    public selectedComparators:IProduct[] = []
    public comparators:IProduct[] = []

    public groups = ['Callout']
    public selectedGroup = 'Callout'

    public selectedCompositeRules:IProductComparisonRule[] = []
    public compositeRules:IProductComparisonRule[] = []
    public includePublicRules: boolean = false

    public includeReRuns = false

    get greetedUser() {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
            return userProfile.id;
        }
        return -1
    }

    public async selectAll(filter:string){
        switch(filter){
            case 'experimentType':{
                this.selectedExperimentType = this.experimentTypes
                break
            }
            case 'instruments':{
                this.selectedInstruments = this.instruments
                break
            }
            case 'adfName':{
                this.selectedAdfs = this.adfs
                break
            }
            case 'adfVersion':{
                this.selectedAdfVersions = this.adfVersions
                break
            }
            case 'callouts':{
                this.selectedCallouts = this.callouts
                break
            }
            case 'comparators':{
                this.selectedComparators = this.comparators
                break
            }
            default: {
                console.log(`Invalid filter: ${filter}`)
            }
        }
        this.$forceUpdate()
    }

    public removeRule (item) {
        console.log(item)
        const index = this.selectedCompositeRules.indexOf(item)
        if (index >= 0) this.selectedCompositeRules.splice(index, 1)
    }

    public determineSelections(currentSelections:any[], currentItems:any[]):boolean{
        return(currentSelections.length === currentItems.length || currentSelections.length===0)
    }

    public removeSubset(currentSelections:any[], currentItems:any[]):any[]{
        currentSelections = currentSelections.filter((c)=> currentItems.includes(c))
        return(currentSelections)
    }

    public async submitFilters(){
        this.unSubmittedFilters.filters = []
        const filterKeyValues = {experimentType:{table:'run_tracker', values:this.selectedExperimentType, field:'experiment_type',operator:'in', needed:true}, 
            rig:{table:'instrument', values:this.selectedInstruments, field:'rig',operator:'in', needed:this.selectedInstruments.length!==this.instruments.length},
            adfName:{table:'assay', values:this.selectedAdfs, field:'name',operator:'in', needed:this.selectedAdfs.length!==this.adfs.length},
            adfVersion:{table:'assay', values:this.selectedAdfVersions, field:'assay_version',operator:'in', needed:this.selectedAdfVersions.length!==this.adfVersions.length},
            includeReRuns:{table:'run_tracker', values:null, field:'parent_run_id',operator:'is', needed:!this.includeReRuns},
        }
        Object.keys(filterKeyValues).forEach((k) =>{
            if( filterKeyValues[k].needed ){
                const isZeroLen = filterKeyValues[k].values===null || filterKeyValues[k].values.length===0

                this.unSubmittedFilters.filters.push({field:filterKeyValues[k].field,
                    table:filterKeyValues[k].table,
                    operator:isZeroLen? 'is': filterKeyValues[k].operator, 
                    value:isZeroLen? null: filterKeyValues[k].values})
            }
        })
        this.unSubmittedFilters.ppaNpaParams.callouts = this.selectedCallouts.map((c)=>c.id)
        this.unSubmittedFilters.ppaNpaParams.comparators = this.selectedComparators.map((c)=>c.name)
        this.unSubmittedFilters.ppaNpaParams.rules = this.selectedCompositeRules.map((c)=>c.name)
        
        this.filters = deepClone(this.unSubmittedFilters)
        this.expansionPanel = []
    }

    public buildDateFilter():IFilter{
        let value:string|string[] = []
        let operator:string = ''
        if( this.unSubmittedFilters.dateRange.length==1 || this.unSubmittedFilters.dateRange[0] === this.unSubmittedFilters.dateRange[1] ){
            operator = "is"
            value = this.unSubmittedFilters.dateRange[0]
        } else {
            operator = "between"
            value = this.unSubmittedFilters.dateRange.join(',')
        }
        const dateFilter:IFilter = {field:'date_of_run', table:'run_tracker',operator:operator,value:value}
        return(dateFilter)
    }

    public async dateUpdated() {
        if (this.$refs.dateMenu !== undefined){
            const saveButton = this.$refs.dateMenu as any
            saveButton.save(this.unSubmittedFilters.dateRange)
        }
        //Call apis that need to be fixed as apart of the data update
        this.dateMenu=false
        const dateFilter:IFilter[] = this.defaultFilters.concat([this.buildDateFilter()])

        //Query ADFS
        const selectAllAdfs = this.determineSelections(this.selectedAdfs, this.adfs)
        const payloadAdf={field:'name', skip:0, limit:1000, f:'', filters:dateFilter}
        await api.getAssayFields(readToken(this.$store),payloadAdf).then((response) => {
            this.adfs = response.data.map((r)=>r.text)
            this.selectedAdfs = selectAllAdfs? this.adfs:this.selectedAdfs
            if( !selectAllAdfs ){
                this.selectedAdfs = this.removeSubset(this.selectedAdfs, this.adfs)
            }
        }).catch((error) => {
            console.log(error)
            this.adfs = []
            this.selectedAdfs = []
        });
        const selectAllAdfsVersion = this.determineSelections(this.selectedAdfVersions, this.adfs)
        const payloadAdfVersions={field:'assay_version', skip:0, limit:1000, f:'', filters:dateFilter}
        await api.getAssayFields(readToken(this.$store),payloadAdfVersions).then((response) => {
            this.adfVersions = response.data.map((r)=>r.text)
            this.selectedAdfVersions = selectAllAdfsVersion? this.adfVersions:this.selectedAdfVersions
            if( !selectAllAdfsVersion ){
                this.selectedAdfVersions = this.removeSubset(this.selectedAdfVersions, this.adfVersions)
            }
        }).catch((error) => {
            console.log(error)
            this.adfVersions = []
            this.selectedAdfVersions = []
        });

        //Query Instruments
        const selectAllInstruments = this.determineSelections(this.selectedExperimentType, this.experimentTypes)
        const payloadInst={field:'rig', skip:0, limit:1000, f:'', filters:dateFilter}
        await api.getInstrumentFields(readToken(this.$store),payloadInst).then((response) => {
            //TODO assign data and choose wether to select all or not
            this.instruments = response.data.map((r)=>r.text)
            this.selectedInstruments = selectAllInstruments? this.instruments:this.selectedInstruments
            if( !selectAllInstruments ){
                this.selectedInstruments = this.removeSubset(this.selectedInstruments, this.instruments)
            }
        }).catch((error) => {
            console.log(error)
            this.instruments = []
            this.selectedInstruments = []
        });
        //Query Experiment types
        const selectAllExpTypes = this.determineSelections(this.selectedExperimentType, this.experimentTypes)
        const payload={field:'experiment_type', skip:0, limit:1000, f:'', filters:dateFilter}
        await api.getRunFields(readToken(this.$store),payload).then((response) => {
            this.experimentTypes = response.data.map((r)=>r.text)
            this.selectedExperimentType = selectAllExpTypes? this.experimentTypes:this.selectedExperimentType
            if( !selectAllExpTypes ){
                this.selectedExperimentType = this.removeSubset(this.selectedExperimentType, this.experimentTypes)
            }
        }).catch((error) => {
            console.log(error)
            this.experimentTypes = []
            this.selectedExperimentType = []
        });
    }

    public async productUpdated(){
        //Query Product Callouts
        const selectAllCallouts = this.determineSelections(this.selectedCallouts, this.callouts)
        await api.getCalloutMapByProduct(readToken(this.$store), this.selectedProduct.name).then((response) => {
            this.callouts = response.data
            this.selectedCallouts = selectAllCallouts? this.callouts:this.selectedCallouts
            if( !selectAllCallouts ){
                this.selectedCallouts = this.removeSubset(this.selectedCallouts, this.callouts)
            }
        }).catch((error) => {
            console.log(error)
            this.callouts = []
            this.selectedCallouts = []
        });

        //TODO Query External Products: We may need to link external products to a internal product in the future
        const selectAllComparators = this.determineSelections(this.selectedComparators, this.comparators)
        await api.getInternalProducts(readToken(this.$store), false).then((response) => {
            this.comparators = response.data
            this.selectedComparators = selectAllComparators? this.comparators:this.selectedComparators
            if( !selectAllComparators ){
                this.selectedCallouts = this.removeSubset(this.selectedComparators, this.comparators)
            }
        }).catch((error) => {
            console.log(error)
            this.comparators = []
            this.selectedComparators = []
        });
    }

    public async fetchComparisonRules() {
        //Get rules for select product
        await api.getDistinctProductComparisonRules(readToken(this.$store), this.selectedProduct.name, this.includePublicRules).then((response) => {
            this.compositeRules = response.data
            const selectedIds = this.selectedCompositeRules.map((s)=>s.id)
            this.selectedCompositeRules = this.compositeRules.filter((c)=>selectedIds.includes(c.id))
        }).catch((error) => {
            console.log(error)
            this.compositeRules = []
        });
    }

    public async mounted(){
        if( this.filters.dateRange.length === 0 ){
            const currentDay: Date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
            const pastWeek: Date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
            this.filters.dateRange = [new Date(pastWeek.setDate(pastWeek.getDate()-this.defaultDateRange)).toISOString().substr(0, 10), currentDay.toISOString().substr(0, 10)]
        }
        this.unSubmittedFilters = deepClone(this.filters)

        await api.getInternalProducts(readToken(this.$store), true).then((response) => {
            this.internalProducts = response.data
            const foundProduct =this.internalProducts.find((p)=>p.name===this.defaultProduct)
            if( foundProduct ){
                this.selectedProduct = foundProduct
            }
        }).catch((error) => {
            console.log(error)
            this.internalProducts = []
        });

        await this.fetchComparisonRules()
        this.selectedCompositeRules = this.compositeRules.filter((r)=>r.share_status==='LOCKED')

        this.currentUserID = this.greetedUser

        await this.productUpdated()
        await this.dateUpdated()
        this.submitFilters()
    }
  }
</script>
  
<style scoped>

</style>