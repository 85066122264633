import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';

// import './plugins/bootstrap-vue';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';

import './plugins/socketio';

import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';

import 'vuetify/dist/vuetify.min.css';

import eventBus from './eventBus';

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
