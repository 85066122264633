import axios from 'axios';
import { apiUrl } from '@/env';
import { IAutocompleteItem, IFilter,
} from '@/interfaces';
import { ISignal, ISignalWithProbe, IAmpfea, IAmpfeaWithProbe, IAmpfeaStats, IProbe,
         IRunTracker, IArrayTemplate,
         IStaticThermalAnalysis, IThermalSignals, IThermalStats, ITransitionAnalysis,
         IOpticalSignals, IRuleSet, IProbeRuleCreate, IProbeResults, IPanelRuleCreate, ICalloutRuleCreate, ISample, INXXCalloutResults,
         IRunErrors, ICallout,
         IRunTrackerExtended,
         IClinicalRuntracker
} from '@/interfaces/bcdb';
import { ITrace
} from '@/interfaces/index';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getRun(token: string, runId: number) {
    return axios.get<IRunTracker>(`${apiUrl}/api/v1/runs/${runId}`, authHeaders(token));
  },
  async getRuns(token: string) {
    return axios.get<IRunTracker[]>(`${apiUrl}/api/v1/runs/`, authHeaders(token));
  },
  async getRunNames(token: string) {
    return axios.get<object[]>(`${apiUrl}/api/v1/runs/names`, authHeaders(token));
  },
  async searchRuns(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    return axios.post<IRunTrackerExtended[]>(`${apiUrl}/api/v1/runs/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getRunsCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/runs/stats`, filters, authHeaders(token));
  },
  async getRunFields(token: string, payload: {field: string, skip: number, limit: number, f: string, filters:IFilter[]}) {
    return axios.post<IAutocompleteItem[]>(`${apiUrl}/api/v1/runs/field`, payload, authHeaders(token));
  },
  async getLatestRuns(token: string, daysAgo: number = 1) {
    return axios.get<IRunTracker[]>(`${apiUrl}/api/v1/runs/latest?days=${daysAgo}`, authHeaders(token));
  },
  async postRunsFromRange(token: string, daterange:string[], filters:IFilter[]=[] ) {
    //TODO: make input a date query not a range
    const dateQuery= {dates_in:daterange, return_obj: 'RunTracker', filters:filters}
    return axios.post<IRunTracker[]>(`${apiUrl}/api/v1/runs/runsFromRange`, dateQuery, authHeaders(token));
  },
  async postRunsFromRangeClinical(token: string, daterange:string[], filters:IFilter[]=[] ) {
    const dateQuery= {dates_in:daterange, return_obj: 'ClinicalRunTracker', filters:filters}
    return axios.post<IClinicalRuntracker[]>(`${apiUrl}/api/v1/runs/runsFromRange`, dateQuery, authHeaders(token));
  },

  async getRunSummary(token: string, payload: {
    runs: number[], format: string,
    groupBy: string,
    extraData?: object[],
  }) {
    // console.log(payload);
    const runs = payload.runs.join('&t=');
    const { headers } = authHeaders(token);
    let responseFormat;

    const data = {
       extra_data: payload.extraData || [],
    };



    if (payload.format === 'EXCEL') {
      responseFormat = 'blob';
    }
    const responseType = responseFormat || 'text';
    return axios.post(
      `${apiUrl}/api/v1/runs/summary?out_format=${payload.format}&t=${runs}&group_by=${payload.groupBy}`,
      data,
      {responseType, headers},
    );
  },
  async getRunSignals(token: string, runId: number) {
    return axios.get<ISignal[]>(`${apiUrl}/api/v1/runs/${runId}/signals`, authHeaders(token));
  },
  async getRunSignalsBySourceVersion(token: string, runId: number, source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<ISignal[]>(`${apiUrl}/api/v1/runs/${runId}/signals_source_version${appended_vars}`, authHeaders(token));
  },
  async getRunSignalsWithProbe(token: string, runId: number) {
    return axios.get<ISignalWithProbe[]>(`${apiUrl}/api/v1/runs/${runId}/signals?includeProbe=true`, authHeaders(token));
  },
  async getRunSignalsWithProbeBySourceVersion(token: string, runId: number, source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `&source=${source}` : ''
    appended_vars += version!==null ? `&version=${version}` : ''
    return axios.get<ISignalWithProbe[]>(`${apiUrl}/api/v1/runs/${runId}/signals_source_version?includeProbe=true${appended_vars}`, authHeaders(token));
  },
  async getRunAmpfeas(token: string, runId: number) {
    return axios.get<IAmpfea[]>(`${apiUrl}/api/v1/runs/${runId}/ampfeas`, authHeaders(token));
  },
  async getRunAmpfeasBySourceVersion(token: string, runId: number, source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<IAmpfea[]>(`${apiUrl}/api/v1/runs/${runId}/ampfeas_source_version${appended_vars}`, authHeaders(token));
  },
  async getRunAmpfeasWithProbe(token: string, runId: number) {
    return axios.get<IAmpfeaWithProbe[]>(`${apiUrl}/api/v1/runs/${runId}/ampfeas?includeProbe=true`, authHeaders(token));
  },
  async getRunAmpfeasWithProbeBySourceVersion(token: string, runId: number, source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `&source=${source}` : ''
    appended_vars += version!==null ? `&version=${version}` : ''
    return axios.get<IAmpfeaWithProbe[]>(`${apiUrl}/api/v1/runs/${runId}/ampfeas_source_version?includeProbe=true${appended_vars}`, authHeaders(token));
  },
  async getRunsAmpfeas(token: string, runIds: number[] = []) {
    return axios.post<IAmpfea[]>(`${apiUrl}/api/v1/runs/ampfeas`, runIds, authHeaders(token));
  },
  async getRunsAmpfeasBySourceVersion(token: string, runIds: number[] = [], source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.post<IAmpfea[]>(`${apiUrl}/api/v1/runs/ampfeas_source_version${appended_vars}`, runIds, authHeaders(token));
  },
  async getRunAmpfeaStats(token: string, runId: number) {
    return axios.get<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/${runId}/ampfea_stats`, authHeaders(token));
  },
  async getRunAmpfeaStatsBySourceVersion(token: string, runId: number, source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/${runId}/ampfea_stats_source_version${appended_vars}`, authHeaders(token));
  },
  async getRunsAmpfeaStats(token: string, runIds: number[] = []) {
    return axios.post<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/ampfea_stats`, runIds, authHeaders(token));
  },
  async getRunsAmpfeaStatsBySourceVersion(token: string, runIds: number[] = [], source: string|null = null, version: string|null = null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.post<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/ampfea_stats_source_version${appended_vars}`, runIds, authHeaders(token));
  },
  async getRunsProbesMetricsAmpfeaStats(token: string, params: { runIds: number[], probeIds: number[], metrics: string[] }) {
    return axios.post<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/filtered_ampfea_stats`, params, authHeaders(token));
  },
  async getRunsProbesMetricsAmpfeaStatsBySourceVersion(token: string, params: { runIds:number[], probeIds:number[], metrics:string[], source:string|null, version:string|null }) {
    return axios.post<IAmpfeaStats[]>(`${apiUrl}/api/v1/runs/filtered_ampfea_stats_by_version_source`, params, authHeaders(token));
  },
  async getRunStaticThermalAnalyses(token: string, runId: number) {
    return axios.get<IStaticThermalAnalysis[]>(`${apiUrl}/api/v1/runs/${runId}/static_thermal_analyses`,
                                               authHeaders(token));
  },
  async getRunStaticThermalAnalysesVersionSource(token: string, runId: number, version: string|null=null, source:string|null=null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<IThermalStats[]>(`${apiUrl}/api/v1/runs/${runId}/static_thermal_analysis_version_source${appended_vars}`,authHeaders(token));
  },
  async getRunThermalSignals(token: string, runId: number) {
    return axios.get<IThermalSignals>(`${apiUrl}/api/v1/runs/${runId}/thermal_signals`, authHeaders(token));
  },
  async getRunOpticalSignals(token: string, runId: number) {
    return axios.get<IOpticalSignals[]>(`${apiUrl}/api/v1/runs/${runId}/optical_signals`, authHeaders(token));
  },
  async getRunThermalStats(token: string, runId: number) {
    return axios.get<IThermalStats[]>(`${apiUrl}/api/v1/runs/${runId}/thermal_stats`, authHeaders(token));
  },
  async getRunThermalStatsVersionSource(token: string, runId: number, version: string|null=null, source:string|null=null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<IThermalStats[]>(`${apiUrl}/api/v1/runs/${runId}/thermal_stats_version_source${appended_vars}`,authHeaders(token));
  },
  async getRunTransitionAnalyses(token: string, runId: number) {
    return axios.get<ITransitionAnalysis[]>(`${apiUrl}/api/v1/runs/${runId}/transition_analyses`, authHeaders(token));
  },
  async getRunTransitionAnalysesVersionSource(token: string, runId: number, version: string|null=null, source:string|null=null) {
    let appended_vars = ''
    appended_vars += source!==null ? `?source=${source}` : ''
    if(version!==null){
      appended_vars += appended_vars == '' ? '?' : '&'
      appended_vars += `version=${version}`
    }
    return axios.get<ITransitionAnalysis[]>(`${apiUrl}/api/v1/runs/${runId}/transition_analysess_version_source${appended_vars}`,authHeaders(token));
  },
  async getRunArrayTemplate(token: string, runId: number) {
    return axios.get<IArrayTemplate>(`${apiUrl}/api/v1/runs/${runId}/array_template`, authHeaders(token));
  },
  async queueDataReduction(token: string, params: { runIds: number[], ruleSet: IRuleSet, rules: IProbeRuleCreate[], panelRules: IPanelRuleCreate[], calloutRules: ICalloutRuleCreate[], comparators: string[] }) {
    return axios.post<number>(`${apiUrl}/api/v1/runs/reduce`, 
                              { run_ids: params.runIds, rule_set: params.ruleSet, rules: params.rules, panelRules: params.panelRules, calloutRules: params.calloutRules, comparators: params.comparators },
                              authHeaders(token));
  },
  async queueRuleApplication(token: string, params: { runIds: number[], rules: IProbeRuleCreate[], reducedData: IProbeResults[] }) {
    return axios.post<number>(`${apiUrl}/api/v1/runs/applyRules`, 
                              { run_ids: params.runIds, rules: params.rules, reducedData: params.reducedData },
                              authHeaders(token));
  },
  async getCalloutResults(token: string, runIds: number[] = []) {
    return axios.post<INXXCalloutResults[]>(`${apiUrl}/api/v1/runs/run_callout_results`, runIds, authHeaders(token));
  },
  async getRunErrors(token: string, runIds: number[] = []) {
    return axios.post<IRunErrors[]>(`${apiUrl}/api/v1/runs/run_errors`, runIds, authHeaders(token));
  },
  async getUniqueCallouts(token: string, runIds: number[] = []) {
    return axios.post<ICallout[]>(`${apiUrl}/api/v1/runs/run_unique_callouts`, runIds, authHeaders(token));
  },
  async getCalloutRunsEqual(token: string, params:{ run_ids: number[], callout_id: number, result_str: string} ) {
    return axios.post<number[]>(`${apiUrl}/api/v1/runs/run_callout_results_equal`, {run_ids_in: params.run_ids, callout_id_in: params.callout_id, result_str: params.result_str},
    authHeaders(token));
  },
  async getPpaNpa(token: string, params: {runIds:number[], calloutIds:number[], comparators:string[], composite_rules:string[], groupBy:string|undefined}) {
    return axios.post<string>(`${apiUrl}/api/v1/runs/ppa_npa_results`, params, authHeaders(token));
  },
  async getPressure(token:string, runId: number) {
    return axios.get<ITrace>(`${apiUrl}/api/v1/runs/${runId}/pressure_data`, authHeaders(token))
  },
  async getPressureCurves(token:string, runIds: number[]) {
    return axios.post<ITrace[]>(`${apiUrl}/api/v1/runs/pressure_curves`, runIds, authHeaders(token))
  },
  async updateRunDescriptions(token: string, run_id: number, data: IRunTracker) {
    return axios.put<IRunTracker>(`${apiUrl}/api/v1/runs/${run_id}`, data, authHeaders(token)); 
  },
  async emailDLL(token:string, receiverEmail, runIds: number[]) {
    return axios.post<number>(`${apiUrl}/api/v1/runs/download_dll?receiverEmail=${receiverEmail}`, runIds, authHeaders(token))
  },
};
